import React from "react";
import {
  useRoutes,
} from "react-router-dom";
import routes from "./routes";
import "./index.css";
import { ToastContainer } from "react-toastify";

function App() {
  const allRoutes = useRoutes(routes);


  return (
    <>
      <div className="`w-full">{allRoutes}</div>
      <ToastContainer  position="top-right" className={"z-[9999]"} />
    </>
  );
}

export default App;
