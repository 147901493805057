import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const authData = createApi({
  reducerPath: "authData",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAuthData: builder.query<any, void>({
      query: () => ({
        url: `auth/user`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAuthDataQuery} =
  authData;
