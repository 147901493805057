import { Button } from "components/Button";
import { Loader } from "components/Loader";
import ModalContainer from "components/Modal";
import { TextInput } from "components/common/form/Input";
import { TextArea } from "components/common/form/textArea";

function EditShipmentModal({
  data,
  open,
  setOpen,
  handleEditSave,
  editLoading,
  isLoading,
  setEditDetails,
  editDetails,
  formErrors,
  setFormErrors,
}: any) {
  return (
    <ModalContainer
      open={open}
      showCloseIcon={false}
      tailwindClassName="w-[466px]"
    >
      <div className="w-full p-8 bg-white flex flex-col  rounded-[12px] overflow-y-scroll max-h-[95vh]">
        <p className="text-lg pb-4 border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
          Edit Shipment
        </p>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <>
              <div className="mt-6">
                <TextInput
                  type="text"
                  label="Drop-off address"
                  disabled
                  value={data?.orderDetails[0]?.recipientAddress}
                  placeholder="Enter address"
                  className="bg-[#f8f8f8]"
                />
              </div>

              <div className="mt-6">
                <TextInput
                  type="text"
                  label="Recipient state"
                  isRequired
                  value={data?.orderDetails[0]?.recipientAddress}
                  disabled
                  className="bg-[#f8f8f8]"
                  placeholder="Select State"
                />
              </div>
              <div className="mt-6">
                <TextInput
                  type="text"
                  label="Recipient name"
                  isRequired
                  disabled
                  className="bg-[#f8f8f8]"
                  value={data?.orderDetails[0]?.recipientName}
                  placeholder="Enter name"
                />
              </div>
              <div className="mt-6">
                <TextInput
                  type="number"
                  onChange={(e: any) => {
                    if (e.target.value.length === 11) {
                      setFormErrors({});
                    }
                    setEditDetails((prev: any) => ({
                      ...prev,
                      recipientPhone: e.target.value,
                    }));
                  }}
                  label="Recipient phone number"
                  isRequired
                  value={editDetails.recipientPhone}
                  className="bg-[#f8f8f8]"
                  placeholder="Enter phone number"
                />
                {formErrors.recipientPhone && (
                  <span className="text-xs text-[#BA3838] font-onestMedium">
                    {formErrors.recipientPhone}
                  </span>
                )}
              </div>
              <div className="mt-6">
                <TextInput
                  type="email"
                  label="Recipient email"
                  placeholder="Enter email"
                  onChange={(e: any) =>
                    setEditDetails((prev: any) => ({
                      ...prev,
                      recipientEmail: e.target.value,
                    }))
                  }
                  className="bg-[#f8f8f8]"
                  value={editDetails.recipientEmail}
                />
              </div>

              <div className="flex flex-col md:flex-row gap-x-3">
                {data?.orderDetails?.[0]?.weight && (
                  <div className="mt-6 w-full">
                    <TextInput
                      type="text"
                      label="Item weight"
                      value={data?.orderDetails?.[0]?.weight}
                      disabled
                      className="bg-[white]"
                      placeholder="Enter weight"
                    />
                  </div>
                )}
                {data?.orderDetails?.[0]?.quantity && (
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      disabled
                      value={data?.orderDetails?.[0]?.quantity}
                      label="Quantity"
                      placeholder="Enter quantity"
                    />
                  </div>
                )}
              </div>
              {data?.orderDetails?.[0]?.additionalNote && (
                <div className="mt-6">
                  <TextArea
                    label="Item description"
                    disabled
                    value={data?.orderDetails?.[0]?.additionalNote}
                    placeholder="Write a description"
                  />
                </div>
              )}
            </>

            <div className="flex justify-between items-center pt-[30px]">
              <Button
                onClick={() => setOpen(false)}
                title="Cancel"
                disabled={editLoading}
                className="!py-[10px] !border-[#BEC0BE] !px-6 !w-fit !rounded-[100px] !bg-transparent !border !text-[#289D17]"
              />
              <Button
                onClick={handleEditSave}
                disabled={editLoading}
                loading={editLoading}
                title="Save"
                className="!py-[10px] !w-fit !rounded-[100px] !px-6 !bg-[#18AF04] !text-[#fff]"
              />
            </div>
          </>
        )}
      </div>
    </ModalContainer>
  );
}

export default EditShipmentModal;

