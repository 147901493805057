interface SwitchProps {
  value: boolean;
  onChange: () => void;
}

function SwitchTab(props: SwitchProps) {
  const toggleClass = "transform translate-x-6";
  return (
    <div
      onClick={props.onChange}
      className={`${
        props.value ? "bg-[#289D17]" : "bg-gray-300"
      } md:w-12 md:h-6 w-12 h-6 flex items-center rounded-full p-1 cursor-pointer`}
    >
      <div
        className={`bg-white w-5 h-5 rounded-full shadow-md transition duration-300 ease-in-out ${
          props.value ? toggleClass : ""
        }`}
      ></div>
    </div>
  );
}

export default SwitchTab;
