const Card = ({ children, className }: any) => {
  return (
    <div className={`mt-7 sm:mt-[72px]  ${className} rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center`}>
        <div className='w-full px-4 sm:px-10 py-6 sm:py-8 onestBold'>
            {children}
        </div>
    </div>
  );
};

export default Card;