import moment from "moment";

export const getDateRange = (period: string): { startDate: string; endDate: string } => {
  let startDate = "";
  let endDate = moment().add(1, "days").format("YYYY-MM-DD");

  switch (period) {
    case "last7days":
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
      break;
    case "lastmonth":
      startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
      break;
    case "lastquarter":
      startDate = moment().subtract(3, "months").format("YYYY-MM-DD");
      break;
    case "lastyear":
      startDate = moment().subtract(12, "months").format("YYYY-MM-DD");
      break;
    default:
      startDate = "";
      endDate = "";
  }

  return { startDate, endDate };
};
