import React, { ReactNode } from "react";

interface Props{
    children : ReactNode
    headerTitle?: ReactNode
}
const AuthLayout =({children, headerTitle}:Props)=>{

    return(
        <div className="w-full  overflow-x-hidden">
        <div className="xl:px-[100px] px-[30px] py-[40px]">
          <div className="flex items-center justify-between ">
            <img src="/images/FezLogo.svg" alt="" className="w-[50px] md:w-[106px] h-[34px] md:h-[40px]" />
            {headerTitle}
          </div>
          <div className="md:mt-[40px] mt-[30px]  w-full flex lg:flex-row flex-col ">
            <div className="w-[60%]  lg:block hidden">
              <p className="text-[58px] w-[90%]  leading-[69px] font-onestThin text-[#607C5D] -tracking-[2%]">
                Empowering your business with seamless last-mile solutions
              </p>
              <div className=" w-[400px] mt-[50px]">
                <div className="grid grid-cols-2 items-end gap-4 ">
                  <img src="/images/imageOne.svg" alt="" className="" />
                  <img src="/images/imageThree.svg" alt="" className="" />
                </div>
                <div className="flex ml-16 mt-4">
                  <img src="/images/imageTwo.svg" alt="" className="" />
                </div>
              </div>
            </div>
            <div className="lg:w-[40%] w-full flex justify-center lg:justify-end">
            {children}
            </div>
          </div>
        </div>
      </div>
    )
}


export default AuthLayout