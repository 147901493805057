import {
  useGetCountryQuery,
  useGetExportCountryQuery,
  useGetItemCategoryQuery,
  useOrderMutation,
  useRemovePendingOrderMutation,
  useUpdateReferralMutation,
} from "services/slices/shipNow";

import { FaCircle } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button";
import { DropDownSelect } from "components/common/form/Dropdown";
import { useEffect, useRef, useState } from "react";
import FileDragNDrop from "components/dashboard/FileDragNDrop";
import { useExtractOrderMutation } from "services/slices/shipNow";
import { TextInput } from "components/common/form/Input";
import { useGetStatesQuery } from "services/slices/dashboard";
import {
  useGetExportCostPriceMutation,
  useGetImportCostPriceMutation,
  useGetLocalCostPriceMutation,
} from "services/slices/pricing";
import { usePaystackPayment } from "react-paystack";
import { generateRandomDataWithDelay } from "helpers/generateRandomNumber";
import { formatMoney } from "helpers/formatMoney";
import { IoWarningOutline } from "react-icons/io5";
import { Loader } from "components/Loader";
import {
  useGetWalletBalanceQuery,
  useWalletCheckoutMutation,
} from "services/slices/wallet";
import ModalContainer from "components/Modal";
import { useGetUnReadNotificationsQuery } from "services/slices/notification";
import { bulkDeliveryType, excelSample } from "constant/data";
import { handleErrorToast } from "helpers/toastMessage";
import BulkLocalUpload from "components/dashboard/BulkUpload/Local";
import BulkImportUpload from "components/dashboard/BulkUpload/Import";
import { HiOutlineChevronDown } from "react-icons/hi";
import Dropdown from "components/common/form/SelectDropdown";
import { PiCopySimpleLight } from "react-icons/pi";
import { copyToClipboard } from "helpers/handleCopy";
import { Item } from "./SingleShipment";
import BulkExportUpload, {
  ItemWeight,
} from "components/dashboard/BulkUpload/Export";
import getSymbolFromCurrency from "currency-symbol-map";

interface Props {
  recipientAddress: string;
  recipientState: string;
  recipientName: string;
  recipientPhone: string;
  uniqueID: string;
  BatchID: string;
  orderRequestSource: string;
  paymentReference: string;
  recipientEmail: string;
  weight: string;
  pickUpAddress: string;
  pickUpState: string;
  referralCode: string;
  additionalDetails: string;
  cost: number;
  discountedCost: number;
  itemDescription: string;
  locker_box_name: string;
  valueOfItem?: number;
  clientPhone: string;
  offline: string;
}

interface State {
  id: string;
  state: string;
  country?: {
    name: string;
  };
}
export interface User {
  id: number;
  name: string;
  address: string;
  pickUpAddress: string;
  itemDescription: string;
  zone: string;
  pickUpZone: string;
  phone: string;
  altPhone: string;
  pickUpPhone: string;
  email: string;
  itemValue: number;
  cost: any;
  discountedCost: any;
  weight: string;
  quantity: string;
  description: string;
  created: string;
  action: string;
  valid: boolean;
  nameValid: boolean;
  itemValueValid: boolean;
  addressValid: boolean;
  pickUpAddressValid: boolean;
  pickUpZoneValid: boolean;
  pickUpPhoneValid: boolean;
  zoneValid: boolean;
  weightValid: boolean;
  emailValid: boolean;
  phoneValid: boolean;
  altPhoneValid: boolean;
  costValid: boolean;
  rowValid: boolean;
}

export interface ImportUser {
  id: number;
  name: string;
  address: string;
  itemDescription: string;
  zone: string;
  phone: string;
  altPhone: string;
  pickUpPhone: string;
  lga: string;
  additionalInfo: string;
  deliveryCity: string;
  landMark: string;
  email: string;
  trackingId: string;
  itemValue: number;
  itemCategory: string;
  cost: any;
  discountedCost: any;
  weight: string;
  quantity: string;
  description: string;
  created: string;
  action: string;
  valid: boolean;
  nameValid: boolean;
  itemValueValid: boolean;
  addressValid: boolean;
  pickUpPhoneValid: boolean;
  zoneValid: boolean;
  weightValid: boolean;
  emailValid: boolean;
  phoneValid: boolean;
  altPhoneValid: boolean;
  costValid: boolean;
  rowValid: boolean;
  itemCategoryValid: boolean;
  landMarkValid: boolean;
  deliveryCityValid: boolean;
  lgaValid: boolean;
  trackingIdValid: boolean;
  additionalInfoValid: boolean;
}

export interface ExportUser {
  id: number;
  name: string;
  address: string;
  pickUpAddress: string;
  itemDescription: string;
  pickUpZone: string;
  phone: string;
  altPhone: string;
  pickUpPhone: string;
  additionalInfo: string;
  deliveryCity: string;
  deliveryState: string;
  zipCode: string;
  deliveryCountry: string;
  email: string;
  itemValue: number;
  itemCategory: string;
  cost: any;
  discountedCost: any;
  weight: string;
  quantity: string;
  description: string;
  created: string;
  action: string;
  valid: boolean;
  nameValid: boolean;
  itemValueValid: boolean;
  pickUpAddressValid: boolean;
  addressValid: boolean;
  pickUpPhoneValid: boolean;
  pickUpZoneValid: boolean;
  weightValid: boolean;
  emailValid: boolean;
  phoneValid: boolean;
  altPhoneValid: boolean;
  costValid: boolean;
  rowValid: boolean;
  itemCategoryValid: boolean;
  deliveryCityValid: boolean;
  deliveryStateValid: boolean;
  deliveryCountryValid: boolean;
  zipCodeValid: boolean;
  additionalInfoValid: boolean;
}
export type EditableState = {
  id: number | null;
  field: keyof User | null;
};

export type ImportEditableState = {
  id: number | null;
  field: keyof ImportUser | null;
};
export type ExportEditableState = {
  id: number | null;
  field: keyof ExportUser | null;
};
type Ilist = {
  id: number;
  name: string;
};
interface Address {
  id: number;
  import_location_id: number;
  address: string;
  status: number;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}
interface Location {
  state: string;
  city: string;
  postCode: string;
  addresses: Address[];
}
export interface ExportState {
  id: string;
  name: string;
}
const initialValues = {
  importCountry: "",
};
const BulkShipment = () => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const [editable, setEditable] = useState<EditableState>({
    id: null,
    field: null,
  });
  const [importEditable, setImportEditable] = useState<ImportEditableState>({
    id: null,
    field: null,
  });
  const [exportEditable, setExportEditable] = useState<ExportEditableState>({
    id: null,
    field: null,
  });
  const [isEditOrder, setIsEditOrder] = useState<boolean>(false);
  const [searchDeliveryZone, setSearchDeliveryZone] = useState<string>("");
  const [itemName, setItemName] = useState<string>("");
  const [searchPickUpZone, setSearchPickUpZone] = useState<string>("");
  const [searchItem, setSearchItem] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [isDeliveryType, setIsDeliveryType] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState<Ilist>();
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [result, setResult] = useState<any>({});
  const [editDetails, setEditDetails] = useState<User | any>({});
  const [importEditDetails, setImportEditDetails] = useState<ImportUser | any>(
    {}
  );
  const [exportEditDetails, setExportEditDetails] = useState<ExportUser | any>(
    {}
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [uiFileType, setUiFileType] = useState<string>("");
  const [acceptedDocFile, setAcceptedDocFile] = useState<any>(null);
  const [acceptedFiles, setAcceptedFiles] = useState<any>([]);
  const [isExtractOrder, setIsExtractOrder] = useState<boolean>(false);
  const [showBulkTableData, setBulkTableData] = useState<boolean>(false);
  const [payType, setPayType] = useState<string>("");
  const [addMoney, setAddMoney] = useState<boolean>(false);
  const [showAddresses, setShowAddresses] = useState<Location>();
  const [showClipboard, setShowClipboard] = useState<boolean>(false);
  const [copiedAdd, setCopiedAdd] = useState<string>("");
  const [amountAdded, setAmountAdded] = useState<any>();
  const [addMoneySuccess, setAddMoneySuccess] = useState<boolean>(false);
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [referenceId, setReferenceId] = useState<string>("");
  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalDiscountCost, setTotalDiscountCost] = useState<number>(0);
  const [referenceIdTwo, setReferenceIdTwo] = useState<string>("");
  const [discountCode, setDiscountCode] = useState<string>("");
  const [moneyReference, setMoneyReference] = useState<string>("");
  const [isModalPayment, setIsModalPayment] = useState<boolean>(false);
  const [noPayment, setNoPayment] = useState<boolean>(false);
  const [noCostError, setNoCostError] = useState<boolean>(false);
  const [fetchNotifications, setFetchNotifications] = useState<boolean>(false);
  const [deliveryDetails, setDeliveryDetails] = useState(initialValues);
  const [currencyValue, setCurrencyValue] = useState<string>("");
  const [importCountryStateName, setImportCountryStateName] =
    useState<string>("");
  const [users, setUsers] = useState<User[]>([]);
  const [exportUsers, setExportUsers] = useState<ExportUser[]>([]);
  const [importUsers, setImportUsers] = useState<any[]>([]);
  const [updateReferral, { isLoading: isUpdatingReferral }] =
    useUpdateReferralMutation();
  const navigate = useNavigate();
  const handleClick = () => setIsBulkUpload(false);
  const onGoBack = () => navigate(-1);
  const [getCost, { isLoading }] = useGetLocalCostPriceMutation();
  const [getImportCostPrice, { isLoading: importLoading }] =
    useGetImportCostPriceMutation();
  const [getExportCostPrice, { isLoading: exportLoading }] =
    useGetExportCostPriceMutation();
  const {
    data: exportCountryData,
    error: exportCountryError,
    isLoading: isExportCountryLoading,
  } = useGetExportCountryQuery();
  const [selectedWeightOption, setSelectedWeightOption] = useState<Ilist>();

  const orgCode: any = localStorage.getItem("fez-org-code");
  const userData: any = localStorage.getItem("fez-user");
  const [searchCountryState, setSearchCountryState] = useState<string>("");
  const fezOrg: any = localStorage.getItem("fez-org-details");
  const orgEmail = localStorage.getItem("fez-org-email");
  const { data: countryData, error: countryError } = useGetCountryQuery();
  const { data: itemData, isLoading: isItemLoading } =
    useGetItemCategoryQuery();
  const { data, error: dataError } = useGetStatesQuery();
  const [getOrder, { isLoading: orderLoading }] = useOrderMutation();
  const [
    extractOrder,
    {
      isLoading: isExtractOrderLoading,
      error: extractOrderError,
      isError: isExtractOrderError,
      data: extractData,
    },
  ] = useExtractOrderMutation();
  const [removeOrder, { isLoading: isRemoveOrderLoading }] =
    useRemovePendingOrderMutation();
  const {
    data: walletData,
    error: isWalletError,
    isLoading: isWalletLoading,
  } = useGetWalletBalanceQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });
  const [walletCheckOut, { isLoading: isWalletCheckoutLoading }] =
    useWalletCheckoutMutation();

  const id = userData?.userID;
  const { refetch: refetchNotification } = useGetUnReadNotificationsQuery(
    {
      id,
    },
    { skip: !fetchNotifications }
  );
  const fezOrgDetails = JSON.parse(fezOrg);

  useEffect(() => {
    if (fetchNotifications) {
      refetchNotification();
      setFetchNotifications(false);
    }
  }, [fetchNotifications, refetchNotification]);

  const toggleDeliveryType = () => {
    setIsDeliveryType((prev) => !prev);
  };
  const onHandleBackPayment = () => {
    setShowPayment(false);
  };
  const validateImportField = (field: string, value: string) => {
    if (field === "name" && value.trim() === "") {
      return { valid: false, errorMessage: "Name is required" };
    }
    if (field === "address" && value.trim() === "") {
      return { valid: false, errorMessage: "Address is required" };
    }

    if (field === "zone" && value.trim() === "") {
      return { valid: false, errorMessage: "Zone is required" };
    }
    if (field === "itemCategory" && value.trim() === "") {
      return { valid: false, errorMessage: "Item category is required" };
    }
    if (field === "itemValue" && value.trim() === "") {
      return { valid: false, errorMessage: "item value is required" };
    }
    if (field === "trackingId" && value.trim() === "") {
      return { valid: false, errorMessage: "Tracking ID is required" };
    }
    if (field === "email" && value.trim() === "") {
      return { valid: true, errorMessage: "" };
    } else if (
      field === "email" &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value.trim())
    ) {
      return { valid: false, errorMessage: "Invalid email format" };
    }
    if (field === "phone" && value.trim() === "") {
      return { valid: false, errorMessage: "Phone is required" };
    } else if (field === "phone" && value.trim() !== "") {
      const phoneRegex = /^[1-9]\d{9}$/;
      const validPhone = phoneRegex.test(value);
      if (!validPhone) {
        return { valid: false, errorMessage: "Phone number is required" };
      }
    }
    if (field === "altPhone" && value.trim() === "") {
      return { valid: true, errorMessage: "" };
    } else if (field === "altPhone" && value.trim() !== "") {
      const phoneRegex = /^[1-9]\d{9}$/;
      const validPhone = phoneRegex.test(value);
      if (!validPhone) {
        return { valid: false, errorMessage: "Valid Phone number is required" };
      }
    }

    return { valid: true, errorMessage: null };
  };
  const validateField = (field: string, value: string) => {
    if (field === "name" && value.trim() === "") {
      return { valid: false, errorMessage: "Name is required" };
    }
    if (field === "address" && value.trim() === "") {
      return { valid: false, errorMessage: "Address is required" };
    }
    if (field === "pickUpAddress" && value.trim() === "") {
      return { valid: false, errorMessage: "pickUpAddress is required" };
    }
    if (field === "zone" && value.trim() === "") {
      return { valid: false, errorMessage: "Zone is required" };
    }
    if (field === "pickUpZone" && value.trim() === "") {
      return { valid: false, errorMessage: "pickUpZone is required" };
    }
    if (field === "itemValue" && value.trim() === "") {
      return { valid: false, errorMessage: "item value is required" };
    }
    if (field === "email" && value.trim() === "") {
      return { valid: true, errorMessage: "" };
    } else if (
      field === "email" &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value.trim())
    ) {
      return { valid: false, errorMessage: "Invalid email format" };
    }
    if (field === "phone" && value.trim() === "") {
      return { valid: false, errorMessage: "Phone is required" };
    } else if (field === "phone" && value.trim() !== "") {
      const phoneRegex = /^[1-9]\d{9}$/;
      const validPhone = phoneRegex.test(value);
      if (!validPhone) {
        return { valid: false, errorMessage: "Phone number is required" };
      }
    }
    if (field === "altPhone" && value.trim() === "") {
      return { valid: true, errorMessage: "" };
    } else if (field === "altPhone" && value.trim() !== "") {
      const phoneRegex = /^[1-9]\d{9}$/;
      const validPhone = phoneRegex.test(value);
      if (!validPhone) {
        return { valid: false, errorMessage: "Valid Phone number is required" };
      }
    }

    if (field === "pickUpPhone" && value.trim() === "") {
      return { valid: true, errorMessage: "" };
    } else if (field === "pickUpPhone" && value.trim() !== "") {
      const phoneRegex = /^[1-9]\d{9}$/;
      const validPhone = phoneRegex.test(value);
      if (!validPhone) {
        return {
          valid: false,
          errorMessage: "Pick-up Phone number is required",
        };
      }
    }
    return { valid: true, errorMessage: null };
  };
  const validateExportField = (field: string, value: string) => {
    if (field === "name" && value.trim() === "") {
      return { valid: false, errorMessage: "Name is required" };
    }
    if (field === "address" && value.trim() === "") {
      return { valid: false, errorMessage: "Address is required" };
    }
    if (field === "pickUpAddress" && value.trim() === "") {
      return { valid: false, errorMessage: "pick-up address is required" };
    }
    if (field === "deliveryCountry" && value.trim() === "") {
      return { valid: false, errorMessage: "Destination country is required" };
    }
    if (field === "zipCode" && value.trim() === "") {
      return { valid: false, errorMessage: "Zip code is required" };
    }
    if (field === "pickUpZone" && value.trim() === "") {
      return { valid: false, errorMessage: "pick-up zone is required" };
    }
    if (field === "itemValue" && value.trim() === "") {
      return { valid: false, errorMessage: "item value is required" };
    }
    if (field === "email" && value.trim() === "") {
      return { valid: true, errorMessage: "" };
    } else if (
      field === "email" &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value.trim())
    ) {
      return { valid: false, errorMessage: "Invalid email format" };
    }
    if (field === "phone" && value.trim() === "") {
      return { valid: false, errorMessage: "Phone is required" };
    } else if (field === "phone" && value.trim() !== "") {
      if (value.trim().length < 9 || value.trim().length > 13)
        return {
          valid: false,
          errorMessage: "phone number should be between 9 - 13 digit",
        };
    }

    if (field === "altPhone" && value.trim() === "") {
      return { valid: true, errorMessage: "" };
    }

    if (field === "pickUpPhone" && value.trim() === "") {
      return { valid: true, errorMessage: "" };
    } else if (field === "pickUpPhone" && value.trim() !== "") {
      const phoneRegex = /^[1-9]\d{9}$/;
      const validPhone = phoneRegex.test(value);
      if (!validPhone) {
        return {
          valid: false,
          errorMessage: "Pick-up Phone number is required",
        };
      }
    }
    return { valid: true, errorMessage: null };
  };
  const validateDeliveryType = (value: any) => {
    let errors: any = {};
    if (!value || value === "") {
      errors.deliveryType = "Delivery type is required";
    }
    return errors;
  };
  const validateAmount = (value: string) => {
    let errors: any = {};
    if (!value || value === "") {
      errors.amount = "Amount is required";
    } else if (value !== "") {
      errors.amount = "";
    }
    return errors;
  };

  const handleDeliveryType = (option: any) => {
    setSelectedOption(option);
    setQuery(option.name);
    setIsDeliveryType(false);
    setFormErrors((prev: any) => ({
      ...prev,
      deliveryType: "",
    }));
  };
  const handleCopy = (address: string) => {
    setCopiedAdd(address);
    copyToClipboard(address, setShowClipboard);
  };
  const handleEditClick = (id: number, field: keyof User) => {
    setEditable({ id, field });
  };
  const handleImportEditClick = (id: number, field: keyof ImportUser) => {
    setImportEditable({ id, field });
  };
  const handleExportEditClick = (id: number, field: keyof ExportUser) => {
    setExportEditable({ id, field });
  };

  const handleErrorChange = (name: string, value: any) => {
    if (name === "email" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    } else if (name === "email" && value !== "" && !regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "Invalid email format",
      }));
    } else if (name === "email" && value !== "" && regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    } else if (name === "pickUpAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "",
      }));
    } else if (name === "dropOffAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffAddress: "",
      }));
    } else if (name === "recipientName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientName: "",
      }));
    } else if (name === "recipientPhoneNumber" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientPhoneNumber: "",
      }));
    }
  };

  const onEditOrder = (e: any) => {
    setIsEditOrder(true);
    const updatedUser: any = users.find((user: User) => user.id === e?.id);
    const importUpdatedUser: ImportUser = importUsers.find(
      (user: ImportUser) => user.id === e?.id
    );
    const exportUpdatedUser: any = exportUsers.find(
      (user: ExportUser) => user.id === e?.id
    );
    console.log("exportUpdatedUser", exportUpdatedUser);
    setEditDetails(updatedUser);
    setImportEditDetails(importUpdatedUser);
    setExportEditDetails(exportUpdatedUser);
  };

  const handleDeliveryChange = (e: any, field: string) => {
    const { name, value } = e?.target;
    handleErrorChange(name, value);
    setUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        if (user.id === editDetails?.id) {
          const validation = validateField(field, value);
          return {
            ...user,
            [field]: value,
            [`${field}Valid`]: validation.valid,
            rowValid:
              user.nameValid &&
              user.addressValid &&
              user.pickUpAddressValid &&
              user.zoneValid &&
              user.pickUpZoneValid &&
              user.phoneValid &&
              user.emailValid &&
              user.costValid &&
              user.weightValid &&
              user.itemValueValid &&
              user.pickUpPhoneValid,
          };
        }
        return user;
      });
      const updatedUser = updatedUsers.find(
        (user) => user.id === editDetails?.id
      );

      if (
        updatedUser &&
        (field === "weight" || field === "zone" || field === "pickUpZone")
      ) {
        getCost({
          recipientState: updatedUser.zone,
          pickUpState: updatedUser.pickUpZone,
          weight: updatedUser.weight !== "" ? updatedUser.weight : "",
          referralCode: discountCode,
        })
          .unwrap()
          .then((data: any) => {
            setNoCostError(false);
            setUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === editDetails?.id) {
                  return {
                    ...user,
                    cost: data?.data?.cost,
                    costValid: true,
                    discountedCost: data?.data?.data?.discountedCost,
                  };
                }

                return user;
              })
            );
            setEditDetails((prev: any) => ({
              ...prev,
              cost: data?.data?.cost,
              costValid: true,
              discountedCost: data?.data?.data?.discountedCost,
            }));
          })
          .catch((e: any) => {
            setNoCostError(true);
            setUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === editDetails?.id) {
                  return {
                    ...user,
                    costValid: false,
                    cost: 0,
                    discountedCost: 0,
                  };
                }

                return user;
              })
            );
            setEditDetails((prev: any) => ({
              ...prev,
              costValid: false,
              cost: 0,
              discountedCost: 0,
            }));
            handleErrorToast(e);
          });
        setSearchPickUpZone("");
        setSearchDeliveryZone("");
      }
      setEditDetails(updatedUser);

      return updatedUsers;
    });
  };
  const handleImportDeliveryChange = (e: any, field: string) => {
    const { name, value } = e.target;
    handleErrorChange(name, value);

    setImportUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        if (user.id === importEditDetails?.id) {
          const validation = validateImportField(field, value);
          return {
            ...user,
            [field]: value,
            [`${field}Valid`]: validation.valid,
            rowValid:
              user.nameValid &&
              user.addressValid &&
              user.zoneValid &&
              user.phoneValid &&
              user.emailValid &&
              user.costValid &&
              user.weightValid &&
              user.itemValueValid &&
              user.itemCategoryValid &&
              user.trackingIdValid,
          };
        }
        return user;
      });
      const updatedUser = updatedUsers.find(
        (user) => user.id === importEditDetails?.id
      );

      if (updatedUser && (field === "weight" || field === "zone")) {
        getImportCostPrice({
          destinationState: updatedUser.zone,
          importLocationId: Number(deliveryDetails.importCountry),
          weight: updatedUser.weight !== "" ? updatedUser.weight : "",
          referralCode: discountCode,
        })
          .unwrap()
          .then((data: any) => {
            setNoCostError(false);
            setImportUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === editDetails?.id) {
                  return {
                    ...user,
                    cost: data?.data?.price,
                    costValid: true,
                    discountedCost: data?.data?.data?.discountedCost,
                  };
                }

                return user;
              })
            );
            setImportEditDetails((prev: any) => ({
              ...prev,
              cost: data?.data?.price,
              costValid: true,
              discountedCost: data?.data?.data?.discountedCost,
            }));
          })
          .catch((e: any) => {
            setNoCostError(true);
            setImportUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === editDetails?.id) {
                  return {
                    ...user,
                    costValid: false,
                    cost: 0,
                    discountedCost: 0,
                  };
                }

                return user;
              })
            );
            setImportEditDetails((prev: any) => ({
              ...prev,
              costValid: false,
              cost: 0,
              discountedCost: 0,
            }));
            handleErrorToast(e);
          });
        setSearchDeliveryZone("");
      }
      setImportEditDetails(updatedUser);

      return updatedUsers;
    });
  };

  const handleExportDeliveryChange = (e: any, field: string) => {
    const { name, value } = e?.target;

    handleErrorChange(name, value);

    setExportUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        if (user.id === exportEditDetails?.id) {
          const validation = validateExportField(field, value);
          return {
            ...user,
            [field]: value,
            [`${field}Valid`]: validation.valid,
            rowValid:
              user.nameValid &&
              user.addressValid &&
              user.pickUpAddressValid &&
              user.pickUpZoneValid &&
              user.phoneValid &&
              user.emailValid &&
              user.costValid &&
              user.weightValid &&
              user.itemValueValid &&
              user.deliveryCountryValid &&
              user.zipCodeValid &&
              user.pickUpPhoneValid,
          };
        }
        return user;
      });
      const updatedUser = updatedUsers.find(
        (user) => user.id === exportEditDetails?.id
      );

      if (
        updatedUser &&
        (field === "weight" ||
          field === "deliveryCountry" ||
          field === "pickUpZone")
      ) {
        const exportLocation = filteredExportCountrySearch.find(
          (country: ExportState) => country.name === updatedUser.deliveryCountry
        );
        const weights = exportLocation?.int_price?.map(
          (item: ItemWeight) => item?.weight
        );
        const weightId = weights?.find(
          (weight: Ilist) => weight?.name === updatedUser?.weight
        );

        getExportCostPrice({
          destinationState: updatedUser.pickUpZone,
          exportLocationId: Number(exportLocation?.id),
          weightId: Number(weightId?.id),
          referralCode: discountCode,
        })
          .unwrap()
          .then((data: any) => {
            setNoCostError(false);
            setExportUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === exportEditDetails?.id) {
                  return {
                    ...user,
                    cost: data?.data?.price,
                    costValid: true,
                    discountedCost: data?.data?.discountedRate,
                  };
                }

                return user;
              })
            );
            setExportEditDetails((prev: any) => ({
              ...prev,
              cost: data?.data?.price,
              costValid: true,
              discountedCost: data?.data?.discountedRate,
            }));
          })
          .catch((e: any) => {
            setNoCostError(true);
            setExportUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === editDetails?.id) {
                  return {
                    ...user,
                    costValid: false,
                    cost: 0,
                    discountedCost: 0,
                  };
                }

                return user;
              })
            );
            setExportEditDetails((prev: any) => ({
              ...prev,
              costValid: false,
              cost: 0,
              discountedCost: 0,
            }));
            handleErrorToast(e);
          });
        setSearchCountryState("");
        setSearchDeliveryZone("");
      }
      setExportEditDetails(updatedUser);

      return updatedUsers;
    });
  };
  const handleBlur = () => {
    setEditable({ id: null, field: null });
    setImportEditable({ id: null, field: null });
    setExportEditable({ id: null, field: null });
  };

  const handleChange = (id: number, field: keyof User, value: string) => {
    setUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        if (user.id === id) {
          const validation = validateField(field, value);
          return {
            ...user,
            [field]: value,
            [`${field}Valid`]: validation.valid,
            rowValid:
              user.nameValid &&
              user.addressValid &&
              user.pickUpAddressValid &&
              user.zoneValid &&
              user.pickUpZoneValid &&
              user.phoneValid &&
              user.emailValid &&
              user.costValid &&
              user.weightValid &&
              user.itemValueValid &&
              user.pickUpPhoneValid,
          };
        }

        return user;
      });

      const updatedUser = updatedUsers.find((user) => user.id === id);
      if (
        updatedUser &&
        (field === "weight" || field === "zone" || field === "pickUpZone")
      ) {
        getCost({
          pickUpState: updatedUser.pickUpZone,
          recipientState: updatedUser.zone,
          weight: updatedUser.weight !== "" ? updatedUser.weight : "1",
          referralCode: discountCode,
        })
          .unwrap()
          .then((data: any) => {
            setUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === id) {
                  return {
                    ...user,
                    cost: data?.data?.cost,
                    costValid: true,
                    discountedCost: data?.data?.discount?.discountedPrice,
                  };
                }
                return user;
              })
            );
          })
          .catch((e) => {
            setUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === editDetails?.id) {
                  return {
                    ...user,
                    costValid: false,
                    cost: 0,
                    discountedCost: 0,
                  };
                }
                return user;
              })
            );
            setEditDetails((prev: any) => ({
              ...prev,
              costValid: false,
              cost: 0,
              discountedCost: 0,
            }));
          });
        setSearchPickUpZone("");
        setSearchDeliveryZone("");
      }

      return updatedUsers;
    });
  };
  const handleImportChange = (
    id: number,
    field: keyof ImportUser,
    value: string
  ) => {
    setImportUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        if (user.id === id) {
          const validation = validateImportField(field, value);
          return {
            ...user,
            [field]: value,
            [`${field}Valid`]: validation.valid,
            rowValid:
              user.nameValid &&
              user.addressValid &&
              user.zoneValid &&
              user.phoneValid &&
              user.emailValid &&
              user.costValid &&
              user.weightValid &&
              user.itemValueValid &&
              user.itemCategoryValid &&
              user.trackingIdValid,
          };
        }

        return user;
      });

      const updatedUser = updatedUsers.find((user) => user.id === id);
      if (updatedUser && (field === "weight" || field === "zone")) {
        getImportCostPrice({
          destinationState: updatedUser.zone,
          importLocationId: Number(deliveryDetails.importCountry),
          weight: updatedUser.weight !== "" ? updatedUser.weight : "",
          referralCode: discountCode,
        })
          .unwrap()
          .then((data: any) => {
            setImportUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === id) {
                  return {
                    ...user,
                    cost: data?.data?.price,
                    costValid: true,
                    discountedCost: data?.data?.discount?.discountedPrice,
                  };
                }
                return user;
              })
            );
          })
          .catch((e) => {
            setImportUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === editDetails?.id) {
                  return {
                    ...user,
                    costValid: false,
                    cost: 0,
                    discountedCost: 0,
                  };
                }
                return user;
              })
            );
            setImportEditDetails((prev: any) => ({
              ...prev,
              costValid: false,
              cost: 0,
              discountedCost: 0,
            }));
          });
      }

      return updatedUsers;
    });
  };
  const handleExportChange = (
    id: number,
    field: keyof ExportUser,
    value: string
  ) => {
    setExportUsers((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        if (user.id === id) {
          const validation = validateExportField(field, value);
          return {
            ...user,
            [field]: value,
            [`${field}Valid`]: validation.valid,
            rowValid:
              user.nameValid &&
              user.addressValid &&
              user.pickUpAddressValid &&
              user.pickUpZoneValid &&
              user.phoneValid &&
              user.emailValid &&
              user.costValid &&
              user.weightValid &&
              user.itemValueValid &&
              user.deliveryCountryValid &&
              user.zipCodeValid &&
              user.pickUpPhoneValid,
          };
        }

        return user;
      });

      const updatedUser = updatedUsers.find((user) => user.id === id);
      if (
        updatedUser &&
        (field === "weight" ||
          field === "deliveryCountry" ||
          field === "pickUpZone")
      ) {
        const exportLocation = filteredExportCountrySearch.find(
          (country: ExportState) => country.name === updatedUser.deliveryCountry
        );
        const weights = exportLocation?.int_price?.map(
          (item: ItemWeight) => item?.weight
        );
        const weightId = weights?.find(
          (weight: Ilist) => weight?.name === updatedUser?.weight
        );

        getExportCostPrice({
          destinationState: updatedUser.pickUpZone,
          exportLocationId: Number(exportLocation?.id),
          weightId: Number(weightId?.id),
          referralCode: discountCode,
        })
          .unwrap()
          .then((data: any) => {
            setExportUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === id) {
                  return {
                    ...user,
                    cost: data?.data?.price,
                    costValid: true,
                    discountedCost: data?.data?.discountedRate,
                  };
                }
                return user;
              })
            );
          })
          .catch((e) => {
            setExportUsers((prevUsers) =>
              prevUsers.map((user) => {
                if (user.id === editDetails?.id) {
                  return {
                    ...user,
                    costValid: false,
                    cost: 0,
                    discountedCost: 0,
                  };
                }
                return user;
              })
            );
            setExportEditDetails((prev: any) => ({
              ...prev,
              costValid: false,
              cost: 0,
              discountedCost: 0,
            }));
          });
        setSearchCountryState("");
        setSearchPickUpZone("");
      }

      return updatedUsers;
    });
  };
  function countValidEntries(data: any) {
    let trueCount = 0;
    let falseCount = 0;
    let rowValidCount = 0;
    let rowInvalidCount = 0;

    if (Array.isArray(data)) {
      data.forEach((manifest) => {
        const fields = [
          "nameValid",
          "addressValid",
          "pickUpAddressValid",
          "zoneValid",
          "pickUpZoneValid",
          "phoneValid",
          "emailValid",
          "weightValid",
          "quantityValid",
          "descriptionValid",
          "costValid",
          "itemValueValid",
          "pickUpPhoneValid",
        ];

        let isRowValid = true;

        fields.forEach((field) => {
          if (manifest[field]) {
            trueCount++;
          } else {
            falseCount++;
            isRowValid = false;
          }
        });

        if (isRowValid) {
          rowValidCount++;
        } else {
          rowInvalidCount++;
        }
      });
    } else {
      console.error("Data is not an array", data);
    }
    return { trueCount, falseCount, rowValidCount, rowInvalidCount };
  }
  function countImportValidEntries(data: any) {
    let trueCount = 0;
    let falseCount = 0;
    let rowValidCount = 0;
    let rowInvalidCount = 0;

    if (Array.isArray(data)) {
      data.forEach((manifest) => {
        const fields = [
          "nameValid",
          "addressValid",
          "zoneValid",
          "phoneValid",
          "emailValid",
          "weightValid",
          "costValid",
          "itemValueValid",
          "trackingIdValid",
          "itemCategoryValid",
        ];

        let isRowValid = true;

        fields.forEach((field) => {
          if (manifest[field]) {
            trueCount++;
          } else {
            falseCount++;
            isRowValid = false;
          }
        });

        if (isRowValid) {
          rowValidCount++;
        } else {
          rowInvalidCount++;
        }
      });
    } else {
      console.error("Data is not an array", data);
    }

    return { trueCount, falseCount, rowValidCount, rowInvalidCount };
  }
  function countExportValidEntries(data: any) {
    let trueCount = 0;
    let falseCount = 0;
    let rowValidCount = 0;
    let rowInvalidCount = 0;

    if (Array.isArray(data)) {
      data.forEach((manifest) => {
        const fields = [
          "nameValid",
          "addressValid",
          "pickUpAddressValid",
          "phoneValid",
          "emailValid",
          "weightValid",
          "costValid",
          "itemValueValid",
          "pickUpZoneValid",
          "deliveryCountryValid",
          "zipCodeValid",
          "pickUpPhoneValid",
        ];

        let isRowValid = true;

        fields.forEach((field) => {
          if (manifest[field]) {
            trueCount++;
          } else {
            falseCount++;
            isRowValid = false;
          }
        });

        if (isRowValid) {
          rowValidCount++;
        } else {
          rowInvalidCount++;
        }
      });
    } else {
      console.error("Data is not an array", data);
    }

    return { trueCount, falseCount, rowValidCount, rowInvalidCount };
  }
  const filteredExportCountrySearch = exportCountryData?.data?.exportLocations
    ?.filter((item: ExportState) =>
      item?.name?.toLowerCase()?.includes(searchCountryState?.toLowerCase())
    )
    ?.sort((a: ExportState, b: ExportState) => a.name.localeCompare(b.name));

  const filteredItemSearch: Item[] | [] = itemData?.data?.categories
    ?.slice()
    ?.sort((a: Item, b: Item) => a.name.localeCompare(b.name))
    ?.filter((item: Item) =>
      item?.name?.toLowerCase()?.includes(searchItem.toLowerCase())
    );
  const filteredCountrySearch = countryData?.data?.importLocations
    ?.filter((item: State) =>
      item?.country?.name
        ?.toLowerCase()
        ?.includes(searchCountryState.toLowerCase())
    )
    ?.sort((a: State, b: State) =>
      (a?.country?.name || "").localeCompare(b?.country?.name || "")
    );
  const filteredDeliveryZoneSearch: State[] | undefined = data?.states
    ?.slice()
    ?.sort((a: State, b: State) => a.state.localeCompare(b.state))
    ?.filter((item: any) =>
      item?.state?.toLowerCase()?.includes(searchDeliveryZone.toLowerCase())
    );
  const filteredPickUpZoneSearch: State[] | undefined = data?.states
    ?.slice()
    ?.sort((a: State, b: State) => a.state.localeCompare(b.state))
    ?.filter((item: any) =>
      item?.state?.toLowerCase()?.includes(searchPickUpZone.toLowerCase())
    );
  const onUpdateReferralCode = async () => {
    const orderNos = JSON.parse(localStorage.getItem("orderNos") || "[]");

    const resultData = Object.values(orderNos).map((orderNo) => ({
      orderNo,
      discountCode,
    }));
    const orders = {
      orders: resultData?.map((data: any) => ({
        referralCode: data.discountCode,
        orderNo: data.orderNo,
      })),
    };

    if (resultData.length > 0 && discountCode !== "") {
      await updateReferral(orders);
    }
  };
  const onSuccess = () => {
    onUpdateReferralCode();
    setIsModalPayment(true);
  };

  const onClose = () => {
    onDeleteOrder();
    onGenerate();
  };

  const processPayment = async () => {
    const body = {
      totalCost:totalDiscountCost||totalCost,
      paymentReference: "w" + referenceId.slice(1),
    };
    await walletCheckOut(body)
      .unwrap()
      .then((data: any) => {
        onUpdateReferralCode();

        setQuery("");
        setIsModalPayment(true);
        setFetchNotifications(true);
        setTimeout(() => {
          setFetchNotifications(false);
        }, 60000);
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  const onDrop = async (acceptedFiles: File[]) => {
    setLoading(true);
    const file = acceptedFiles[0];
    setAcceptedDocFile(file);

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const base64String = event.target?.result as string;
      const base64Result = base64String.split(",")[1];
      setUiFileType(base64Result);
      setLoading(false);
    };

    reader.onerror = (error: ProgressEvent<FileReader>) => {
      console.error("Error reading file:", error);
      setLoading(false);
    };

    reader.readAsDataURL(file);
  };

  const onBulkUpload = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateDeliveryType(query));
    if (query === "" && !query) {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "Delivery type is required",
      }));
    } else {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
    }

    if (query !== "") {
      setIsBulkUpload(true);
    }
  };

  const onImportBulkUpload = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateDeliveryType(query));
    if (query === "" && !query) {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "Delivery type is required",
      }));
    } else {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
    }

    if (query === "International - Sending to Nigeria (Import)") {
      if (
        !deliveryDetails.importCountry ||
        deliveryDetails.importCountry === ""
      ) {
        setFormErrors((prev: any) => ({
          ...prev,
          importCountry: "Pick up country is required",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          importCountry: "",
        }));
      }
    }

    if (
      query !== "" &&
      deliveryDetails.importCountry !== undefined &&
      deliveryDetails.importCountry !== ""
    ) {
      setIsBulkUpload(true);
    }
  };

  useEffect(() => {
    const result = countValidEntries(users);
    const importResult = countImportValidEntries(importUsers);
    const exportResult = countExportValidEntries(exportUsers);
    if (query === "Local") {
      setResult(result);
    } else if (query === "International - Sending to Nigeria (Import)") {
      setResult(importResult);
    } else if (query === "International - Sending from Nigeria (Export)") {
      setResult(exportResult);
    }
  }, [users, query, exportUsers, importUsers]);

  useEffect(() => {
    if (extractData) {
      setBulkTableData(true);
      if (query === "Local") {
        const usersInfo: User[] = extractData.data.manifest.map(
          (manifestItem: any, index: number) => {
            const findField = (
              fieldName: string,
              optionalFields: string[] = []
            ) => {
              const field = manifestItem.row.find(
                (item: any) => item.name === fieldName
              );
              if (!field && optionalFields.includes(fieldName)) {
                return { value: "", valid: true, errorMessage: null };
              }
              return field || { value: "", valid: false, errorMessage: null };
            };

            const optionalFields = [
              "itemDescription",
              "itemQuantity",
              "weight",
              "email",
              "PickUpPhoneNumber",
              "altPhoneNumber",
              "deliveryCityLandmark",
              "lga",
            ];

            const isRowValid = manifestItem.row.every(
              (item: any) => item.valid || optionalFields.includes(item.name)
            );

            return {
              id: index,
              name: findField("recipientName").value || "",
              nameValid: findField("recipientName").valid || false,
              nameError: findField("recipientName").errorMessage || null,
              address: findField("deliveryAddress").value || "",
              addressValid: findField("deliveryAddress").valid || false,
              addressError: findField("deliveryAddress").errorMessage || null,
              pickUpAddress: findField("pickUpAddress").value || "",
              pickUpAddressValid: findField("pickUpAddress").valid || false,
              pickUpAddressError:
                findField("pickUpAddress").errorMessage || null,
              zone: findField("state").value || "",
              zoneValid: findField("state").valid || false,
              zoneError: findField("state").errorMessage || null,
              pickUpZone: findField("pickUpState").value || "",
              pickUpZoneValid: findField("pickUpState").valid || false,
              pickUpZoneError: findField("pickUpState").errorMessage || null,
              phone: findField("phoneNumber").value || "",
              phoneValid: findField("phoneNumber").valid || false,
              phoneError: findField("phoneNumber").errorMessage || null,
              altPhone: findField("altPhoneNumber", optionalFields).value || "",
              altPhoneValid:
                findField("altPhoneNumber", optionalFields).valid || true,
              email: findField("email", optionalFields).value || "",
              emailValid: findField("email", optionalFields).valid || false,
              emailError:
                findField("email", optionalFields).errorMessage || null,
              cost: manifestItem.cost,
              costValid: !(
                manifestItem.cost === null ||
                manifestItem.cost === undefined ||
                manifestItem.cost === 0
              ),
              discountedCost: manifestItem.discountedCost,
              weight: findField("weight", optionalFields).value || "",
              weightValid: findField("weight", optionalFields).valid || false,
              weightError:
                findField("weight", optionalFields).errorMessage || null,
              quantity: findField("itemQuantity", optionalFields).value || "",
              itemValue: findField("value", optionalFields).value || "",
              itemValueValid: findField("value", optionalFields).valid || true,
              pickUpPhone:
                findField("PickUpPhoneNumber", optionalFields).value || "",
              pickUpPhoneValid:
                findField("PickUpPhoneNumber", optionalFields).valid || true,
              quantityValid:
                findField("itemQuantity", optionalFields).valid || true,
              quantityError:
                findField("itemQuantity", optionalFields).errorMessage || null,
              description:
                findField("itemDescription", optionalFields).value || "",
              descriptionValid:
                findField("itemDescription", optionalFields).valid || true,
              descriptionError:
                findField("itemDescription", optionalFields).errorMessage ||
                null,
              action: "Delete",
              rowValid: isRowValid,
              rowError: isRowValid
                ? null
                : manifestItem.row
                    .filter(
                      (item: any) =>
                        !item.valid && !optionalFields.includes(item.name)
                    )
                    .map((item: any) => item.errorMessage)
                    .join("; "),
            };
          }
        );

        const sortedUsersInfo = usersInfo.sort((a, b) => {
          return a.rowValid === b.rowValid ? 0 : a.rowValid ? 1 : -1;
        });

        setUsers(sortedUsersInfo);
      } else if (query === "International - Sending to Nigeria (Import)") {
        const usersInfo: ImportUser[] = extractData.data.manifest.map(
          (manifestItem: any, index: number) => {
            const findField = (
              fieldName: string,
              optionalFields: string[] = []
            ) => {
              const field = manifestItem.row.find(
                (item: any) => item.name === fieldName
              );
              if (!field && optionalFields.includes(fieldName)) {
                return { value: "", valid: true, errorMessage: null };
              }
              return field || { value: "", valid: false, errorMessage: null };
            };

            const optionalFields = [
              "additionalInformation",
              "itemQuantity",
              "weight",
              "email",
              "altPhoneNumber",
              "deliveryCity",
              "deliveryCityLandmark",
              "lga",
            ];

            const isRowValid = manifestItem.row.every(
              (item: any) => item.valid || optionalFields.includes(item.name)
            );

            return {
              id: index,
              name: findField("recipientName").value || "",
              nameValid: findField("recipientName").valid || false,
              nameError: findField("recipientName").errorMessage || null,
              address: findField("deliveryAddress").value || "",
              addressValid: findField("deliveryAddress").valid || false,
              addressError: findField("deliveryAddress").errorMessage || null,
              zone: findField("state").value || "",
              zoneValid: findField("state").valid || false,
              zoneError: findField("state").errorMessage || null,
              phone: findField("phoneNumber").value || "",
              phoneValid: findField("phoneNumber").valid || false,
              phoneError: findField("phoneNumber").errorMessage || null,
              altPhone: findField("altPhoneNumber", optionalFields).value || "",
              altPhoneValid:
                findField("altPhoneNumber", optionalFields).valid || true,
              email: findField("email", optionalFields).value || "",
              emailValid: findField("email", optionalFields).valid || false,
              emailError:
                findField("email", optionalFields).errorMessage || null,
              trackingId: findField("trackingId", optionalFields).value || "",
              trackingIdValid:
                findField("trackingId", optionalFields).valid || false,
              trackingIdError:
                findField("trackingId", optionalFields).errorMessage || null,
              itemCategory:
                findField("itemCategory", optionalFields).value || "",
              itemCategoryValid:
                findField("itemCategory", optionalFields).valid || false,
              itemCategoryError:
                findField("itemCategory", optionalFields).errorMessage || null,
              deliveryCity:
                findField("deliveryCity", optionalFields).value || "",
              deliveryCityValid:
                findField("deliveryCity", optionalFields).valid || false,
              deliveryCityError:
                findField("deliveryCity", optionalFields).errorMessage || null,
              landMark:
                findField("deliveryCityLandmark", optionalFields).value || "",
              landMarkValid:
                findField("deliveryCityLandmark", optionalFields).valid ||
                false,
              landMarkError:
                findField("deliveryCityLandmark", optionalFields)
                  .errorMessage || null,
              lga: findField("lga", optionalFields).value || "",
              lgaValid: findField("lga", optionalFields).valid || false,
              lgaError: findField("lga", optionalFields).errorMessage || null,
              cost: manifestItem.cost,
              costValid: !(
                manifestItem.cost === null ||
                manifestItem.cost === undefined ||
                manifestItem.cost === 0
              ),
              discountedCost: manifestItem.discountedCost,
              weight: findField("weight", optionalFields).value || "",
              weightValid: findField("weight", optionalFields).valid || false,
              weightError:
                findField("weight", optionalFields).errorMessage || null,
              quantity: findField("itemQuantity", optionalFields).value || "",
              itemValue: findField("value", optionalFields).value || "",
              itemValueValid: findField("value", optionalFields).valid || true,

              quantityValid:
                findField("itemQuantity", optionalFields).valid || true,
              quantityError:
                findField("itemQuantity", optionalFields).errorMessage || null,
              additionalInfo:
                findField("additionalInformation", optionalFields).value || "",
              additionalInfoValid:
                findField("additionalInformation", optionalFields).valid ||
                true,
              additionalInfoError:
                findField("additionalInformation", optionalFields)
                  .errorMessage || null,
              action: "Delete",
              rowValid: isRowValid,
              rowError: isRowValid
                ? null
                : manifestItem.row
                    .filter(
                      (item: any) =>
                        !item.valid && !optionalFields.includes(item.name)
                    )
                    .map((item: any) => item.errorMessage)
                    .join("; "),
            };
          }
        );
        const sortedUsersInfo = usersInfo.sort((a, b) => {
          return a.rowValid === b.rowValid ? 0 : a.rowValid ? 1 : -1;
        });

        setImportUsers(sortedUsersInfo);
      } else if (query === "International - Sending from Nigeria (Export)") {
        const usersInfo: ExportUser[] = extractData.data.manifest.map(
          (manifestItem: any, index: number) => {
            const findField = (
              fieldName: string,
              optionalFields: string[] = []
            ) => {
              const field = manifestItem.row.find(
                (item: any) => item.name === fieldName
              );
              if (!field && optionalFields.includes(fieldName)) {
                return { value: "", valid: true, errorMessage: null };
              }
              return field || { value: "", valid: false, errorMessage: null };
            };

            const optionalFields = [
              "additionalInformation",
              "quantity",
              "email",
              "altPhoneNumber",
              "city",
              "state",
            ];

            const isRowValid = manifestItem.row.every(
              (item: any) => item.valid || optionalFields.includes(item.name)
            );

            return {
              id: index,
              name: findField("recipientName").value || "",
              nameValid: findField("recipientName").valid || false,
              nameError: findField("recipientName").errorMessage || null,
              address: findField("deliveryAddress").value || "",
              addressValid: findField("deliveryAddress").valid || false,
              addressError: findField("deliveryAddress").errorMessage || null,
              deliveryCity: findField("city", optionalFields).value || "",
              deliveryCityValid:
                findField("city", optionalFields).valid || false,
              deliveryCityError:
                findField("city", optionalFields).errorMessage || null,
              zipCode: findField("zipCode", optionalFields).value || "",
              zipCodeValid: findField("zipCode", optionalFields).valid || false,
              zipCodeError:
                findField("zipCode", optionalFields).errorMessage || null,
              deliveryState: findField("state", optionalFields).value || "",
              deliveryStateValid:
                findField("state", optionalFields).valid || false,
              deliveryStateError:
                findField("state", optionalFields).errorMessage || null,
              deliveryCountry: findField("destinationCountry").value || "",
              deliveryCountryValid:
                findField("destinationCountry").valid || false,
              deliveryCountryError:
                findField("destinationCountry").errorMessage || null,
              pickUpZone: findField("pickUpState").value || "",
              pickUpZoneValid: findField("pickUpState").valid || false,
              pickUpZoneError: findField("pickUpState").errorMessage || null,
              phone: findField("phoneNumber").value || "",
              phoneValid: findField("phoneNumber").valid || false,
              phoneError: findField("phoneNumber").errorMessage || null,
              altPhone: findField("altPhoneNumber", optionalFields).value || "",
              altPhoneValid:
                findField("altPhoneNumber", optionalFields).valid || true,
              pickUpPhone: findField("PickUpPhoneNumber").value || "",
              pickUpPhoneValid: findField("PickUpPhoneNumber").valid || false,
              pickUpPhoneError:
                findField("PickUpPhoneNumber").errorMessage || null,
              email: findField("email", optionalFields).value || "",
              emailValid: findField("email", optionalFields).valid || false,
              emailError:
                findField("email", optionalFields).errorMessage || null,
              pickUpAddress: findField("pickUpAddress").value || "",
              pickUpAddressValid: findField("pickUpAddress").valid || false,
              pickUpAddressError:
                findField("pickUpAddress").errorMessage || null,
              cost: manifestItem.cost,
              costValid: !(
                manifestItem.cost === null ||
                manifestItem.cost === undefined ||
                manifestItem.cost === 0
              ),
              discountedCost: manifestItem.discountedCost,
              weight: findField("weight", optionalFields).value || "",
              weightValid: findField("weight", optionalFields).valid || false,
              weightError:
                findField("weight", optionalFields).errorMessage || null,
              itemValue: findField("value", optionalFields).value || "",
              itemValueValid: findField("value", optionalFields).valid || true,
              itemValueError:
                findField("value", optionalFields).errorMessage || null,
              quantity: findField("quantity", optionalFields).value || "",
              quantityValid:
                findField("quantity", optionalFields).valid || true,
              quantityError:
                findField("quantity", optionalFields).errorMessage || null,
              additionalInfo:
                findField("additionalInformation", optionalFields).value || "",
              additionalInfoValid:
                findField("additionalInformation", optionalFields).valid ||
                true,
              additionalInfoError:
                findField("additionalInformation", optionalFields)
                  .errorMessage || null,
              action: "Delete",
              rowValid: isRowValid,
              rowError: isRowValid
                ? null
                : manifestItem.row
                    .filter(
                      (item: any) =>
                        !item.valid && !optionalFields.includes(item.name)
                    )
                    .map((item: any) => item.errorMessage)
                    .join("; "),
            };
          }
        );

        const sortedUsersInfo = usersInfo.sort((a, b) => {
          return a.rowValid === b.rowValid ? 0 : a.rowValid ? 1 : -1;
        });

        setExportUsers(sortedUsersInfo);
      }
    }
  }, [extractData, query]);

  useEffect(() => {
    if (extractOrderError && isExtractOrderError) {
      handleErrorToast(extractOrderError);
    }
  }, [isExtractOrderError, extractOrderError]);

  useEffect(() => {
    if (query !== "" && uiFileType !== "") {
      setIsExtractOrder(!isExtractOrder);
    }
  }, [query, uiFileType]);

  const handleSubmitOrder = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (uiFileType !== "") {
        const body: any = {
          fileName: acceptedDocFile?.name,
          type:
            query === "International - Sending to Nigeria (Import)"
              ? "import"
              : query === "International - Sending from Nigeria (Export)"
              ? "export"
              : "local",
          file: uiFileType,
          referralCode: discountCode,
        };
        if (query === "International - Sending to Nigeria (Import)") {
          body.importLocationId = deliveryDetails.importCountry;
        }
        await extractOrder(body).unwrap();
      }
    } catch (e) {}
  };
  useEffect(() => {
    localStorage.removeItem("amountAdded");
  }, []);

  useEffect(() => {
    if (query === "Local") {
      const totalCost = users.reduce(
        (accumulator: number, currentItem: any) => {
          return accumulator + Number(currentItem.cost);
        },
        0
      );
      const totalDiscountCost = users.reduce(
        (accumulator: number, currentItem: any) => {
          return accumulator + Number(currentItem.discountedCost);
        },
        0
      );
      setTotalCost(totalCost);
      setTotalDiscountCost(totalDiscountCost);
    } else if (query === "International - Sending to Nigeria (Import)") {
      const totalCost = importUsers.reduce(
        (accumulator: number, currentItem: any) => {
          return accumulator + Number(currentItem.cost);
        },
        0
      );
      const totalDiscountCost = importUsers.reduce(
        (accumulator: number, currentItem: any) => {
          return accumulator + Number(currentItem.discountedCost);
        },
        0
      );
      setTotalCost(totalCost);
      setTotalDiscountCost(totalDiscountCost);
    } else if (query === "International - Sending from Nigeria (Export)") {
      const totalCost = exportUsers.reduce(
        (accumulator: number, currentItem: any) => {
          return accumulator + Number(currentItem.cost);
        },
        0
      );
      const totalDiscountCost = exportUsers.reduce(
        (accumulator: number, currentItem: any) => {
          return accumulator + Number(currentItem.discountedCost);
        },
        0
      );
      setTotalCost(totalCost);
      setTotalDiscountCost(totalDiscountCost);
    }
  }, [users, importUsers, exportUsers, query]);

  const onGenerate = () => {
    const response = generateRandomDataWithDelay();
    if (orgCode) {
      setReferenceId(`c_${response}_${orgCode}_OP`);
      setReferenceIdTwo(`${response}_${orgCode}_OP`);
      setMoneyReference(`c_${orgCode}_${response}${new Date().valueOf()}_BFW`);
    }
  };

  const config = {
    reference: referenceIdTwo,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: (Number(totalDiscountCost)||Number(totalCost) )* 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };

  const addMoneyConfig = {
    reference: moneyReference,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: Number(amountAdded) * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };
  const initializeAddMoney = usePaystackPayment(addMoneyConfig);
  const initializePayment = usePaystackPayment(config);

  const onAddMoney = () => {
    setFormErrors(validateAmount(amountAdded));
    if (amountAdded !== "") {
      initializeAddMoney(onAddMoneySuccess, onAddMoneyClose);
    }
  };

  const onAddMoneySuccess = () => {
    localStorage.setItem("amountAdded", amountAdded);
    setAddMoney(false);
    setAddMoneySuccess(true);
    setFetchNotifications(true);

    setTimeout(() => {
      setFetchNotifications(false);
    }, 60000);
  };
  const onAddMoneyClose = () => {
    onGenerate();
  };

  const handleDelete = (id: number) => {
    const updatedUsers = users.filter((user) => user.id !== id);
    setUsers(updatedUsers);
  };
  const handleImportDelete = (id: number) => {
    const updatedUsers = importUsers.filter((user) => user.id !== id);
    setImportUsers(updatedUsers);
  };
  const handleExportDelete = (id: number) => {
    const updatedUsers = exportUsers.filter((user) => user.id !== id);
    setExportUsers(updatedUsers);
  };
  const onDeleteOrder = async () => {
    const orderNos: any = localStorage.getItem("orderNos");
      const parsedOrderNos = JSON.parse(orderNos);
    const valuesString = Object.values(parsedOrderNos).join(", ");
    await removeOrder({ orderNo: valuesString })
      .unwrap()
      .then((data) => {
        // onHandlePayment(payType);
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  const onHandlePayment = async (payType: string) => {
    const newFirstLetter = payType.toLowerCase() === "wallet" ? "w" : "c";

    const requestPayload = users.reduce((acc, user, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;
      acc[uniqueID] = {
        recipientAddress: user.address,
        recipientState: user.zone,
        recipientName: user.name,
        recipientPhone: user.phone,
        uniqueID: `fez-${referenceIdTwo}-${idx}`,
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: newFirstLetter + referenceId.slice(1),
        recipientEmail: user.email,
        weight: user.weight,
        pickUpAddress: user.pickUpAddress,
        pickUpState: user.pickUpZone,
        referralCode: discountCode,
        additionalDetails: "",
        valueOfItem: Number(user.itemValue),
        clientPhone: user.pickUpPhone,
        cost: Number(user.cost),
        discountedCost: Number(user?.discountedCost),
        itemDescription: user.itemDescription,
        locker_box_name: "",
        offline: payType.toLowerCase() === "pay-later" ? "PL" : "",
      };
      return acc;
    }, {} as { [key: string | number]: Props });

    await getOrder({ body: requestPayload })
      .unwrap()
      .then((data) => {
        if (data) {
          localStorage.setItem("orderNos", JSON.stringify(data?.orderNos));
          if (payType.toLowerCase() === "paystack") {
            initializePayment(onSuccess, onClose);
          } else if (payType.toLowerCase() === "wallet") {
            processPayment();
          } else {
            onUpdateReferralCode();
            setIsModalPayment(true);
          }
        }
      })
      .catch((e) => {
        handleErrorToast(e);
      });
  };
  const onHandleImportPayment = async () => {
    const requestPayload = importUsers.reduce((acc, item, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;
      const idOfItemCategory =
        filteredItemSearch.find(
          (category: { id: any; name: string }) =>
            category.name === item.itemCategory
        )?.id || "";

      acc[uniqueID] = {
        recipientName: item.name,
        recipientPhone: item.phone,
        recipientAddress: item.address,
        recipientState: item.zone,
        recipientEmail: item.email,
        weight: item.weight,
        referralCode: discountCode,
        additionalDetails: item.additionalInfo,
        uniqueID: uniqueID,
        cost: Number(item.cost),
        discountedCost: Number(item.discountedCost),
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: "c" + referenceId.slice(1),
        itemDescription: "",
        recipientCity: item?.deliveryCity,
        importLocationId: deliveryDetails.importCountry,
        valueOfItem: Number(item.itemValue),
        trackingId: item.trackingId,
        itemCategory: idOfItemCategory,
        businessName: fezOrgDetails?.["Org Full Name"],
      };
      return acc;
    }, {} as { [key: string | number]: Props });
    await getOrder({ body: requestPayload, requestType: "import" })
      .unwrap()
      .then((data) => {
        onUpdateReferralCode();
        localStorage.setItem("orderNos", JSON.stringify(data?.orderNos));
        setImportUsers([]);
        setIsModalPayment(true);
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };
  const onHandleExportPayment = async (payType: string) => {
    const newFirstLetter = payType.toLowerCase() === "wallet" ? "w" : "c";

    const requestPayload = exportUsers.reduce((acc, item, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;
      const exportLocation = filteredExportCountrySearch.find(
        (country: ExportState) => country.name === item.deliveryCountry
      );
      const weights = exportLocation?.int_price?.map(
        (data: ItemWeight) => data?.weight
      );
      const weightId = weights?.find(
        (weight: Ilist) => weight?.name === item?.weight
      );

      acc[uniqueID] = {
        recipientName: item.name,
        recipientPhone: item.phone,
        recipientAddress: item.address,
        recipientEmail: item.email,
        weight: Number(weightId?.id),
        pickUpAddress: item.pickUpAddress,
        pickUpState: item.pickUpZone,
        referralCode: discountCode,
        additionalDetails: item.additionalInfo,
        uniqueID,
        cost: Number(item.cost),
        discountedCost: Number(item.discountedCost),
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: newFirstLetter + referenceId.slice(1),
        itemDescription: item.additionalInfo,
        recipientCity: item.deliveryCity,
        zipCode: item.zipCode,
        exportLocationId: exportLocation?.id,
        valueOfItem: Number(item.itemValue),
        offline: payType.toLowerCase() === "pay-later" ? "PL" : "",
      };
      return acc;
    }, {} as { [key: string | number]: any });
    await getOrder({ body: requestPayload, requestType: "export" })
      .unwrap()
      .then((data) => {
        localStorage.setItem("orderNos", JSON.stringify(data?.orderNos));
        if (payType.toLowerCase() === "paystack") {
          initializePayment(onSuccess, onClose);
        } else if (payType.toLowerCase() === "wallet") {
          processPayment();
        } else {
          onUpdateReferralCode();
          setExportUsers([]);
          setIsModalPayment(true);
        }
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  useEffect(() => {
    if (
      query === "Local" &&
      (result.rowInvalidCount > 0 ||
        users.some((user) => user.cost === 0) ||
        isLoading ||
        noCostError)
    ) {
      setNoPayment(true);
    } else if (
      query === "International - Sending to Nigeria (Import)" &&
      (result.rowInvalidCount > 0 ||
        importUsers.some((user) => user.cost === 0) ||
        importLoading ||
        noCostError)
    ) {
      setNoPayment(true);
    } else if (
      query === "International - Sending from Nigeria (Export)" &&
      (result.rowInvalidCount > 0 ||
        exportUsers.some((user) => user.cost === 0) ||
        exportLoading ||
        noCostError)
    ) {
      setNoPayment(true);
    } else {
      setNoPayment(false);
    }
  }, [
    users,
    importUsers,
    exportUsers,
    query,
    result,
    importLoading,
    exportLoading,
    isLoading,
    noCostError,
  ]);

  const errorRefs = useRef<{ [key: string]: HTMLTableCellElement | null }>({});

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  const handleGoToError = () => {
    const firstInvalidField = Object.values(errorRefs.current).find(
      (ref) => ref instanceof HTMLTableCellElement
    );

    if (firstInvalidField) {
      firstInvalidField.scrollIntoView(false);
      firstInvalidField.focus();
    }
  };
  return (
    <div className="mb">
      {!showPayment && (
        <>
          {!showBulkTableData ? (
            <div className=" overflow-x-hidden ">
              {!isBulkUpload && (
                <>
                  <div
                    onClick={() => onGoBack()}
                    className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-[100px] md:hidden"
                  >
                    <div>
                      {" "}
                      <img
                        className="w-3 h-3"
                        src="/images/backArrow.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                      Go back
                    </p>
                  </div>
                  <div className="mt-2  sm:mt-[72px] rounded-lg px-4 md:px-10 max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
                    <div className="w-full md:px-0 py-6 sm:py-8 onestBold">
                      <div className="hidden md:flex items-center justify-between">
                        <div
                          onClick={onGoBack}
                          className="flex gap-1 cursor-pointer w-[100px]"
                        >
                          <div>
                            {" "}
                            <img
                              className="w-3 h-3"
                              src="/images/backArrow.svg"
                              alt=""
                            />
                          </div>
                          <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                            Go back
                          </p>
                        </div>
                        <div className="flex gap-2.5">
                          <div className="">
                            <div
                              className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                            ></div>
                            <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                              Add
                            </p>
                          </div>
                          <div>
                            <div
                              className={`w-[82px] h-[6px] bg-[#e0f4dc] rounded-xl`}
                            ></div>
                            <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                              Upload
                            </p>
                          </div>
                          <div>
                            <div
                              className={`w-[82px] h-[6px] bg-[#e0f4dc] rounded-xl`}
                            ></div>
                            <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                              Payment
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex md:hidden items-center justify-between mt-4 mb-6 mx-4">
                        <div className="relative">
                          <FaCircle color="#18AF04" />
                          <p className="absolute mt-1 text-[#68726B] font-[500] -left-2 text-[12px] font-onestMedium w-[100px]">
                            Add
                          </p>
                        </div>
                        <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                        <div className="relative">
                          <FaCircle color="#D4F3D5" />
                          <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                            Upload
                          </p>
                        </div>
                        <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                        <div className="relative">
                          <FaCircle color="#D4F3D5" />
                          <p className="absolute mt-1 text-[#68726B] font-[500] -left-4 text-[12px] font-onestMedium w-[100px]">
                            Payment
                          </p>
                        </div>
                      </div>
                      <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                        You are bulk shipping your orders
                      </h1>
                      <p className="text-[#0e1728] text-sm font-[500] font-onestMedium w-[90%] mt-12">
                        Upload an Excel File with Delivery details and upload an
                        Excel file with delivery details.
                      </p>

                      <div>
                        <div className="flex mt-6 gap-2 items-center">
                          <p className="border-[2px] border-[#E4EBF5] text-[#4a564d] rounded-full px-2.5 py-1 text-xs font-[500] font-onestMedium">
                            1
                          </p>
                          <h1 className="text-[#4a564d] text-[13px] font-[500] font-onestMedium">
                            Select delivery type
                          </h1>
                        </div>
                        <div className="mt-6 pb-4">
                          <DropDownSelect
                            title="Select delivery type"
                            lists={bulkDeliveryType}
                            value={query}
                            setQuery={setQuery}
                            isOpen={isDeliveryType}
                            toggleDropdown={toggleDeliveryType}
                            handleOptionClick={handleDeliveryType}
                            placeholder="Select type"
                            name="example-dropdown"
                          />
                          {formErrors.deliveryType && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.deliveryType}
                            </span>
                          )}
                        </div>
                      </div>
                      {query ===
                        "International - Sending to Nigeria (Import)" && (
                        <>
                          <div className="mt-3">
                            <div className="flex">
                              <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                                Select Pick-up country
                              </p>
                              <span className="text-red-500 text-sm">*</span>
                            </div>
                            <Dropdown
                              dropdown={
                                <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                  <div className="flex items-center space-x-1">
                                    <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                    <span
                                      className={` ${
                                        deliveryDetails.importCountry === ""
                                          ? "text-[#9899A1]"
                                          : "text-[#000]"
                                      }  font-onestMedium text-sm`}
                                    >
                                      {deliveryDetails.importCountry === ""
                                        ? "Select country"
                                        : importCountryStateName}
                                    </span>
                                  </div>
                                  <HiOutlineChevronDown />
                                </div>
                              }
                              search={
                                <div>
                                  <TextInput
                                    value={searchCountryState}
                                    name={"search"}
                                    type={"text"}
                                    placeholder="Search"
                                    onChange={(e) =>
                                      setSearchCountryState(e.target.value)
                                    }
                                  />
                                </div>
                              }
                              dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                              dropdownOptions={[
                                {
                                  customChild: (
                                    <div>
                                      <div>
                                        {filteredCountrySearch?.map(
                                          (item: any, idx: number) => {
                                            return (
                                              <div
                                                key={idx}
                                                onClick={() => {
                                                  setSearchCountryState("");
                                                  setDeliveryDetails(
                                                    (prev) => ({
                                                      ...prev,
                                                      importCountry: item.id,
                                                    })
                                                  );
                                                  setImportCountryStateName(
                                                    item?.country?.name
                                                  );
                                                  setShowAddresses(item);
                                                  setCurrencyValue(
                                                    item?.currency?.code
                                                  );
                                                  setFormErrors(
                                                    (prev: any) => ({
                                                      ...prev,
                                                      importCountry: "",
                                                    })
                                                  );
                                                }}
                                              >
                                                <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                                  <p>{item?.country?.name}</p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  ),
                                },
                              ]}
                            />
                            {formErrors.importCountry && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.importCountry}
                              </span>
                            )}
                          </div>
                          {showAddresses &&
                            showAddresses?.addresses?.length > 0 && (
                              <div className="mt-6">
                                <h6 className=" items-start text-[#4A564D] flex flex-col text-[14px]  font-onestMedium">
                                  Shipping Address
                                  {showAddresses?.addresses?.map(
                                    (data: any, idx: number) => (
                                      <div className="w-full mt-2" key={idx}>
                                        <label
                                          htmlFor={"state"}
                                          className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                        >
                                          Address
                                        </label>
                                        <div className="flex space-x-3 mb-3 !border-[#E4EBF5] text-[#161718] rounded-[8px]  p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border  w-full items-center">
                                          <div
                                            className="text-[#161718]  pl-1 flex flex-col text-[15px]"
                                            key={idx}
                                          >
                                            {data?.address}
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleCopy(data?.address)
                                            }
                                            className="cursor-pointer flex space-x-1 items-center"
                                          >
                                            <PiCopySimpleLight />

                                            <p className="text-[#BEC0BE] text-sm">
                                              {showClipboard &&
                                              copiedAdd === data?.address
                                                ? "Copied!"
                                                : "Copy"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  {showAddresses && (
                                    <>
                                      <div className="w-full flex space-x-3">
                                        {showAddresses?.state && (
                                          <div className="w-full">
                                            <label
                                              htmlFor={"state"}
                                              className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                            >
                                              State
                                            </label>
                                            <div className="flex space-x-3 mb-3   p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border  !border-[#E4EBF5] rounded-[8px]  w-full items-center">
                                              <div className=" text-[#161718]  pl-1 flex flex-col text-[15px]">
                                                {showAddresses?.state}
                                              </div>
                                              <div
                                                onClick={() =>
                                                  handleCopy(
                                                    showAddresses?.state
                                                  )
                                                }
                                                className="cursor-pointer flex space-x-1 items-center"
                                              >
                                                <PiCopySimpleLight />

                                                <p className="text-[#BEC0BE] text-sm">
                                                  {showClipboard &&
                                                  copiedAdd ===
                                                    showAddresses?.state
                                                    ? "Copied!"
                                                    : "Copy"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                        {showAddresses?.city && (
                                          <div className=" w-full">
                                            <label
                                              htmlFor={"state"}
                                              className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                            >
                                              City
                                            </label>
                                            <div className="flex  mb-3 space-x-3 text-[#161718] rounded-[8px] p-2 text-[14px] cursor-text border !border-[#E4EBF5]  w-full items-center">
                                              <div className="text-[#161718]  pl-1 flex flex-col text-[15px]">
                                                {showAddresses?.city}
                                              </div>
                                              <div
                                                onClick={() =>
                                                  handleCopy(
                                                    showAddresses?.city
                                                  )
                                                }
                                                className="cursor-pointer flex space-x-1 items-center"
                                              >
                                                <PiCopySimpleLight />

                                                <p className="text-[#BEC0BE] text-sm">
                                                  {showClipboard &&
                                                  copiedAdd ===
                                                    showAddresses?.city
                                                    ? "Copied!"
                                                    : "Copy"}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="flex  space-x-3 w-full">
                                        <div>
                                          {showAddresses?.postCode && (
                                            <label
                                              htmlFor={"state"}
                                              className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                            >
                                              Post Code
                                            </label>
                                          )}
                                          {showAddresses?.postCode && (
                                            <div className="flex space-x-3 mb-3 text-[#161718] p-2 text-[14px]  !border-[#E4EBF5] border-2  w-full items-center">
                                              <div className="text-green-800  pl-1 flex flex-col text-[15px]">
                                                {showAddresses?.postCode}
                                              </div>
                                              <div
                                                onClick={() =>
                                                  handleCopy(
                                                    showAddresses?.postCode
                                                  )
                                                }
                                                className="cursor-pointer flex space-x-1 items-center"
                                              >
                                                <PiCopySimpleLight />

                                                <p className="text-[#BEC0BE] text-sm">
                                                  {showClipboard &&
                                                  copiedAdd ===
                                                    showAddresses?.postCode
                                                    ? "Copied!"
                                                    : "Copy"}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </h6>
                              </div>
                            )}
                        </>
                      )}
                      {excelSample.map((data) => (
                        <>
                          {query === data.name && (
                            <div>
                              <div className="flex mt-6 gap-2 items-center">
                                <p className="border-[2px] border-[#E4EBF5] text-[#4a564d] rounded-full px-2.5 py-1 text-xs font-[500] font-onestMedium">
                                  2
                                </p>
                                <h1 className="text-[#4a564d] text-[13px] cursor-pointer font-[500] font-onestMedium">
                                  Download Excel file for your upload
                                </h1>
                              </div>
                              <a
                                download={data.data}
                                href={data.data}
                                className="flex gap-2 items-center cursor-pointer mb-12 md:mb-20 border-[1px] mt-3 border-[#D0D7E2] rounded-[25px] w-[170px] py-2 px-3"
                              >
                                <img src="/images/excel.svg" alt="" />
                                <span className="text-[#18af04] text-[13px] font-[400] font-onestRegular">
                                  Download Excel file
                                </span>
                              </a>
                            </div>
                          )}
                        </>
                      ))}
                      <div className="flex ">
                        <Button
                          onClick={
                            query !==
                            "International - Sending to Nigeria (Import)"
                              ? onBulkUpload
                              : onImportBulkUpload
                          }
                          className={`w-full !py-2.5 !px-8 ${
                            query !==
                            "International - Sending to Nigeria (Import)"
                              ? !query && "!bg-[#8C9D8A]"
                              : !deliveryDetails.importCountry &&
                                "!bg-[#8C9D8A]"
                          }`}
                          loading={isExtractOrderLoading}
                          disabled={isExtractOrderLoading}
                          title="Next"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {isBulkUpload && (
                <>
                  <div
                    onClick={() => setIsBulkUpload(false)}
                    className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-[100px] md:hidden"
                  >
                    <div>
                      {" "}
                      <img
                        className="w-3 h-3"
                        src="/images/backArrow.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                      Go back
                    </p>
                  </div>
                  <div className="mt-2 sm:mt-[72px] mx-4 rounded-lg px-4 md:px-10 pb-8 md:py-8 max-w-[38rem] md:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
                    <div className="w-full md:px-0 onestBold">
                      <div className="hidden md:flex items-center justify-between">
                        <div
                          onClick={handleClick}
                          className="flex gap-1 cursor-pointer w-[100px]"
                        >
                          <div>
                            {" "}
                            <img
                              className="w-3 h-3"
                              src="/images/backArrow.svg"
                              alt=""
                            />
                          </div>
                          <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                            Go back
                          </p>
                        </div>
                        <div className="flex gap-2.5">
                          <div className="">
                            <div
                              className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                            ></div>
                            <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                              Add
                            </p>
                          </div>
                          <div>
                            <div
                              className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                            ></div>
                            <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                              Upload
                            </p>
                          </div>
                          <div>
                            <div
                              className={`w-[82px] h-[6px] bg-[#e0f4dc] rounded-xl`}
                            ></div>
                            <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                              Payment
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex md:hidden items-center justify-between mt-4 mb-6 mx-4">
                        <div className="relative">
                          <FaCircle color="#18AF04" />
                          <p className="absolute mt-1 text-[#68726B] font-[500] -left-2 text-[12px] font-onestMedium w-[100px]">
                            Add
                          </p>
                        </div>
                        <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                        <div className="relative">
                          <FaCircle color="#18AF04" />
                          <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                            Upload
                          </p>
                        </div>
                        <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                        <div className="relative">
                          <FaCircle color="#D4F3D5" />
                          <p className="absolute mt-1 text-[#68726B] font-[500] -left-4 text-[12px] font-onestMedium w-[100px]">
                            Payment
                          </p>
                        </div>
                      </div>
                      <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                        You are bulk shipping your orders
                      </h1>
                      <p className="mt-12 text-[#4a564d] text-[13px] font-[500]">
                        Upload your orders
                      </p>

                      <FileDragNDrop
                        setUiFileType={setUiFileType}
                        acceptedFiles={acceptedFiles}
                        setAcceptedFiles={setAcceptedFiles}
                        onDrop={onDrop}
                        loading={loading}
                        text={" Drop your file here to upload or"}
                        secondText={"Click to upload your Excel"}
                        icon={"/images/upload.svg"}
                        bgColor={"bg-[#F4F5F6]"}
                      />
                      <div className="">
                        <TextInput
                          type="text"
                          label="Enter discount code if you have one"
                          placeholder="Enter discount code"
                          name="discountCode"
                          onChange={(e) => setDiscountCode(e.target.value)}
                          value={discountCode}
                        />
                      </div>
                      <div className="mt-6 flex">
                        <Button
                          onClick={handleSubmitOrder}
                          className={`w-full !py-2.5 !px-8 font-[600] ${
                            acceptedFiles.length === 0 &&
                            "!text-[#0A5001] !bg-[#8C9D8A]"
                          }`}
                          title="Submit orders"
                          disabled={isExtractOrderLoading}
                          loading={isExtractOrderLoading}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              {query.toLowerCase() === "local" && (
                <BulkLocalUpload
                  setIsBulkUpload={setIsBulkUpload}
                  setBulkTableData={setBulkTableData}
                  users={users}
                  result={result}
                  handleGoToError={handleGoToError}
                  tableContainerRef={tableContainerRef}
                  editable={editable}
                  errorRefs={errorRefs}
                  handleChange={handleChange}
                  formErrors={formErrors}
                  handleEditClick={handleEditClick}
                  handleBlur={handleBlur}
                  searchDeliveryZone={searchDeliveryZone}
                  setSearchDeliveryZone={setSearchDeliveryZone}
                  filteredDeliveryZoneSearch={filteredDeliveryZoneSearch}
                  setFormErrors={setFormErrors}
                  searchPickUpZone={searchPickUpZone}
                  setSearchPickUpZone={setSearchPickUpZone}
                  filteredPickUpZoneSearch={filteredPickUpZoneSearch}
                  onGenerate={onGenerate}
                  setShowPayment={setShowPayment}
                  handleClick={handleClick}
                  isEditOrder={isEditOrder}
                  onEditOrder={onEditOrder}
                  handleDelete={handleDelete}
                  setIsEditOrder={setIsEditOrder}
                  editDetails={editDetails}
                  noPayment={noPayment}
                  handleDeliveryChange={handleDeliveryChange}
                  setEditDetails={setEditDetails}
                  loading={isLoading}
                />
              )}
              {query === "International - Sending to Nigeria (Import)" && (
                <BulkImportUpload
                  setIsBulkUpload={setIsBulkUpload}
                  setBulkTableData={setBulkTableData}
                  users={importUsers}
                  result={result}
                  handleGoToError={handleGoToError}
                  tableContainerRef={tableContainerRef}
                  editable={importEditable}
                  errorRefs={errorRefs}
                  handleChange={handleImportChange}
                  formErrors={formErrors}
                  handleEditClick={handleImportEditClick}
                  handleBlur={handleBlur}
                  setItemName={setItemName}
                  itemName={itemName}
                  setSearchItem={setSearchItem}
                  searchItem={searchItem}
                  filteredItemSearch={filteredItemSearch}
                  searchDeliveryZone={searchDeliveryZone}
                  setSearchDeliveryZone={setSearchDeliveryZone}
                  filteredDeliveryZoneSearch={filteredDeliveryZoneSearch}
                  setFormErrors={setFormErrors}
                  searchPickUpZone={searchPickUpZone}
                  setSearchPickUpZone={setSearchPickUpZone}
                  filteredPickUpZoneSearch={filteredPickUpZoneSearch}
                  onGenerate={onGenerate}
                  setShowPayment={setShowPayment}
                  handleClick={handleClick}
                  isEditOrder={isEditOrder}
                  onEditOrder={onEditOrder}
                  handleDelete={handleImportDelete}
                  setIsEditOrder={setIsEditOrder}
                  editDetails={importEditDetails}
                  noPayment={noPayment}
                  currencyValue={currencyValue}
                  handleDeliveryChange={handleImportDeliveryChange}
                  setEditDetails={setImportEditDetails}
                  loading={importLoading}
                />
              )}
              {query === "International - Sending from Nigeria (Export)" && (
                <BulkExportUpload
                  setIsBulkUpload={setIsBulkUpload}
                  setBulkTableData={setBulkTableData}
                  users={exportUsers}
                  result={result}
                  handleGoToError={handleGoToError}
                  tableContainerRef={tableContainerRef}
                  editable={exportEditable}
                  errorRefs={errorRefs}
                  handleChange={handleExportChange}
                  formErrors={formErrors}
                  handleEditClick={handleExportEditClick}
                  handleBlur={handleBlur}
                  filteredExportCountrySearch={filteredExportCountrySearch}
                  setFormErrors={setFormErrors}
                  searchPickUpZone={searchPickUpZone}
                  setSearchPickUpZone={setSearchPickUpZone}
                  filteredPickUpZoneSearch={filteredPickUpZoneSearch}
                  onGenerate={onGenerate}
                  setShowPayment={setShowPayment}
                  handleClick={handleClick}
                  isEditOrder={isEditOrder}
                  onEditOrder={onEditOrder}
                  searchCountryState={searchCountryState}
                  setSearchCountryState={setSearchCountryState}
                  handleDelete={handleExportDelete}
                  setIsEditOrder={setIsEditOrder}
                  editDetails={exportEditDetails}
                  noPayment={noPayment}
                  handleDeliveryChange={handleExportDeliveryChange}
                  setEditDetails={setExportEditDetails}
                  loading={exportLoading}
                  selectedWeightOption={selectedWeightOption}
                  weightLoading={isExportCountryLoading}
                  setSelectedWeightOption={setSelectedWeightOption}
                />
              )}
            </>
          )}
        </>
      )}
      {showPayment && (
        <div className="flex justify-center  pb-10 items-center">
          <div className=" mt-14 md:mt-0 w-[80%] lg:w-[75%] xl:w-[65%] ">
            <div
              onClick={onHandleBackPayment}
              className="mx-5 flex mb-2 md:hidden"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>
            <div className="md:mt-[72px] pb-10 ">
              <div className="border-[1px] border-[#E1F0DF] md:pt-8 px-4 md:px-4 lg:px-8 bg-dashboard-gradient rounded-lg">
                <div className="hidden md:flex items-center justify-between">
                  <div
                    onClick={onHandleBackPayment}
                    className="flex gap-1 cursor-pointer w-[100px]"
                  >
                    <div>
                      {" "}
                      <img
                        className="w-3 h-3"
                        src="/images/backArrow.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                      Go back
                    </p>
                  </div>
                  <div className="flex gap-2.5">
                    <div className="">
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                        Add
                      </p>
                    </div>
                    <div>
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                        Upload
                      </p>
                    </div>
                    <div>
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                        Payment
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex md:hidden items-center justify-between mt-6 mx-4">
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-2 text-[12px] font-onestMedium w-[100px]">
                      Add
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                      Upload
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-4 text-[12px] font-onestMedium w-[100px]">
                      Payment
                    </p>
                  </div>
                </div>
                <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  Make Payment
                </h1>
                <p className=" text-[#4a564d] text-[13px] font-[500]">
                  Local (within Nigeria)
                </p>
                <div className="bg-[#f3fbf2] mt-12 rounded-lg px-4 py-6 border-[1px] border-[#e1f0df]">
                  <div className="flex justify-between items-center">
                    <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                      Wallet Balance
                    </h4>
                    <div className="hidden md:block w-[45%] border-b-[1px] border-dashed border-[#b8c2cf]"></div>
                    <div>
                      {isWalletLoading ? (
                        <Loader />
                      ) : (
                        <h1
                          className={`font-[800] text-lg font-onestBold ${
                            totalCost >= walletData?.balance?.business_wallet
                              ? "text-[#F64108]"
                              : "text-[#0a5001]"
                          } `}
                        >
                          ₦
                          {formatMoney().format(
                            walletData?.balance?.business_wallet +
                              Number(localStorage.getItem("amountAdded"))
                          )}
                        </h1>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-5">
                    <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                      No of orders
                    </h4>
                    <div className="hidden md:block w-[45%] border-b-[1px] -ml-[20%] border-dashed border-[#b8c2cf]"></div>
                    {query === "Local" && (
                      <h1 className="font-[800] text-lg font-onestBold text-[#0a5001]">
                        {formatMoney().format(users?.length)}
                      </h1>
                    )}
                    {query ===
                      "International - Sending to Nigeria (Import)" && (
                      <h1 className="font-[800] text-lg font-onestBold text-[#0a5001]">
                        {formatMoney().format(importUsers?.length)}
                      </h1>
                    )}
                  </div>

                  <div className="flex justify-between items-center mt-5">
                    <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                      Total Amount
                    </h4>
                    <div className="hidden md:block w-[45%] border-b-[1px] -ml-[20%] border-dashed border-[#b8c2cf]"></div>
                    <h1 className="font-[800] whitespace-nowrap text-lg font-onestBold text-[#0a5001]">
                      {query === "International - Sending to Nigeria (Import)"
                        ? getSymbolFromCurrency(currencyValue)
                        : " ₦ "}
                      {formatMoney().format(totalCost)}
                    </h1>
                  </div>
                  {discountCode !== "" && (
                    <div className="flex justify-between items-center mt-5">
                      <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                        Total Discounted Amount
                      </h4>
                      <div className="hidden md:block w-[45%] border-b-[1px] -ml-[20%] border-dashed border-[#b8c2cf]"></div>
                      <h1 className="font-[800] whitespace-nowrap text-lg font-onestBold text-[#0a5001]">
                        {query === "International - Sending to Nigeria (Import)"
                          ? getSymbolFromCurrency(currencyValue)
                          : " ₦ "}{" "}
                        {formatMoney().format(totalDiscountCost)}
                      </h1>
                    </div>
                  )}
                </div>
                <>
                  {query !== "International - Sending to Nigeria (Import)" ? (
                    <>
                      {totalCost >= walletData?.balance?.business_wallet && (
                        <div className="pb-3">
                          <div className="flex gap-1 border-b-[1px] mt-3 p-2 border-[#F59A49] bg-[#FAEFE5] rounded-[10px]">
                            <div className="">
                              {" "}
                              <IoWarningOutline color="#F59A49" />
                            </div>
                            <h1 className="text-xs font-onestRegular font-[400] text-[#0E1728]">
                              Your wallet balance is insufficient, please add
                              money
                            </h1>
                          </div>
                          <Button
                            onClick={() => {
                              onGenerate();
                              setAddMoney(true);
                            }}
                            title="Add Money"
                            className="!w-[150px] py-4 mt-5 !bg-[#F3FBF2] border-[1px] border-[#18af04] !text-[#18af04]"
                          />
                        </div>
                      )}

                      <h1 className="block md:hidden font-[700] my-8 font-onestBold">
                        Total: N{formatMoney().format(totalCost)}
                      </h1>
                      <div className="flex-col md:flex flex-wrap md:flex-row md:space-y-0 gap-1 space-y-4 md:justify-between mt-10 mb-7">
                        <Button
                          title="Pay with Card"
                          loading={
                            orderLoading && payType.toLowerCase() === "paystack"
                          }
                          disabled={orderLoading || isWalletCheckoutLoading}
                          onClick={() => {
                            setPayType("paystack");
                            query === "Local"
                              ? onHandlePayment("paystack")
                              : onHandleExportPayment("paystack");
                          }}
                          className="w-full  py-2.5 "
                        />
                        <Button
                          title="Pay with Wallet"
                          loading={
                            (orderLoading &&
                              payType.toLowerCase() === "wallet") ||
                            isWalletCheckoutLoading
                          }
                          disabled={orderLoading || isWalletCheckoutLoading}
                          onClick={() => {
                            setPayType("wallet");
                            query === "Local"
                              ? onHandlePayment("wallet")
                              : onHandleExportPayment("wallet");
                          }}
                          className="w-full !bg-[#fff] border-[1px] border-[#18af04] !text-[#18af04]  py-2.5 mb-4 md:mb-0"
                        />
                        {fezOrgDetails?.canPayOffline === 1 && (
                          <Button
                            onClick={() => {
                              setPayType("pay-later");
                              query === "Local"
                                ? onHandlePayment("pay-later")
                                : onHandleExportPayment("pay-later");
                            }}
                            loading={
                              orderLoading &&
                              payType.toLowerCase() === "pay-later"
                            }
                            disabled={orderLoading || isWalletCheckoutLoading}
                            title="Pay Later"
                            className="w-full py-2.5 !px-12 !bg-[#d3d2d2]  !text-[#141514]"
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="mb-6">
                      <Button
                        onClick={() => {
                          onHandleImportPayment();
                        }}
                        loading={orderLoading}
                        disabled={orderLoading}
                        title="Continue shipment"
                        className="!w-fit px-4 py-2.5 mt-5  border-[1px] "
                      />
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalContainer
        open={isModalPayment}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => {
          setUsers([]);
          setIsModalPayment(false);
        }}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Yippee!! 💃🏾
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              setUsers([]);
              localStorage.removeItem("items");
              setShowPayment(false);
              navigate("/dashboard/ship-now");
            }}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/fezbox.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your order has been successfully uploaded and is ready to be
            shipped.{" "}
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="View orders"
              onClick={() => {
                setUsers([]);
                localStorage.removeItem("items");
                navigate("/dashboard/shipments");
              }}
              className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={addMoney}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[520px]"
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
            Add money
          </p>
          <div className="mt-6">
            <TextInput
              type="text"
              label="Amount"
              value={amountAdded}
              onChange={(e: any) => {
                if (e.target.value !== "") {
                  setFormErrors((prev: any) => ({
                    ...prev,
                    amount: "",
                  }));
                }
                if (!isNaN(e.target.value)) {
                  setAmountAdded(e.target.value);
                }
              }}
              placeholder="e.g 100"
            />
            {formErrors.amount && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.amount}
              </span>
            )}
          </div>
          <div className="flex justify-between mt-14">
            <Button
              onClick={() => setAddMoney(false)}
              title="Cancel"
              className="!w-[100px] py-4 !bg-[#fff] border-[1px] border-[#E4EBF5] !text-base rounded-full !text-[#18af04] !font-[500]"
            />
            <Button
              title="Add Money"
              onClick={onAddMoney}
              className="!w-[170px] py-4 bg-[#18AF04] rounded-full !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={addMoneySuccess}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setAddMoneySuccess(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Yippee!! 💃🏾
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              setAmountAdded(null);
              setAddMoneySuccess(false);
            }}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/fezbox.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Money has been added to your wallet
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Close"
              onClick={() => {
                setAmountAdded(null);
                setAddMoneySuccess(false);
              }}
              className="!w-[210px] py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default BulkShipment;
