import React, { useState } from "react";
import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import AuthLayout from "components/layout/AuthLayout";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "services/slices/authSlice";
import { handleErrorToast } from "helpers/toastMessage";

const Login = () => {

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const navigate = useNavigate();

  const onTogglePasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsPasswordVisible((prevState) => !prevState);
  };

  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.email || values.email === "") {
      errors.email = "Email is required";
    } 
    if (!values.password || values.password === "") {
      errors.password = "Password is required";
    } 
    return errors;
  };

  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const intialValues = {
    email: "",
    password: "",
  };
  const [users, setUsers] = useState(intialValues);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateStepOne(users));
    const body = {
      user_id: users.email,
      password: users.password,
    };
    if (users.email && users.password) {
      await login(body)
        .unwrap()
        .then((data) => {
          localStorage.setItem("webhook", data?.webhooks?.[0]?.url);
          localStorage.setItem(
            "integration-key",
            data?.platformIntegrations?.[0]?.integration_key
          );
          localStorage.setItem("token", data?.authDetails?.authToken);
          localStorage.setItem("secret-key", data?.orgDetails["secret-key"]);
          localStorage.setItem("fez-org-code", data?.orgDetails["orgCode"]);
          localStorage.setItem("fez-org-id", data?.orgDetails["orgId"]);
          localStorage.setItem("fez-org-email", data?.orgDetails["orgEmail"]);
          localStorage.setItem(
            "expire-token-date",
            data?.authDetails?.expireToken
          );
          localStorage.setItem("fez-user", JSON.stringify(data?.userDetails));
          localStorage.setItem(
            "fez-org-details",
            JSON.stringify(data?.orgDetails)
          );
          navigate("/dashboard");
        })
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };

  const handleErrorChange = (name: string, value: any) => {
    if (name === "email" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    }  else if (name === "password" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUsers((prev) => ({
      ...prev,
      [name]: value,
    }));
    handleErrorChange(name, value);
  };

  return (
    <AuthLayout
      children={
        <div className="sm:px-[40px] w-full lg:px-0 ">
       
          <div className="flex justify-center max-w-[600]">
            <div className="h-fit w-full  ">
              <div className=" bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-10 ">
                <div className=" ">
                  <p className="md:text-2xl text-xl text-black font-onestMedium">
                    Hello there
                  </p>
                  <p className="text-[#A0ABA3] font-onestMedium text-base md:text-lg">
                    Sign in to continue
                  </p>
                </div>
                <form action="" onSubmit={handleSubmit}>
                  <div className="">
                    <div className="my-[30px]  space-y-[18px]">
                      <div className=" w-full">
                        <TextInput
                          type="text"
                          label="Email Address or User ID"
                          name="email"
                          onChange={handleChange}
                          value={users.email}
                          placeholder="Enter email address/ user id"
                        />
                        {formErrors.email && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.email}
                          </span>
                        )}
                      </div>
                      <div className=" w-full">
                        <TextInput
                          type={isPasswordVisible ? "text" : "password"}
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          value={users.password}
                          onTogglePasswordVisibility={
                            onTogglePasswordVisibility
                          }
                          placeholder="Enter password"
                          isPasswordVisible={isPasswordVisible}
                          isPassword
                        />
                        {formErrors.password && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.password}
                          </span>
                        )}
                      </div>
                      <p
                        className="text-sm text-[#15B900] font-onestMedium text-end cursor-pointer"
                        onClick={() => navigate("/forgot-password")}
                      >
                        Forgot Password?
                      </p>
                    </div>
                    <div className=" mt-[18px]">
                      <Button
                        title="Sign In"
                        type="submit"
                        loading={isLoginLoading}
                        disabled={isLoginLoading}
                      />
                    </div>
                    <p className="text-sm font-onestMedium text-[#A0ABA3] text-center mt-2">
                      Don’t have an account?{" "}
                      <span
                        className="text-[#15B900] cursor-pointer"
                        onClick={() => navigate("/register")}
                      >
                        Sign Up
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
export default Login;
