import { IoWarningOutline } from "react-icons/io5";
import { FaCircle } from "react-icons/fa6";
import { DropDownSelect } from "components/common/form/Dropdown";
import { useEffect, useState } from "react";
import { usePaystackPayment } from "react-paystack";
import { useNavigate } from "react-router";
import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import { TextArea } from "components/common/form/textArea";
import { Link } from "react-router-dom";
import ModalContainer from "components/Modal";
import { MdAdd } from "react-icons/md";
import { PiCopySimpleLight } from "react-icons/pi";
import {
  useGetCountryQuery,
  useGetExportCountryQuery,
  useGetOrderWeightQuery,
  useOrderMutation,
  useRemovePendingOrderMutation,
  useUpdateReferralMutation,
} from "services/slices/shipNow";
import { useGetStatesQuery } from "services/slices/dashboard";
import Dropdown from "components/common/form/SelectDropdown";
import {
  useGetExportCostPriceMutation,
  useGetImportCostPriceMutation,
  useGetLocalCostPriceMutation,
} from "services/slices/pricing";
import { formatMoney } from "helpers/formatMoney";
import {
  useGetWalletBalanceQuery,
  useWalletCheckoutMutation,
} from "services/slices/wallet";
import { generateRandomDataWithDelay } from "helpers/generateRandomNumber";
import { Loader } from "components/Loader";
import { useGetUnReadNotificationsQuery } from "services/slices/notification";
import { handleErrorToast } from "helpers/toastMessage";
import { useGetItemCategoryQuery } from "services/slices/shipNow";
import { HiOutlineChevronDown } from "react-icons/hi";
import getSymbolFromCurrency from "currency-symbol-map";
import { copyToClipboard } from "helpers/handleCopy";

export type Ilist = {
  id: number | null;
  name: string;
};

interface Props {
  recipientAddress: string;
  recipientState: string;
  recipientName: string;
  recipientPhone: string;
  uniqueID: string;
  BatchID: string;
  orderRequestSource: string;
  paymentReference: string;
  recipientEmail: string;
  weight: string;
  pickUpAddress: string;
  pickUpState: string;
  referralCode: string;
  additionalDetails: string;
  cost: number;
  discountedCost: number;
  itemDescription: string;
  locker_box_name: string;
  offline: string;
}
interface Address {
  id: number;
  import_location_id: number;
  address: string;
  status: number;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

export interface Location {
  state: string;
  city: string;
  postCode: string;
  addresses: Address[];
}
const initialValues = {
  state: "",
  pickUpState: "",
  weight: "",
  pickUpAddress: "",
  recipientName: "",
  recipientPhoneNumber: "",
  recipientEmail: "",
  quantity: "",
  itemDescription: "",
  importCountry: "",
  discountCode: "",
  dropOffAddress: "",
  valueOfItem: "",
  pickUpPhoneNumber: "",
  importItem: "",
  importReferral: "",
  importDescription: "",
  importQuantity: "",
  importWeight: "",
  importRecipientEmail: "",
  importRecipientPhone: "",
  importRecipientName: "",
  importDropOffAdd: "",
  importVendorName: "",
  importOrderNumber: "",
  importShippingAdd: "",
  importDropOffState: "",
  importValueofItem: "",
  file: "",
  fileNames: "",
  exportCountry: "",
  exportRecipientName: "",
  exportWeight: "",
  exportReferral: "",
  exportItemDescription: "",
  exportQuantity: "",
  exportRecipientEmail: "",
  exportPhoneNumber: "",
  exportZipCode: "",
  exportDropOffAddress: "",
  exportDropOffCity: "",
  exportPickUpState: "",
  exportValueofItem: "",
  exportPickUpPhoneNumber: "",
  exportPickUpAddress: "",
};

interface State {
  id: string;
  state: string;
  country?: {
    name: string;
  };
}
interface ExportState {
  id: string;
  name: string;
}
export interface Item {
  id: string;
  name: string;
}
function SingleShipment() {
  const navigate = useNavigate();
  const orgEmail = localStorage.getItem("fez-org-email");
  const fezOrg: any = localStorage.getItem("fez-org-details");
  const userData: any = localStorage.getItem("fez-user");
  const orgCode: any = localStorage.getItem("fez-org-code");
  const id = userData?.userID;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const [query, setQuery] = useState<string>("");
  const [payType, setPayType] = useState<string>("");
  const [isDeliveryType, setIsDeliveryType] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Ilist>({
    id: null,
    name: "",
  });
  const [selectedWeightOption, setSelectedWeightOption] = useState<Ilist>();
  const [totalWeight, setTotalWeight] = useState(0);
  const [weightIsOpen, setWeightIsOpen] = useState<boolean>(false);
  const [isPayment, setIsPayment] = useState<boolean>(false);
  const [isCreateShipment, setIsCreateShipment] = useState<boolean>(false);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [addOrder, setAddOrder] = useState<boolean>(false);
  const [differentDeliveryType, setDifferentDeliveryType] =
    useState<boolean>(false);
  const [editedDetails, setEditedDetails] = useState<any>();
  const [referenceId, setReferenceId] = useState<string>("");
  const [proof, setProof] = useState<number>(0);
  const [currencyValue, setCurrencyValue] = useState<string>("");
  const [referenceIdTwo, setReferenceIdTwo] = useState<string>("");
  const [moneyReference, setMoneyReference] = useState<string>("");
  const [importDropOffStateName, setImportDropOffStateName] =
    useState<string>("");
  const [showAddresses, setShowAddresses] = useState<Location>();
  const [showClipboard, setShowClipboard] = useState<boolean>(false);
  const [copiedAdd, setCopiedAdd] = useState("");
  const [isModalPayment, setModalPayment] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<string>("");
  const [searchCountryState, setSearchCountryState] = useState<string>("");
  const [searchItem, setSearchItem] = useState<string>("");
  const [importCountryStateName, setImportCountryStateName] =
    useState<string>("");
  const [exportCountryStateName, setExportCountryStateName] =
    useState<string>("");
  const [searchPickUpState, setSearchPickUpState] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [itemName, setItemName] = useState<string>("");
  const [pickUpStateName, setPickUpStateName] = useState<string>("");
  const [exportPickUpStateName, setExportPickUpStateName] =
    useState<string>("");
  const [deliveryDetails, setDeliveryDetails] = useState(initialValues);
  const [editOrder, setEditOrder] = useState<boolean>(false);
  const [addMoney, setAddMoney] = useState<boolean>(false);
  const [amountAdded, setAmountAdded] = useState<any>();
  const [addMoneySuccess, setAddMoneySuccess] = useState<boolean>(false);
  const [clickPayment, setClickPayment] = useState<boolean>(false);
  const [weightArray, setWeightArray] = useState<Item[]>([]);
  const [fetchNotifications, setFetchNotifications] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const { data, isLoading: isLoadingState } = useGetStatesQuery();
  const [getCost, { isLoading }] = useGetLocalCostPriceMutation();
  const [updateReferral, { isLoading: isUpdatingReferral }] =
    useUpdateReferralMutation();
  const [getImportCostPrice, { isLoading: importLoading }] =
    useGetImportCostPriceMutation();
  const [getExportCostPrice, { isLoading: exportLoading }] =
    useGetExportCostPriceMutation();
  const [getOrder, { isLoading: orderLoading }] = useOrderMutation();
  const { data: itemData, isLoading: isItemLoading } =
    useGetItemCategoryQuery();

  const {
    data: weightData,
    isLoading: isWeightLoading,
    refetch: refetchWeight,
  } = useGetOrderWeightQuery({ id: deliveryDetails?.exportCountry });

  const { data: walletData, isLoading: isWalletLoading } =
    useGetWalletBalanceQuery(undefined, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [walletCheckOut, { isLoading: isWalletCheckoutLoading }] =
    useWalletCheckoutMutation();
  const [removeOrder] = useRemovePendingOrderMutation();
  const {
    data: countryData,
    error: countryError,
    isLoading: isCountryLoading,
  } = useGetCountryQuery();
  const {
    data: exportCountryData,
    error: exportCountryError,
    isLoading: isExportCountryLoading,
  } = useGetExportCountryQuery();

  useEffect(() => {
    if (Array.isArray(weightData?.weight)) {
      const sortedWeights = [...weightData.weight].sort(
        (a: Item, b: Item) => parseFloat(a.name) - parseFloat(b.name)
      );
      setWeightArray(sortedWeights);
    }
  }, [weightData]);

  const { refetch: refetchNotification } = useGetUnReadNotificationsQuery(
    {
      id,
    },
    { skip: !fetchNotifications }
  );
  const deliveryType: any = [
    { id: 1, name: "Local (within Nigeria)" },
    { id: 2, name: "International - Sending to Nigeria (Import)" },
    { id: 3, name: "International - Sending from Nigeria (Export)" },
    // { id: 4, name: "Safelocker" },
  ];
  const filteredStateSearch: State[] | [] = data?.states
    ?.slice()
    ?.sort((a: State, b: State) => a.state.localeCompare(b.state))
    ?.filter((item: State) =>
      item?.state?.toLowerCase()?.includes(searchState.toLowerCase())
    );
  const filteredPickUpStateSearch: State[] | [] = data?.states
    ?.slice()
    ?.sort((a: State, b: State) => a.state.localeCompare(b.state))
    ?.filter((item: State) =>
      item.state.toLowerCase().includes(searchPickUpState.toLowerCase())
    );
  const filteredItemSearch: Item[] | [] = itemData?.data?.categories
    ?.slice()
    ?.sort((a: Item, b: Item) => a.name.localeCompare(b.name))
    ?.filter((item: Item) =>
      item?.name?.toLowerCase()?.includes(searchItem.toLowerCase())
    );
  const filteredCountrySearch = countryData?.data?.importLocations
    ?.filter((item: State) =>
      item?.country?.name
        ?.toLowerCase()
        ?.includes(searchCountryState.toLowerCase())
    )
    ?.sort((a: State, b: State) =>
      (a?.country?.name || "").localeCompare(b?.country?.name || "")
    );

  const filteredExportCountrySearch = exportCountryData?.data?.exportLocations
    ?.filter((item: ExportState) =>
      item?.name?.toLowerCase()?.includes(searchCountryState?.toLowerCase())
    )
    ?.sort((a: ExportState, b: ExportState) => a.name.localeCompare(b.name));

  const onHandleBackPayment = () => {
    setIsPayment(false);
  };
  const fezOrgDetails = JSON.parse(fezOrg);

  useEffect(() => {
    if (fezOrgDetails) {
      setDeliveryDetails((prev) => ({
        ...prev,
        pickUpAddress: fezOrgDetails.orgAddress,
        pickUpPhoneNumber: fezOrgDetails.orgPhone,
        pickUpState: fezOrgDetails.orgState,
      }));
      setPickUpStateName(fezOrgDetails.orgState);
    }
  }, []);

  const [items, setItems] = useState<any[]>(() => {
    const savedItems = localStorage.getItem("items");
    return savedItems ? JSON.parse(savedItems) : [];
  });

  const handleCopy = (address: string) => {
    setCopiedAdd(address);
    copyToClipboard(address, setShowClipboard);
  };

  useEffect(() => {
    if (deliveryDetails?.exportCountry) {
      refetchWeight();
    }
  }, [deliveryDetails?.exportCountry]);

  useEffect(() => {
    if (fetchNotifications) {
      refetchNotification();
      setFetchNotifications(false);
    }
  }, [fetchNotifications, refetchNotification]);

  const toggleDeliveryType = () => {
    setIsDeliveryType((prev) => !prev);
  };

  const handleDeliveryType = (option: any) => {
    if (items.length > 0) {
      items.map((item) => {
        if (item.deliveryType === option.name) {
          setSelectedOption(option);
          setQuery(option.name);
          setDeliveryDetails((prev) => ({
            ...prev,
            deliveryType: option.name,
          }));
          setFormErrors((prev: any) => ({
            ...prev,
            deliveryType: "",
          }));
          setIsDeliveryType(false);
          setDifferentDeliveryType(false);
        } else {
          setDifferentDeliveryType(true);
        }
      });
    } else if (items.length === 0) {
      setSelectedOption(option);
      setQuery(option.name);
      setDeliveryDetails((prev) => ({
        ...prev,
        deliveryType: option.name,
      }));
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
      setIsDeliveryType(false);
      setDifferentDeliveryType(false);
    }
  };

  useEffect(() => {
    const newTotalWeight = items.reduce(
      (total, item) => total + item.itemWeight * item.quantity,
      0
    );
    setTotalWeight(newTotalWeight);
  }, [items]);

  const validateStepOne = (values: any) => {
    let errors: any = {};

    if (!values.pickUpState || values.pickUpState === "") {
      errors.pickUpState = "Pick up state is required";
    }

    if (!values.dropOffAddress || values.dropOffAddress === "") {
      errors.dropOffAddress = "Drop-off address is required";
    }

    if (!values.pickUpAddress || values.pickUpAddress === "") {
      errors.pickUpAddress = "Pick-up address is required";
    }

    if (!values.state || values.state === "") {
      errors.state = "State is required";
    }

    if (!values.deliveryType || values.deliveryType === "") {
      errors.deliveryType = "DeliveryType is required";
    }
    if (!values.recipientPhoneNumber || values.recipientPhoneNumber === "") {
      errors.recipientPhoneNumber = "Recipient phone number is required";
    } else if (values.recipientPhoneNumber?.length !== 11) {
      errors.recipientPhoneNumber = "Recipient phone number must be 11 digits";
    }
    if (values.pickUpPhoneNumber === "" || !values.pickUpPhoneNumber) {
      errors.pickUpPhoneNumber = "";
    } else if (
      values.pickUpPhoneNumber &&
      values.pickUpPhoneNumber?.length !== 11
    ) {
      errors.pickUpPhoneNumber = "Pick-up phone number must be 11 digits";
    }
    if (!values.recipientName || values.recipientName === "") {
      errors.recipientName = "Recipient name is required";
    }
    if (!values.itemDescription || values.itemDescription === "") {
      errors.itemDescription = "Item description is required";
    }
    if (!values.weight || values.weight === "") {
      errors.weight = "Weight is required";
    }
    if (!values.valueOfItem || values.valueOfItem === "") {
      errors.valueOfItem = "Value of item is required";
    }
    if (values.recipientEmail && !regex.test(values.recipientEmail)) {
      errors.recipientEmail = "Invalid email format";
    }
    return errors;
  };
  const validateStepTwo = (values: any) => {
    let errors: any = {};

    if (!values.importDropOffState || values.importDropOffState === "") {
      errors.importDropOffState = "Drop-off country is required";
    }

    if (!values.importCountry || values.importCountry === "") {
      errors.importCountry = "Pick-up country is required";
    }

    if (!values.importOrderNumber || values.importOrderNumber === "") {
      errors.importOrderNumber = "Order number is required";
    }

    if (!values.importVendorName || values.importVendorName === "") {
      errors.importVendorName = "Vendor/Business name is required";
    }
    if (!values.importDropOffAdd || values.importDropOffAdd === "") {
      errors.importDropOffAdd = "Drop-off address is required";
    }
    if (!values.importItem || values.importItem === "") {
      errors.importItem = "Item category is required";
    }
    if (!values.importRecipientPhone || values.importRecipientPhone === "") {
      errors.importRecipientPhone = "Recipient phone number is required";
    } else if (values.importRecipientPhone?.length !== 11) {
      errors.importRecipientPhone = "Recipient phone number must be 11 digits";
    }

    if (!values.importRecipientName || values.importRecipientName === "") {
      errors.importRecipientName = "Recipient name is required";
    }
    if (!values.importWeight || values.importWeight === "") {
      errors.importWeight = "Weight is required";
    }
    if (!values.importValueofItem || values.importValueofItem === "") {
      errors.importValueofItem = "Value of item is required";
    }
    if (!values.importDescription || values.importDescription === "") {
      errors.importDescription = "Item description is required";
    }
    if (
      values.importRecipientEmail &&
      !regex.test(values.importRecipientEmail)
    ) {
      errors.recipientEmail = "Invalid email format";
    }
    return errors;
  };
  const validateStepThree = (values: any) => {
    let errors: any = {};

    if (!values.exportCountry || values.exportCountry === "") {
      errors.exportCountry = "Drop-off country is required";
    }
    if (!values.exportPickUpState || values.exportPickUpState === "") {
      errors.exportPickUpState = "Pick-up state is required";
    }
    if (!values.exportDropOffCity || values.exportDropOffCity === "") {
      errors.exportDropOffCity = "Drop-off city is required";
    }

    if (!values.exportZipCode || values.exportZipCode === "") {
      errors.exportZipCode = "Zip code is required";
    }
    if (!values.exportDropOffAddress || values.exportDropOffAddress === "") {
      errors.exportDropOffAddress = "Drop-off address is required";
    }
    if (!values.exportPickUpAddress || values.exportPickUpAddress === "") {
      errors.exportPickUpAddress = "Pick-up address is required";
    }

    if (!values.exportPhoneNumber || values.exportPhoneNumber === "") {
      errors.exportPhoneNumber = "Recipient phone number is required";
    } else if (
      values.exportPickUpPhoneNumber?.length < 9 ||
      values.exportPickUpPhoneNumber?.length > 13
    ) {
      errors.exportPickUpPhoneNumber =
        "phone number must be between 9 - 13 digits.";
    }
    if (
      !values.exportPickUpPhoneNumber ||
      values.exportPickUpPhoneNumber === ""
    ) {
      errors.exportPickUpPhoneNumber = "Pick-up phone number is required";
    } else if (values.exportPickUpPhoneNumber?.length !== 11) {
      errors.exportPickUpPhoneNumber = "Pick-up phone number must be 11 digits";
    }

    if (!values.exportRecipientName || values.exportRecipientName === "") {
      errors.exportRecipientName = "Recipient name is required";
    }
    if (!values.exportWeight || values.exportWeight === "") {
      errors.exportWeight = "Weight is required";
    }
    if (!values.exportValueofItem || values.exportValueofItem === "") {
      errors.exportValueofItem = "Value of item is required";
    }
    if (!values.exportItemDescription || values.exportItemDescription === "") {
      errors.exportItemDescription = "Item description is required";
    }
    if (
      values.exportRecipientEmail &&
      !regex.test(values.exportRecipientEmail)
    ) {
      errors.recipientEmail = "Invalid email format";
    }
    return errors;
  };

  const validateAmount = (value: string) => {
    let errors: any = {};
    if (!value || value === "") {
      errors.amount = "Amount is required";
    } else if (value !== "") {
      errors.amount = "";
    }
    return errors;
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    isPayment?: boolean
  ) => {
    e.preventDefault();

    setFormErrors(validateStepOne(deliveryDetails));

    if (!query || query === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "Delivery type is required",
      }));
    } else if (query !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
    }

    if (
      deliveryDetails.recipientName !== "" &&
      deliveryDetails.recipientName !== undefined &&
      deliveryDetails.pickUpAddress !== undefined &&
      deliveryDetails.dropOffAddress !== undefined &&
      deliveryDetails.recipientPhoneNumber !== undefined &&
      deliveryDetails.recipientPhoneNumber !== "" &&
      deliveryDetails.recipientPhoneNumber.length === 11 &&
      deliveryDetails.pickUpState !== undefined &&
      deliveryDetails.state !== undefined &&
      deliveryDetails.weight !== undefined &&
      deliveryDetails.itemDescription !== "" &&
      deliveryDetails.itemDescription !== undefined &&
      deliveryDetails.state !== "" &&
      deliveryDetails.weight !== "" &&
      query !== "" &&
      query !== undefined &&
      (!deliveryDetails.pickUpPhoneNumber ||
        deliveryDetails.pickUpPhoneNumber === "" ||
        (deliveryDetails.pickUpPhoneNumber !== undefined &&
          deliveryDetails.pickUpPhoneNumber !== "" &&
          deliveryDetails.pickUpPhoneNumber.length === 11)) &&
      deliveryDetails.valueOfItem !== "" &&
      deliveryDetails.valueOfItem !== undefined &&
      deliveryDetails.pickUpAddress !== "" &&
      deliveryDetails.dropOffAddress !== "" &&
      deliveryDetails.pickUpState !== "" &&
      (deliveryDetails.recipientEmail === "" ||
        deliveryDetails.recipientEmail === undefined ||
        !deliveryDetails.recipientEmail ||
        (deliveryDetails.recipientEmail !== "" &&
          regex.test(deliveryDetails.recipientEmail)))
    ) {
      await getCost({
        recipientState: deliveryDetails.state,
        pickUpState: deliveryDetails.pickUpState,
        mode: "",
        weight: Number(deliveryDetails.weight),
        referralCode: deliveryDetails.discountCode,
      })
        .unwrap()
        .then((data: any) => {
          setClickPayment(false);
          if (editOrder) {
            const itemIndex = items.findIndex(
              (item: any) => item.uniqueID === editedDetails?.uniqueID
            );
            if (itemIndex !== -1) {
              const updatedItem = {
                ...items[itemIndex],
                ...deliveryDetails,
                cost: data?.data?.cost,
                discountedCost: data?.data?.discount?.discountedPrice,
              };
              const updatedItems = [...items];
              updatedItems[itemIndex] = updatedItem;
              setItems(updatedItems);
              setAddOrder(true);
            }
            setEditOrder(false);
          } else {
            const body = {
              ...deliveryDetails,
              uniqueID: crypto.randomUUID(),
              cost: data?.data?.cost,
              discountedCost: data?.data?.discount?.discountedPrice,
            };
            setItems((prevItems: any) => [...prevItems, body]);
            if (isPayment) {
              onGenerate();
              setIsPayment(true);
            } else {
              setAddOrder(true);
            }
          }
          setDeliveryDetails(initialValues);
          setQuery("");
          localStorage.setItem("items", JSON.stringify(items));
        })
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };

  const handleImportSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    isPayment?: boolean
  ) => {
    e.preventDefault();
    setFormErrors(validateStepTwo(deliveryDetails));

    if (!query || query === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "Delivery type is required",
      }));
    } else if (query !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
    }
    if (!deliveryDetails.file || deliveryDetails.file === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        file: "Invoice is required",
      }));
    } else if (deliveryDetails.file !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        file: "",
      }));
    }
    if (
      deliveryDetails.importRecipientName !== "" &&
      deliveryDetails.importRecipientName !== undefined &&
      deliveryDetails.importDropOffAdd !== "" &&
      deliveryDetails.importDropOffAdd !== undefined &&
      deliveryDetails.importRecipientPhone !== "" &&
      deliveryDetails.importRecipientPhone !== undefined &&
      deliveryDetails.importOrderNumber !== "" &&
      deliveryDetails.importOrderNumber !== undefined &&
      deliveryDetails.importItem !== "" &&
      deliveryDetails.importItem !== undefined &&
      deliveryDetails.importDropOffState !== "" &&
      deliveryDetails.importDropOffState !== undefined &&
      deliveryDetails.importRecipientPhone.length === 11 &&
      deliveryDetails.importCountry !== "" &&
      deliveryDetails.importCountry !== undefined &&
      deliveryDetails.importWeight !== "" &&
      deliveryDetails.importWeight !== undefined &&
      ((deliveryDetails.importValueofItem !== "" &&
        Number(deliveryDetails.importValueofItem) >= Number(proof) &&
        deliveryDetails.file !== "") ||
        (deliveryDetails.importValueofItem !== "" &&
          Number(deliveryDetails.importValueofItem) !== Number(proof) &&
          deliveryDetails.file === "")) &&
      query !== "" &&
      query !== undefined &&
      deliveryDetails.importValueofItem !== "" &&
      deliveryDetails.importValueofItem !== undefined &&
      deliveryDetails.importDescription !== "" &&
      deliveryDetails.importDescription !== undefined &&
      deliveryDetails.importVendorName !== "" &&
      deliveryDetails.importVendorName !== undefined &&
      (deliveryDetails.importRecipientEmail === "" ||
        deliveryDetails.importRecipientEmail === undefined ||
        !deliveryDetails.importRecipientEmail ||
        (deliveryDetails.importRecipientEmail !== "" &&
          regex.test(deliveryDetails.importRecipientEmail)))
    ) {
      await getImportCostPrice({
        destinationState: deliveryDetails.importDropOffState,
        importLocationId: Number(deliveryDetails.importCountry),
        weight: Number(deliveryDetails.importWeight),
        referralCode: deliveryDetails.importReferral,
      })
        .unwrap()
        .then((data: any) => {
          const { pickUpState, recipientName, ...restOfDeliveryDetails } =
            deliveryDetails;
          setClickPayment(false);
          if (editOrder) {
            const itemIndex = items.findIndex(
              (item: any) => item.uniqueID === editedDetails?.uniqueID
            );
            if (itemIndex !== -1) {
              const updatedItem = {
                ...items[itemIndex],
                ...restOfDeliveryDetails,
                cost: data?.data?.price,
                discountedCost: data?.data?.discountedPrice,
              };
              const updatedItems = [...items];
              updatedItems[itemIndex] = updatedItem;
              setItems(updatedItems);
              setAddOrder(true);
            }
            setEditOrder(false);
          } else {
            const body = {
              ...restOfDeliveryDetails,
              uniqueID: crypto.randomUUID(),
              cost: data?.data?.price,
              discountedCost: data?.data?.discountedPrice,
            };
            setItems((prevItems: any) => [...prevItems, body]);
            if (isPayment) {
              onGenerate();
              setIsPayment(true);
            } else {
              setAddOrder(true);
            }
          }
          setDeliveryDetails(initialValues);
          setShowAddresses({
            state: "",
            city: "",
            postCode: "",
            addresses: [],
          });
          setQuery("");
          localStorage.setItem("items", JSON.stringify(items));
        })
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };
  const handleExportSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    isPayment?: boolean
  ) => {
    e.preventDefault();
    setFormErrors(validateStepThree(deliveryDetails));

    if (!query || query === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "Delivery type is required",
      }));
    } else if (query !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        deliveryType: "",
      }));
    }
    if (
      deliveryDetails.exportRecipientName !== "" &&
      deliveryDetails.exportRecipientName !== undefined &&
      deliveryDetails.exportDropOffAddress !== "" &&
      deliveryDetails.exportDropOffAddress !== undefined &&
      deliveryDetails.exportPhoneNumber !== "" &&
      deliveryDetails.exportPhoneNumber !== undefined &&
      (deliveryDetails.exportPickUpPhoneNumber.length >= 9 ||
        deliveryDetails.exportPickUpPhoneNumber.length <= 13) &&
      deliveryDetails.exportZipCode !== "" &&
      deliveryDetails.exportZipCode !== undefined &&
      deliveryDetails.exportDropOffCity !== "" &&
      deliveryDetails.exportDropOffCity !== undefined &&
      deliveryDetails.exportPickUpPhoneNumber !== "" &&
      deliveryDetails.exportPickUpPhoneNumber !== undefined &&
      deliveryDetails.exportPickUpPhoneNumber.length === 11 &&
      deliveryDetails.exportCountry !== "" &&
      deliveryDetails.exportCountry !== undefined &&
      deliveryDetails.exportPickUpState !== "" &&
      deliveryDetails.exportPickUpState !== undefined &&
      deliveryDetails.exportWeight !== "" &&
      deliveryDetails.exportWeight !== undefined &&
      query !== "" &&
      query !== undefined &&
      deliveryDetails.exportValueofItem !== "" &&
      deliveryDetails.exportValueofItem !== undefined &&
      deliveryDetails.exportItemDescription !== "" &&
      deliveryDetails.exportItemDescription !== undefined &&
      (deliveryDetails.exportRecipientEmail === "" ||
        deliveryDetails.exportRecipientEmail === undefined ||
        !deliveryDetails.exportRecipientEmail ||
        (deliveryDetails.exportRecipientEmail !== "" &&
          regex.test(deliveryDetails.exportRecipientEmail)))
    ) {
      await getExportCostPrice({
        destinationState: deliveryDetails.exportPickUpState,
        exportLocationId: Number(deliveryDetails.exportCountry),
        weightId: Number(deliveryDetails.exportWeight),
        referralCode: deliveryDetails.exportReferral,
      })
        .unwrap()
        .then((data: any) => {
          const { pickUpState, recipientName, ...restOfDeliveryDetails } =
            deliveryDetails;

          setClickPayment(false);
          if (editOrder) {
            const itemIndex = items.findIndex(
              (item: any) => item.uniqueID === editedDetails?.uniqueID
            );
            if (itemIndex !== -1) {
              const updatedItem = {
                ...items[itemIndex],
                ...restOfDeliveryDetails,
                cost: data?.data?.price,
                discountedCost: data?.data?.discountedRate,
              };
              const updatedItems = [...items];
              updatedItems[itemIndex] = updatedItem;
              setItems(updatedItems);
              setAddOrder(true);
            }
            setEditOrder(false);
          } else {
            const body = {
              ...restOfDeliveryDetails,
              uniqueID: crypto.randomUUID(),
              cost: data?.data?.price,
              discountedCost: data?.data?.discountedRate,
            };
            setItems((prevItems: any) => [...prevItems, body]);
            if (isPayment) {
              onGenerate();
              setIsPayment(true);
            } else {
              setAddOrder(true);
            }
          }
          setDeliveryDetails(initialValues);
          setSelectedWeightOption({
            id: null,
            name: "",
          });
          setQuery("");
          localStorage.setItem("items", JSON.stringify(items));
        })
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };
  const handleErrorChange = (name: string, value: any) => {
    if (name === "recipientEmail") {
      if (value !== "" && !regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientEmail: "Invalid email format",
        }));
      } else if (value !== "" && regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientEmail: "",
        }));
      }
    }
    if (name === "importRecipientEmail") {
      if (value !== "" && !regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientEmail: "Invalid email format",
        }));
      } else if (value !== "" && regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientEmail: "",
        }));
      }
    }
    if (name === "exportRecipientEmail") {
      if (value !== "" && !regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportRecipientEmail: "Invalid email format",
        }));
      } else if (value !== "" && regex.test(value)) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportRecipientEmail: "",
        }));
      }
    }
    if (name === "pickUpAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "",
      }));
    } else if (name === "pickUpAddress" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        pickUpAddress: "Pick-up address is required",
      }));
    }
    if (name === "exportPickUpState" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportPickUpState: "",
      }));
    } else if (name === "exportPickUpState" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportPickUpState: "Pick-up state is required",
      }));
    }
    if (name === "dropOffAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffAddress: "",
      }));
    } else if (name === "dropOffAddress" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        dropOffAddress: "Drop-off address is required",
      }));
    }

    if (name === "importDropOffAdd" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importDropOffAdd: "",
      }));
    } else if (name === "importDropOffAdd" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importDropOffAdd: "Drop-off address is required",
      }));
    }
    if (name === "exportDropOffAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportDropOffAddress: "",
      }));
    } else if (name === "exportDropOffAddress" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportDropOffAddress: "Drop-off address is required",
      }));
    }
    if (name === "exportPickUpAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportPickUpAddress: "",
      }));
    } else if (name === "exportPickUpAddress" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportPickUpAddress: "Pick-up address is required",
      }));
    }
    if (name === "importOrderNumber" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importOrderNumber: "",
      }));
    } else if (name === "importOrderNumber" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importOrderNumber: "Order number is required",
      }));
    }
    if (name === "exportDropOffCity" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportDropOffCity: "",
      }));
    } else if (name === "exportDropOffCity" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportDropOffCity: "Drop-off city is required",
      }));
    }
    if (name === "exportZipCode" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportZipCode: "",
      }));
    } else if (name === "exportZipCode" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportZipCode: "Drop-off city is required",
      }));
    }
    if (name === "importVendorName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importVendorName: "",
      }));
    } else if (name === "importVendorName" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importVendorName: "Vendor/Business name is required",
      }));
    }
    if (name === "recipientName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientName: "",
      }));
    } else if (name === "recipientName" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        recipientName: "Recipient name is required",
      }));
    }
    if (name === "importRecipientName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importRecipientName: "",
      }));
    } else if (name === "importRecipientName" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importRecipientName: "Recipient name is required",
      }));
    }
    if (name === "exportRecipientName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportRecipientName: "",
      }));
    } else if (name === "exportRecipientName" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportRecipientName: "Recipient name is required",
      }));
    }
    if (name === "recipientPhoneNumber") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientPhoneNumber: "Recipient phone number is required",
        }));
      } else if (value.length !== 11) {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientPhoneNumber: "Phone number must be 11 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          recipientPhoneNumber: "",
        }));
      }
    }

    if (name === "exportPhoneNumber") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPhoneNumber: "Recipient phone number is required",
        }));
      } else if (value.length < 9 || value.length > 13) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPhoneNumber: "phone number must be between 9 - 13 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPhoneNumber: "",
        }));
      }
    }
    if (name === "exportPickUpPhoneNumber") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPickUpPhoneNumber: "Pick-up phone number is required",
        }));
      } else if (value.length !== 11) {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPickUpPhoneNumber: "Pick-up phone number must be 11 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          exportPickUpPhoneNumber: "",
        }));
      }
    }
    if (name === "importRecipientPhone") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientPhone: "Recipient phone number is required",
        }));
      } else if (value.length !== 11) {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientPhone: "Phone number must be 11 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          importRecipientPhone: "",
        }));
      }
    }
    if (name === "pickUpPhoneNumber") {
      if (!value) {
        setFormErrors((prev: any) => ({
          ...prev,
          pickUpPhoneNumber: "",
        }));
      } else if (value.length !== 11) {
        setFormErrors((prev: any) => ({
          ...prev,
          pickUpPhoneNumber: "Phone number must be 11 digits.",
        }));
      } else {
        setFormErrors((prev: any) => ({
          ...prev,
          pickUpPhoneNumber: "",
        }));
      }
    }
    if (name === "weight" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        weight: "",
      }));
    } else if (name === "weight" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        weight: "Weight is required",
      }));
    }
    if (name === "importWeight" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importWeight: "",
      }));
    } else if (name === "importWeight" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importWeight: "Weight is required",
      }));
    }
    if (name === "exportWeight" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportWeight: "",
      }));
    } else if (name === "exportWeight" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportWeight: "Weight is required",
      }));
    }
    if (name === "valueOfItem" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        valueOfItem: "",
      }));
    } else if (name === "valueOfItem" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        valueOfItem: "Value of item is required",
      }));
    }
    if (name === "itemDescription" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        itemDescription: "",
      }));
    } else if (name === "itemDescription" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        itemDescription: "Item description is required",
      }));
    }

    if (name === "importDescription" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importDescription: "",
      }));
    } else if (name === "importDescription" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importDescription: "Item description is required",
      }));
    }
    if (name === "exportItemDescription" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportItemDescription: "",
      }));
    } else if (name === "exportItemDescription" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportItemDescription: "Item description is required",
      }));
    }
    if (name === "importValueofItem" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importValueofItem: "",
      }));
    } else if (name === "importValueofItem" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        importValueofItem: "Value of item is required",
      }));
    }
    if (name === "exportValueofItem" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportValueofItem: "",
      }));
    } else if (name === "exportValueofItem" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        exportValueofItem: "Value of item is required",
      }));
    }
  };

  const handleDeliveryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "recipientPhoneNumber") {
      if (!/^\d*$/.test(value)) {
        handleErrorChange(
          name,
          "Please enter a valid phone number (numbers only)."
        );
        return;
      } else {
        handleErrorChange(name, value);
      }
    } else {
      handleErrorChange(name, value);
    }

    setDeliveryDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (editedDetails) {
      const selectedType = deliveryType.find(
        (type: { name: string }) => type.name === editedDetails?.deliveryType
      );
      setSelectedOption({
        id: selectedType.id,
        name: "",
      });
      setDeliveryDetails({
        pickUpAddress: editedDetails?.pickUpAddress,
        state: editedDetails?.state,
        pickUpState: editedDetails?.pickUpState,
        weight: editedDetails?.weight,
        recipientName: editedDetails?.recipientName,
        recipientPhoneNumber: editedDetails?.recipientPhoneNumber,
        recipientEmail: editedDetails?.recipientEmail,
        quantity: editedDetails?.quantity,
        itemDescription: editedDetails?.itemDescription,
        discountCode: editedDetails?.discountCode,
        dropOffAddress: editedDetails?.dropOffAddress,
        valueOfItem: editedDetails?.valueOfItem,
        pickUpPhoneNumber: editedDetails?.pickUpPhoneNumber,
        importItem: editedDetails?.importItem,
        importCountry: editedDetails?.importCountry,
        importReferral: editedDetails?.importReferral,
        importDescription: editedDetails?.importDescription,
        importQuantity: editedDetails?.importQuantity,
        importWeight: editedDetails?.importWeight,
        importRecipientEmail: editedDetails?.importRecipientEmail,
        importRecipientPhone: editedDetails?.importRecipientPhone,
        importRecipientName: editedDetails?.importRecipientName,
        importDropOffAdd: editedDetails?.importDropOffAdd,
        importVendorName: editedDetails?.importVendorName,
        importOrderNumber: editedDetails?.importOrderNumber,
        importShippingAdd: editedDetails?.importShippingAdd,
        importDropOffState: editedDetails?.importDropOffState,
        importValueofItem: editedDetails?.importValueofItem,
        file: editedDetails.file,
        fileNames: editedDetails.fileNames,
        exportCountry: editedDetails.exportCountry,
        exportRecipientName: editedDetails.exportRecipientName,
        exportWeight: editedDetails.exportWeight,
        exportReferral: editedDetails.exportReferral,
        exportItemDescription: editedDetails.exportItemDescription,
        exportQuantity: editedDetails.exportQuantity,
        exportRecipientEmail: editedDetails.exportRecipientEmail,
        exportPhoneNumber: editedDetails.exportPhoneNumber,
        exportZipCode: editedDetails.exportZipCode,
        exportDropOffAddress: editedDetails.exportDropOffAddress,
        exportDropOffCity: editedDetails.exportDropOffCity,
        exportPickUpState: editedDetails.exportPickUpState,
        exportValueofItem: editedDetails.exportValueofItem,
        exportPickUpPhoneNumber: editedDetails.exportPickUpPhoneNumber,
        exportPickUpAddress: editedDetails.exportPickUpAddress,
      });
      const deliveryData: any = deliveryType?.find(
        (item: any) => item?.name === editedDetails?.deliveryType
      );
      setStateName(editedDetails?.state);
      setPickUpStateName(editedDetails?.pickUpState);
      const country = filteredCountrySearch?.find(
        (country: State) => country.id === editedDetails?.importCountry
      );
      const dropOff = filteredPickUpStateSearch?.filter(
        (country: State) => country.state === editedDetails?.importDropOffState
      );

      const item = filteredItemSearch?.filter(
        (item: Item) => item.id === editedDetails?.importItem
      );
      setImportDropOffStateName(dropOff?.[0]?.state);
      setImportCountryStateName(country?.country?.name);
      setProof(country?.proof);
      setItemName(item[0]?.name);
      setQuery(deliveryData?.name);
    }
  }, [editedDetails]);

  const handleEdit = (uniqueID: string) => {
    setEditOrder(true);
    const updatedItems = items.find((item) => item.uniqueID === uniqueID);
    setEditedDetails(updatedItems);
    const pickUpCountry = filteredCountrySearch?.find(
      (item: State) => item.id === updatedItems.importCountry
    );
    const weightSelected = weightData?.weight?.find(
      (item: ExportState) => item.id === updatedItems.exportWeight
    );
    const exportCountry = filteredExportCountrySearch?.find(
      (item: ExportState) => item.id === updatedItems.exportCountry
    );
    setSelectedWeightOption(weightSelected);
    setShowAddresses(pickUpCountry);
    setExportCountryStateName(exportCountry?.name);
    setExportPickUpStateName(updatedItems.exportPickUpState);
  };

  const handleDelete = (uniqueID: string) => {
    const updatedItems = items.filter((item) => item.uniqueID !== uniqueID);
    setItems(updatedItems);
    localStorage.setItem("items", JSON.stringify(updatedItems));
  };

  const onGenerate = () => {
    const response = generateRandomDataWithDelay();

    if (orgCode) {
      setReferenceId(`c_${response}_${orgCode}_OP`);
      setReferenceIdTwo(`${response}_${orgCode}_OP`);
      setMoneyReference(`c_${orgCode}_${response}${new Date().valueOf()}_BFW`);
    }
  };

  const config = {
    reference: referenceIdTwo,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: Number(totalCost) * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };

  const addMoneyConfig = {
    reference: moneyReference,
    email:
      orgEmail === "null" || !orgEmail ? `${orgCode}@fezltd.com` : orgEmail,
    amount: Number(amountAdded) * 100,
    publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
  };
  const initializePayment = usePaystackPayment(config);
  const initializeAddMoney = usePaystackPayment(addMoneyConfig);

  const onUpdateReferralCode = async () => {
    const orderNos = JSON.parse(localStorage.getItem("orderNos") || "[]");

    const resultData = Object.values(orderNos).map((orderNo, index) => ({
      orderNo,
      discountCode: 
      selectedOption?.id === 1 
        ? items[index]?.discountCode 
        : selectedOption?.id === 2 
          ? items[index]?.importReferral 
          : items[index]?.exportReferral,
    }));

    const orders = {
      orders: resultData?.map((data: any) => ({
        referralCode: data.discountCode,
        orderNo: data.orderNo,
      })),
    };

    if (resultData.length > 0 && resultData[0]?.discountCode !== "") {
      await updateReferral(orders);
    }
  };

  const onSuccess = () => {
    onUpdateReferralCode();
    setDeliveryDetails(initialValues);
    setQuery("");
    setModalPayment(true);
    setFetchNotifications(true);
    localStorage.removeItem("items");

    setTimeout(() => {
      setFetchNotifications(false);
    }, 60000);
  };

  const onClose = () => {
    onDeleteOrder();
    onGenerate();
  };
  const onAddMoneySuccess = () => {
    localStorage.setItem("amountAdded", amountAdded);
    setAddMoney(false);
    setAddMoneySuccess(true);
    setFetchNotifications(true);

    setTimeout(() => {
      setFetchNotifications(false);
    }, 60000);
  };

  const onAddMoneyClose = () => {
    onGenerate();
  };

  useEffect(() => {
    localStorage.removeItem("amountAdded");
  }, []);

  const onDeleteOrder = async () => {
    const orderNos: any = localStorage.getItem("orderNos");

    const parsedOrderNos = JSON.parse(orderNos);
    const valuesString = Object.values(parsedOrderNos).join(", ");

    await removeOrder({ orderNo: valuesString })
      .unwrap()
      .then((data) => {
        // onHandlePayment(payType);
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  const processPayment = async () => {
    const body = {
      totalCost,
      paymentReference: "w" + referenceId.slice(1),
    };
    await walletCheckOut(body)
      .unwrap()
      .then((data) => {
        if (data) {
          onUpdateReferralCode();
          setDeliveryDetails(initialValues);
          setQuery("");
          setModalPayment(true);
          setFetchNotifications(true);
          setTimeout(() => {
            setFetchNotifications(false);
          }, 60000);
        }
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  const onHandlePayment = async (payType: string) => {
    const newFirstLetter = payType.toLowerCase() === "wallet" ? "w" : "c";
    const requestPayload = items.reduce((acc, item, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;

      acc[uniqueID] = {
        recipientAddress: item.dropOffAddress,
        recipientState: item.state,
        recipientName: item.recipientName,
        recipientPhone: item.recipientPhoneNumber,
        uniqueID: uniqueID,
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: newFirstLetter + referenceId.slice(1),
        recipientEmail: item.recipientEmail,
        weight: item.weight,
        pickUpAddress: item.pickUpAddress,
        valueOfItem: Number(item.valueOfItem),
        clientPhone: item.pickUpPhoneNumber,
        pickUpState: item.pickUpState,
        referralCode: item.discountCode,
        additionalDetails: item.additional,
        cost: Number(item.cost),
        discountedCost: Number(item.discountedCost),
        itemDescription: item.itemDescription,
        locker_box_name: "",
        offline: payType.toLowerCase() === "pay-later" ? "PL" : "",
      };
      return acc;
    }, {} as { [key: string | number]: Props });
    await getOrder({ body: requestPayload })
      .unwrap()
      .then((data) => {
        localStorage.setItem("orderNos", JSON.stringify(data?.orderNos));
        if (payType.toLowerCase() === "paystack") {
          initializePayment(onSuccess, onClose);
        } else if (payType.toLowerCase() === "wallet") {
          processPayment();
        } else {
          onUpdateReferralCode();
          setItems([]);
          localStorage.removeItem("items");
          setModalPayment(true);
        }
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };
  const onHandleImportPayment = async () => {
    const requestPayload = items.reduce((acc, item, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;

      acc[uniqueID] = {
        recipientName: item.importRecipientName,
        recipientPhone: item.importRecipientPhone,
        recipientAddress: item.importDropOffAdd,
        recipientState: item.importDropOffState,
        recipientEmail: item.importRecipientEmail,
        weight: item.importWeight,
        referralCode: item.importReferral,
        additionalDetails: null,
        uniqueID: uniqueID,
        cost: Number(item.cost),
        discountedCost: Number(item.discountedCost),
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: "c" + referenceId.slice(1),
        itemDescription: item.importDescription,
        recipientCity: "",
        importLocationId: item.importCountry,
        valueOfItem: Number(item.importValueofItem),
        trackingId: item.importOrderNumber,
        itemCategory: item.importItem,
        businessName: item.importVendorName,
        uploadedProof: item.file,
        uploadedProofExtension: item.fileName,
      };
      return acc;
    }, {} as { [key: string | number]: any });
    await getOrder({ body: requestPayload, requestType: "import" })
      .unwrap()
      .then((data) => {
        onUpdateReferralCode();
        localStorage.setItem("orderNos", JSON.stringify(data?.orderNos));
         setItems([]);
         localStorage.removeItem("items");
         setModalPayment(true);
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };
  const onHandleExportPayment = async (payType: string) => {
    const newFirstLetter = payType.toLowerCase() === "wallet" ? "w" : "c";

    const requestPayload = items.reduce((acc, item, idx) => {
      const uniqueID = `fez-${referenceIdTwo}-${idx}`;

      acc[uniqueID] = {
        recipientName: item.exportRecipientName,
        recipientPhone: item.exportPhoneNumber,
        recipientAddress: item.exportDropOffAddress,
        recipientEmail: item.exportRecipientEmail,
        weight: item.exportWeight,
        pickUpAddress: item.exportPickUpAddress,
        pickUpState: item.exportPickUpState,
        referralCode: item.exportReferral,
        pickUpPhone: item.exportPickUpPhoneNumber,
        additionalDetails: null,
        uniqueID,
        cost: Number(item.cost),
        discountedCost: Number(item.discountedCost),
        BatchID: `${orgCode}-${referenceId}`,
        orderRequestSource: "B2B Dashboard",
        paymentReference: newFirstLetter + referenceId.slice(1),
        itemDescription: item.exportItemDescription,
        recipientCity: item.exportDropOffCity,
        zipCode: item.exportZipCode,
        exportLocationId: item.exportCountry,
        valueOfItem: Number(item.exportValueofItem),
        offline: payType.toLowerCase() === "pay-later" ? "PL" : "",
      };
      return acc;
    }, {} as { [key: string | number]: any });
    await getOrder({ body: requestPayload, requestType: "export" })
      .unwrap()
      .then((data) => {
        localStorage.setItem("orderNos", JSON.stringify(data?.orderNos));
        if (payType.toLowerCase() === "paystack") {
          initializePayment(onSuccess, onClose);
        } else if (payType.toLowerCase() === "wallet") {
          processPayment();
        } else {
          onUpdateReferralCode();
          setItems([]);
          localStorage.removeItem("items");

          setModalPayment(true);
        }
      })
      .catch((error: { data: { description: string } }) => {
        handleErrorToast(error);
      });
  };

  useEffect(() => {
    const totalCost = items.reduce((accumulator: number, currentItem: any) => {
      return (
        accumulator +
        (Number(currentItem.discountedCost) || Number(currentItem.cost))
      );
    }, 0);
    setTotalCost(totalCost);
  }, [items]);

  const onAddMoney = () => {
    setFormErrors(validateAmount(amountAdded));
    if (amountAdded !== "") {
      initializeAddMoney(onAddMoneySuccess, onAddMoneyClose);
    }
  };

  const handleFileChange = (event: any) => {
    if (event.target === null) {
      console.error("No file selected");
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      console.error("Failed to retrieve file");
      return;
    }
    if (file) {
      setFormErrors((prev: any) => ({
        ...prev,
        file: "",
      }));
      setDeliveryDetails((prev) => ({
        ...prev,
        fileName: file?.type.split("/")[1],
      }));
      setDeliveryDetails((prev) => ({
        ...prev,
        fileNames: file?.name,
      }));
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event: any) => {
      const base64EncodedData = event.target.result;
      const base64Data = base64EncodedData.substring(
        base64EncodedData.indexOf(",") + 1
      );
      setDeliveryDetails((prev) => ({
        ...prev,
        file: base64Data,
      }));
    };
  };

  return (
    <>
      {isCreateShipment ? (
        !isPayment ? (
          <div className="mt-14 md:mt-0 overflow-x-hidden ">
            <div
              onClick={() => setIsCreateShipment(false)}
              className="mx-5 flex mb-2 md:hidden"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>
            <div className="grid lg:grid-cols-[62%_38%] lg:gap-4 xl:gap-[22px] md:mt-[72px] mx-5 bmd:mx-[50px] xl:mx-[80px]">
              <div className="border-[1px] border-[#E1F0DF] px-5 xl:px-8 bg-dashboard-gradient rounded-lg">
                <div className="hidden md:flex items-center justify-between pt-8  w-full">
                  <div
                    onClick={() => setIsCreateShipment(false)}
                    className="hidden md:flex gap-1 cursor-pointer w-[100px]"
                  >
                    <div>
                      {" "}
                      <img
                        className="w-3 h-3"
                        src="/images/backArrow.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                      Go back
                    </p>
                  </div>
                  <div className="flex justify-center md:justify-end w-full gap-5 md:gap-2.5">
                    <div className="block md:hidden">
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-center md:justify-end font-[500] text-[#68726B] font-onestMedium">
                        Pick-up
                      </p>
                    </div>
                    <div className="">
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex md:justify-end font-[500] text-[#68726B] font-onestMedium">
                        Delivery details
                      </p>
                    </div>
                    <div>
                      <div
                        className={`w-[82px] h-[6px] bg-[#e0f4dc] rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-center md:justify-end font-[500] text-[#68726B] font-onestMedium">
                        Payment
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex md:hidden items-center justify-between mt-6 mx-4">
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-2 text-[12px] font-onestMedium w-[100px]">
                      Pick-up
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                      Delivery
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                  <div className="relative">
                    <FaCircle color="#D4F3D5" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-4 text-[12px] font-onestMedium w-[100px]">
                      Payment
                    </p>
                  </div>
                </div>
                <h1 className="text-xl md:text-2xl mt-14 md:mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  You are creating single shipping orders
                </h1>
                <p className="text-[#0e1728] hidden md:block text-sm font-[500] font-onestMedium w-[90%] mt-3 md:mt-12">
                  Enter your pick-up and delivery details
                </p>
                {addOrder && (
                  <div className="block lg:hidden">
                    {items.length > 0 && (
                      <div className="h-full xl:h-[596px]">
                        <div className="flex gap-[14px] items-center pt-8 pb-2 bg-white">
                          <h1 className="text-[#0a5001] text-sm font-[700] font-onestBold">
                            MY ORDERS ({items.length})
                          </h1>
                        </div>
                        <div className="bg-white h-[70%]">
                          {items.map((item) => {
                            const pickUpCountry = filteredCountrySearch.filter(
                              (state: any) => {
                                return state.id === item.importCountry;
                              }
                            );
                            return (
                              <div
                                key={item.id}
                                className="flex gap-3 border-[1px] px-4 rounded-lg my-6 border-[#e4ebf5] py-4"
                              >
                                <div className="bg-[#F4F5F6] w-[28px] h-[28px] flex items-center justify-center rounded-[30px]">
                                  <img
                                    className="w-[14px] h-[14px]"
                                    src="/images/location.svg"
                                    alt=""
                                  />
                                </div>
                                <div className="flex justify-between w-full">
                                  <div>
                                    <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                                      {item.recipientName ||
                                        item.importRecipientName ||
                                        item.exportRecipientName}
                                    </h1>
                                    <p className="text-[#686868] flex cursor-pointer items-center font-onestRegular text-[13px] mb-0.5">
                                      {item.deliveryType}{" "}
                                      <FaCircle
                                        color="#d1d1d2"
                                        size={6}
                                        className="bg-[#d1d1d2] mx-2 rounded-lg"
                                      />{" "}
                                      {item.pickUpState ||
                                        pickUpCountry?.[0]?.country?.name ||
                                        item.exportPickUpState}
                                    </p>
                                    <div className="flex space-x-2">
                                      <p
                                        className="text-[#18AF04] cursor-pointer text-xs font-onestRegular"
                                        onClick={() => {
                                          setAddOrder(false);
                                          handleEdit(item.uniqueID);
                                        }}
                                      >
                                        Edit order
                                      </p>

                                      <p
                                        onClick={() => {
                                          handleDelete(item.uniqueID);
                                          if (items.length === 1) {
                                            setAddOrder(false);
                                            setIsPayment(false);
                                          }
                                        }}
                                        className="ml-3 text-[#F64108] cursor-pointer text-xs font-onestRegular"
                                      >
                                        Delete
                                      </p>
                                    </div>
                                  </div>
                                  {selectedOption?.id === 2 ? (
                                    <div className="flex space-x-1 ">
                                      {item.discountedCost &&
                                      item.discountedCost !== item.cost ? (
                                        <div className="flex space-x-1 ">
                                          <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                            {getSymbolFromCurrency(
                                              currencyValue
                                            )}
                                            {formatMoney().format(item.cost)}
                                          </p>
                                          <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                            {getSymbolFromCurrency(
                                              currencyValue
                                            )}
                                            {formatMoney().format(
                                              item.discountedCost
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                          {getSymbolFromCurrency(currencyValue)}
                                          {formatMoney().format(item.cost)}
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="flex space-x-1 ">
                                      {item.discountedCost &&
                                      item.discountedCost !== item.cost ? (
                                        <div className="flex space-x-1">
                                          <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                            ₦{formatMoney().format(item.cost)}
                                          </p>
                                          <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                            ₦
                                            {formatMoney().format(
                                              item.discountedCost
                                            )}
                                          </p>
                                        </div>
                                      ) : (
                                        <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                          ₦{formatMoney().format(item.cost)}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="xl:px-8 flex-col xl:flex xl:flex-row items-center justify-between mt-6">
                          {selectedOption?.id !== 2 && (
                            <h1 className="font-[700] font-onestBold">
                              Total: ₦{formatMoney().format(totalCost)}
                            </h1>
                          )}

                          <div className="space-y-3 pb-5">
                            <Button
                              title={
                                selectedOption?.id === 2
                                  ? "Continue with your shipment"
                                  : "Make Payment"
                              }
                              onClick={() => {
                                onGenerate();
                                setIsPayment(true);
                              }}
                              className="!w-full mt-5 py-4 bg-[#18AF04] !font-[700] !text-xs xl:!text-[13px] font-onestBold"
                            />
                            <Button
                              title="Add New Order"
                              onClick={() => setAddOrder(false)}
                              className="!w-full mt-5 py-4 !text-[#18AF04] !bg-transparent border border-[#18AF04] !font-[700] !text-xs xl:!text-[13px] font-onestBold"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <form
                  className={`lg:block hidden `}
                  onSubmit={
                    selectedOption?.id === 1
                      ? handleSubmit
                      : selectedOption?.id === 2
                      ? handleImportSubmit
                      : handleExportSubmit
                  }
                >
                  <div className="mt-6 border-b pb-6 border-[#e3ecf9]">
                    <DropDownSelect
                      title="Select delivery type"
                      lists={deliveryType}
                      value={query}
                      setQuery={setQuery}
                      isOpen={isDeliveryType}
                      isRequired
                      toggleDropdown={toggleDeliveryType}
                      handleOptionClick={handleDeliveryType}
                      placeholder="Select type"
                      name="deliveryType"
                    />
                    {formErrors.deliveryType && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.deliveryType}
                      </span>
                    )}
                  </div>
                  {selectedOption?.id === 1 && (
                    <>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                            Pick-up State
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <Dropdown
                          loader={isLoadingState}
                          dropdown={
                            <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                              <div className="flex items-center space-x-1">
                                <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                <span
                                  className={` ${
                                    deliveryDetails?.pickUpState === ""
                                      ? "text-[#9899A1]"
                                      : "text-[#000]"
                                  }  font-onestRegular text-sm`}
                                >
                                  {deliveryDetails?.pickUpState === "" ||
                                  deliveryDetails?.pickUpState === undefined
                                    ? "Select Pick-up state"
                                    : pickUpStateName}
                                </span>
                              </div>

                              <img
                                src={"/images/chevronDown.svg"}
                                alt="drop-down"
                                className="mt-0.5 pr-2 cursor-pointer"
                              />
                            </div>
                          }
                          search={
                            <div>
                              <TextInput
                                value={searchPickUpState}
                                name={"search"}
                                type={"text"}
                                placeholder="Search"
                                onChange={(e) =>
                                  setSearchPickUpState(e.target.value)
                                }
                              />
                            </div>
                          }
                          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                          dropdownOptions={[
                            {
                              customChild: (
                                <div>
                                  <div>
                                    {filteredPickUpStateSearch?.map(
                                      (item: any, idx: number) => {
                                        return (
                                          <div
                                            key={idx}
                                            onClick={() => {
                                              setDeliveryDetails((prev) => ({
                                                ...prev,
                                                pickUpState: item.state,
                                              }));
                                              setPickUpStateName(item?.state);
                                              setSearchPickUpState("");
                                              setFormErrors((prev: any) => ({
                                                ...prev,
                                                pickUpState: "",
                                              }));
                                            }}
                                          >
                                            <div className="px-3 py-2 font-onestMedium text-sm  hover:bg-[#edecec] cursor-pointer">
                                              <p>{item?.state}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                        {formErrors.pickUpState && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.pickUpState}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Pick-up address"
                          isRequired
                          name="pickUpAddress"
                          value={deliveryDetails.pickUpAddress}
                          onChange={handleDeliveryChange}
                          placeholder="Enter pick-up address"
                        />
                        {formErrors.pickUpAddress && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.pickUpAddress}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="number"
                          label="Pick-up phone number"
                          name="pickUpPhoneNumber"
                          value={deliveryDetails.pickUpPhoneNumber}
                          onChange={handleDeliveryChange}
                          placeholder="Enter pick-up phone number"
                        />
                        {formErrors.pickUpPhoneNumber && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.pickUpPhoneNumber}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Drop-off address"
                          isRequired
                          name="dropOffAddress"
                          value={deliveryDetails.dropOffAddress}
                          onChange={handleDeliveryChange}
                          placeholder="Enter drop-off address"
                        />
                        {formErrors.dropOffAddress && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.dropOffAddress}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                            Recipient State
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <Dropdown
                          dropdown={
                            <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                              <div className="flex items-center space-x-1">
                                <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                <span
                                  className={` ${
                                    deliveryDetails?.state === ""
                                      ? "text-[#9899A1]"
                                      : "text-[#000]"
                                  }  font-onestMedium text-sm`}
                                >
                                  {deliveryDetails?.state === "" ||
                                  deliveryDetails?.state === undefined
                                    ? "Select state"
                                    : stateName}
                                </span>
                              </div>

                              <img
                                src={"/images/chevronDown.svg"}
                                alt="drop-down"
                                className="mt-0.5 pr-2 cursor-pointer"
                              />
                            </div>
                          }
                          search={
                            <div>
                              <TextInput
                                value={searchState}
                                name={"search"}
                                type={"text"}
                                placeholder="Search"
                                onChange={(e) => setSearchState(e.target.value)}
                              />
                            </div>
                          }
                          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                          dropdownOptions={[
                            {
                              customChild: (
                                <div>
                                  <div>
                                    {filteredStateSearch?.map(
                                      (item: any, idx: number) => {
                                        return (
                                          <div
                                            key={idx}
                                            onClick={() => {
                                              setDeliveryDetails((prev) => ({
                                                ...prev,
                                                state: item.state,
                                              }));
                                              setSearchState("");
                                              setStateName(item?.state);
                                              setFormErrors((prev: any) => ({
                                                ...prev,
                                                state: "",
                                              }));
                                            }}
                                          >
                                            <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                              <p>{item?.state}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                        {formErrors.state && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.state}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Recipient name"
                          isRequired
                          value={deliveryDetails.recipientName}
                          name="recipientName"
                          placeholder="Enter name"
                          onChange={handleDeliveryChange}
                        />
                        {formErrors.recipientName && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.recipientName}
                          </span>
                        )}
                      </div>
                      <div className="grid md:grid-cols-2 gap-x-3">
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Recipient phone number"
                            isRequired
                            name="recipientPhoneNumber"
                            value={deliveryDetails.recipientPhoneNumber || ""}
                            placeholder="Enter phone number"
                            onChange={handleDeliveryChange}
                          />
                          {formErrors.recipientPhoneNumber && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.recipientPhoneNumber}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Recipient email"
                            placeholder="Enter email"
                            name="recipientEmail"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.recipientEmail}
                          />
                          {formErrors.recipientEmail && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.recipientEmail}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Item weight"
                            isRequired
                            placeholder="Enter weight"
                            name="weight"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.weight || ""}
                          />
                          {formErrors.weight && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.weight}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Quantity"
                            placeholder="Enter quantity"
                            name="quantity"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.quantity}
                          />
                          {formErrors.quantity && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.quantity}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="number"
                          label="Value of Item"
                          placeholder="Enter value"
                          name="valueOfItem"
                          isRequired
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.valueOfItem}
                        />
                        {formErrors.valueOfItem && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.valueOfItem}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextArea
                          label="Item description"
                          placeholder="Write a description"
                          name="itemDescription"
                          isRequired
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.itemDescription}
                        />
                        {formErrors.itemDescription && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.itemDescription}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Enter discount code if you have one"
                          placeholder="Enter discount code"
                          name="discountCode"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.discountCode}
                        />
                      </div>
                    </>
                  )}

                  {selectedOption?.id === 2 && (
                    <>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                            Select Pick-up country
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <Dropdown
                          loader={isCountryLoading}
                          dropdown={
                            <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                              <div className="flex items-center space-x-1">
                                <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                <span
                                  className={` ${
                                    deliveryDetails.importCountry === ""
                                      ? "text-[#9899A1]"
                                      : "text-[#000]"
                                  }  font-onestMedium text-sm`}
                                >
                                  {deliveryDetails.importCountry === ""
                                    ? "Select country"
                                    : importCountryStateName}
                                </span>
                              </div>
                              <HiOutlineChevronDown />
                            </div>
                          }
                          search={
                            <div>
                              <TextInput
                                value={searchCountryState}
                                name={"search"}
                                type={"text"}
                                placeholder="Search"
                                onChange={(e) =>
                                  setSearchCountryState(e.target.value)
                                }
                              />
                            </div>
                          }
                          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                          dropdownOptions={[
                            {
                              customChild: (
                                <div>
                                  <div>
                                    {filteredCountrySearch?.map(
                                      (item: any, idx: number) => {
                                        return (
                                          <div
                                            key={idx}
                                            onClick={() => {
                                              setProof(item?.proof);
                                              setSearchCountryState("");
                                              setDeliveryDetails((prev) => ({
                                                ...prev,
                                                importCountry: item.id,
                                              }));
                                              setImportCountryStateName(
                                                item?.country?.name
                                              );
                                              setShowAddresses(item);
                                              setCurrencyValue(
                                                item?.currency?.code
                                              );
                                              setFormErrors((prev: any) => ({
                                                ...prev,
                                                importCountry: "",
                                              }));
                                            }}
                                          >
                                            <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                                              <p>{item?.country?.name}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                        {formErrors.importCountry && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.importCountry}
                          </span>
                        )}
                      </div>
                      {showAddresses &&
                        showAddresses?.addresses?.length > 0 && (
                          <div className="mt-6">
                            <h6 className=" items-start text-[#4A564D] flex flex-col text-[14px]  font-onestMedium">
                              Shipping Address
                              {showAddresses?.addresses?.map(
                                (data: any, idx: number) => (
                                  <div className="w-full mt-2" key={idx}>
                                    <label
                                      htmlFor={"state"}
                                      className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                    >
                                      Address
                                    </label>
                                    <div className="flex space-x-3 mb-3 !border-[#E4EBF5] text-[#161718] rounded-[8px]  p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border  w-full items-center">
                                      <div
                                        className="text-[#161718]  pl-1 flex flex-col text-[15px]"
                                        key={idx}
                                      >
                                        {data?.address}
                                      </div>
                                      <div
                                        onClick={() =>
                                          handleCopy(data?.address)
                                        }
                                        className="cursor-pointer flex space-x-1 items-center"
                                      >
                                        <PiCopySimpleLight />

                                        <p className="text-[#BEC0BE] text-sm">
                                          {showClipboard &&
                                          copiedAdd === data?.address
                                            ? "Copied!"
                                            : "Copy"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              {showAddresses && (
                                <>
                                  <div className="w-full flex space-x-3">
                                    {showAddresses?.state && (
                                      <div className="w-full">
                                        <label
                                          htmlFor={"state"}
                                          className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                        >
                                          State
                                        </label>
                                        <div className="flex space-x-3 mb-3   p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border  !border-[#E4EBF5] rounded-[8px]  w-full items-center">
                                          <div className=" text-[#161718]  pl-1 flex flex-col text-[15px]">
                                            {showAddresses?.state}
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleCopy(showAddresses?.state)
                                            }
                                            className="cursor-pointer flex space-x-1 items-center"
                                          >
                                            <PiCopySimpleLight />

                                            <p className="text-[#BEC0BE] text-sm">
                                              {showClipboard &&
                                              copiedAdd === showAddresses?.state
                                                ? "Copied!"
                                                : "Copy"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {showAddresses?.city && (
                                      <div className=" w-full">
                                        <label
                                          htmlFor={"state"}
                                          className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                        >
                                          City
                                        </label>
                                        <div className="flex  mb-3 space-x-3 text-[#161718] rounded-[8px] p-2 text-[14px] cursor-text border !border-[#E4EBF5]  w-full items-center">
                                          <div className="text-[#161718]  pl-1 flex flex-col text-[15px]">
                                            {showAddresses?.city}
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleCopy(showAddresses?.city)
                                            }
                                            className="cursor-pointer flex space-x-1 items-center"
                                          >
                                            <PiCopySimpleLight />

                                            <p className="text-[#BEC0BE] text-sm">
                                              {showClipboard &&
                                              copiedAdd === showAddresses?.city
                                                ? "Copied!"
                                                : "Copy"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex  space-x-3 w-full">
                                    <div>
                                      {showAddresses?.postCode && (
                                        <label
                                          htmlFor={"state"}
                                          className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                        >
                                          Post Code
                                        </label>
                                      )}
                                      {showAddresses?.postCode && (
                                        <div className="flex space-x-3 mb-3 text-[#161718] p-2 text-[14px]  !border-[#E4EBF5] border-2  w-full items-center">
                                          <div className="text-green-800  pl-1 flex flex-col text-[15px]">
                                            {showAddresses?.postCode}
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleCopy(
                                                showAddresses?.postCode
                                              )
                                            }
                                            className="cursor-pointer flex space-x-1 items-center"
                                          >
                                            <PiCopySimpleLight />

                                            <p className="text-[#BEC0BE] text-sm">
                                              {showClipboard &&
                                              copiedAdd ===
                                                showAddresses?.postCode
                                                ? "Copied!"
                                                : "Copy"}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </h6>
                          </div>
                        )}

                      <div className="grid md:grid-cols-2 gap-3">
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Order number/Tracking ID"
                            isRequired
                            placeholder="Enter order number/tracking id"
                            name="importOrderNumber"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importOrderNumber}
                          />

                          {formErrors.importOrderNumber && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importOrderNumber}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <div className="flex">
                            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                              Select Item category
                            </p>
                            <span className="text-red-500 text-sm">*</span>
                          </div>
                          <Dropdown
                            loader={isItemLoading}
                            dropdown={
                              <div className="flex border  rounded-[8px] items-center space-x-2  whitespace-nowrap h-[43px] p-2 justify-between ">
                                <div className="flex items-center space-x-1">
                                  <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                  <span
                                    className={` ${
                                      deliveryDetails?.importItem === ""
                                        ? "text-[#9899A1]"
                                        : "text-[#000]"
                                    }  font-onestMedium text-sm`}
                                  >
                                    {deliveryDetails?.importItem === "" ||
                                    deliveryDetails?.importItem === undefined
                                      ? "Select item category"
                                      : itemName}
                                  </span>
                                </div>

                                <img
                                  src={"/images/chevronDown.svg"}
                                  alt="drop-down"
                                  className="mt-0.5 pr-2 cursor-pointer"
                                />
                              </div>
                            }
                            search={
                              <div>
                                <TextInput
                                  value={searchItem}
                                  name={"search"}
                                  type={"text"}
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setSearchItem(e.target.value)
                                  }
                                />
                              </div>
                            }
                            dropdownContainerClasses={`shadow-cardShadow-4  !w-full  !right-0  border border-slate-100  rounded `}
                            dropdownOptions={[
                              {
                                customChild: (
                                  <div>
                                    {filteredItemSearch?.length > 0 ? (
                                      <div>
                                        {filteredItemSearch?.map(
                                          (item: any, idx: number) => {
                                            return (
                                              <div
                                                key={idx}
                                                onClick={() => {
                                                  setDeliveryDetails(
                                                    (prev) => ({
                                                      ...prev,
                                                      importItem: item.id,
                                                    })
                                                  );
                                                  setSearchItem("");
                                                  setItemName(item?.name);
                                                  setFormErrors(
                                                    (prev: any) => ({
                                                      ...prev,
                                                      importItem: "",
                                                    })
                                                  );
                                                }}
                                              >
                                                <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                                  <p>{item?.name}</p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    ) : (
                                      <p>No data !!</p>
                                    )}
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {formErrors.importItem && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importItem}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Vendor/Business name"
                          isRequired
                          placeholder="eg:Zara, ASOS, TESCO, next, MATALAN"
                          name="importVendorName"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.importVendorName}
                        />

                        {formErrors.importVendorName && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.importVendorName}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                            Drop-off zone
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <Dropdown
                          dropdown={
                            <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                              <div className="flex items-center space-x-1">
                                <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                <span
                                  className={` ${
                                    deliveryDetails?.importDropOffState === ""
                                      ? "text-[#9899A1]"
                                      : "text-[#000]"
                                  }  font-onestMedium text-sm`}
                                >
                                  {deliveryDetails?.importDropOffState === "" ||
                                  deliveryDetails?.importDropOffState ===
                                    undefined
                                    ? "Select Drop-off zone"
                                    : importDropOffStateName}
                                </span>
                              </div>
                              <img
                                src={"/images/chevronDown.svg"}
                                alt="drop-down"
                                className="mt-0.5 pr-2 cursor-pointer"
                              />
                            </div>
                          }
                          search={
                            <div>
                              <TextInput
                                value={searchPickUpState}
                                name={"search"}
                                type={"text"}
                                placeholder="Search"
                                onChange={(e) =>
                                  setSearchPickUpState(e.target.value)
                                }
                              />
                            </div>
                          }
                          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                          dropdownOptions={[
                            {
                              customChild: (
                                <div>
                                  <div>
                                    {filteredPickUpStateSearch?.map(
                                      (item: any, idx: number) => {
                                        return (
                                          <div
                                            key={idx}
                                            onClick={() => {
                                              setDeliveryDetails((prev) => ({
                                                ...prev,
                                                importDropOffState: item.state,
                                              }));
                                              setImportDropOffStateName(
                                                item?.state
                                              );
                                              setSearchPickUpState("");
                                              setFormErrors((prev: any) => ({
                                                ...prev,
                                                importDropOffState: "",
                                              }));
                                            }}
                                          >
                                            <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                              <p>{item?.state}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                        {formErrors.importDropOffState && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.importDropOffState}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Drop off address"
                          isRequired
                          placeholder="Enter address"
                          name="importDropOffAdd"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.importDropOffAdd}
                        />

                        {formErrors.importDropOffAdd && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.importDropOffAdd}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Recipient name"
                          isRequired
                          placeholder="Enter name"
                          name="importRecipientName"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.importRecipientName}
                        />

                        {formErrors.importRecipientName && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.importRecipientName}
                          </span>
                        )}
                      </div>
                      <div className="grid md:grid-cols-2 gap-x-3">
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Recipient phone number"
                            isRequired
                            placeholder="Enter phone number"
                            name="importRecipientPhone"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importRecipientPhone}
                          />

                          {formErrors.importRecipientPhone && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importRecipientPhone}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="email"
                            label="Recipient email"
                            placeholder="Enter email"
                            name="importRecipientEmail"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importRecipientEmail}
                          />

                          {formErrors.importRecipientEmail && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importRecipientEmail}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Item weight"
                            isRequired
                            placeholder="Enter weight"
                            name="importWeight"
                            isWeight
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importWeight}
                          />

                          {formErrors.importWeight && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importWeight}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Quantity"
                            placeholder="Enter quantity"
                            name="importQuantity"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importQuantity}
                          />

                          {formErrors.importQuantity && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importQuantity}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mt-6">
                        <TextArea
                          label="Item description"
                          isRequired
                          placeholder="Write a description"
                          name="importDescription"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.importDescription}
                        />
                        {formErrors.importDescription && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.importDescription}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="number"
                          label={`Value of Item in ${currencyValue}`}
                          placeholder="Enter value"
                          name="importValueofItem"
                          isRequired
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.importValueofItem}
                        />
                        {formErrors.importValueofItem && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.importValueofItem}
                          </span>
                        )}
                      </div>
                      {deliveryDetails.importValueofItem !== "" &&
                        Number(deliveryDetails.importValueofItem) >=
                          Number(proof) && (
                          <div className="mt-6">
                            <TextInput
                              type="file"
                              name="invoice"
                              label="Invoice"
                              isRequired
                              accept=".jpg, .jpeg, .png, .pdf"
                              placeholder={""}
                              onChange={handleFileChange}
                              file={deliveryDetails.fileNames}
                            />
                            {formErrors.file && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.file}
                              </span>
                            )}
                          </div>
                        )}
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Enter discount code if you have one"
                          placeholder="Enter discount code"
                          name="importReferral"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.importReferral}
                        />
                      </div>
                    </>
                  )}
                  {selectedOption?.id === 3 && (
                    <>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                            Select Drop-off country
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <Dropdown
                          loader={isExportCountryLoading}
                          dropdown={
                            <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                              <div className="flex items-center space-x-1">
                                <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                <span
                                  className={` ${
                                    deliveryDetails.exportCountry === ""
                                      ? "text-[#9899A1]"
                                      : "text-[#000]"
                                  }  font-onestMedium text-sm`}
                                >
                                  {deliveryDetails.exportCountry === ""
                                    ? "Select country"
                                    : exportCountryStateName}
                                </span>
                              </div>
                              <HiOutlineChevronDown />
                            </div>
                          }
                          search={
                            <div>
                              <TextInput
                                value={searchCountryState}
                                name={"search"}
                                type={"text"}
                                placeholder="Search"
                                onChange={(e) =>
                                  setSearchCountryState(e.target.value)
                                }
                              />
                            </div>
                          }
                          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                          dropdownOptions={[
                            {
                              customChild: (
                                <div>
                                  <div>
                                    {filteredExportCountrySearch?.map(
                                      (item: any, idx: number) => {
                                        return (
                                          <div
                                            key={idx}
                                            onClick={() => {
                                              setSearchCountryState("");
                                              setDeliveryDetails((prev) => ({
                                                ...prev,
                                                exportCountry: item.id,
                                              }));
                                              setExportCountryStateName(
                                                item?.name
                                              );
                                              setFormErrors((prev: any) => ({
                                                ...prev,
                                                exportCountry: "",
                                              }));
                                            }}
                                          >
                                            <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                              <p>{item?.name}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                        {formErrors.exportCountry && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.exportCountry}
                          </span>
                        )}
                      </div>

                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Drop-off address"
                          isRequired
                          placeholder="Enter address"
                          name="exportDropOffAddress"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.exportDropOffAddress}
                        />
                        {formErrors.exportDropOffAddress && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.exportDropOffAddress}
                          </span>
                        )}
                      </div>
                      <div className="grid md:grid-cols-2 gap-x-3">
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Drop-off city"
                            isRequired
                            placeholder="Select a city"
                            name="exportDropOffCity"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportDropOffCity}
                          />
                          {formErrors.exportDropOffCity && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportDropOffCity}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Drop-off zip code"
                            isRequired
                            placeholder="Enter zip code"
                            name="exportZipCode"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportZipCode}
                          />
                          {formErrors.exportZipCode && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportZipCode}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Pick-up address"
                          isRequired
                          placeholder="Enter pick-up address"
                          name="exportPickUpAddress"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.exportPickUpAddress}
                        />
                        {formErrors.exportPickUpAddress && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.exportPickUpAddress}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                            Pick-up State
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <Dropdown
                          loader={isLoadingState}
                          dropdown={
                            <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                              <div className="flex items-center space-x-1">
                                <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                <span
                                  className={` ${
                                    deliveryDetails?.exportPickUpState === ""
                                      ? "text-[#9899A1]"
                                      : "text-[#000]"
                                  }  font-onestMedium text-sm`}
                                >
                                  {deliveryDetails?.exportPickUpState === "" ||
                                  deliveryDetails?.exportPickUpState ===
                                    undefined
                                    ? "Select state"
                                    : exportPickUpStateName}
                                </span>
                              </div>

                              <img
                                src={"/images/chevronDown.svg"}
                                alt="drop-down"
                                className="mt-0.5 pr-2 cursor-pointer"
                              />
                            </div>
                          }
                          search={
                            <div>
                              <TextInput
                                value={searchState}
                                name={"search"}
                                type={"text"}
                                placeholder="Search"
                                onChange={(e) => setSearchState(e.target.value)}
                              />
                            </div>
                          }
                          dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                          dropdownOptions={[
                            {
                              customChild: (
                                <div>
                                  <div>
                                    {filteredStateSearch?.map(
                                      (item: any, idx: number) => {
                                        return (
                                          <div
                                            key={idx}
                                            onClick={() => {
                                              setDeliveryDetails((prev) => ({
                                                ...prev,
                                                exportPickUpState: item.state,
                                              }));
                                              setSearchState("");
                                              setExportPickUpStateName(
                                                item?.state
                                              );
                                              setFormErrors((prev: any) => ({
                                                ...prev,
                                                exportPickUpState: "",
                                              }));
                                            }}
                                          >
                                            <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                              <p>{item?.state}</p>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                        {formErrors.exportPickUpState && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.exportPickUpState}
                          </span>
                        )}
                      </div>

                      <div className="mt-6">
                        <TextInput
                          type="number"
                          label="Pick-up phone number"
                          isRequired
                          placeholder="Enter phone number"
                          name="exportPickUpPhoneNumber"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.exportPickUpPhoneNumber}
                        />
                        {formErrors.exportPickUpPhoneNumber && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.exportPickUpPhoneNumber}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Recipient name"
                          isRequired
                          placeholder="Enter name"
                          name="exportRecipientName"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.exportRecipientName}
                        />
                        {formErrors.exportRecipientName && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.exportRecipientName}
                          </span>
                        )}
                      </div>
                      <div className="grid md:grid-cols-2 gap-x-3">
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Recipient phone number"
                            isRequired
                            placeholder="Enter phone number"
                            name="exportPhoneNumber"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportPhoneNumber}
                          />
                          {formErrors.exportPhoneNumber && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportPhoneNumber}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="email"
                            label="Recipient email"
                            placeholder="Enter email"
                            name="exportRecipientEmail"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportRecipientEmail}
                          />
                          {formErrors.exportRecipientEmail && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportRecipientEmail}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <DropDownSelect
                            isRequired
                            title="Select weight"
                            showcheckCountrySelected
                            checkCountrySelected={deliveryDetails.exportCountry}
                            lists={weightArray}
                            loading={isWeightLoading}
                            value={selectedWeightOption?.name}
                            // setQuery={setQuery}
                            isOpen={weightIsOpen}
                            toggleDropdown={() =>
                              setWeightIsOpen((prev) => !prev)
                            }
                            handleOptionClick={(e: any) => {
                              setSelectedWeightOption(e);
                              setDeliveryDetails((prev) => ({
                                ...prev,
                                exportWeight: e.id,
                              }));
                              setWeightIsOpen(false);
                              setFormErrors((prev: any) => ({
                                ...prev,
                                exportWeight: "",
                              }));
                            }}
                            placeholder="Select weight"
                          />
                          {formErrors.exportWeight && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportWeight}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Quantity"
                            placeholder="Enter quantity"
                            name="exportQuantity"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportQuantity}
                          />
                          {formErrors.exportQuantity && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportQuantity}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mt-6">
                        <TextArea
                          label="Item description"
                          isRequired
                          placeholder="Write a description"
                          name="exportItemDescription"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.exportItemDescription}
                        />
                        {formErrors.exportItemDescription && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.exportItemDescription}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="number"
                          label={`Value of Item`}
                          placeholder="Enter value"
                          name="exportValueofItem"
                          isRequired
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.exportValueofItem}
                        />
                        {formErrors.exportValueofItem && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.exportValueofItem}
                          </span>
                        )}
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Enter discount code if you have one"
                          placeholder="Enter discount code"
                          name="exportReferral"
                          onChange={handleDeliveryChange}
                          value={deliveryDetails.exportReferral}
                        />
                      </div>
                    </>
                  )}
                  {/* {selectedOption?.id === 4 && (
                    <>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Drop-off"
                          isRequired
                          placeholder="Select drop-off zone"
                          name="dropOffZone"
                          value={deliveryDetails.dropOffZone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Safe locker"
                          isRequired
                          placeholder="Select available safe locker"
                          name="safeLocker"
                          value={deliveryDetails.safeLocker}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Recipient name"
                          isRequired
                          placeholder="Enter name"
                          name="recipientName"
                          value={users.recipientName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="grid md:grid-cols-2 gap-x-3">
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Recipient phone number"
                            isRequired
                            placeholder="Enter phone number"
                            name="phoneNumber"
                            value={users.phoneNumber}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="email"
                            label="Recipient email"
                            placeholder="Enter email"
                            name="email"
                            value={users.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Item weight"
                            isRequired
                            placeholder="Enter weight"
                            name="itemWeight"
                            value={users.itemWeight}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Quantity"
                            placeholder="Enter quantity"
                            name="quantity"
                            onChange={handleChange}
                            value={users.quantity}
                          />
                        </div>
                        <div className="mt-6">
                          <TextArea
                            label="Item description"
                            isRequired
                            placeholder="Write a description"
                            name="itemDescription"
                            value={users.itemDescription}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-6">
                          <TextArea
                            label="Additional Information"
                            placeholder="Add more information"
                            name="additionalInformation"
                            value={users.additionalInformation}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Enter discount code if you have one"
                          placeholder="Enter discount code"
                          value={users.discountCode}
                          onChange={handleChange}
                          name="discountCode"
                        />
                      </div>
                    </>
                  )} */}

                  {items.length > 0 && (
                    <div className="block md:hidden xl:px-8 flex-col items-center justify-between mt-6">
                      <h1 className="font-[700] font-onestBold">
                        Total: N{totalWeight}
                      </h1>
                      <Button
                        title={
                          selectedOption?.id === 2
                            ? "Continue with your shipment"
                            : "Make Payment"
                        }
                        onClick={() => {
                          onGenerate();
                          setIsPayment(true);
                        }}
                        className="!w-fit py-2.5 bg-[#18AF04] mt-3 !font-[700] !text-xs xl:!text-[13px] font-onestBold"
                      />
                    </div>
                  )}
                  <div className="flex justify-between mt-3 md:mt-[64px] mb-[47px]">
                    <Button
                      title={
                        selectedOption?.id === 2
                          ? "Continue with your shipment"
                          : "Make Payment"
                      }
                      type="submit"
                      disabled={
                        selectedOption?.id === 1
                          ? isLoading
                          : selectedOption?.id === 2
                          ? importLoading
                          : exportLoading
                      }
                      loading={
                        selectedOption?.id === 1
                          ? isLoading && clickPayment
                          : selectedOption?.id === 2
                          ? importLoading && clickPayment
                          : exportLoading && clickPayment
                      }
                      onClick={(e: React.FormEvent<HTMLFormElement>) => {
                        selectedOption?.id === 1
                          ? handleSubmit(e, true)
                          : selectedOption?.id === 2
                          ? handleImportSubmit(e, true)
                          : handleExportSubmit(e, true);
                        setClickPayment(true);
                      }}
                      className={`flex justify-center !w-fit py-2.5 !text-white border-[1px] border-[#18af04]  !font-[700] !text-base font-onestBold `}
                    />
                    <Button
                      title="Add New Order"
                      type="submit"
                      icon={<MdAdd />}
                      iconLeft
                      disabled={
                        selectedOption?.id === 1
                          ? isLoading
                          : selectedOption?.id === 2
                          ? importLoading
                          : exportLoading
                      }
                      loading={
                        selectedOption?.id === 1
                          ? isLoading && !clickPayment
                          : selectedOption?.id === 2
                          ? importLoading && !clickPayment
                          : exportLoading && !clickPayment
                      }
                      className="!w-[100%]  py-3.5 !text-[#18AF04] !bg-transparent border border-[#18AF04] !font-[700] !text-xs xl:!text-[13px] font-onestBold"
                    />
                  </div>
                </form>

                {!addOrder && (
                  <form
                    className={`lg:hidden block`}
                    onSubmit={
                      selectedOption?.id === 1
                        ? handleSubmit
                        : selectedOption?.id === 2
                        ? handleImportSubmit
                        : handleExportSubmit
                    }
                  >
                    <div className="mt-6 border-b-2 pb-6 border-[#e4ebf5]">
                      <DropDownSelect
                        title="Select delivery type"
                        lists={deliveryType}
                        value={query}
                        setQuery={setQuery}
                        isOpen={isDeliveryType}
                        isRequired
                        toggleDropdown={toggleDeliveryType}
                        handleOptionClick={handleDeliveryType}
                        placeholder="Select type"
                        name="deliveryType"
                      />
                      {formErrors.deliveryType && (
                        <span className="text-xs text-[#BA3838] font-onestMedium">
                          {formErrors.deliveryType}
                        </span>
                      )}
                    </div>
                    {selectedOption?.id === 1 && (
                      <>
                        <div className="mt-6">
                          <div className="flex">
                            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                              Pick-up State
                            </p>
                            <span className="text-red-500 text-sm">*</span>
                          </div>
                          <Dropdown
                            loader={isLoadingState}
                            dropdown={
                              <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                                <div className="flex items-center space-x-1">
                                  <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                  <span
                                    className={` ${
                                      deliveryDetails?.pickUpState === ""
                                        ? "text-[#9899A1]"
                                        : "text-[#000]"
                                    }  font-onestMedium text-sm`}
                                  >
                                    {deliveryDetails?.pickUpState === "" ||
                                    deliveryDetails?.pickUpState === undefined
                                      ? "Select Pick-up state"
                                      : pickUpStateName}
                                  </span>
                                </div>

                                <img
                                  src={"/images/chevronDown.svg"}
                                  alt="drop-down"
                                  className="mt-0.5 pr-2 cursor-pointer"
                                />
                              </div>
                            }
                            search={
                              <div>
                                <TextInput
                                  value={searchPickUpState}
                                  name={"search"}
                                  type={"text"}
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setSearchPickUpState(e.target.value)
                                  }
                                />
                              </div>
                            }
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                            dropdownOptions={[
                              {
                                customChild: (
                                  <div>
                                    <div>
                                      {filteredPickUpStateSearch?.map(
                                        (item: any, idx: number) => {
                                          return (
                                            <div
                                              key={idx}
                                              onClick={() => {
                                                setDeliveryDetails((prev) => ({
                                                  ...prev,
                                                  pickUpState: item.state,
                                                }));
                                                setPickUpStateName(item?.state);
                                                setFormErrors((prev: any) => ({
                                                  ...prev,
                                                  pickUpState: "",
                                                }));
                                              }}
                                            >
                                              <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                                <p>{item?.state}</p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {formErrors.pickUpState && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.pickUpState}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Pick-up address"
                            isRequired
                            name="pickUpAddress"
                            value={deliveryDetails.pickUpAddress}
                            onChange={handleDeliveryChange}
                            placeholder="Enter pick-up address"
                          />
                          {formErrors.pickUpAddress && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.pickUpAddress}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Pick-up phone number"
                            name="pickUpPhoneNumber"
                            value={deliveryDetails.pickUpPhoneNumber}
                            onChange={handleDeliveryChange}
                            placeholder="Enter pick-up phone number"
                          />
                          {formErrors.pickUpPhoneNumber && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.pickUpPhoneNumber}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Drop-off address"
                            isRequired
                            name="dropOffAddress"
                            value={deliveryDetails.dropOffAddress}
                            onChange={handleDeliveryChange}
                            placeholder="Enter drop-off address"
                          />
                          {formErrors.dropOffAddress && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.dropOffAddress}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <div className="flex">
                            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                              Recipient State
                            </p>
                            <span className="text-red-500 text-sm">*</span>
                          </div>
                          <Dropdown
                            dropdown={
                              <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                <div className="flex items-center space-x-1">
                                  <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                  <span
                                    className={` ${
                                      deliveryDetails?.state === ""
                                        ? "text-[#9899A1]"
                                        : "text-[#000]"
                                    }  font-onestMedium text-sm`}
                                  >
                                    {deliveryDetails?.state === "" ||
                                    deliveryDetails?.state === undefined
                                      ? "Select state"
                                      : stateName}
                                  </span>
                                </div>

                                <img
                                  src={"/images/chevronDown.svg"}
                                  alt="drop-down"
                                  className="mt-0.5 pr-2 cursor-pointer"
                                />
                              </div>
                            }
                            search={
                              <div>
                                <TextInput
                                  value={searchState}
                                  name={"search"}
                                  type={"text"}
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setSearchState(e.target.value)
                                  }
                                />
                              </div>
                            }
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                            dropdownOptions={[
                              {
                                customChild: (
                                  <div>
                                    <div>
                                      {filteredStateSearch?.map(
                                        (item: any, idx: number) => {
                                          return (
                                            <div
                                              key={idx}
                                              onClick={() => {
                                                setDeliveryDetails((prev) => ({
                                                  ...prev,
                                                  state: item.state,
                                                }));
                                                setStateName(item?.state);
                                                setFormErrors((prev: any) => ({
                                                  ...prev,
                                                  state: "",
                                                }));
                                              }}
                                            >
                                              <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                                                <p>{item?.state}</p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {formErrors.state && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.state}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Recipient name"
                            isRequired
                            value={deliveryDetails.recipientName}
                            name="recipientName"
                            placeholder="Enter name"
                            onChange={handleDeliveryChange}
                          />
                          {formErrors.recipientName && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.recipientName}
                            </span>
                          )}
                        </div>
                        <div className="grid md:grid-cols-2 gap-x-3">
                          <div className="mt-6">
                            <TextInput
                              type="text"
                              label="Recipient phone number"
                              isRequired
                              name="recipientPhoneNumber"
                              value={deliveryDetails.recipientPhoneNumber || ""}
                              placeholder="Enter phone number"
                              onChange={handleDeliveryChange}
                            />
                            {formErrors.recipientPhoneNumber && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.recipientPhoneNumber}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="email"
                              label="Recipient email"
                              placeholder="Enter email"
                              name="recipientEmail"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.recipientEmail}
                            />
                            {formErrors.recipientEmail && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.recipientEmail}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="number"
                              label="Item weight"
                              isRequired
                              placeholder="Enter weight"
                              name="weight"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.weight}
                            />
                            {formErrors.weight && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.weight}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="number"
                              label="Quantity"
                              placeholder="Enter quantity"
                              name="quantity"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.quantity}
                            />
                            {formErrors.quantity && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.quantity}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Value of Item"
                            placeholder="Enter value"
                            name="valueOfItem"
                            isRequired
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.valueOfItem}
                          />
                          {formErrors.valueOfItem && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.valueOfItem}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextArea
                            label="Item description"
                            placeholder="Write a description"
                            name="itemDescription"
                            isRequired
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.itemDescription}
                          />
                          {formErrors.itemDescription && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.itemDescription}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Enter discount code if you have one"
                            placeholder="Enter discount code"
                            name="discountCode"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.discountCode}
                          />
                        </div>
                      </>
                    )}

                    {selectedOption?.id === 2 && (
                      <>
                        <div className="mt-6">
                          <div className="flex">
                            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                              Select Pick-up country
                            </p>
                            <span className="text-red-500 text-sm">*</span>
                          </div>
                          <Dropdown
                            loader={isCountryLoading}
                            dropdown={
                              <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                <div className="flex items-center space-x-1">
                                  <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                  <span
                                    className={` ${
                                      deliveryDetails.importCountry === ""
                                        ? "text-[#9899A1]"
                                        : "text-[#000]"
                                    }  font-onestMedium text-sm`}
                                  >
                                    {deliveryDetails.importCountry === ""
                                      ? "Select country"
                                      : importCountryStateName}
                                  </span>
                                </div>
                                <HiOutlineChevronDown />
                              </div>
                            }
                            search={
                              <div>
                                <TextInput
                                  value={searchCountryState}
                                  name={"search"}
                                  type={"text"}
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setSearchCountryState(e.target.value)
                                  }
                                />
                              </div>
                            }
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                            dropdownOptions={[
                              {
                                customChild: (
                                  <div>
                                    <div>
                                      {filteredCountrySearch?.map(
                                        (item: any, idx: number) => {
                                          return (
                                            <div
                                              key={idx}
                                              onClick={() => {
                                                setProof(item?.proof);
                                                setSearchCountryState("");
                                                setDeliveryDetails((prev) => ({
                                                  ...prev,
                                                  importCountry: item.id,
                                                }));
                                                setImportCountryStateName(
                                                  item?.state
                                                );
                                                setShowAddresses(item);
                                                setCurrencyValue(
                                                  item?.currency?.code
                                                );
                                                setFormErrors((prev: any) => ({
                                                  ...prev,
                                                  importCountry: "",
                                                }));
                                              }}
                                            >
                                              <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                                                <p>{item?.country?.name}</p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {formErrors.importCountry && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importCountry}
                            </span>
                          )}
                        </div>
                        {showAddresses &&
                          showAddresses?.addresses?.length > 0 && (
                            <div className="mt-6">
                              <h6 className="items-start text-[#4A564D] flex flex-col text-[14px]  font-onestMedium">
                                Shipping Address
                                {showAddresses?.addresses?.map(
                                  (data: any, idx: number) => (
                                    <div className="w-full mt-2" key={idx}>
                                      <label
                                        htmlFor={"state"}
                                        className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                      >
                                        Address
                                      </label>
                                      <div className="flex space-x-3 mb-3 !border-[#E4EBF5] text-[#161718] rounded-[8px]  p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border  w-full items-center">
                                        <div
                                          className="text-[#161718]  pl-1 flex flex-col text-[15px]"
                                          key={idx}
                                        >
                                          {data?.address}
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleCopy(data?.address)
                                          }
                                          className="cursor-pointer flex space-x-1 items-center"
                                        >
                                          <PiCopySimpleLight />

                                          <p className="text-[#BEC0BE] text-sm">
                                            {showClipboard &&
                                            copiedAdd === data?.address
                                              ? "Copied!"
                                              : "Copy"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                                {showAddresses && (
                                  <div className="w-full">
                                    {showAddresses?.state && (
                                      <>
                                        <label
                                          htmlFor={"state"}
                                          className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                        >
                                          State
                                        </label>
                                        <div className="flex space-x-3 mb-3   p-2 text-[14px] outline-0 focus:outline-none focus:ring-0 cursor-text border  !border-[#E4EBF5] rounded-[8px]  w-full items-center">
                                          <div className=" text-[#161718]  pl-1 flex flex-col text-[15px]">
                                            {showAddresses?.state}
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleCopy(showAddresses?.state)
                                            }
                                            className="cursor-pointer flex space-x-1 items-center"
                                          >
                                            <PiCopySimpleLight />

                                            <p className="text-[#BEC0BE] text-sm">
                                              {showClipboard &&
                                              copiedAdd === showAddresses?.state
                                                ? "Copied!"
                                                : "Copy"}
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    <div className="flex w-full space-x-3 mb-1 justify-between">
                                      {showAddresses?.city && (
                                        <label
                                          htmlFor={"state"}
                                          className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                        >
                                          City
                                        </label>
                                      )}

                                      {showAddresses?.postCode && (
                                        <label
                                          htmlFor={"state"}
                                          className="text-[#4A564D] mb-1  items-center flex text-[14px]  font-onestMedium"
                                        >
                                          Post Code
                                        </label>
                                      )}
                                    </div>
                                    <div className="flex  space-x-3 w-full">
                                      {showAddresses?.city && (
                                        <div className="flex  mb-3 space-x-3 text-[#161718] p-2 text-[14px] rounded-[8px] cursor-text border !border-[#E4EBF5]  w-full items-center">
                                          <div className="text-[#161718]  pl-1 flex flex-col text-[15px]">
                                            {showAddresses?.city}
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleCopy(showAddresses?.city)
                                            }
                                            className="cursor-pointer flex space-x-1 items-center"
                                          >
                                            <PiCopySimpleLight />

                                            <p className="text-[#BEC0BE] text-sm">
                                              {showClipboard &&
                                              copiedAdd === showAddresses?.city
                                                ? "Copied!"
                                                : "Copy"}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      {showAddresses?.postCode && (
                                        <div className="flex space-x-3 mb-3 text-[#161718] p-2 text-[14px]  !border-[#E4EBF5] border-2  w-full items-center">
                                          <div className="text-green-800  pl-1 flex flex-col text-[15px]">
                                            {showAddresses?.postCode}
                                          </div>
                                          <div
                                            onClick={() =>
                                              handleCopy(
                                                showAddresses?.postCode
                                              )
                                            }
                                            className="cursor-pointer flex space-x-1 items-center"
                                          >
                                            <PiCopySimpleLight />

                                            <p className="text-[#BEC0BE] text-sm">
                                              {showClipboard &&
                                              copiedAdd ===
                                                showAddresses?.postCode
                                                ? "Copied!"
                                                : "Copy"}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </h6>
                            </div>
                          )}

                        <div className="grid gap-4 md:grid-cols-2">
                          <div className="mt-6">
                            <TextInput
                              type="text"
                              label="Order number/Tracking ID"
                              isRequired
                              placeholder="Enter order number/tracking id"
                              name="importOrderNumber"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.importOrderNumber}
                            />

                            {formErrors.importOrderNumber && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.importOrderNumber}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <div className="flex">
                              <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                                Select Item category
                              </p>
                              <span className="text-red-500 text-sm">*</span>
                            </div>
                            <Dropdown
                              loader={isItemLoading}
                              dropdown={
                                <div className="flex border  rounded-[8px] items-center space-x-2  whitespace-nowrap h-[43px] p-2 justify-between ">
                                  <div className="flex items-center space-x-1">
                                    <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                    <span
                                      className={` ${
                                        deliveryDetails?.importItem === ""
                                          ? "text-[#9899A1]"
                                          : "text-[#000]"
                                      }  font-onestMedium text-sm`}
                                    >
                                      {deliveryDetails?.importItem === "" ||
                                      deliveryDetails?.importItem === undefined
                                        ? "Select item category"
                                        : itemName}
                                    </span>
                                  </div>

                                  <img
                                    src={"/images/chevronDown.svg"}
                                    alt="drop-down"
                                    className="mt-0.5 pr-2 cursor-pointer"
                                  />
                                </div>
                              }
                              search={
                                <div>
                                  <TextInput
                                    value={searchItem}
                                    name={"search"}
                                    type={"text"}
                                    placeholder="Search"
                                    onChange={(e) =>
                                      setSearchItem(e.target.value)
                                    }
                                  />
                                </div>
                              }
                              dropdownContainerClasses={`shadow-cardShadow-4  !w-full  !right-0  border border-slate-100  rounded `}
                              dropdownOptions={[
                                {
                                  customChild: (
                                    <div>
                                      {filteredItemSearch?.length > 0 ? (
                                        <div>
                                          {filteredItemSearch?.map(
                                            (item: any, idx: number) => {
                                              return (
                                                <div
                                                  key={idx}
                                                  onClick={() => {
                                                    setDeliveryDetails(
                                                      (prev) => ({
                                                        ...prev,
                                                        importItem: item.id,
                                                      })
                                                    );
                                                    setSearchItem("");
                                                    setItemName(item?.name);
                                                    setFormErrors(
                                                      (prev: any) => ({
                                                        ...prev,
                                                        importItem: "",
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                                                    <p>{item?.name}</p>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      ) : (
                                        <p>No data !!</p>
                                      )}
                                    </div>
                                  ),
                                },
                              ]}
                            />
                            {formErrors.importItem && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.importItem}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Vendor/Business name"
                            isRequired
                            placeholder="eg:Zara, ASOS, TESCO, next, MATALAN"
                            name="importVendorName"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importVendorName}
                          />

                          {formErrors.importVendorName && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importVendorName}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <div className="flex">
                            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                              Drop-off zone
                            </p>
                            <span className="text-red-500 text-sm">*</span>
                          </div>
                          <Dropdown
                            dropdown={
                              <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                                <div className="flex items-center space-x-1">
                                  <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                  <span
                                    className={` ${
                                      deliveryDetails?.importDropOffState === ""
                                        ? "text-[#9899A1]"
                                        : "text-[#000]"
                                    }  font-onestMedium text-sm`}
                                  >
                                    {deliveryDetails?.importDropOffState ===
                                      "" ||
                                    deliveryDetails?.importDropOffState ===
                                      undefined
                                      ? "Select Pick-up state"
                                      : importDropOffStateName}
                                  </span>
                                </div>
                                <img
                                  src={"/images/chevronDown.svg"}
                                  alt="drop-down"
                                  className="mt-0.5 pr-2 cursor-pointer"
                                />
                              </div>
                            }
                            search={
                              <div>
                                <TextInput
                                  value={searchPickUpState}
                                  name={"search"}
                                  type={"text"}
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setSearchPickUpState(e.target.value)
                                  }
                                />
                              </div>
                            }
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                            dropdownOptions={[
                              {
                                customChild: (
                                  <div>
                                    <div>
                                      {filteredPickUpStateSearch?.map(
                                        (item: any, idx: number) => {
                                          return (
                                            <div
                                              key={idx}
                                              onClick={() => {
                                                setDeliveryDetails((prev) => ({
                                                  ...prev,
                                                  importDropOffState:
                                                    item.state,
                                                }));
                                                setImportDropOffStateName(
                                                  item?.state
                                                );
                                                setSearchPickUpState("");
                                                setFormErrors((prev: any) => ({
                                                  ...prev,
                                                  importDropOffState: "",
                                                }));
                                              }}
                                            >
                                              <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                                <p>{item?.state}</p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {formErrors.importDropOffState && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importDropOffState}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Drop off address"
                            isRequired
                            placeholder="Enter address"
                            name="importDropOffAdd"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importDropOffAdd}
                          />

                          {formErrors.importDropOffAdd && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importDropOffAdd}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Recipient name"
                            isRequired
                            placeholder="Enter name"
                            name="importRecipientName"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importRecipientName}
                          />

                          {formErrors.importRecipientName && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importRecipientName}
                            </span>
                          )}
                        </div>
                        <div className="grid md:grid-cols-2 gap-x-3">
                          <div className="mt-6">
                            <TextInput
                              type="number"
                              label="Recipient phone number"
                              isRequired
                              placeholder="Enter phone number"
                              name="importRecipientPhone"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.importRecipientPhone}
                            />

                            {formErrors.importRecipientPhone && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.importRecipientPhone}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="email"
                              label="Recipient email"
                              placeholder="Enter email"
                              name="importRecipientEmail"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.importRecipientEmail}
                            />

                            {formErrors.importRecipientEmail && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.importRecipientEmail}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="number"
                              label="Item weight"
                              isRequired
                              isWeight
                              placeholder="Enter weight"
                              name="importWeight"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.importWeight}
                            />

                            {formErrors.importWeight && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.importWeight}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="text"
                              label="Quantity"
                              placeholder="Enter quantity"
                              name="importQuantity"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.importQuantity}
                            />

                            {formErrors.importQuantity && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.importQuantity}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-6">
                          <TextArea
                            label="Item description"
                            isRequired
                            placeholder="Write a description"
                            name="importDescription"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importDescription}
                          />
                          {formErrors.importDescription && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importDescription}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label={`Value of Item in ${currencyValue}`}
                            placeholder="Enter value"
                            name="importValueofItem"
                            isRequired
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importValueofItem}
                          />
                          {formErrors.importValueofItem && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.importValueofItem}
                            </span>
                          )}
                        </div>
                        {deliveryDetails.importValueofItem !== "" &&
                          Number(deliveryDetails.importValueofItem) >=
                            Number(proof) && (
                            <div className="mt-6">
                              <TextInput
                                type="file"
                                name="invoice"
                                label="Invoice"
                                isRequired
                                accept=".jpg, .jpeg, .png, .pdf"
                                placeholder={""}
                                onChange={handleFileChange}
                                file={deliveryDetails.fileNames}
                              />
                              {formErrors.file && (
                                <span className="text-xs text-[#BA3838] font-onestMedium">
                                  {formErrors.file}
                                </span>
                              )}
                            </div>
                          )}
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Enter discount code if you have one"
                            placeholder="Enter discount code"
                            name="importReferral"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.importReferral}
                          />
                        </div>
                      </>
                    )}
                    {selectedOption?.id === 3 && (
                      <>
                        <div className="mt-6">
                          <div className="flex">
                            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                              Select Drop-off country
                            </p>
                            <span className="text-red-500 text-sm">*</span>
                          </div>
                          <Dropdown
                            dropdown={
                              <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                <div className="flex items-center space-x-1">
                                  <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                  <span
                                    className={` ${
                                      deliveryDetails.exportCountry === ""
                                        ? "text-[#9899A1]"
                                        : "text-[#000]"
                                    }  font-onestMedium text-sm`}
                                  >
                                    {deliveryDetails.exportCountry === ""
                                      ? "Select country"
                                      : exportCountryStateName}
                                  </span>
                                </div>
                                <HiOutlineChevronDown />
                              </div>
                            }
                            search={
                              <div>
                                <TextInput
                                  value={searchCountryState}
                                  name={"search"}
                                  type={"text"}
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setSearchCountryState(e.target.value)
                                  }
                                />
                              </div>
                            }
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                            dropdownOptions={[
                              {
                                customChild: (
                                  <div>
                                    <div>
                                      {filteredExportCountrySearch?.map(
                                        (item: any, idx: number) => {
                                          return (
                                            <div
                                              key={idx}
                                              onClick={() => {
                                                setSearchCountryState("");
                                                setDeliveryDetails((prev) => ({
                                                  ...prev,
                                                  exportCountry: item.id,
                                                }));
                                                setExportCountryStateName(
                                                  item?.name
                                                );

                                                setFormErrors((prev: any) => ({
                                                  ...prev,
                                                  exportCountry: "",
                                                }));
                                              }}
                                            >
                                              <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                                                <p>{item?.name}</p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {formErrors.exportCountry && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportCountry}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Drop-off address"
                            isRequired
                            placeholder="Enter address"
                            name="exportDropOffAddress"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportDropOffAddress}
                          />
                          {formErrors.exportDropOffAddress && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportDropOffAddress}
                            </span>
                          )}
                        </div>

                        <div className="grid md:grid-cols-2 gap-x-3">
                          <div className="mt-6">
                            <TextInput
                              type="text"
                              label="Drop-off city"
                              isRequired
                              placeholder="Select a city"
                              name="exportDropOffCity"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.exportDropOffCity}
                            />
                            {formErrors.exportDropOffCity && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.exportDropOffCity}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="text"
                              label="Drop-off zip code"
                              isRequired
                              placeholder="Enter zip code"
                              name="exportZipCode"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.exportZipCode}
                            />
                            {formErrors.exportZipCode && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.exportZipCode}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Pick-up address"
                            isRequired
                            placeholder="Enter pick-up address"
                            name="exportPickUpAddress"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportPickUpAddress}
                          />
                          {formErrors.exportPickUpAddress && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportPickUpAddress}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <div className="flex">
                            <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                              Pick-up State
                            </p>
                            <span className="text-red-500 text-sm">*</span>
                          </div>
                          <Dropdown
                            loader={isLoadingState}
                            dropdown={
                              <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                                <div className="flex items-center space-x-1">
                                  <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                  <span
                                    className={` ${
                                      deliveryDetails?.exportPickUpState === ""
                                        ? "text-[#9899A1]"
                                        : "text-[#000]"
                                    }  font-onestMedium text-sm`}
                                  >
                                    {deliveryDetails?.exportPickUpState ===
                                      "" ||
                                    deliveryDetails?.exportPickUpState ===
                                      undefined
                                      ? "Select state"
                                      : exportPickUpStateName}
                                  </span>
                                </div>

                                <img
                                  src={"/images/chevronDown.svg"}
                                  alt="drop-down"
                                  className="mt-0.5 pr-2 cursor-pointer"
                                />
                              </div>
                            }
                            search={
                              <div>
                                <TextInput
                                  value={searchState}
                                  name={"search"}
                                  type={"text"}
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setSearchState(e.target.value)
                                  }
                                />
                              </div>
                            }
                            dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                            dropdownOptions={[
                              {
                                customChild: (
                                  <div>
                                    <div>
                                      {filteredStateSearch?.map(
                                        (item: any, idx: number) => {
                                          return (
                                            <div
                                              key={idx}
                                              onClick={() => {
                                                setDeliveryDetails((prev) => ({
                                                  ...prev,
                                                  exportPickUpState: item.state,
                                                }));
                                                setSearchState("");
                                                setExportPickUpStateName(
                                                  item?.state
                                                );
                                                setFormErrors((prev: any) => ({
                                                  ...prev,
                                                  exportPickUpState: "",
                                                }));
                                              }}
                                            >
                                              <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                                                <p>{item?.state}</p>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {formErrors.exportPickUpState && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportPickUpState}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Pick-up phone number"
                            isRequired
                            placeholder="Enter phone number"
                            name="exportPickUpPhoneNumber"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportPickUpPhoneNumber}
                          />
                          {formErrors.exportPickUpPhoneNumber && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportPickUpPhoneNumber}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Recipient name"
                            isRequired
                            placeholder="Enter name"
                            name="exportRecipientName"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportRecipientName}
                          />
                          {formErrors.exportRecipientName && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportRecipientName}
                            </span>
                          )}
                        </div>
                        <div className="grid md:grid-cols-2 gap-x-3">
                          <div className="mt-6">
                            <TextInput
                              type="text"
                              label="Recipient phone number"
                              isRequired
                              placeholder="Enter phone number"
                              name="exportPhoneNumber"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.exportPhoneNumber}
                            />
                            {formErrors.exportPhoneNumber && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.exportPhoneNumber}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="email"
                              label="Recipient email"
                              placeholder="Enter email"
                              name="exportRecipientEmail"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.exportRecipientEmail}
                            />
                            {formErrors.exportRecipientEmail && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.exportRecipientEmail}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <DropDownSelect
                              loading={isWeightLoading}
                              isRequired
                              title="Select weight"
                              showcheckCountrySelected
                              checkCountrySelected={
                                deliveryDetails.exportCountry
                              }
                              lists={weightData?.weight}
                              value={selectedWeightOption?.name}
                              // setQuery={setQuery}
                              isOpen={weightIsOpen}
                              toggleDropdown={() =>
                                setWeightIsOpen((prev) => !prev)
                              }
                              handleOptionClick={(e: any) => {
                                setSelectedWeightOption(e);
                                setDeliveryDetails((prev) => ({
                                  ...prev,
                                  exportWeight: e.id,
                                }));
                                setWeightIsOpen(false);
                                setFormErrors((prev: any) => ({
                                  ...prev,
                                  exportWeight: "",
                                }));
                              }}
                              placeholder="Select weight"
                            />
                            {formErrors.exportWeight && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.exportWeight}
                              </span>
                            )}
                          </div>
                          <div className="mt-6">
                            <TextInput
                              type="text"
                              label="Quantity"
                              placeholder="Enter quantity"
                              name="exportQuantity"
                              onChange={handleDeliveryChange}
                              value={deliveryDetails.exportQuantity}
                            />
                            {formErrors.exportQuantity && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.exportQuantity}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mt-6">
                          <TextArea
                            label="Item description"
                            isRequired
                            placeholder="Write a description"
                            name="exportItemDescription"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportItemDescription}
                          />
                          {formErrors.exportItemDescription && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportItemDescription}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label={`Value of Item`}
                            placeholder="Enter value"
                            name="exportValueofItem"
                            isRequired
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportValueofItem}
                          />
                          {formErrors.exportValueofItem && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.exportValueofItem}
                            </span>
                          )}
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Enter discount code if you have one"
                            placeholder="Enter discount code"
                            name="exportReferral"
                            onChange={handleDeliveryChange}
                            value={deliveryDetails.exportReferral}
                          />
                        </div>
                      </>
                    )}
                    {/* {selectedOption?.id === 4 && (
                    <>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Drop-off"
                          isRequired
                          placeholder="Select drop-off zone"
                          name="dropOffZone"
                          value={deliveryDetails.dropOffZone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Safe locker"
                          isRequired
                          placeholder="Select available safe locker"
                          name="safeLocker"
                          value={deliveryDetails.safeLocker}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Recipient name"
                          isRequired
                          placeholder="Enter name"
                          name="recipientName"
                          value={users.recipientName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="grid md:grid-cols-2 gap-x-3">
                        <div className="mt-6">
                          <TextInput
                            type="text"
                            label="Recipient phone number"
                            isRequired
                            placeholder="Enter phone number"
                            name="phoneNumber"
                            value={users.phoneNumber}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="email"
                            label="Recipient email"
                            placeholder="Enter email"
                            name="email"
                            value={users.email}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Item weight"
                            isRequired
                            placeholder="Enter weight"
                            name="itemWeight"
                            value={users.itemWeight}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-6">
                          <TextInput
                            type="number"
                            label="Quantity"
                            placeholder="Enter quantity"
                            name="quantity"
                            onChange={handleChange}
                            value={users.quantity}
                          />
                        </div>
                        <div className="mt-6">
                          <TextArea
                            label="Item description"
                            isRequired
                            placeholder="Write a description"
                            name="itemDescription"
                            value={users.itemDescription}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-6">
                          <TextArea
                            label="Additional Information"
                            placeholder="Add more information"
                            name="additionalInformation"
                            value={users.additionalInformation}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="mt-6">
                        <TextInput
                          type="text"
                          label="Enter discount code if you have one"
                          placeholder="Enter discount code"
                          value={users.discountCode}
                          onChange={handleChange}
                          name="discountCode"
                        />
                      </div>
                    </>
                  )} */}

                    <div className="mt-3 space-y-5 md:mt-[64px] mb-[47px]">
                      <Button
                        title="Add to Orders"
                        type="submit"
                        disabled={
                          selectedOption?.id === 1
                            ? isLoading
                            : selectedOption?.id === 2
                            ? importLoading
                            : exportLoading
                        }
                        loading={
                          selectedOption?.id === 1
                            ? isLoading
                            : selectedOption?.id === 2
                            ? importLoading
                            : exportLoading
                        }
                        className={`flex justify-center py-2.5 !text-white border-[1px] border-[#18af04]  !font-[700] !text-base font-onestBold `}
                      />
                      <Button
                        title="View Orders"
                        type="submit"
                        disabled={
                          selectedOption?.id === 1
                            ? isLoading
                            : selectedOption?.id === 2
                            ? importLoading
                            : exportLoading
                        }
                        onClick={() => {
                          items.length > 0 && setAddOrder(true);
                        }}
                        className={`flex justify-center py-2.5 !bg-white border-[1px] !text-[#18af04]  border-[#18af04]  !font-[700] !text-base font-onestBold `}
                      />
                    </div>
                  </form>
                )}
              </div>
              <div className={`hidden lg:block`}>
                {items.length === 0 && (
                  <div className="hidden bmd:block border-[1px] border-[#E1F0DF] h-[596px] px-8 py-8 rounded-lg">
                    <div className="flex gap-[14px] items-center">
                      <h1 className="text-[#A0ABA3] text-sm font-[700] font-onestBold">
                        MY ORDERS
                      </h1>
                      <img src="/images/order-box.svg" alt="" />
                    </div>
                    <p className="text-[#A0ABA3] text-center mt-[151px] mx-auto w-[60%] text-[13px]">
                      When you add a shipment, it will show here
                    </p>
                  </div>
                )}
                {items.length > 0 && (
                  <div className="hidden md:block border-[1px] border-[#E1F0DF] h-full max-h-[567px] bg-[#F4F5F6]  rounded-lg ">
                    <div className="flex gap-[14px] items-center px-8 pt-8 pb-6 bg-white">
                      <h1 className="text-[#0a5001] text-sm font-[700] font-onestBold">
                        MY ORDERS ({items.length})
                      </h1>
                    </div>
                    <div className="px-8 bg-white overflow-y-auto h-[400px]">
                      {items.map((item) => {
                        const pickUpCountry = filteredCountrySearch.filter(
                          (state: any) => {
                            return state.id === item.importCountry;
                          }
                        );

                        return (
                          <div
                            key={item.id}
                            className="flex gap-3 border-b-[1px] border-[#e4ebf5] py-4"
                          >
                            <div className="bg-[#F4F5F6] w-[28px] h-[28px] flex items-center justify-center rounded-[30px]">
                              <img
                                className="w-[14px] h-[14px]"
                                src="/images/location.svg"
                                alt=""
                              />
                            </div>
                            <div className="flex justify-between w-full">
                              <div>
                                <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                                  {item.recipientName ||
                                    item.importRecipientName ||
                                    item.exportRecipientName}
                                </h1>
                                <p className="text-[#686868] flex items-center font-onestRegular text-[13px] mb-0.5">
                                  <span>{item.deliveryType} </span>
                                  <FaCircle
                                    color="#d1d1d2"
                                    size={6}
                                    className="bg-[#d1d1d2] mx-2 rounded-lg"
                                  />{" "}
                                  <span>
                                    {item.pickUpState ||
                                      pickUpCountry?.[0]?.country?.name ||
                                      item.exportPickUpState}
                                  </span>
                                </p>
                                <div className="flex space-x-3">
                                  <p
                                    className="text-[#18AF04] cursor-pointer text-xs font-onestRegular"
                                    onClick={() => {
                                      handleEdit(item.uniqueID);
                                    }}
                                  >
                                    Edit order
                                  </p>
                                  <p
                                    onClick={() => {
                                      handleDelete(item.uniqueID);
                                      if (items.length === 1) {
                                        setAddOrder(false);
                                        setIsPayment(false);
                                      }
                                    }}
                                    className=" text-[#F64108] cursor-pointer text-xs font-onestRegular"
                                  >
                                    Delete
                                  </p>
                                </div>
                              </div>

                              {selectedOption?.id === 2 ? (
                                <div className="flex space-x-1 ">
                                  {item.discountedCost &&
                                  item.discountedCost !== item.cost ? (
                                    <div className="flex space-x-1 ">
                                      <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                        {getSymbolFromCurrency(currencyValue)}
                                        {formatMoney().format(item.cost)}
                                      </p>
                                      <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                        {getSymbolFromCurrency(currencyValue)}
                                        {formatMoney().format(
                                          item.discountedCost
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                      {getSymbolFromCurrency(currencyValue)}
                                      {formatMoney().format(item.cost)}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="flex space-x-1 ">
                                  {item.discountedCost &&
                                  item.discountedCost !== item.cost ? (
                                    <div className="flex space-x-1">
                                      <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                        ₦{formatMoney().format(item.cost)}
                                      </p>
                                      <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                        ₦
                                        {formatMoney().format(
                                          item.discountedCost
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                      ₦{formatMoney().format(item.cost)}
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="px-4 xl:px-8 pt-5 bmd:flex-col lg:flex xl:flex xl:flex-row items-center justify-between ">
                      {selectedOption?.id !== 2 && (
                        <h1 className="font-[700] font-onestBold">
                          ₦{formatMoney().format(totalCost)}
                        </h1>
                      )}
                      <Button
                        title={
                          selectedOption?.id === 2
                            ? "Continue with your shipment"
                            : "Make Payment"
                        }
                        onClick={() => {
                          onGenerate();
                          setIsPayment(true);
                        }}
                        className="!w-fit py-2.5 bg-[#18AF04] !font-[700] !text-xs xl:!text-[13px] font-onestBold"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="overflow-x-hidden mt-14 md:mt-0">
            <div
              onClick={onHandleBackPayment}
              className="mx-5 flex mb-2 md:hidden cursor-pointer"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className=" text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>
            <div className="grid lg:grid-cols-[65%_35%] gap-[22px] md:mt-[72px] mx-5 md:mx-[100px] pb-[150px]">
              <div className="border-[1px] border-[#E1F0DF] md:pt-8 px-5 md:px-8 bg-dashboard-gradient rounded-lg">
                <div className="hidden md:flex items-center justify-between">
                  <div
                    onClick={onHandleBackPayment}
                    className="flex gap-1 cursor-pointer w-[100px]"
                  >
                    <div>
                      {" "}
                      <img
                        className="w-3 h-3"
                        src="/images/backArrow.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                      Go back
                    </p>
                  </div>
                  <div className="flex gap-2.5">
                    <div className="">
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                        Pick-up
                      </p>
                    </div>
                    <div>
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                        Delivery
                      </p>
                    </div>
                    <div>
                      <div
                        className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                      ></div>
                      <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                        Payment
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex md:hidden items-center justify-between mt-6 mx-4">
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-2 text-[12px] font-onestMedium w-[100px]">
                      Pick-up
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                      Delivery
                    </p>
                  </div>
                  <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                  <div className="relative">
                    <FaCircle color="#18AF04" />
                    <p className="absolute mt-1 text-[#68726B] font-[500] -left-4 text-[12px] font-onestMedium w-[100px]">
                      Payment
                    </p>
                  </div>
                </div>
                <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  Make Payment
                </h1>
                <p className=" text-[#4a564d] text-[13px] font-[500]">
                  Local (within Nigeria)
                </p>
                <div className="bg-[#f3fbf2] mt-12 rounded-lg px-4 py-6 border-[1px] border-[#e1f0df]">
                  <div className="flex justify-between items-center">
                    <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                      Wallet Balance
                    </h4>
                    <div className="hidden md:block w-[45%] border-b-[1px] border-dashed border-[#b8c2cf]"></div>
                    <div>
                      {isWalletLoading ? (
                        <Loader />
                      ) : (
                        <h1
                          className={`font-[800] text-lg font-onestBold ${
                            totalCost >= walletData?.balance?.business_wallet
                              ? "text-[#F64108]"
                              : "text-[#0a5001]"
                          } `}
                        >
                          ₦
                          {formatMoney().format(
                            walletData?.balance?.business_wallet +
                              Number(localStorage.getItem("amountAdded"))
                          )}
                        </h1>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-5">
                    <h4 className="text-[#0e1728] font-[500] w-full md:w-[20%] font-onestMedium text-sm">
                      No of orders
                    </h4>
                    <div className="hidden md:block w-[45%] border-b-[1px] -ml-[20%] border-dashed border-[#b8c2cf]"></div>
                    <h1 className="font-[800] text-lg font-onestBold text-[#0a5001]">
                      {formatMoney().format(items?.length)}
                    </h1>
                  </div>
                </div>
                {totalCost >= walletData?.balance?.business_wallet && (
                  <div className="pb-3">
                    <div className="flex gap-1 border-b-[1px] mt-3 p-2 border-[#F59A49] bg-[#FAEFE5] rounded-[10px]">
                      <div className="">
                        {" "}
                        <IoWarningOutline color="#F59A49" />
                      </div>
                      <h1 className="text-xs font-onestRegular font-[400] text-[#0E1728]">
                        Your wallet balance is insufficient, please add money
                      </h1>
                    </div>
                    <Button
                      onClick={() => {
                        onGenerate();
                        setAddMoney(true);
                      }}
                      title="Add Money"
                      className="!w-[150px] py-4 mt-5 !bg-[#F3FBF2] border-[1px] border-[#18af04] !text-[#18af04]"
                    />
                  </div>
                )}

                {selectedOption?.id !== 2 && (
                  <h1 className="block md:hidden font-[700] my-8 font-onestBold">
                    Total: ₦{formatMoney().format(totalCost)}
                  </h1>
                )}
                {selectedOption?.id === 2 ? (
                  <div className="flex-col space-y-3 md:space-y-0 md:flex md:flex-row justify-between mt-10 md:mt-[120px] mb-7">
                    <Button
                      title="Continue with your shipment"
                      loading={orderLoading}
                      disabled={orderLoading}
                      onClick={() => {
                        onHandleImportPayment();
                      }}
                      className="w-fit  py-2.5 "
                    />
                  </div>
                ) : (
                  <div className="flex-col space-y-3 md:space-y-0 md:flex md:flex-row justify-between md:mt-[120px] mb-7">
                    <Button
                      title="Pay with Card"
                      loading={
                        orderLoading && payType.toLowerCase() === "paystack"
                      }
                      disabled={orderLoading}
                      onClick={() => {
                        setPayType("paystack");
                        selectedOption?.id === 1
                          ? onHandlePayment("paystack")
                          : onHandleExportPayment("paystack");
                      }}
                      className="w-full  py-2.5 "
                    />
                    <Button
                      title="Pay with Wallet"
                      loading={
                        (orderLoading && payType.toLowerCase() === "wallet") ||
                        isWalletCheckoutLoading
                      }
                      disabled={orderLoading || isWalletCheckoutLoading}
                      onClick={() => {
                        setPayType("wallet");
                        selectedOption?.id === 1
                          ? onHandlePayment("wallet")
                          : onHandleExportPayment("wallet");
                      }}
                      className="w-full !bg-[#fff] border-[1px] border-[#18af04] !text-[#18af04]  py-2.5 mb-4 md:mb-0"
                    />
                    {fezOrgDetails?.canPayOffline === 1 && (
                      <Button
                        onClick={() => {
                          setPayType("pay-later");
                          selectedOption?.id === 1
                            ? onHandlePayment("pay-later")
                            : onHandleExportPayment("pay-later");
                        }}
                        loading={
                          orderLoading && payType.toLowerCase() === "pay-later"
                        }
                        disabled={orderLoading || isWalletCheckoutLoading}
                        title="Pay Later"
                        className="w-full md:!w-[150px] py-2.5 !bg-[#d3d2d2]  !text-[#141514]"
                      />
                    )}
                  </div>
                )}
              </div>
              <div>
                {items.length > 0 && (
                  <div className="hidden bmd:block border-[1px] border-[#E1F0DF] h-full xl:h-[526px]  rounded-lg">
                    <div className="flex gap-[14px] items-center px-8 pt-8 pb-6 bg-white">
                      <h1 className="text-[#0a5001] text-sm font-[700] font-onestBold">
                        MY ORDERS ({formatMoney().format(items.length)})
                      </h1>
                    </div>
                    <div className="px-8 bg-white h-[70%]">
                      {items.map((item) => {
                        const pickUpCountry = filteredCountrySearch.filter(
                          (state: any) => {
                            return state.id === item.importCountry;
                          }
                        );
                        return (
                          <div
                            key={item.id}
                            className="flex gap-3 border-b-[1px] border-[#e4ebf5] py-4"
                          >
                            <div className="bg-[#F4F5F6] w-[28px] h-[28px] flex items-center justify-center rounded-[30px]">
                              <img
                                className="w-[14px] h-[14px]"
                                src="/images/location.svg"
                                alt=""
                              />
                            </div>
                            <div className="flex justify-between w-full">
                              <div>
                                <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                                  {item.recipientName ||
                                    item.importRecipientName ||
                                    item.exportRecipientName}
                                </h1>
                                <p className="text-[#686868] flex items-center font-onestRegular text-[13px] mb-0.5">
                                  <span>{item.deliveryType} </span>
                                  <FaCircle
                                    color="#d1d1d2"
                                    size={6}
                                    className="bg-[#d1d1d2] mx-2 rounded-lg"
                                  />{" "}
                                  <span>
                                    {item.pickUpState ||
                                      pickUpCountry?.[0]?.country?.name ||
                                      item.exportPickUpState}
                                  </span>
                                </p>
                                <div className="flex space-x-3 items-center">
                                  <p
                                    className="text-[#18AF04] cursor-pointer text-xs font-onestRegular"
                                    onClick={() => {
                                      handleEdit(item.uniqueID);
                                      setIsPayment(false);
                                    }}
                                  >
                                    Edit order
                                  </p>
                                  <p
                                    onClick={() => {
                                      handleDelete(item.uniqueID);
                                      if (items.length === 1) {
                                        setAddOrder(false);
                                        setIsPayment(false);
                                      }
                                    }}
                                    className="ml-3 text-[#F64108] cursor-pointer text-xs font-onestRegular"
                                  >
                                    Delete
                                  </p>
                                </div>
                              </div>
                              {selectedOption?.id === 2 ? (
                                <div className="flex space-x-1 ">
                                  {item.discountedCost &&
                                  item.discountedCost !== item.cost ? (
                                    <div className="flex space-x-1 ">
                                      <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                        {getSymbolFromCurrency(currencyValue)}
                                        {formatMoney().format(item.cost)}
                                      </p>
                                      <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                        {getSymbolFromCurrency(currencyValue)}
                                        {formatMoney().format(
                                          item.discountedCost
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                      {getSymbolFromCurrency(currencyValue)}
                                      {formatMoney().format(item.cost)}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="flex space-x-1 ">
                                  {item.discountedCost &&
                                  item.discountedCost !== item.cost ? (
                                    <div className="flex space-x-1">
                                      <p className="text-red-400 font-onestMedium line-through text-[14px] font-[500]">
                                        ₦{formatMoney().format(item.cost)}
                                      </p>
                                      <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                        ₦
                                        {formatMoney().format(
                                          item.discountedCost
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                                      ₦{formatMoney().format(item.cost)}
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="px-4 xl:px-8 flex-col xl:flex xl:flex-row items-center justify-between mt-6">
                      {selectedOption?.id !== 2 && (
                        <h1 className="font-[700] font-onestBold">
                          ₦{formatMoney().format(totalCost)}
                        </h1>
                      )}
                    </div>
                  </div>
                )}
                <ModalContainer
                  open={isModalPayment}
                  showCloseIcon={false}
                  tailwindClassName="w-[350px] sm:w-[466px]"
                  closeModal={() => setModalPayment(false)}
                >
                  <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
                    <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
                      Yippee!! 💃🏾
                    </p>
                    <img
                      className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
                      onClick={() => {
                        setItems([]);
                        localStorage.removeItem("items");
                        navigate("/dashboard/shipments");
                      }}
                      src="/images/close.svg"
                      alt=""
                    />
                    <div className="flex justify-center mt-8">
                      {" "}
                      <img
                        src="/images/fezbox.svg"
                        alt=""
                        className="w-[123px] h-[98px]"
                      />
                    </div>
                    <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
                      Your order has been successfully uploaded and is ready to
                      be shipped.{" "}
                    </p>
                    <div className="flex justify-center mt-14">
                      <Button
                        title="View orders"
                        onClick={() => {
                          localStorage.removeItem("items");
                          navigate("/dashboard/shipments");
                        }}
                        className="!w-[210px] py-4 bg-[#18AF04] rounded-lg !font-[500] !text-base"
                      />
                    </div>
                  </div>
                </ModalContainer>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="mt-7 sm:mt-[72px] rounded-lg max-w-[38rem] mx-4 md:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
          <div className="w-full px-4 sm:px-10 py-6 sm:py-8 onestBold">
            <h1 className="text-2xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
              Create a shipment
            </h1>
            <p className="text-[#a0aba3] font-[500] font-onestRegular tracking-normal mb-12">
              Create a single shipments or bulk shipment
            </p>
            <p className="text-[#071227] font-[700] font-onestMedium">
              What type of shipment do you want to create?
            </p>
            <div className="grid bmd:grid-cols-2 gap-6 mt-6">
              <div
                onClick={() => setIsCreateShipment(true)}
                className="rounded-[12px] cursor-pointer px-5 border-[1px] pb-5 border-[#E4EBF5]"
              >
                <img
                  className="mt-6 md:mt-[60px]"
                  src="/images/singleShipment.svg"
                  alt="Single shipment upload"
                />
                <h1 className="text-[#071227] pt-5 pb-1.5 text-sm font-[600] font-onestMedium">
                  Single shipment upload
                </h1>
                <p className="text-[#a0aba3] text-xs font-[500] font-onestRegular tracking-normal">
                  Create one shipment or many one at a time
                </p>
              </div>
              <Link
                to="/dashboard/ship-now/bulk-upload"
                className="rounded-[12px] px-5 border-[1px] pb-5 border-[#E4EBF5]"
              >
                <img
                  className="mt-6 md:mt-[60px]"
                  src="/images/bulkShipment.svg"
                  alt="Bulk shipment upload"
                />
                <h1 className="text-[#071227] pt-5 pb-1.5 text-sm font-[600] font-onestMedium">
                  Bulk shipment upload
                </h1>
                <p className="text-[#a0aba3] text-xs font-[500] font-onestRegular tracking-normal">
                  Add all your shipment at once with our bulk upload feature
                </p>
              </Link>
            </div>
          </div>
        </div>
      )}
      <ModalContainer
        open={addMoney}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[520px]"
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] font-onestBold text-[#0A5001] ">
            Add money
          </p>
          <div className="mt-6">
            <TextInput
              type="number"
              label="Amount"
              value={amountAdded}
              onChange={(e: any) => {
                if (e.target.value !== "") {
                  setFormErrors((prev: any) => ({
                    ...prev,
                    amount: "",
                  }));
                }
                if (!isNaN(e.target.value)) {
                  setAmountAdded(e.target.value);
                }
              }}
              placeholder="e.g 100"
            />
            {formErrors.amount && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.amount}
              </span>
            )}
          </div>
          <div className="flex justify-between mt-14">
            <Button
              onClick={() => setAddMoney(false)}
              title="Cancel"
              className="!w-[100px] py-4 !bg-[#fff] border-[1px] border-[#E4EBF5] !text-base rounded-full !text-[#18af04] !font-[500]"
            />
            <Button
              title="Add Money"
              onClick={onAddMoney}
              className="!w-[170px] py-4 bg-[#18AF04] rounded-full !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={addMoneySuccess}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => setAddMoneySuccess(false)}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Yippee!! 💃🏾
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {
              setAmountAdded(null);
              setAddMoneySuccess(false);
            }}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/fezbox.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Money has been added to your wallet
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Close"
              onClick={() => {
                setAmountAdded(null);
                setAddMoneySuccess(false);
              }}
              className="!w-[210px] py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
      <ModalContainer
        open={differentDeliveryType}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Only Same Delivery Type!!
          </p>
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/warning.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Please note: You can only select one delivery type at a time. To
            proceed, choose a single delivery type for all orders in this batch.
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="OK"
              onClick={() => {
                setDifferentDeliveryType(false);
              }}
              className=" !px-9 py-2.5 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
}

export default SingleShipment;
