import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import { copyToClipboard } from "helpers/handleCopy";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";
import { useEffect, useState } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useUpdateBumpaKeyMutation } from "services/slices/bumpa";

const data = [
  {
    id: 1,
    title:
      "Login into your Bumpa account. Use the web app:https://app.getbumpa.com",
  },
  {
    id: 2,
    title: `Navigate to the left-hand side menu, click on "Store," and then select "API Key`,
  },
  {
    id: 3,
    title: `On the API Key page, input "Fez" as the Token Name and click "Generate Key" to obtain your API Key. Copy the generated API Key`,
  },
  {
    id: 4,
    title:
      "Log in to the Fez account at `https://businessportal.fezdelivery.co/`",
  },
  {
    id: 5,
    title: `In the left-hand side menu, click on "Import from Bumpa", and select " Manage Keys"`,
  },
  {
    id: 6,
    title: `Input the "API Key" and click "Save Secret Key". You get a note that the API connection is successful.`,
  },
  {
    id: 7,
    title: `Click on "Import Orders" and easily move all pending orders. You can filter for the date range you want to ship.`,
  },
  {
    id: 8,
    title: `Select the orders you want to import and click “bulk import” when you have multiple orders or select the import button at the end column when it’s just an order. You will get “imported successfully”`,
  },
];

function ManageKey() {
  const hook = localStorage.getItem("integration-key");

  const [webhook, setWebhook] = useState<any>(hook === "undefined"?"":hook);
  const [editWebhook, setEditWebhook] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [updateBumpaKey, { isLoading: isUpdateBumpaLoading }] =
    useUpdateBumpaKeyMutation();
  const userId: any = localStorage.getItem("fez-org-id");

  const handleCopyWebhook = () => {
    copyToClipboard(webhook, setCopySuccess);
    
  };

  const onUpdateBumpa = async () => {
    const body = {
      client_id: userId,
      integration_key: webhook,
      platform: "bumpa",
    };
    if (webhook !== "") {
      await updateBumpaKey(body)
        .unwrap()
        .then((data) => {
          if (data) {
            handleSuccessToast("updated key successfully");
            setEditWebhook(false);
          }
        })
        .catch((e: any) => {
          handleErrorToast(e);
        });
    }
  };

  return (
    <div className="mt-[60px] px-[20px] bmd:px-[72px] mb-5">
      <h1 className="text-2xl font-onestBold font-[800] text-[#0A5001] tracking-wide">
        Manage Keys
      </h1>
      <div className="grid grid-cols-1 xl:grid-cols-[63%_35%] justify-between">
        <div className="rounded-lg border-[1px] p-6 h-[250px] mt-12 border-[#E1F0DF] bg-dashboard-gradient">
          <h1 className="text-lg font-onestBold font-[800] text-[#0A5001] tracking-wide">
            Secret API Key
          </h1>
          <div className="mt-10  flex justify-between gap-3">
            <div
              className={`border-[1px] ${
                !editWebhook && "py-2.5"
              } w-[90%] rounded-lg overflow-hidden text-ellipsis whitespace-nowrap border-[#D4F3D5] flex items-center justify-between`}
            >
              {editWebhook && (
                <TextInput
                  value={webhook || ""}
                  type="string"
                  placeholder="webhook"
                  className="!mt-0 !border-0 "
                  onChange={(e) => {
                    setWebhook(e.target.value);
                  }}
                />
              )}
              {!editWebhook && (
                <h1 className="text-sm break-words w-[80%] pl-4">
                  {webhook ? webhook : ""}
                </h1>
              )}
              <div className="w-[15%] flex  justify-end bg-white pr-2">
                <span
                  className="text-sm cursor-pointer flex items-center gap-1 text-[#0A5001] px-2 rounded-md font-[400] font-onestRegular"
                  onClick={() => setEditWebhook(true)}
                >
                  <MdOutlineModeEditOutline />
                  Edit
                </span>
              </div>
            </div>
            <div
              className="flex items-center gap-2 border-[1px] px-4 bg- w-fit py-2.5 rounded-lg border-[#D4F3D5] cursor-pointer"
              onClick={handleCopyWebhook}
            >
              <img src="/images/copy.svg" alt="copy-icon" />
              <p className="text-[#BEC0BE] text-sm">
                {copySuccess ? "Copied!" : "Copy"}
              </p>
            </div>
          </div>

          <div className="flex">
            <Button
              className={`mt-6 py-4 !px-6 ${"!bg-[#8C9D8A]"}`}
              loading={isUpdateBumpaLoading}
              disabled={isUpdateBumpaLoading}
              title="Update Secret Key"
              onClick={onUpdateBumpa}
            />
          </div>
        </div>
        <div className="rounded-lg border-[1px] p-6 mt-12 border-[#E1F0DF] bg-dashboard-gradient">
          <h1 className="text-[#17730A] font-[700] font-onestMedium">
            How to link Bumpa to the Fez account
          </h1>
          <div className="mt-6">
            {data?.map((item) => {
              return (
                <div className="flex gap-3 my-4">
                  <div>
                    <p className="rounded-full px-2 bg-[#289D17] text-white">
                      {item.id}
                    </p>
                  </div>
                  <p className="text-xs font-onestRegular text-[#464748]">
                    {item.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageKey;
