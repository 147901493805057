
type Order = {
    id?: string;
    recipientAddress?: string;
    recipientState?: string;
    recipientName?: string;
    recipientPhone?: string;
};

type SessionState = {
    id: number;
    state: string;
};

export default function isValidOrder(order: Order, sessionStates:SessionState[] ): string | boolean {

    if (!order.id) {
        return `Order ID  is required and should be set on Bumpa`;
    }

    if (!order.recipientAddress) {
        return `Recipient address is required and should be set on Bumpa`;
    }

    if (!order.recipientState) {
        return `Recipient state is required and should be set on Bumpa`;
    }

    const isValidState = sessionStates.some(
        (sessionState) => sessionState.state === order.recipientState
    );

    if (!isValidState) {
        return `Recipient state is invalid and should be set on Bumpa`;
    }

    if (!order.recipientName) {
        return `Recipient name is required and should be set on Bumpa`;
    }

    if (!order.recipientPhone) {
        return `Recipient phone is required and should be set on Bumpa`;
    }

    return true;
}
