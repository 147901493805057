import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";


interface ErrorData {
    description: string;
  }
const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return typeof error === "object" && error !== null && "status" in error;
  };
  const hasErrorData = (
    error: FetchBaseQueryError
  ): error is FetchBaseQueryError & { data: ErrorData } => {
    return "data" in error && typeof error.data === "object";
  };
  export const handleErrorToast = (error: any) => {
    if (error?.data?.description) {
      if(error.data.description.toLowerCase()==="invalid session"){
        localStorage.removeItem("token")
        window.location.href = "/";
      }
      toast.error(error.data.description, {
        className: "toast-error",
        progressClassName: "Toastify__progress-bar--error",
      });
    } else if (isFetchBaseQueryError(error) && hasErrorData(error)) {
      if(error.data.description.toLowerCase()==="invalid session"){
        localStorage.removeItem("token")
        window.location.href = "/";
      }
      toast.error(error.data.description, {
        className: "toast-error",
        progressClassName: "Toastify__progress-bar--error",
      });
    } 
    else {
      toast.error("An unknown error occurred, please try again", {
        className: "toast-error",
        progressClassName: "Toastify__progress-bar--error",
      });
    }
  };

export const handleSuccessToast = (message: string) => {
  
    toast.success(message, {
      className: "toast-success",
    });
  };