import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface LocalCostProps {
  recipientState?: string;
  pickUpState?: string;
  mode?: string;
  weight:any;
  referralCode?: string;
  state?: string;
}
interface ImportCostProps {
  destinationState?: string;
  importLocationId?: number;
  weight:number;
  referralCode?: string;
}


interface ExportCostProps {
  exportLocationId?: number;
  weightId:number;
  referralCode?: string;
  destinationState:string
}
export const getPricing = createApi({
  reducerPath: "getPricing",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPricing: builder.query<any, void>({
      query: () => ({
        url: `client/cost?t=local`,
        method: "GET",
      }),
    }),
    getOrderPrice: builder.mutation<any, LocalCostProps>({
      query: (body) => ({
        url: `order/cost`,
        method: "POST",
        body
      }),
    }),
    getLocalCostPrice: builder.mutation<any, LocalCostProps>({
      query: (body) => ({
        url: `order/costWithDiscount`,
        method: "POST",
        body,
      }),
    }),
    getImportCostPrice: builder.mutation<any, ImportCostProps>({
      query: (body) => ({
        url: `orders/import-price`,
        method: "POST",
        body,
      }),

    }),
    getExportCostPrice: builder.mutation<any, ExportCostProps>({
      query: (body) => ({
        url: `orders/export-price`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetPricingQuery, useGetLocalCostPriceMutation, useGetOrderPriceMutation, useGetImportCostPriceMutation, useGetExportCostPriceMutation } = getPricing;
