import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ReactPaginate from "react-paginate";

type PagePaginationProps = {
  totalPages?: number;
  page?: number;
  handlePageClick: (e: any) => void;
};

const PagePagination = ({
  page,
  handlePageClick,
  totalPages,
}: PagePaginationProps) => {
  return (
    <ReactPaginate
      previousLabel={
        <button className="flex space-x-1 items-center">
        <AiOutlineArrowLeft />
        <span className="text-[10px] md:text-sm mr-3">Previous</span>
      </button>
      }
      nextLabel={
        <button className="flex items-center">
          <span className="text-[10px] md:text-sm mr-3">Next</span>
          <AiOutlineArrowRight />
        </button>
      }
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      pageCount={totalPages || 0}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName="flex gap-[30px] text-sm"
      activeLinkClassName="font-bold text-[#34A853]"
      forcePage={page}
    />
  );
};

export default PagePagination;
