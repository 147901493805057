import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import AuthLayout from "components/layout/AuthLayout";
import { handleErrorToast } from "helpers/toastMessage";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForgotPasswordMutation } from "services/slices/authSlice";

const ChangePassword = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const [param] = useSearchParams();
  const navigate = useNavigate();
  const onTogglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };
  const onToggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible((prevState) => !prevState);
  };
  const [formErrors, setFormErrors] = useState<any>({});

  const token = param.get("t");
  const email = param.get("u");

  const intialValues = {
    password: "",
    confirmPassword: "",
  };
  const [users, setUsers] = useState(intialValues);
  const [forgotPassword, { isLoading }] =
    useForgotPasswordMutation();

  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.password || values.password === "") {
      errors.password = "Password is required";
    } else if (values.password !== "" && values.password.length < 8) {
      errors.password = "Password must have 8 characters";
    }
    if (!values.confirmPassword || values.confirmPassword === "") {
      errors.confirmPassword = "Confirm password is required";
    } else if (
      values.confirmPassword !== values.password &&
      values.confirmPassword !== ""
    ) {
      errors.confirmPassword = "Confirm password must be same as password";
    } else if (
      values.confirmPassword !== "" &&
      values.confirmPassword.length < 8
    ) {
      errors.confirmPassword = "Password must have 8 characters";
    }
    return errors;
  };
  const handleErrorChange = (name: string, value: any) => {
    if (name === "password" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    } else if (name === "password" && value.length < 8) {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "Password must have 8 characters",
      }));
    } else if (name === "password" && value.length >= 8) {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    }

    if (name === "confirmPassword" && value === users.password) {
      setFormErrors((prev: any) => ({
        ...prev,
        confirmPassword: "",
      }));
    } else if (name === "confirmPassword" && value === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        confirmPassword: "Confirm password is required",
      }));
    } else if (name === "confirmPassword" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        confirmPassword: "",
      }));
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUsers((prev) => ({
      ...prev,
      [name]: value,
    }));

    handleErrorChange(name, value);
  };

  const onChangePassword = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateStepOne(users));
    const body = {
      email,
      action: "create-email.passwordreset",
      token,
      newPassword: users?.confirmPassword,
    };
    if (users?.password !== "" && users?.confirmPassword !== "") {
      await forgotPassword(body)
        .unwrap()
        .then((data) => {
          if (data) navigate("/");
        })
        .catch((e) => {
          handleErrorToast(e)

        });
    }
  };
  return (
    <AuthLayout
      headerTitle={
        <p className="md:text-sm text-xs font-onestRegular text-black">
          Don’t have an account?
          <span
            className="ml-1 font-onestMedium text-[#15B900] cursor-pointer"
            onClick={() => navigate("/register")}
          >
            Sign Up
          </span>
        </p>
      }
      children={
        <div className="sm:px-[40px] w-full lg:px-0 ">
          <div className="flex justify-center max-w-[600]">
            <div className="h-fit w-full  ">
              <div className=" bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-10 ">
                <div className=" ">
                  <p className="md:text-2xl text-xl text-black font-onestMedium">
                    Change Password
                  </p>
                  <p className="text-[#A0ABA3] font-onestMedium text-base md:text-lg">
                    Create a new password and keep it safe
                  </p>
                </div>
                <form action="" onSubmit={onChangePassword}>
                  <div className="">
                    <div className="my-[30px]  space-y-[30px]">
                      <div className=" w-full">
                        <TextInput
                          type={isPasswordVisible ? "text" : "password"}
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          value={users.password}
                          onTogglePasswordVisibility={
                            onTogglePasswordVisibility
                          }
                          placeholder="Enter password"
                          isPasswordVisible={isPasswordVisible}
                          isPassword
                          isRegister
                        />
                        {formErrors.password && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.password}
                          </span>
                        )}
                      </div>
                      <div className=" w-full">
                        <TextInput
                          type={isConfirmPasswordVisible ? "text" : "password"}
                          label="Confirm Password"
                          name="confirmPassword"
                          onTogglePasswordVisibility={
                            onToggleConfirmPasswordVisibility
                          }
                          isPassword
                          onChange={handleChange}
                          value={users.confirmPassword}
                          placeholder="Confirm password"
                        />
                        {formErrors.confirmPassword && (
                          <span className="text-xs text-[#BA3838] font-onestMedium">
                            {formErrors.confirmPassword}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" mt-[30px]">
                      <Button
                        title="Save & Sign In"
                        type="submit"
                        loading={isLoading}
                        disabled={isLoading}
                      />
                    </div>
                    <p className="text-sm font-onestMedium text-[#A0ABA3] text-center mt-2">
                      Have trouble signing in?{" "}
                      <span
                        className="text-[#15B900] cursor-pointer"
                        onClick={() => navigate("")}
                      >
                        Contact Support
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
export default ChangePassword;
