import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface createTicket {
  subjectId: string;
  message: string;
  orderNo: string;
}
interface ticketMessage {
  ticketId: string;
  message: string;
}
interface AllTicket {
  startDate?: string;
  endDate?: string;
  status?: string;
}

interface GetAllTicketParams {
  body: AllTicket;
  page: number;
  search: string;
}

export const ticket = createApi({
  reducerPath: "ticket",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllTicket: builder.mutation<any, GetAllTicketParams>({
      query: ({ body, page, search }) => ({
        url: `tickets?page=${page}&search=${search}`,
        method: "POST",
        body,
      }),
    }),
    getTicketStats: builder.mutation<any, any>({
      query: ({ body, search }) => ({
        url: `ticket/statsWithDateRange?search=${search}`,
        method: "POST",
        body,
      }),
    }),
    getTicketSubjects: builder.query<any, void>({
      query: () => ({
        url: `ticket/subjects`,
        method: "GET",
      }),
    }),
    getTicketMessage: builder.query<any, { id: string; page: number }>({
      query: ({ id, page }) => ({
        url: `ticket/${id}/messages?page=${page}`,
        method: "GET",
      }),
    }),
    getSingleTicket: builder.query<any, string>({
      query: (id) => ({
        url: `tickets/${id}`,
        method: "GET",
      }),
    }),
    createTicket: builder.mutation<any, createTicket>({
      query: (body) => ({
        url: `ticket`,
        method: "POST",
        body,
      }),
    }),
    closeTicket: builder.mutation<any, string>({
      query: (id) => ({
        url: `ticket/${id}/close`,
        method: "POST",
      }),
    }),
    reOpenTicket: builder.mutation<any, string>({
      query: (id) => ({
        url: `ticket/${id}/re-open`,
        method: "POST",
      }),
    }),
    addTicketMessage: builder.mutation<any, ticketMessage>({
      query: (body) => ({
        url: `ticket/addMessage`,
        method: "POST",
        body,
      }),
    }),
    updateTicket: builder.mutation<any, { id: any; body: any }>({
      query: ({ id, body }) => ({
        url: `ticket/${id}/close`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllTicketMutation,
  useCreateTicketMutation,
  useGetSingleTicketQuery,
  useGetTicketSubjectsQuery,
  useGetTicketMessageQuery,
  useAddTicketMessageMutation,
  useCloseTicketMutation,
  useReOpenTicketMutation,
  useGetTicketStatsMutation
} = ticket;
