export const copyToClipboard = (
    text: string,
    setShowClipboard: React.Dispatch<React.SetStateAction<boolean>>,
    duration = 2000
  ) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setShowClipboard(true);
        setTimeout(() => {
          setShowClipboard(false);
        }, duration);
      })
      .catch((err) => {
        console.error("Unable to copy to clipboard", err);
      });
  };
  