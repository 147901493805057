import { truncate } from "lodash";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

type FileDragNDropProps = {
  children?: React.ReactNode;
  onDrop: (files: any) => void;
  loading?: boolean;
  uiType?: "dragndrop" | "text";
  setUiFileType?: any;
  setAcceptedFiles?: any;
  acceptedFiles?: any;
  icon?: any;
  text?: String;
  secondText?: String;
  bgColor?: any;
};

function FileDragNDrop({
  children,
  onDrop,
  loading,
  uiType = "dragndrop",
  setUiFileType,
  setAcceptedFiles,
  acceptedFiles,
  icon,
  text,
  secondText,
  bgColor,
}: FileDragNDropProps) {
  const [fileRejections, setFileRejections] = useState<any[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => {
      setAcceptedFiles(files);
      onDrop(files);
    },
    onDropRejected: (rejectedFiles) => {
      setFileRejections(rejectedFiles);
      toast.error(rejectedFiles?.[0]?.errors?.[0]?.message);
    },
    disabled: loading,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/pdf": [".pdf"],
    },
  });

  const removeFile = (fileName: string) => {
    setAcceptedFiles(
      acceptedFiles.filter((file: any) => file.name !== fileName)
    );
    setUiFileType("");
  };

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path} className="break-words">
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container">
      {uiType === "text" && (
        <div
          {...getRootProps({
            className: `${
              fileRejections?.length ? "text-red-500" : "text-[#eeeeee]"
            } cursor-pointer`,
          })}
        >
          <input {...getInputProps()} />
          <div>{children}</div>
        </div>
      )}
      {uiType === "dragndrop" && (
        <>
          <div
            {...getRootProps({
              className: `border-2 mt-2 flex ${bgColor} justify-center rounded-lg  ${
                fileRejections?.length ? "border-red-500" : "border-[#eeeeee]"
              } border-dashed border-[1px] cursor-pointer`,
            })}
          >
            <input {...getInputProps()} />
            <div
              className={`${
                files?.length > 0 ? "hidden md:flex" : "flex"
              } gap-6 md:justify-center px-2.5 md:w-full md:gap-1 flex-wrap items-center py-8`}
            >
              <img src={icon} alt="Upload img" />
              <div className="md:flex gap-1">
                <p className="text-[#4a564d] text-[13px] font-[500]">{text}</p>
                <p className="text-[#18AF04] text-[13px] font-[600]">
                  {secondText}
                </p>
              </div>
            </div>
          </div>
          <aside className="mb-[40px]">
            {files?.length > 0 &&
              acceptedFiles?.map((file: any) => (
                <div
                  key={file.path}
                  className="items-center p-3 mt-3 rounded-lg border-[#DBE4EF] border-[1px]"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex gap-3 items-center">
                      <img
                        className="border-[2px] w-[28px] h-[28px] border-[#E4EBF5] text-[#4a564d] rounded-full p-1 text-xs font-[500] font-onestMedium"
                        src="/images/upload.svg"
                        alt=""
                      />
                      <p className="text-[#4a564d] md:block hidden text-[13px] font-[500]">
                        {truncate(file.path, {length:45})}
                      </p>
                      <p className="text-[#4a564d] md:hidden block text-[13px] font-[500]">
                        {truncate(file.path, {length:20})}
                      </p>
                    </div>
                    <img
                      onClick={() => removeFile(file.name)}
                      className="cursor-pointer text-[#4a564d] rounded-full p-0 text-xs font-[500] font-onestMedium"
                      src="/images/cancel.svg"
                      alt=""
                    />
                  </div>
                  <div className="bg-[#F4F5F6] rounded-2xl h-1.5 mt-3">
                    <div className="bg-[#18AF04] h-1.5 rounded-2xl w-[100%]"></div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <p className="text-[#A0ABA3] text-[11px] font-[500]">
                      {file.size / 1000}kb
                    </p>
                    <p className="text-[#18AF04] text-[11px] font-[500]">
                      Upload Successful
                    </p>
                  </div>
                </div>
              ))}
          </aside>
        </>
      )}
    </section>
  );
}

export default FileDragNDrop;
