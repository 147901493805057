import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface AuthProps {
  userFullName?: string;
  userEmailAddress?: string;
  businessName?: string;
  businessAddress?: string;
  businessState?: number;
  businessType?: number;
  businessEmail?: string;
  phone?: string;
  password: string;
  password_confirmation?: string;
  referral?: string;
}

interface AuthLoginProps {
  user_id: string;
  password: string;
}
interface ChangePasswordProps {
  user_id: string;
  oldPassword: string;
  newPassword: string;
}

interface ForgotPasswordProps {
  email: string | null;
  action: string;
  token?: string | null;
  newPassword?: string;
}

interface AuthOtpProps {
  email: string;
}

interface AuthValidateOtpProps {
  email: string;
  otp: string;
}

interface AuthValidateBusinessDataProps {
  type: string;
  value: string;
}

export interface getAccountProps {
  status: string;
  description: string;
  authDetails: {
    authToken: string;
    expireToken: string;
  };
  clientDetails: any;
  usingTempPassword: string;
}

export const auth = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    signup: builder.mutation<any, AuthProps>({
      query: (body) => ({
        url: `register`,
        method: "POST",
        body,
      }),
    }),
    inviteSignup: builder.mutation<any, AuthProps>({
      query: (body) => ({
        url: `user/invited/register`,
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation<any, AuthLoginProps>({
      query: (body) => ({
        url: `user/authenticate`,
        method: "POST",
        body,
      }),
    }),
    changePassword: builder.mutation<any, ChangePasswordProps>({
      query: (body) => ({
        url: `user/changePassword`,
        method: "POST",
        body,
      }),
    }),
    sendAuthOtp: builder.mutation<any, AuthOtpProps>({
      query: (body) => ({
        url: `onboarding/sendotp`,
        method: "POST",
        body,
      }),
    }),
    validateAuthOtp: builder.mutation<any, AuthValidateOtpProps>({
      query: (body) => ({
        url: `onboarding/validateotp`,
        method: "POST",
        body,
      }),
    }),
    validateBusinessData: builder.mutation<any, AuthValidateBusinessDataProps>({
      query: (body) => ({
        url: `onboarding/validateData`,
        method: "POST",
        body,
      }),
    }),
    forgotPassword: builder.mutation<any, ForgotPasswordProps>({
      query: (body) => ({
        url: `user/passwordToken`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useChangePasswordMutation,
  useLoginMutation,
  useSendAuthOtpMutation,
  useValidateAuthOtpMutation,
  useValidateBusinessDataMutation,
  useForgotPasswordMutation,
  useInviteSignupMutation
} = auth;
