import React from "react";

type ModalContainerProps = {
  children: React.ReactNode;
  open: boolean;
  closeModal?: () => void;
  tailwindClassName?: string;
  modalClassName?: string;
  showCloseIcon?: boolean;
  noPadding?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
};

const ModalContainer = ({
  children,
  open,
  closeModal,
  tailwindClassName,
  noPadding,
  showCloseIcon,
  modalRef,
}: ModalContainerProps) => {
  return open ? (
      <div className="overflow-y-auto overflow-x-hidden w-[100%] flex justify-center items-center my-auto  fixed top-[0px] bg-modal-bg   mmd:top-0  bg- right-0 left-0 z-[1001] md:inset-0 h-[100vh]">
        <div className="relative p-4 w-full flex items-center  max-w-[650px] h-[100vh] md:h-auto mx-auto">
          <div className={`relative bg-[white] ${noPadding?"":"py-1"}  flex justify-center items-center mx-auto  rounded-[20px]   drop-shadow-md shadow-modalShadow`}>
          <div className=" flex justify-center flex-col  items-center ">
              <div
                ref={modalRef}
                className={` ${tailwindClassName} overflow-y-scroll`}
                onClick={(evt) => evt.stopPropagation()}
              >
                {children}
              </div>
              {showCloseIcon && (
                <span className="material-icons text-white text-3xl absolute right-4 sm:right-9 top-6 cursor-pointer font-bold bg-opacity-100">
                  close
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
  ) : null;
};

export default ModalContainer;
