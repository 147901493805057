import { Loader } from "components/Loader";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

interface Props {
  title?: string;
  className?: string;
  placeholder?: string;
  label?: any;
  active?: boolean;
  lists?: any;
  intlLists?: any;
  countryLists?: any;
  itemLists?: any;
  value?: any;
  setQuery?: any;
  isOpen?: any;
  errorList?: any;
  name?: string;
  setIsOpen?: any;
  weight?: any;
  setZoneOption?: any;
  handleOptionClick?: any;
  handleOnChange?: any;
  onFocus?: any;
  toggleDropdown?: any;
  showCursor?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  loading?: boolean;
  itAbsolute?: boolean;
  zindex?: string;
  checkCountrySelected?: string;
  showcheckCountrySelected?: boolean;
  titleClassName?: string;
}

export const DropDownSelect = ({
  lists,
  isRequired,
  value,
  title,
  name,
  handleOptionClick,
  toggleDropdown,
  setQuery,
  isOpen,
  disabled,
  placeholder,
  itAbsolute,
  loading,
  titleClassName,
  className,
  checkCountrySelected,
  showcheckCountrySelected,
}: Props) => {
  return (
    <div className="relative w-full">
      <p
        className={`text-[#4a564d] text-[14px] mb-2 font-onestMedium ${titleClassName}`}
      >
        {title}
        {isRequired && <span className="text-red-500 text-sm">*</span>}
      </p>
      <div
        className={`flex border-[1px] w-full  cursor-pointer border-[#e4ebf5] focus:border-2 rounded-[8px] focus:border-inputBorderGreen space-x-1 items-center ${className}`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          value={value}
          name={name}
          readOnly
          autoComplete="off"
          onChange={(e) => setQuery(e.target.value)}
          disabled={disabled}
          className={`text-[#2c2c2c] whitespace-nowrap bg-[red] w-full font-[400] font-onestRegular cursor-pointer px-4 py-2 text-[14px]  outline-0 focus:outline-none focus:ring-0   focus:border-0 border-0   bg-transparent bg-opacity-20 placeholder-shown:text-grey `}
          placeholder={placeholder}
        />
        <MdOutlineKeyboardArrowDown className="mt-0.5 pr-2 text-2xl " />
      </div>
      {isOpen && (
        <ul
          className={` cursor-pointer ${
            itAbsolute && "absolute"
          }   border-[1px] border-[#e4ebf5] ${
            lists?.length > 0 ? "max-h-[300px]" : "h-[100px]"
          }  bg-white shadow-md rounded-b-lg  z-[100]  px-3 py-2   w-full overflow-y-scroll border-0 rounded-t-none  mt-1`}
        >
          {loading ? (
            <div className="">
              <Loader />
            </div>
          ) : (
            <>
              {showcheckCountrySelected && (
                <>
                  {checkCountrySelected &&
                  checkCountrySelected !== "" &&
                  checkCountrySelected !== undefined ? (
                    <>
                      {lists && lists?.length > 0 ? (
                        lists?.map((d: any) => (
                          <li
                            key={d.id}
                            onClick={() => handleOptionClick(d)}
                            className={` ${
                              d.id % 2 === 0
                                ? "border-t-[1px] border-b-[1px]  border-[#e4ebf5]"
                                : ""
                            } py-2 `}
                          >
                            <p className="text-[13px] text-[#4a564d] font-[400] font-onestRegular p-2">
                              {d.name}
                            </p>
                          </li>
                        ))
                      ) : (
                        <p className="text-[13px] text-red-500">No data</p>
                      )}
                    </>
                  ) : (
                    <p className="text-[13px] text-red-500">
                      Select a drop-off country
                    </p>
                  )}
                </>
              )}

              {!showcheckCountrySelected && (
                <>
                  {lists?.map((d: any) => (
                    <li
                      key={d.id}
                      onClick={() => handleOptionClick(d)}
                      className={` ${
                        d.id % 2 === 0
                          ? "border-t-[1px] border-b-[1px] border-[#e4ebf5]"
                          : ""
                      } py-2 `}
                    >
                      <p className="text-[13px] text-[#4a564d] font-[400] font-onestRegular p-2">
                        {d.name}
                      </p>
                    </li>
                  ))}
                </>
              )}
            </>
          )}
        </ul>
      )}
    </div>
  );
};

export const SearchableDropdown = ({
  lists,
  value,
  title,
  handleOptionClick,
  toggleDropdown,
  setQuery,
  isOpen,
  setIsOpen,
  name,
  showCursor,
  setZoneOption,
  isRequired,
  onFocus,
  intlLists,
  countryLists,
  itemLists,
  weight,
  disabled,
  errorList,
}: Props) => {
  return (
    <div className="relative w-full ">
      <p className="text-[#4a564d] items-center flex text-[14px] font-onestMedium">
        {title}
        {isRequired && <span className="text-red-500 text-[20px]">*</span>}
      </p>
      <div
        className={`flex border-[1px] ${
          showCursor && "cursor-pointer"
        } border-[#e4ebf5]  focus:border-2 rounded-[8px] focus:border-inputBorderGreen space-x-1 items-center`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          name={name}
          value={value}
          // onTouchStart={toggleDropdown}
          onChange={(e: any) => {
            setQuery(e.target.value);
            setIsOpen(true);
          }}
          autoComplete="off"
          disabled={disabled}
          onFocus={onFocus}
          // onFocus={toggleDropdown}
          className={`${
            disabled && " font-bold"
          } py-2 px-4 text-[14px] text-[#2c2c2c] font-onestRegular cursor-text outline-0 focus:outline-none focus:ring-0 focus:border-0 border-0 bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full`}
          placeholder={`${title}`}
        />
        <MdOutlineKeyboardArrowDown className="mt-0.5 pr-2 text-2xl" />
      </div>
      {isOpen && !disabled && (
        <>
          {lists && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                lists?.length > 0 ? "max-h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {lists && lists?.length > 0 ? (
                lists.map((d: any) => (
                  <li key={d} className="font-onestRegular">
                    <p className="bg-black text-white">{d.name}</p>
                    <p className="bg-[green] p-2">{d.zone}</p>
                    {d.locations && d?.locations.length > 0 ? (
                      d.locations?.map((option: any) => (
                        <div
                          key={option.id}
                          onClick={() => {
                            setZoneOption(d.zone);
                            handleOptionClick(option, d.state, d.hub);
                          }}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                        >
                          {option.location}
                        </div>
                      ))
                    ) : (
                      <p className="text-[green]">No location available!!!</p>
                    )}
                  </li>
                ))
              ) : (
                <li className="text-[green]">No data available!!!</li>
              )}
            </ul>
          )}
          {intlLists && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                intlLists.length > 0 ? "h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {intlLists && intlLists?.length > 0 ? (
                intlLists.map((d: any) => (
                  <li
                    key={d.id}
                    onClick={() => {
                      setZoneOption(d.name);
                      handleOptionClick(d);
                    }}
                    className="p-2 cursor-pointer hover:bg-gray-200 font-onestRegular"
                  >
                    {d.name}
                  </li>
                ))
              ) : (
                <>
                  {weight === "" && (
                    <p className="text-[red]">Select a weight</p>
                  )}
                  {errorList === "" && weight !== "" && (
                    <p className="text-[red]">Select a new zone</p>
                  )}
                  {intlLists?.length === 0 && weight !== "" && (
                    <p className="text-[green]">No location available!!!</p>
                  )}
                </>
              )}
            </ul>
          )}
          {countryLists && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                countryLists.length > 0 ? "h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {countryLists && countryLists?.length > 0 ? (
                countryLists.map((d: any) => (
                  <li
                    key={d.id}
                    onClick={() => {
                      setZoneOption(d?.country.name);
                      handleOptionClick(d);
                    }}
                    className="p-2 cursor-pointer font-onestRegular hover:bg-gray-200"
                  >
                    {d?.country.name}
                  </li>
                ))
              ) : (
                <p className="text-[green]">No country available!!!</p>
              )}
            </ul>
          )}
          {itemLists && (
            <ul
              className={`absolute z-50 px-3 py-2 border w-full ${
                itemLists.length > 0 ? "h-[300px]" : "h-[100px]"
              } overflow-y-scroll rounded-t-none border-t-0 border-l-0 border-r-0 shadow-lg bg-white  mt-1`}
            >
              {itemLists && itemLists?.length > 0 ? (
                itemLists.map((d: any) => (
                  <li
                    key={d.id}
                    onClick={() => {
                      setZoneOption(d?.name);
                      handleOptionClick(d);
                    }}
                    className="p-2 cursor-pointer font-onestRegular hover:bg-gray-200"
                  >
                    {d?.name}
                  </li>
                ))
              ) : (
                <p className="text-[green]">No item category available!!!</p>
              )}
            </ul>
          )}
        </>
      )}
    </div>
  );
};
