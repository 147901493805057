import { FaRegCircleCheck } from "react-icons/fa6";
import { GoChevronRight } from "react-icons/go";
import { Button } from "components/Button";
import { DropDownSelect } from "components/common/form/Dropdown";
import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { useEffect, useState } from "react";
import { useGetStatesQuery } from "services/slices/dashboard";
import SwitchTab from "./Switch";
import { useGetAuthDataQuery } from "services/slices/getAuthSlice";
import {
  useChangePasswordMutation,
  useUpdateClientMutation,
  useUpdateUsersMutation,
  useUserNotificationMutation,
} from "services/slices/setting";
import { useNavigate } from "react-router";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";

type Ilist = {
  id: number;
  name: string;
};

const intialValues = {
  businessName: "",
  businessPhoneNumber: "",
  businessEmail: "",
  state: "",
  businessType: "",
  businessAddress: "",
};

const intialDetails = {
  fullName: "",
  email: "",
  role: "",
};

const initialPasswordValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function Setting() {
  const [ticketTab, setTicketTab] = useState(0);
  const [settingValue, setSettingValue] = useState("Profile");
  const [isSetting, setIsSetting] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState<Ilist | undefined>(
    undefined
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordChangeVisible, setIsPasswordChangeVisible] = useState(false);
  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] =
    useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRole, setIsRole] = useState(false);
  const [searchState, setSearchState] = useState<string>("");
  const [users, setUsers] = useState<any>(intialValues);
  const [role, setRole] = useState("");
  const [personalDetails, setPersonalDetails] = useState<any>(intialDetails);
  const { data: authData, refetch } = useGetAuthDataQuery();
  const [changePasswordTab, setChangePasswordTab] = useState(false);
  const [stateName, setStateName] = useState<string>("");
  const { data } = useGetStatesQuery();
  const [isEditable, setIsEditable] = useState(false);
  const [isEditablePersonalDetail, setIsEditablePersonalDetail] =
    useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [validateBusinessNameError, setValidateBusinessNameError] =
    useState<string>("");
  const [validatePhoneError, setValidatePhoneError] = useState<string>("");
  const [isEmailOn, setIsEmailOn] = useState<boolean>(false);
  const [isAppOn, setIsAppOn] = useState<boolean>(false);
  const [isSmsOn, setIsSmsOn] = useState<boolean>(false);
  const [passwords, setPasswords] = useState(initialPasswordValues);
  const [error, setError] = useState<string>("");
  const userDetail = authData?.userDetails;
  const userName = userDetail?.["Full Name"];
  const org = authData?.orgDetails;
  const orgName = org?.["Org Full Name"];
  const navigate = useNavigate();

  useEffect(() => {
    setIsEmailOn(authData?.orgDetails?.emailNotification === 1 ? true : false);
    setIsAppOn(authData?.orgDetails?.appNotification === 1 ? true : false);
    setIsSmsOn(authData?.orgDetails?.smsNotification === 1 ? true : false);
  }, [authData?.orgDetails?.emailNotification]);

  const [
    updateClient,
    {
      isLoading: isClientLoading,
      error: errorUsers,
      isError,
      isSuccess,
      data: updateUserData,
    },
  ] = useUpdateClientMutation();
  const [
    updateUsers,
    { isLoading: isUsersLoading, data: updateData, error: updateError },
  ] = useUpdateUsersMutation();
  const [
    changePassword,
    { isLoading: isChangePasswordLoading, data: changePasswordData },
  ] = useChangePasswordMutation();
  const [userNotification, { isLoading: isUserNotificationLoading }] =
    useUserNotificationMutation();
  const handleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  const handleEditPersonalDetail = () => {
    setIsEditablePersonalDetail((prev) => !prev);
  };
  useEffect(() => {
    localStorage.setItem(
      "fez-org-details",
      JSON.stringify(authData?.orgDetails)
    );

    localStorage.setItem("fez-user", JSON.stringify(authData?.userDetails));
  }, [authData]);

  useEffect(() => {
    setUsers({
      businessName: orgName,
      businessEmail: org?.orgEmail,
      businessPhoneNumber: org?.orgPhone,
      businessAddress: org?.orgAddress,
      state: org?.orgState,
    });
    setQuery(org?.orgBusinessType);
    setStateName(org?.orgState);
  }, [orgName, org]);

  useEffect(() => {
    setPersonalDetails({
      fullName: userName,
      email: userDetail?.userEmail,
      role: userDetail?.role,
    });
    setRole(userDetail?.role);
  }, [userName, userDetail]);

  const onTogglePasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsPasswordVisible((prevState) => !prevState);
  };
  const onToggleChangePasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsPasswordChangeVisible((prevState) => !prevState);
  };
  const onToggleConfirmPasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsPasswordConfirmVisible((prevState) => !prevState);
  };
  const toggleSettingType = () => {
    setIsSetting((prev) => !prev);
  };

  const toggleDropdown = () => {
    if (isEditable) {
      setIsOpen((prev) => !prev);
    }
  };
  const toggleRoleDropdown = () => {
    setIsRole((prev) => !prev);
  };

  const handleRoleOptionClick = (option: any) => {
    setRole(option.name);
    setIsRole(false);
  };

  const handleOptionClick = (option: any) => {
    setQuery(option.name);
    setIsOpen(false);
    setFormErrors((prev: any) => ({
      ...prev,
      businessType: "",
    }));
  };

  const handleErrorChangeTwo = (name: string, value: any) => {
    if (name === "businessName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessName: "",
      }));
    }
    if (name === "businessPhoneNumber" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessPhoneNumber: "",
      }));
    }
    if (name === "businessAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessAddress: "",
      }));
    }
  };

  const handleErrorChange = (name: string, value: any) => {
    if (name === "fullName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "",
      }));
    } else if (name === "fullName" && value.length > 50) {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "Fullname cannot be more than 50 character length",
      }));
    } else if (name === "fullName" && value.length <= 50) {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "",
      }));
    }
  };

  const handleErrorChangePassword = (name: string, value: any) => {
    if (name === "newPassword" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    } else if (name === "newPassword" && value.length < 8) {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "Password must have 8 characters",
      }));
    } else if (name === "newPassword" && value.length >= 8) {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    }

    if (name === "confirmPassword" && value === passwords.confirmPassword) {
      setFormErrors((prev: any) => ({
        ...prev,
        confirmPassword: "",
      }));
    } else if (
      name === "confirmPassword" &&
      value === passwords.confirmPassword
    ) {
      setError("");
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUsers((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleErrorChangeTwo(name, value);
  };

  const handleChangePassword = (e: any) => {
    const { name, value } = e.target;
    setPasswords((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleErrorChangePassword(name, value);
  };

  const handleChangeTwo = (e: any) => {
    const { name, value } = e.target;
    setPersonalDetails((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleErrorChange(name, value);
  };

  const handleSettingType = (option: any) => {
    setSelectedOption(option);
    setSettingValue(option.name);
    setIsSetting(false);
  };

  const settingType: any = [
    { id: 1, name: "Profile" },
    { id: 2, name: "Security" },
    { id: 3, name: "Notifications" },
    // { id: 4, name: "Plans & Pricing" },
  ];

  const businessType: any = [
    { id: 1, name: "SME" },
    { id: 2, name: "Fintech" },
    { id: 3, name: "eCommerce" },
    { id: 4, name: "Bank" },
    { id: 5, name: "Pharma" },
    { id: 6, name: "Beauty" },
    { id: 7, name: "Logistics" },
    { id: 8, name: "Others" },
  ];

  const RoleType: any = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Basic" },
  ];

  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.businessName || values.businessName === "") {
      errors.businessName = "Business name is required";
    }
    if (!values.state) {
      errors.state = "State is required";
    }
    if (!values.businessAddress || values.businessAddress === "") {
      errors.businessAddress = "Business address is required";
    }
    if (!values.businessPhoneNumber || values.businessPhoneNumber === "") {
      errors.businessPhoneNumber = "Business phone number is required";
    }
    return errors;
  };

  const validateStepTwo = (values: any) => {
    let errors: any = {};

    if (!values.fullName || values.fullName === "") {
      errors.fullName = "Name is required";
    }
    return errors;
  };

  const validatePasswordChange = (values: any) => {
    let errors: any = {};
    if (!values.currentPassword || values.currentPassword === "") {
      errors.currentPassword = "Current password is required";
    }
    if (!values.newPassword || values.newPassword === "") {
      errors.newPassword = "Password is required";
    } else if (values.newPassword !== "" && values.newPassword.length < 8) {
      errors.password = "Password must have 8 characters";
    }
    if (!values.confirmPassword || values.confirmPassword === "") {
      errors.confirmPassword = "Confirm password is required";
    } else if (
      values.confirmPassword !== values.newPassword &&
      values.confirmPassword !== ""
    ) {
      errors.confirmPassword = "Confirm password must be same as password";
    } else if (
      values.confirmPassword !== "" &&
      values.confirmPassword.length < 8
    ) {
      errors.confirmPassword = "Password must have 8 characters";
    }
    return errors;
  };

  const filteredStateSearch: any = data?.states?.filter((item: any) =>
    item?.state?.toLowerCase()?.includes(searchState.toLowerCase())
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSelectedOption(settingType[0]);
        setSettingValue(settingType[0].name);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const selectedBusinessType = businessType.find(
    (business: any) => business.name === query
  );

  const businessId = selectedBusinessType ? selectedBusinessType.id : null;

  const selectedStates = data?.states.find(
    (stateType: any) => stateType.state === users?.state
  );
  const stateId = selectedStates ? selectedStates.id : null;

  const handleEditBusiness = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateStepOne(users));

    if (
      users?.businessEmail !== "" &&
      users?.businessName !== "" &&
      users?.password !== "" &&
      users?.businessAddress !== "" &&
      users?.state !== "" &&
      query !== ""
    ) {
      const body: any = {
        businessName: users?.businessName,
        businessAddress: users?.businessAddress,
        businessState: stateId,
        businessType: businessId,
        businessEmail: users?.businessEmail,
        phone: users?.businessPhoneNumber,
      };

      setStateName(org?.orgState);
      if (
        users?.businessEmail !== org?.orgEmail ||
        users?.businessName !== orgName ||
        users?.state !== org?.orgState ||
        users?.businessAddress !== org?.orgAddress ||
        query !== org?.orgBusinessType
      ) {
        await updateClient(body)
          .unwrap()
          .then(data)
          .catch((e) => {
            handleErrorToast(e);
          });
      }

      setIsEditable(false);
    }
  };

  useEffect(() => {
    if (updateUserData) {
      refetch();

      handleSuccessToast(updateUserData.description);
    }
  }, [updateUserData]);
  useEffect(() => {
    if (isError && errorUsers) {
      handleErrorToast(error);
    }
  }, [isError, errorUsers]);

  const onSubmitEditPersonalDetail = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateStepTwo(personalDetails));
    if (
      personalDetails?.fullName !== "" &&
      personalDetails?.email !== "" &&
      role !== ""
    ) {
      const body: any = {
        fullname: personalDetails?.fullName,
        email: personalDetails?.email,
        user_id: userDetail?.userID,
        role: role.toLowerCase(),
      };
      setPersonalDetails({
        fullName: userName,
        email: userDetail?.userEmail,
        role: userDetail?.role,
      });
      setRole(userDetail?.role);
      if (
        personalDetails?.fullName !== userName ||
        personalDetails?.email !== userDetail?.userEmail ||
        userDetail?.role.toLowerCase() !== role.toLowerCase()
      ) {
        await updateUsers(body)
          .unwrap()
          .then((data: any) => {
            refetch();
            handleSuccessToast(data.description);
          })
          .catch((e) => {
            handleErrorToast(e);
          });
      }

      setIsEditablePersonalDetail(false);
    }
  };

  const onSubmitChangePassword = async (e: any) => {
    e.preventDefault();
    setFormErrors(validatePasswordChange(passwords));

    if (
      passwords.currentPassword !== "" &&
      passwords.newPassword !== "" &&
      passwords.confirmPassword !== ""
    ) {
      if (passwords.newPassword !== passwords.confirmPassword) {
        setFormErrors((prev: any) => ({
          ...prev,
          confirmPassword: "Passwords do not match",
        }));
        return;
      }

      const body: any = {
        user_id: userDetail?.userID,
        oldPassword: passwords.currentPassword,
        newPassword: passwords?.newPassword,
      };
      await changePassword(body)
        .unwrap()
        .then((data: any) => {
          handleSuccessToast(data.description);
        })
        .catch((e) => {
          handleErrorToast(e);
        });

      setChangePasswordTab(false);
    }
  };

  const onSubmitNotificatin = async (e: any) => {
    e.preventDefault();

    const body: any = {
      appNotification: isAppOn,
      emailNotification: isEmailOn,
      smsNotification: isSmsOn,
    };

    await userNotification(body)
      .unwrap()
      .then((data: any) => {
        handleSuccessToast(data.description);
        refetch();
      })
      .catch((e) => {
        handleErrorToast(e);
      });
  };

  return (
    <>
      <div className="mb-[180px] hidden md:block max-w-screen-md mx-auto xl:mx-[72px] ">
        <div className="hidden md:block max-w-screen-md mx-10 mt-[72px] px-3 bg-[#FAFAFA]">
          <div className="flex justify-center">
            <span
              onClick={() => {
                setTicketTab(0);
              }}
              className={`cursor-pointer text-sm px-8 font-onestMedium py-[18px] xl:px-[40px] ${
                ticketTab === 0
                  ? "border-b-[4px] border-[#45C734] font-[700]"
                  : ""
              } text-[#4A564D] text-sm `}
            >
              Profile
            </span>
            <span
              onClick={() => {
                setTicketTab(1);
              }}
              className={`cursor-pointer py-[18px] font-onestMedium px-8 xl:px-[40px] ${
                ticketTab === 1
                  ? "border-b-[4px] border-[#45C734] font-[700]"
                  : ""
              } text-[#4A564D] text-[13px]`}
            >
              Security
            </span>
            <span
              onClick={() => {
                setTicketTab(2);
              }}
              className={`cursor-pointer font-onestMedium py-[18px] px-8 xl:px-[40px] ${
                ticketTab === 2
                  ? "border-b-[4px] border-[#45C734] font-[700]"
                  : ""
              } text-[#4A564D] text-[13px]`}
            >
              Notifications
            </span>
            {/* <span
              onClick={() => {
                setTicketTab(3);
                setStatus("");
              }}
              className={`cursor-pointer font-onestMedium py-[18px] px-8 xl:px-[40px] ${
                ticketTab === 3
                  ? "border-b-[4px] border-[#45C734] font-[700]"
                  : ""
              } text-[#4A564D] text-[13px]`}
            >
              Plans & Pricing
            </span> */}
          </div>
        </div>

        {ticketTab === 0 && (
          <div className="block border-[#E1F0DF] h-[100%] border-[1.5px] max-w-screen-md mx-4 md:mx-10 mt-[40px] rounded-2xl px-6 py-8 ">
            <div className="hidden md:block">
              <div>
                <div className="flex justify-between  ">
                  <div>
                    <h1 className="text-base md:text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
                      Business Details
                    </h1>
                    <p className="text-[#A0ABA3] font-onestRegular w-[85%] text-xs md:text-sm">
                      Your entire business information is here
                    </p>
                  </div>
                  <div className="cursor-pointer">
                    {!isEditable ? (
                      <div
                        onClick={handleEdit}
                        className="bg-[#D4F3D5] flex items-center rounded-md px-4 md:px-6 py-2.5 gap-1.5"
                      >
                        <img src="/images/edit.svg" alt="" />
                        <Button
                          title="Edit"
                          className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={handleEditBusiness}
                        className="bg-[#D4F3D5] flex items-center rounded-md px-4 md:px-6 py-2.5 gap-1.5"
                      >
                        <Button
                          loading={isClientLoading}
                          disabled={isClientLoading}
                          title="Save Changes"
                          className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                        />
                        <FaRegCircleCheck color="#17730A" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <form className="grid grid-cols-2 gap-6">
                <div>
                  <div
                    className={` ${
                      isEditable ? "flex items-center relative" : ""
                    } w-full mt-7 `}
                  >
                    <TextInput
                      type="text"
                      label="Business Name"
                      name="businessName"
                      value={users.businessName}
                      disabled={!isEditable}
                      maxLength={50}
                      onChange={handleChange}
                      placeholder="Enter business name"
                      className={`${
                        !isEditable
                          ? "bg-transparent !text-[#9899A1] font-[500]"
                          : ""
                      }`}
                    />
                    {isEditable && (
                      <div className="absolute right-4 pt-6">
                        <img src="/images/edit.svg" alt="" className="" />
                      </div>
                    )}
                  </div>
                  {validateBusinessNameError !== "" && (
                    <div className="flex mt-1 items-center space-x-1">
                      <img src="/images/warning.svg" alt="" />
                      <p className="text-xs text-[#BA3838] font-onestMedium">
                        {validateBusinessNameError}
                      </p>
                    </div>
                  )}
                  {formErrors.businessName && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.businessName}
                    </span>
                  )}
                </div>
                <div
                  className={` ${
                    isEditable ? "flex items-center relative" : ""
                  } w-full mt-7 `}
                >
                  <TextInput
                    type="text"
                    name="businessEmail"
                    label="Business Email"
                    disabled
                    onChange={handleChange}
                    value={users.businessEmail}
                    placeholder="Enter business email"
                    className={`${
                      !isEditable
                        ? "bg-transparent !text-[#9899A1] font-[500]"
                        : "bg-transparent"
                    }`}
                  />
                </div>
                <div>
                  <div
                    className={` ${
                      isEditable ? "flex items-center relative" : ""
                    } w-full `}
                  >
                    <TextInput
                      type="number"
                      name="businessPhoneNumber"
                      label="Business Phone Number"
                      disabled={!isEditable}
                      onChange={handleChange}
                      value={users?.businessPhoneNumber}
                      placeholder="Enter business phone number"
                      className={`${
                        !isEditable
                          ? "bg-transparent !text-[#9899A1] font-[500]"
                          : ""
                      }`}
                    />
                    {isEditable && (
                      <div className="absolute right-4 pt-6">
                        <img src="/images/edit.svg" alt="" className="" />
                      </div>
                    )}
                  </div>
                  {validatePhoneError !== "" && (
                    <div className="flex mt-1 items-center space-x-1">
                      <img src="/images/warning.svg" alt="" />
                      <p className="text-xs text-[#BA3838] font-onestMedium">
                        {validatePhoneError}
                      </p>
                    </div>
                  )}
                  {formErrors.businessPhoneNumber && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.businessPhoneNumber}
                    </span>
                  )}
                </div>
                <div>
                  <DropDownSelect
                    title="Business type"
                    titleClassName="!font-normal font-onestRegular"
                    lists={businessType}
                    value={query}
                    setQuery={setQuery}
                    isOpen={isOpen}
                    disabled
                    toggleDropdown={toggleDropdown}
                    handleOptionClick={handleOptionClick}
                    placeholder="Select type"
                    name="example-dropdown"
                  />
                  {formErrors.businessType && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.businessType}
                    </span>
                  )}
                </div>
                <div>
                  <div
                    className={` ${
                      isEditable ? "flex items-center relative" : "mt-0"
                    } w-full`}
                  >
                    <TextInput
                      type={"text"}
                      name="businessAddress"
                      disabled={!isEditable}
                      label="Business Address"
                      onChange={handleChange}
                      value={users?.businessAddress}
                      placeholder="Enter business address"
                      className={`${
                        !isEditable
                          ? "bg-transparent !text-[#9899A1] font-[500]"
                          : ""
                      }`}
                    />
                    {isEditable && (
                      <div className="absolute right-4 mt-0 pt-6">
                        <img src="/images/edit.svg" alt="" className="" />
                      </div>
                    )}
                  </div>
                  {formErrors.businessAddress && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.businessAddress}
                    </span>
                  )}
                </div>
                <div className="relative mt-0">
                  <div className="flex">
                    <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                      State
                    </p>
                    {/* <span className="text-red-500 text-sm">*</span> */}
                  </div>
                  <div className="absolute w-full">
                    <Dropdown
                      disabled={!isEditable}
                      dropdown={
                        <div className="flex border w-full rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                          <div className="flex items-center space-x-1">
                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                            <span
                              className={` ${
                                users?.state === ""
                                  ? "text-[#9899A1]"
                                  : "text-[#000]"
                              }  font-onestMedium text-sm`}
                            >
                              {users?.state === "" || users?.state === undefined
                                ? "Select state"
                                : stateName}
                            </span>
                          </div>

                          <img
                            src={"/images/chevronDown.svg"}
                            alt="drop-down"
                            className="mt-0.5 pr-2 cursor-pointer"
                          />
                        </div>
                      }
                      search={
                        <div className="">
                          <TextInput
                            value={searchState}
                            name={"search"}
                            type={"text"}
                            placeholder="Search"
                            onChange={(e) => setSearchState(e.target.value)}
                          />
                        </div>
                      }
                      dropdownContainerClasses={`absolute z-50 shadow-card Shadow-4 !w-full !right-0  border border-slate-100  rounded `}
                      dropdownOptions={[
                        {
                          customChild: (
                            <div>
                              <div>
                                {filteredStateSearch?.map(
                                  (item: any, idx: number) => {
                                    return (
                                      <div
                                        key={idx}
                                        onClick={() => {
                                          setUsers((prev: any) => ({
                                            ...prev,
                                            state: item.state,
                                          }));
                                          setStateName(item?.state);
                                          setFormErrors((prev: any) => ({
                                            ...prev,
                                            state: "",
                                          }));
                                        }}
                                      >
                                        <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                          <p>{item?.state}</p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                  {formErrors.state && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.state}
                    </span>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
        {ticketTab === 0 && (
          <div className="block  border-[#E1F0DF] h-[100%] border-[1.5px] max-w-screen-md mx-4 md:mx-10 mt-[20px] rounded-2xl px-6 py-8 ">
            {/* <ProfileHeader
              profileTitle="Personal Details"
              text="Your entire business information is here"
              buttonTitle="Edit"
            /> */}
            <div className="flex justify-between">
              <div>
                <h1 className="text-base md:text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
                  Personal Details
                </h1>
                <p className="text-[#A0ABA3] font-onestRegular w-[85%] text-xs md:text-sm">
                  Your entire business information is here
                </p>
              </div>
              <div className="cursor-pointer">
                {!isEditablePersonalDetail ? (
                  <div className="bg-[#D4F3D5] flex items-center rounded-md px-4 md:px-6 py-2.5 gap-1.5">
                    <Button
                      onClick={handleEditPersonalDetail}
                      title="Edit"
                      icon={<img src="/images/edit.svg" alt="" />}
                      iconLeft
                      className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                    />
                  </div>
                ) : (
                  <div className="bg-[#D4F3D5] flex items-center rounded-md px-4 md:px-6 py-2.5 gap-1.5">
                    <Button
                      onClick={onSubmitEditPersonalDetail}
                      title="Save Changes"
                      loading={isUsersLoading}
                      disabled={isUsersLoading}
                      className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                    />
                    <FaRegCircleCheck color="#17730A" />
                  </div>
                )}
              </div>
            </div>

            <div className="mt-7">
              <div>
                <div
                  className={`${
                    isEditablePersonalDetail ? "flex items-center relative" : ""
                  } w-full `}
                >
                  <TextInput
                    type="text"
                    label="Full Name"
                    name="fullName"
                    value={personalDetails?.fullName}
                    disabled={!isEditablePersonalDetail}
                    maxLength={50}
                    onChange={handleChangeTwo}
                    placeholder="Enter full name"
                    className={`${
                      !isEditablePersonalDetail
                        ? "bg-transparent !text-[#9899A1] font-[500]"
                        : ""
                    }`}
                  />
                  {isEditablePersonalDetail && (
                    <div className="absolute right-4 pt-6">
                      <img src="/images/edit.svg" alt="" className="" />
                    </div>
                  )}
                </div>
                {formErrors.fullName && (
                  <span className="text-xs text-[#BA3838] font-onestMedium">
                    {formErrors.fullName}
                  </span>
                )}
              </div>
              <div className="grid grid-cols-2 gap-6 mt-7">
                <div className=" w-full">
                  <TextInput
                    type="text"
                    name="email"
                    label="Email Address"
                    onChange={handleChangeTwo}
                    disabled
                    value={personalDetails.email}
                    placeholder="Enter email address"
                    defaultValue={personalDetails?.email}
                    className={`${
                      !isEditablePersonalDetail
                        ? "bg-transparent !text-[#9899A1] font-[500]"
                        : "bg-transparent"
                    }`}
                  />
                  {isEditablePersonalDetail && (
                    <div className="absolute right-4 pt-6">
                      <img src="/images/edit.svg" alt="" className="" />
                    </div>
                  )}
                </div>
                <div>
                  <DropDownSelect
                    disabled={!isEditablePersonalDetail}
                    title="Role"
                    titleClassName="!font-normal font-onestRegular"
                    lists={RoleType}
                    value={role}
                    setQuery={setRole}
                    isOpen={isRole}
                    toggleDropdown={toggleRoleDropdown}
                    handleOptionClick={handleRoleOptionClick}
                    placeholder="User role"
                    name="example-dropdown"
                  />
                  {formErrors.businessType && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.businessType}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!changePasswordTab && (
          <>
            {ticketTab === 1 && (
              <>
                <div
                  onClick={() => setChangePasswordTab(true)}
                  className="cursor-pointer border-[#E1F0DF] border-[1.5px] h-[120px] max-w-screen-md mx-4 md:mx-10 mt-[40px] rounded-2xl px-6 py-8 flex justify-between items-center"
                >
                  <div className="flex gap-6">
                    <img src="/images/password.svg" alt="" />
                    <div>
                      <h1 className="text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
                        Change Password
                      </h1>
                      <p className="text-[#464748] font-[400] font-onestRegular text-sm">
                        Manage what payment method(s) your teams can use to make
                        payments.
                      </p>
                    </div>
                  </div>
                  <GoChevronRight />
                </div>
                {/* <div className="border-[#E1F0DF] border-[1.5px] h-[120px] max-w-screen-md mx-4 md:mx-10 mt-[20px] rounded-2xl px-6 py-8 flex justify-between items-center">
                  <div className="flex gap-6">
                    <img src="/images/auth.svg" alt="" />
                    <div>
                      <h1 className="text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
                        Two-Factor Authentication
                      </h1>
                      <p className="text-[#464748] font-[400] font-onestRegular text-sm">
                        Adds an extra layer of security to your account. To log
                        in, in you'll need to provide a 4 digit amazing and
                        create outstanding products to serve your clients
                      </p>
                    </div>
                  </div>
                  <div className="bg-[#D4F3D5] w-[210px] flex items-center !text-xs rounded-md px-4 md:px-6 py-2.5 gap-1.5">
                    <Button
                      title={"Enable 2FA"}
                      className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                    />
                  </div>
                </div> */}
              </>
            )}
          </>
        )}

        {changePasswordTab && (
          <>
            {ticketTab === 1 && (
              <div className=" max-w-screen-md mx-4 md:mx-10 rounded-2xl px-6 py-8">
                <div>
                  <div className=" w-full">
                    <TextInput
                      type={isPasswordVisible ? "text" : "password"}
                      label="Current password"
                      name="currentPassword"
                      onChange={handleChangePassword}
                      value={passwords.currentPassword}
                      onTogglePasswordVisibility={onTogglePasswordVisibility}
                      placeholder="Enter current password"
                      isPasswordVisible={isPasswordVisible}
                      isPassword
                    />
                    {formErrors.currentPassword && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.currentPassword}
                      </span>
                    )}
                  </div>
                  <div className=" w-full mt-8">
                    <TextInput
                      type={isPasswordChangeVisible ? "text" : "password"}
                      label="New password"
                      name="newPassword"
                      onChange={handleChangePassword}
                      value={passwords.newPassword}
                      onTogglePasswordVisibility={
                        onToggleChangePasswordVisibility
                      }
                      placeholder="Enter new password"
                      isPasswordVisible={isPasswordChangeVisible}
                      isPassword
                    />
                    {formErrors.newPassword && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.newPassword}
                      </span>
                    )}
                  </div>
                  <div className=" w-full mt-8">
                    <TextInput
                      type={isPasswordConfirmVisible ? "text" : "password"}
                      label="Confirm new password"
                      name="confirmPassword"
                      value={passwords.confirmPassword}
                      onChange={handleChangePassword}
                      onTogglePasswordVisibility={
                        onToggleConfirmPasswordVisibility
                      }
                      placeholder="Confirm new password"
                      isPasswordVisible={isPasswordConfirmVisible}
                      isPassword
                    />
                    {formErrors.confirmPassword && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.confirmPassword}
                      </span>
                    )}
                  </div>
                  <div className="flex-col md:flex flex-wrap md:flex-row md:space-y-0 gap-1 space-y-4 md:justify-between mt-10 mb-7">
                    <Button
                      title="Cancel"
                      onClick={() => setChangePasswordTab(false)}
                      className="w-full !bg-[#fff] rounded-[32px] border-[1px] border-[#18af04] !text-[#18af04]  py-2.5 mb-4 md:mb-0"
                    />
                    <Button
                      onClick={onSubmitChangePassword}
                      loading={isChangePasswordLoading}
                      disabled={isChangePasswordLoading}
                      title="Change password"
                      className="w-full py-2.5 rounded-[32px] !px-12 !bg-[#18AF04]  !text-[#fff]"
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {ticketTab === 2 && (
          <div className="border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient h-[100%] max-w-screen-md mx-4 md:mx-10 mt-[40px] rounded-2xl px-6 py-8">
            <div className="hidden md:block">
              <div className="">
                <h1 className="text-xl font-onestBold font-[800] mb-6 text-[#161718] tracking-wide">
                  Shipment Updates
                </h1>
                <div className="flex gap-3 mb-4">
                  <p className="font-sm font-onestRegular font-[400]">
                    Email notifications
                  </p>
                  <SwitchTab
                    value={isEmailOn}
                    onChange={() => setIsEmailOn(!isEmailOn)}
                  />
                </div>
                <div className="flex gap-3 mb-4">
                  <p className="font-sm font-onestRegular font-[400]">
                    In-App notifications
                  </p>
                  <SwitchTab
                    value={isAppOn}
                    onChange={() => setIsAppOn(!isAppOn)}
                  />
                </div>
                <div className="flex gap-3 mb-8">
                  <p className="font-sm font-onestRegular font-[400]">
                    SMS notifications
                  </p>
                  <SwitchTab
                    value={isSmsOn}
                    onChange={() => setIsSmsOn(!isSmsOn)}
                  />
                </div>
                {/* <h1 className="text-base font-onestBold font-[800] text-[#161718] tracking-wide">
                  Security Alerts
                </h1>
                <div className="flex gap-3 items-start mt-6">
                  <input type="checkbox" className="accent-[#289D17] mt-1.5" />
                  <div>
                    <p className="text-sm font-onestMedium font-[500]">
                      {" "}
                      Login Alerts
                    </p>
                    <p className="text-xs md:text-sm  font-onestRegular font-[400]">
                      Get notified of new logins to your account.
                    </p>
                  </div>
                </div>
                <div className="flex gap-3 items-start mt-4">
                  <input type="checkbox" className="accent-[#289D17] mt-1.5" />
                  <div>
                    <p className="text-sm font-onestMedium font-[500]">
                      {" "}
                      Password Change Confirmation
                    </p>
                    <p className="text-xs md:text-sm  font-onestRegular font-[400]">
                      Confirmations for password changes.
                    </p>
                  </div>
                </div>
                <div className="flex gap-3 items-start mt-4">
                  <input type="checkbox" className="accent-[#289D17] mt-1.5" />
                  <div>
                    <p className="text-sm font-onestMedium font-[500]">
                      Subscription Updates
                    </p>
                    <p className="text-xs md:text-sm  font-onestRegular font-[400]">
                      Updates on your subscription status.
                    </p>
                  </div>
                </div>
                <div className="flex gap-3 items-start mt-4">
                  <input type="checkbox" className="accent-[#289D17] mt-1.5" />
                  <div>
                    <p className="text-sm font-onestMedium font-[500]">
                      Data Privacy Updates
                    </p>
                    <p className="text-xs md:text-sm font-onestRegular font-[400]">
                      Information on changes to privacy policies.
                    </p>
                  </div>
                </div>
                */}
                <div className="mt-6 md:flex">
                  <Button
                    onClick={onSubmitNotificatin}
                    loading={isUserNotificationLoading}
                    disabled={isUserNotificationLoading}
                    title="Save changes"
                    className="!rounded-[32px]"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mb-[180px] block md:hidden">
        <div className="block md:hidden max-w-screen-md mx-4 mt-[40px]">
          <h1 className="text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
            Settings
          </h1>
        </div>

        <div className="border-[1px] border-[#E1F0DF] bg-dashboard-gradient mt-8 rounded-[12px] py-6 mx-4 p-4">
          <div className="md:hidden mb-8">
            <DropDownSelect
              lists={settingType}
              value={settingValue}
              setQuery={setSettingValue}
              isOpen={isSetting}
              toggleDropdown={toggleSettingType}
              handleOptionClick={handleSettingType}
              name="deliveryType"
              placeholder={settingValue}
            />
          </div>

          {selectedOption?.name === "Profile" && (
            <>
              <div className="border-[#E1F0DF] h-[100%] border-[1.5px] max-w-screen-md mt-[20px] rounded-2xl px-4 py-8">
                <div className="flex justify-between  ">
                  <div>
                    <h1 className="text-base md:text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
                      Business Details
                    </h1>
                    <p className="text-[#A0ABA3] font-onestRegular w-[85%] text-xs md:text-sm">
                      Your entire business information is here
                    </p>
                  </div>
                  <div className="cursor-pointer">
                    {!isEditable ? (
                      <div
                        onClick={handleEdit}
                        className="bg-[#D4F3D5] flex items-center rounded-md px-4 md:px-6 py-2.5 gap-1.5"
                      >
                        <img src="/images/edit.svg" alt="" />
                        <Button
                          title="Edit"
                          className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={handleEditBusiness}
                        className="bg-[#D4F3D5] flex items-center rounded-md px-4 md:px-6 py-2.5 gap-1.5"
                      >
                        <Button
                          loading={isClientLoading}
                          disabled={isClientLoading}
                          title="Save Changes"
                          className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                        />
                        <FaRegCircleCheck color="#17730A" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <div
                      className={` ${
                        isEditable ? "flex items-center relative" : ""
                      } w-full mt-7 `}
                    >
                      <TextInput
                        type="text"
                        label="Business Name"
                        name="businessName"
                        value={users.businessName}
                        disabled={!isEditable}
                        maxLength={50}
                        onChange={handleChange}
                        placeholder="Enter business name"
                        className={`${
                          !isEditable
                            ? "bg-transparent !text-[#9899A1] font-[500]"
                            : ""
                        }`}
                      />
                      {isEditable && (
                        <div className="absolute right-4 pt-6">
                          <img src="/images/edit.svg" alt="" className="" />
                        </div>
                      )}
                    </div>
                    {validateBusinessNameError !== "" && (
                      <div className="flex mt-1 items-center space-x-1">
                        <img src="/images/warning.svg" alt="" />
                        <p className="text-xs text-[#BA3838] font-onestMedium">
                          {validateBusinessNameError}
                        </p>
                      </div>
                    )}
                    {formErrors.businessName && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.businessName}
                      </span>
                    )}
                  </div>
                  <div className=" w-full">
                    <TextInput
                      type="text"
                      name="businessEmail"
                      label="Business Email"
                      disabled
                      onChange={handleChange}
                      value={users.businessEmail}
                      placeholder="Enter business email"
                      className={`${
                        !isEditable
                          ? "bg-transparent !text-[#9899A1] font-[500]"
                          : "bg-transparent"
                      }`}
                    />
                  </div>
                  <div>
                    <div
                      className={` ${
                        isEditable ? "flex items-center relative" : ""
                      } w-full `}
                    >
                      <TextInput
                        type="number"
                        name="businessPhoneNumber"
                        label="Business Phone Number"
                        disabled={!isEditable}
                        onChange={handleChange}
                        value={users.businessPhoneNumber}
                        placeholder="Enter business phone number"
                        className={`${
                          !isEditable
                            ? "bg-transparent !text-[#9899A1] font-[500]"
                            : ""
                        }`}
                      />
                      {isEditable && (
                        <div className="absolute right-4 pt-6">
                          <img src="/images/edit.svg" alt="" className="" />
                        </div>
                      )}
                    </div>
                    {validatePhoneError !== "" && (
                      <div className="flex mt-1 items-center space-x-1">
                        <img src="/images/warning.svg" alt="" />
                        <p className="text-xs text-[#BA3838] font-onestMedium">
                          {validatePhoneError}
                        </p>
                      </div>
                    )}
                    {formErrors.businessPhoneNumber && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.businessPhoneNumber}
                      </span>
                    )}
                  </div>
                  <div>
                    <DropDownSelect
                      title="Business type"
                      titleClassName="!font-normal font-onestRegular"
                      lists={businessType}
                      value={query}
                      setQuery={setQuery}
                      isOpen={isOpen}
                      toggleDropdown={toggleDropdown}
                      handleOptionClick={handleOptionClick}
                      placeholder="Select type"
                      name="example-dropdown"
                    />
                    {formErrors.businessType && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.businessType}
                      </span>
                    )}
                  </div>
                  <div>
                    <div
                      className={` ${
                        isEditable ? "flex items-center relative" : "mt-0"
                      } w-full`}
                    >
                      <TextInput
                        type={"text"}
                        name="businessAddress"
                        disabled={!isEditable}
                        label="Business Address"
                        onChange={handleChange}
                        value={users.businessAddress}
                        placeholder="Enter business address"
                        className={`${
                          !isEditable
                            ? "bg-transparent !text-[#9899A1] font-[500]"
                            : ""
                        }`}
                      />
                      {isEditable && (
                        <div className="absolute right-4 mt-0 pt-6">
                          <img src="/images/edit.svg" alt="" className="" />
                        </div>
                      )}
                    </div>
                    {formErrors.businessAddress && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.businessAddress}
                      </span>
                    )}
                  </div>
                  <div className="mt-0">
                    <div className="flex">
                      <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                        State
                      </p>
                      {/* <span className="text-red-500 text-sm">*</span> */}
                    </div>
                    <Dropdown
                      dropdown={
                        <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                          <div className="flex items-center space-x-1">
                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                            <span
                              className={` ${
                                users?.state === ""
                                  ? "text-[#9899A1]"
                                  : "text-[#000]"
                              }  font-onestMedium text-sm`}
                            >
                              {users?.state === "" || users?.state === undefined
                                ? "Select state"
                                : stateName}
                            </span>
                          </div>

                          <img
                            src={"/images/chevronDown.svg"}
                            alt="drop-down"
                            className="mt-0.5 pr-2 cursor-pointer"
                          />
                        </div>
                      }
                      search={
                        <div>
                          <TextInput
                            value={searchState}
                            name={"search"}
                            type={"text"}
                            placeholder="Search"
                            onChange={(e) => setSearchState(e.target.value)}
                          />
                        </div>
                      }
                      dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                      dropdownOptions={[
                        {
                          customChild: (
                            <div>
                              <div>
                                {filteredStateSearch?.map(
                                  (item: any, idx: number) => {
                                    return (
                                      <div
                                        key={idx}
                                        onClick={() => {
                                          setUsers((prev: any) => ({
                                            ...prev,
                                            state: item.state,
                                          }));
                                          setStateName(item?.state);
                                          // setFormErrors((prev: any) => ({
                                          //   ...prev,
                                          //   state: "",
                                          // }));
                                        }}
                                      >
                                        <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                          <p>{item?.state}</p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          ),
                        },
                      ]}
                    />
                    {formErrors.state && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.state}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="border-[#E1F0DF] h-[100%] border-[1.5px] max-w-screen-md mt-[20px] rounded-2xl px-4 py-8 ">
                {/* <ProfileHeader
                  profileTitle="Personal Details"
                  text="Your entire business information is here"
                  buttonTitle="Edit"
                /> */}
                <div className="flex justify-between">
                  <div>
                    <h1 className="text-base md:text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
                      Personal Details
                    </h1>
                    <p className="text-[#A0ABA3] font-onestRegular w-[85%] text-xs md:text-sm">
                      Your entire business information is here
                    </p>
                  </div>
                  <div className="cursor-pointer">
                    {!isEditablePersonalDetail ? (
                      <div className="bg-[#D4F3D5] flex items-center rounded-md px-4 md:px-6 py-2.5 gap-1.5">
                        <img src="/images/edit.svg" alt="" />
                        <Button
                          onClick={handleEditPersonalDetail}
                          title="Edit"
                          className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                        />
                      </div>
                    ) : (
                      <div className="bg-[#D4F3D5] flex items-center rounded-md px-4 md:px-6 py-2.5 gap-1.5">
                        <Button
                          onClick={onSubmitEditPersonalDetail}
                          title="Save Changes"
                          loading={isUsersLoading}
                          disabled={isUsersLoading}
                          className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                        />
                        <FaRegCircleCheck color="#17730A" />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-6">
                  <div>
                    <div
                      className={`${
                        isEditablePersonalDetail
                          ? "flex items-center relative"
                          : ""
                      } w-full `}
                    >
                      <TextInput
                        type="text"
                        label="Full Name"
                        name="fullName"
                        value={personalDetails?.fullName}
                        disabled={!isEditablePersonalDetail}
                        maxLength={50}
                        onChange={handleChangeTwo}
                        placeholder="Enter full name"
                        className={`${
                          !isEditablePersonalDetail
                            ? "bg-transparent !text-[#9899A1] font-[500]"
                            : ""
                        }`}
                      />
                      {isEditablePersonalDetail && (
                        <div className="absolute right-4 pt-6">
                          <img src="/images/edit.svg" alt="" className="" />
                        </div>
                      )}
                    </div>
                    {formErrors.fullName && (
                      <span className="text-xs text-[#BA3838] font-onestMedium">
                        {formErrors.fullName}
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-1 gap-6 mt-6">
                    <div
                      className={`${
                        isEditablePersonalDetail
                          ? "flex items-center relative"
                          : ""
                      } w-full `}
                    >
                      <TextInput
                        type="text"
                        name="email"
                        label="Email Address"
                        disabled
                        onChange={handleChangeTwo}
                        value={personalDetails.email}
                        placeholder="Enter email address"
                        className={`${
                          !isEditablePersonalDetail
                            ? "bg-transparent !text-[#9899A1] font-[500]"
                            : "bg-transparent"
                        }`}
                      />
                    </div>
                    <div>
                      <DropDownSelect
                        title="Role"
                        titleClassName="!font-normal font-onestRegular"
                        lists={RoleType}
                        value={role}
                        setQuery={setRole}
                        isOpen={isRole}
                        toggleDropdown={toggleRoleDropdown}
                        handleOptionClick={handleRoleOptionClick}
                        placeholder="User role"
                        name="example-dropdown"
                      />
                      {formErrors.businessType && (
                        <span className="text-xs text-[#BA3838] font-onestMedium">
                          {formErrors.businessType}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-6 md:flex">
                  <Button
                    loading={isUsersLoading}
                    disabled={isUsersLoading}
                    onClick={onSubmitEditPersonalDetail}
                    title="Save changes"
                    className="!rounded-[32px]"
                  />
                </div>
              </div>
            </>
          )}

          {selectedOption?.name === "Notifications" && (
            <>
              <div className="block md:hidden">
                <div className="">
                  <h1 className="text-xl font-onestBold font-[800] mb-6 text-[#161718] tracking-wide">
                    Shipment Updates
                  </h1>
                  <div className="flex gap-3 mb-4">
                    <p className="font-sm font-onestRegular font-[400]">
                      Email notifications
                    </p>
                    <SwitchTab
                      value={isEmailOn}
                      onChange={() => setIsEmailOn(!isEmailOn)}
                    />
                  </div>
                  <div className="flex gap-3 mb-4">
                    <p className="font-sm font-onestRegular font-[400]">
                      In-App notifications
                    </p>
                    <SwitchTab
                      value={isAppOn}
                      onChange={() => setIsAppOn(!isAppOn)}
                    />
                  </div>
                  <div className="flex gap-3 mb-8">
                    <p className="font-sm font-onestRegular font-[400]">
                      SMS notifications
                    </p>
                    <SwitchTab
                      value={isSmsOn}
                      onChange={() => setIsSmsOn(!isSmsOn)}
                    />
                  </div>
                  <h1 className="text-base font-onestBold font-[800] text-[#161718] tracking-wide">
                    Security Alerts
                  </h1>
                  <div className="flex gap-3 items-start mt-6">
                    <input
                      type="checkbox"
                      className="accent-[#289D17] mt-1.5"
                      checked
                    />
                    <div>
                      <p className="text-sm font-onestMedium font-[500]">
                        {" "}
                        Login Alerts
                      </p>
                      <p className="text-xs md:text-sm  font-onestRegular font-[400]">
                        Get notified of new logins to your account.
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-3 items-start mt-4">
                    <input
                      type="checkbox"
                      className="accent-[#289D17] mt-1.5"
                      checked
                    />
                    <div>
                      <p className="text-sm font-onestMedium font-[500]">
                        {" "}
                        Password Change Confirmation
                      </p>
                      <p className="text-xs md:text-sm  font-onestRegular font-[400]">
                        Confirmations for password changes.
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-3 items-start mt-4">
                    <input
                      type="checkbox"
                      className="accent-[#289D17] mt-1.5"
                      checked
                    />
                    <div>
                      <p className="text-sm font-onestMedium font-[500]">
                        Subscription Updates
                      </p>
                      <p className="text-xs md:text-sm  font-onestRegular font-[400]">
                        Updates on your subscription status.
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-3 items-start mt-4">
                    <input
                      type="checkbox"
                      className="accent-[#289D17] mt-1.5"
                    />
                    <div>
                      <p className="text-sm font-onestMedium font-[500]">
                        Data Privacy Updates
                      </p>
                      <p className="text-xs md:text-sm font-onestRegular font-[400]">
                        Information on changes to privacy policies.
                      </p>
                    </div>
                  </div>
                  <div className="mt-6 md:flex">
                    <Button
                      onClick={onSubmitNotificatin}
                      loading={isUserNotificationLoading}
                      disabled={isUserNotificationLoading}
                      title="Save changes"
                      className="!rounded-[32px]"
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {!changePasswordTab && (
            <>
              {selectedOption?.name === "Security" && (
                <>
                  <div
                    onClick={() => setChangePasswordTab(true)}
                    className="border-[#E1F0DF] border-[1.5px] max-w-screen-md mt-[40px] cursor-pointer rounded-2xl px-4 py-8 flex justify-between items-center"
                  >
                    <div className="flex gap-6">
                      <img
                        className="hidden"
                        src="/images/password.svg"
                        alt=""
                      />
                      <div>
                        <h1 className="text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
                          Change Password
                        </h1>
                        <p className="text-[#464748] font-[400] w-[80%] font-onestRegular text-sm">
                          Manage what payment method(s) your teams can use to
                          make payments.
                        </p>
                      </div>
                    </div>
                    <GoChevronRight size={48} color={"#17730A"} />
                  </div>
                  <div className="border-[#E1F0DF] border-[1.5px] max-w-screen-md mt-[20px] rounded-2xl px-4 py-8">
                    <div className="flex gap-6">
                      <img className="hidden" src="/images/auth.svg" alt="" />
                      <div>
                        <h1 className="text-xl font-onestBold font-[800] text-[#161718] tracking-wide">
                          Two-Factor Authentication
                        </h1>
                        <p className="text-[#464748] font-[400] font-onestRegular text-sm">
                          Adds an extra layer of security to your account. To
                          log in, in you'll need to provide a 4 digit amazing
                          and create outstanding products to serve your clients
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#D4F3D5] w-[120px] mt-3 flex justify-center items-center !text-xs rounded-md px-4 md:px-6 py-2.5 gap-1.5">
                      <Button
                        title={"Enable 2FA"}
                        className="!bg-transparent !py-0 !px-0 text-xs !font-[400] !font-onestRegular !text-[#17730A]"
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {changePasswordTab && (
            <>
              {selectedOption?.name === "Security" && (
                <div className=" max-w-screen-md rounded-2xl px-4 py-8">
                  <div>
                    <div className=" w-full">
                      <TextInput
                        type={isPasswordVisible ? "text" : "password"}
                        label="Current password"
                        name="currentPassword"
                        onChange={handleChangePassword}
                        value={passwords.currentPassword}
                        onTogglePasswordVisibility={onTogglePasswordVisibility}
                        placeholder="Enter current password"
                        isPasswordVisible={isPasswordVisible}
                        isPassword
                      />
                      {formErrors.currentPassword && (
                        <span className="text-xs text-[#BA3838] font-onestMedium">
                          {formErrors.password}
                        </span>
                      )}
                    </div>
                    <div className=" w-full mt-8">
                      <TextInput
                        type={isPasswordChangeVisible ? "text" : "password"}
                        label="New password"
                        name="newPassword"
                        onChange={handleChangePassword}
                        value={passwords.newPassword}
                        onTogglePasswordVisibility={
                          onToggleChangePasswordVisibility
                        }
                        placeholder="Enter new password"
                        isPasswordVisible={isPasswordChangeVisible}
                        isPassword
                      />
                      {formErrors.newPassword && (
                        <span className="text-xs text-[#BA3838] font-onestMedium">
                          {formErrors.newPassword}
                        </span>
                      )}
                    </div>
                    <div className=" w-full mt-8">
                      <TextInput
                        type={isPasswordConfirmVisible ? "text" : "password"}
                        label="Confirm new password"
                        name="confirmPassword"
                        onChange={handleChangePassword}
                        value={users.password}
                        onTogglePasswordVisibility={
                          onToggleConfirmPasswordVisibility
                        }
                        placeholder="Confirm new password"
                        isPasswordVisible={isPasswordConfirmVisible}
                        isPassword
                      />
                      {formErrors.confirmPassword && (
                        <span className="text-xs text-[#BA3838] font-onestMedium">
                          {formErrors.confirmPassword}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col-reverse gap-2 space-y-4 mb-7">
                      <Button
                        title="Cancel"
                        onClick={() => setChangePasswordTab(false)}
                        className="w-full !bg-[#fff] !rounded-[32px] border-[1px] border-[#18af04] !text-[#18af04]  py-2.5 mb-4 md:mb-0"
                      />
                      <Button
                        onClick={onSubmitChangePassword}
                        loading={isChangePasswordLoading}
                        disabled={isChangePasswordLoading}
                        title="Change password"
                        className="w-full py-2.5 !rounded-[32px] !px-12 !bg-[#18AF04]  !text-[#fff]"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Setting;
