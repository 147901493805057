import { Button } from "components/Button";
import ModalContainer from "components/Modal";
import { useCallback, useEffect, useState } from "react";
import {
  useGetAllTicketMutation,
  useGetTicketStatsMutation,
} from "services/slices/ticket";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PagePagination from "components/Pagination";
import { Loader } from "components/Loader";
import { filterTerms } from "constant/data";
import { getDateRange } from "helpers/getDateRange";
import { debounce } from "lodash";

function AllTickets() {
  const navigate = useNavigate();
  const [ticketTab, setTicketTab] = useState(0);
  const [filterDate, setFilterDate] = useState<boolean>(false);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [debounceSearch, setDebounceSearch] = useState<string>("");
  const [statistics, setStatistics] = useState({
    open: 0,
    close: 0,
  });
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
  const [ticketEndDate, setTicketEndDate] = useState<string>(
    moment().format("DD-MM-YYYY")
  );
  const [ticketStartDate, setTicketStartDate] = useState<string>(
    moment().format("DD-MM-YYYY")
  );
  const [status, setStatus] = useState<string>("open");
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [getTicket, { isLoading, data: ticketData }] =
    useGetAllTicketMutation();
  const [getAllTicket, { isLoading: isLoadingTicket, data: allTicketData }] =
    useGetTicketStatsMutation();

    const debouncedSetSearch = useCallback(
      debounce((value) => {
        setSearch(value);
      }, 500),
      []
    );
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSetSearch(e.target.value);
      setDebounceSearch(e.target.value)
      setPage(0);
    };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setStartDate(date);
      setFormattedDate(dateStart);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setEndDate(date);
      setFormattedEndDate(dateStart);
    }
  };

  const onClearDate = () => {
    setSelectedPeriodName("Last 7 days");
    setSelectedPeriod("last7days");
    setShowDateModal(false);
  };

  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  useEffect(() => {
    if (selectedPeriod) {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setTicketStartDate(startDate);
      setTicketEndDate(endDate);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod === "custom") {
      setShowDateModal(true);
    }
  }, [selectedPeriod]);


  useEffect(() => {
    const getAllTicket = async () => {
      await getTicket({
        body: {
          startDate: ticketStartDate,
          endDate: ticketEndDate,
          status: status === "close" ? "closed" : status,
        },
        page:page+1,
        search,
      });
    };
    getAllTicket();
  }, [ticketStartDate, ticketEndDate, status, search, selectedPeriod, page]);

  useEffect(() => {
    const getTickets = async () => {
      await getAllTicket({
        body: {
          startDate: ticketStartDate,
          endDate: ticketEndDate,
        },
        search,
      });
    };
    getTickets();
  }, [ticketStartDate, ticketEndDate, selectedPeriod, search]);

  useEffect(() => {
    if (Boolean(selectedPeriod.toLowerCase() === "custom")) {
      setShowDateModal(true);
    } else {
      setShowDateModal(false);
    }
  }, [selectedPeriod]);


  useEffect(() => {
    if (allTicketData?.orderStats.length > 0) {
      allTicketData?.orderStats?.map((data: any) => {
        if (data?.Status?.toLowerCase() === "closed") {
          setStatistics((prev) => ({
            ...prev,
            close: data.Count,
          }));
        } else if (data?.Status?.toLowerCase().includes("open")) {
          setStatistics((prev) => ({
            ...prev,
            open: data?.Count,
          }));
        }
        return statistics;
      });
    } else {
      setStatistics({
        open: 0,
        close: 0,
      });
    }
  }, [allTicketData]);

  const onApplyDate = () => {
    setTicketStartDate(formattedDate);
    setTicketEndDate(formattedEndDate);
    setShowDateModal(false);
  };

  
  return (
    <>
      <div className="px-5 bmd:px-[42px] pt-[33px] mb-5">
        <h1 className="text-2xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
          Tickets
        </h1>
        <div className="flex-col flex-wrap gap-5 md:flex md:flex-row justify-between items-center mt-12">
          <div className="flex justify-around gap-12">
            <span
              onClick={() => {
                setTicketTab(0);
                setStatus("open");
              }}
              className={`cursor-pointer ${
                ticketTab === 0
                  ? "border-b-[2px] pb-1.5 border-[#45C734] font-[700]"
                  : ""
              } text-[#4A564D] text-[13px] `}
            >
              Open - {isLoadingTicket ? <Loader /> : statistics?.open}
            </span>
            <span
              onClick={() => {
                setTicketTab(1);
                setStatus("close");
              }}
              className={`cursor-pointer ${
                ticketTab === 1
                  ? "border-b-[2px] pb-1.5 border-[#45C734] font-[700]"
                  : ""
              } text-[#4A564D] text-[13px]`}
            >
              Closed - {isLoadingTicket ? <Loader /> : statistics?.close}
            </span>
            <span
              onClick={() => {
                setTicketTab(2);
                setStatus("");
              }}
              className={`cursor-pointer ${
                ticketTab === 2
                  ? "border-b-[2px] pb-1.5 border-[#45C734] font-[700]"
                  : ""
              } text-[#4A564D] text-[13px]`}
            >
              All Tickets-{" "}
              {isLoadingTicket ? (
                <Loader />
              ) : (
                `${statistics?.open + statistics?.close}`
              )}
            </span>
          </div>

          <div className="flex-col md:flex md:flex-row items-center gap-4 mt-5 md:mt-0">
            <div className="w-full bmd:w-[229px] flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
              <img
                className="w-[14px] h-[14px]"
                src="/images/search-icon.svg"
                alt="search-icon"
              />
              <input
                className="text-[13px] py-[10px] w-[180px] outline-none"
                type="text"
                value={debounceSearch}
                onChange={(e) => handleInputChange(e)}
                placeholder="Search ticket by order ID"
              />
            </div>
            <div
              onClick={() => setFilterDate(!filterDate)}
              className="cursor-pointer w-[210px] relative mt-3 md:mt-0 flex gap-2 items-center rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] "
            >
              <img
                className="w-[14px] h-[14px]"
                src="/images/filter.svg"
                alt="search-icon"
              />
              <p className="text-[13px] text-[#929DAE]  py-[10px] w-[239px] outline-none">
                Filter: {selectedPeriodName}
              </p>
              <img
                className="w-[14px] rounded-xl cursor-pointer h-[14px]"
                src="/images/circle-close.svg"
                alt="close icon"
                onClick={(e) => {
                  e.stopPropagation();
                  onClearDate();
                }}
              />
              {filterDate && (
                <div className="p-4 border-[1px] w-[190px] right-3 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                  <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                    PERIOD
                  </h1>
                  <div>
                    {filterTerms.map((terms) => (
                      <div
                        className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                        onClick={() => {
                          setSelectedPeriodName(terms.name);
                          handlePeriodChange(terms.value);
                        }}
                      >
                        <p className="flex items-center mb-0 cursor-pointer ">
                          <span
                            className={`${
                              selectedPeriod === terms.value && "bg-[#289D17]"
                            } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                          >
                            <span className="w-2 h-2 bg-white rounded-full"></span>
                          </span>
                          <span
                            className={`${
                              selectedPeriod === terms.value
                                ? "text-[#289D17]"
                                : "text-[#464748] group-hover:text-[#289D17]"
                            } ml-3 text-sm  `}
                          >
                            {terms.name}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="mt-20">
            <Loader />
          </div>
        ) : (
          <>
            {ticketData?.["Tickets"]?.data?.length > 0 ? (
              <>
                <div className="grid md:grid-cols-2 xl:grid-cols-4 mt-10 gap-4">
                  {ticketData?.["Tickets"]?.data?.map((item: any) => {
                    return (
                      <div
                        key={item?.id}
                        className="border-[1px] cursor-pointer border-[#E4EBF5] p-4 rounded-lg "
                        onClick={() =>
                          navigate(`/dashboard/ticket/${item?.ticket_id}`)
                        }
                      >
                        <h1 className="text-sm font-[500] font-onestMedium">
                          {item?.subject?.["Subjects"]}
                        </h1>
                        <h1 className="font-[700] text-[#4A564D] text-xs mt-2">
                          {item?.orderNo}
                        </h1>
                        <div className="mt-5 flex items-center justify-between">
                          <p
                            className={`${
                              Boolean(
                                item.status.toLowerCase().includes("open")
                              )
                                ? "text-[#65513F] bg-[#F8E4D1]"
                                : "bg-[#F6F3F0] text-[#A19F9E]"
                            } text-xs font-[500] px-1.5 py-1 rounded-[3px]`}
                          >
                            {Boolean(item.status.toLowerCase().includes("open"))
                              ? "Open"
                              : "Closed"}
                          </p>
                          <p className="text-[#4A564D] text-xs font-onestRegular">
                            {moment(item?.updated_at).format(
                              "ddd, DD MMM, YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {ticketData?.["Tickets"]?.last_page >1 && (
                  <PagePagination
                    totalPages={ticketData?.["Tickets"].last_page}
                    page={page}
                    handlePageClick={(event) => setPage(event.selected)}
                  />
                )}
              </>
            ) : (
              <div className="mt-20 text-center flex justify-center items-center">
                <p>No Ticket yet!</p>
              </div>
            )}
          </>
        )}
      </div>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={onApplyDate}
            />
          </div>
        </div>
      </ModalContainer>
    </>
  );
}

export default AllTickets;
