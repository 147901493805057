import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import AuthLayout from "components/layout/AuthLayout";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "services/slices/authSlice";

const ForgotPassword = () => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const [isShowEmail, setIsShowEmail] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const navigate = useNavigate();

  const intialValues = {
    email: "",
    password: "",
  };
  const [users, setUsers] = useState(intialValues);
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.email || values.email === "") {
      errors.email = "Email is required";
    } else if (values.email !== "" && !regex.test(values.email)) {
      errors.email = "Invalid email format";
    }
    return errors;
  };

  const handleErrorChange = (name: string, value: any) => {
    if (name === "email" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    } else if (name === "email" && value !== "" && !regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "Invalid email format",
      }));
    } else if (name === "email" && value !== "" && regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUsers((prev) => ({
      ...prev,
      [name]: value,
    }));

    handleErrorChange(name, value);
  };

  console.log("formerros", formErrors);
  const onForgotPassword = async (e: any) => {
    e.preventDefault();
    setFormErrors(validateStepOne(users));
    const body = {
      email: users.email,
      action: "create-email",
    };
    if (users?.email) {
      await forgotPassword(body)
        .unwrap()
        .then((data) => {
          if (data) setIsShowEmail(true);
          handleSuccessToast("Email sent successfully");
        })
        .catch((e) => {
          handleErrorToast(e);
        });
    }
  };

  return (
    <AuthLayout
      headerTitle={
        <p className="md:text-sm text-xs font-onestRegular text-black">
          Don’t have an account?
          <span
            className="ml-1 font-onestMedium text-[#15B900] cursor-pointer"
            onClick={() => navigate("/register")}
          >
            Sign Up
          </span>
        </p>
      }
      children={
        <div className="sm:px-[40px] w-full lg:px-0 ">
          <div className="flex justify-center max-w-[600]">
            <div className="h-fit w-full  ">
              {!isShowEmail ? (
                <div className="bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-10 ">
                  <div className=" ">
                    <div
                      className="flex items-center space-x-2 mb-5 w-fit"
                      onClick={() => navigate(-1)}
                    >
                      <img src="/images/arrowBack.svg" alt="" />
                      <p className="text-sm font-onestMedium text-[#A0ABA3]">
                        Go Back
                      </p>
                    </div>
                    <p className="md:text-2xl text-xl text-black font-onestMedium">
                      Forgot Password
                    </p>
                    <p className="text-[#A0ABA3] font-onestMedium text-base md:text-lg">
                      Let’s recover your account
                    </p>
                  </div>
                  <form action="" onSubmit={onForgotPassword}>
                    <div className="">
                      <div className="my-[30px]  space-y-[40px]">
                        <p className="text-[#464748] text-sm md:text-base">
                          Enter the email address you registered with. We will
                          send you a password reset link.
                        </p>
                        <div className=" w-full">
                          <TextInput
                            type="text"
                            label="Email Address"
                            // isRequired
                            name="email"
                            onChange={handleChange}
                            value={users.email}
                            placeholder="Enter email address"
                          />
                          {formErrors.email && (
                            <span className="text-xs text-[#BA3838] font-onestMedium">
                              {formErrors.email}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className=" mt-[40px]">
                        <Button
                          title="Send me a link"
                          type="submit"
                          loading={isLoading}
                          disabled={isLoading}
                        />
                      </div>
                      <p className="text-sm font-onestMedium text-[#A0ABA3] text-center mt-2">
                        Have trouble signing in?{" "}
                        <span
                          className="text-[#15B900] cursor-pointer"
                          onClick={() => navigate("")}
                        >
                          Contact Support
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-10 ">
                  <div className=" ">
                    <div
                      className="flex items-center cursor-pointer space-x-2 mb-5 w-fit"
                      onClick={() => navigate(-1)}
                    >
                      <img src="/images/arrowBack.svg" alt="" />
                      <p className="text-sm font-onestMedium text-[#A0ABA3]">
                        Go Back
                      </p>
                    </div>
                    <p className="md:text-2xl text-xl text-black font-onestMedium">
                      Check your inbox
                    </p>
                    <p className="text-[#A0ABA3] font-onestMedium text-base md:text-lg">
                      Let’s recover your account
                    </p>
                  </div>
                  <div className=" border rounded-[8px] border-[#E6E8E6] p-3 mt-10">
                    <div className=" ">
                      <div className="mb-2 flex items-center space-x-2">
                        <img src="/images/warning.svg" alt="warning-icon" />
                        <p className="text-[#161718] text-sm font-onestMedium">
                          ✅Password reset instructions sent!
                        </p>
                      </div>
                      <p className="text-[#464748] text-sm">
                        We can only send password reset instructions to
                        registered emails with an existing account. If the email
                        doesn't appear in your inbox or spam folder, please
                        check the email you entered or click below to resend.
                      </p>
                      <Button
                        title="Resend email"
                        onClick={onForgotPassword}
                        className="!w-fit mt-3"
                        loading={isLoading}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                  <p className="text-sm mt-8 font-onestMedium text-[#A0ABA3] text-center">
                    Have trouble signing in?{" "}
                    <span
                      className="text-[#15B900] cursor-pointer"
                      onClick={() => navigate("")}
                    >
                      Contact Support
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};
export default ForgotPassword;
