import PrivateRoutes from "components/common/Routes/PrivateRoute";
import React from "react";
import { Outlet } from "react-router-dom";
import DashboardLayout from "./DashboardLayout";

const Dashboard = () => {
  return (
    <PrivateRoutes>
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </PrivateRoutes>
  );
};

export default Dashboard;
