import { ReactNode } from "react";
import { Loader } from "./Loader";
interface ButtonProps {
  active?: boolean;
  loading?: boolean;
  image?: string;
  title: string;
  className?: string;
  type?: "submit" | "button";
  onClick?: any;
  disabled?: boolean;
  icon?: ReactNode;
  iconLeft?: boolean;
  iconRight?: boolean;
  style?: React.CSSProperties;
}
export const Button = ({
  className,
  disabled,
  loading,
  title,
  onClick,
  type = "button",
  icon,
  iconLeft,
  iconRight,
  style,
}: ButtonProps) => {
  return (
    <div>
      <button
        type={type}
        className={`${className} cursor-pointer bg-[#18AF04] flex justify-center text-white rounded-[8px] items-center space-x-1 px-2 sm:px-5 py-2 w-full `}
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="flex  items-center space-x-1">
            {iconLeft && (
              <div className="">
            {icon}
              </div>
            )}
            <p className={`text-[14px] text-center font-onestMedium`}>{title}</p>
            {iconRight && <div>{icon}</div>}
          </div>
        )}
      </button>
    </div>
  );
};
