import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { useInviteSignupMutation } from "services/slices/authSlice";
import { handleErrorToast } from "helpers/toastMessage";

const intialValues = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  referralCode: "",
};

interface AuthProps {
  fullname?: string;
  email?: string;
  businessName?: string;
  password: string;
  user_id: string;
  referral?: string;
}
const BusinessRegister = () => {
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const [users, setUsers] = useState(intialValues);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const email = param.get("mail");
  const businessName = param.get("bn");
  const userId = param.get("userId");
  const referralCode = param.get("referral");
  const [inviteSignup, { isLoading }] = useInviteSignupMutation();
  const onTogglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };
  const onToggleConfirmPasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsConfirmPasswordVisible((prevState) => !prevState);
  };
  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.fullName || values.fullName === "") {
      errors.fullName = "Name is required";
    }
    if (!values.password || values.password === "") {
      errors.password = "Password is required";
    } else if (values.password !== "" && values.password.length < 8) {
      errors.password = "Password must have 8 characters";
    }
    if (!values.confirmPassword || values.confirmPassword === "") {
      errors.confirmPassword = "Confirm password is required";
    } else if (
      values.confirmPassword !== values.password &&
      values.confirmPassword !== ""
    ) {
      errors.confirmPassword = "Confirm password must be same as password";
    } else if (
      values.confirmPassword !== "" &&
      values.confirmPassword.length < 8
    ) {
      errors.confirmPassword = "Password must have 8 characters";
    }
    return errors;
  };
  const handleErrorChange = (name: string, value: any) => {
    if (name === "password" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    } else if (name === "password" && value.length < 8) {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "Password must have 8 characters",
      }));
    } else if (name === "password" && value.length >= 8) {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    }

    if (name === "fullName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "",
      }));
    } else if (name === "fullName" && value.length > 50) {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "Fullname cannot be more than 50 character length",
      }));
    } else if (name === "fullName" && value.length <= 50) {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "",
      }));
    }
    if (name === "confirmPassword" && value === users.password) {
      setFormErrors((prev: any) => ({
        ...prev,
        confirmPassword: "",
      }));
    } else if (name === "confirmPassword" && value === users.password) {
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUsers((prev) => ({
      ...prev,
      [name]: value,
    }));

    handleErrorChange(name, value);
  };

  useEffect(() => {
    if (referralCode) {
      setUsers((prev) => ({
        ...prev,
        referralCode,
      }));
    }
  }, []);
  const onHandleSubmit = async (e: any) => {
    e.preventDefault();

    const validationErrors = validateStepOne(users);

    await setFormErrors(validationErrors);
    const body: AuthProps = {
      businessName: businessName || "",
      fullname: users?.fullName,
      email: email || "",
      user_id: userId || "",
      password: users?.password,
    };
    if (users?.referralCode) {
      body.referral = users?.referralCode;
    }
    if (
      users.fullName !== "" &&
      email !== "" &&
      users.password !== "" &&
      users.confirmPassword
    ) {
      await inviteSignup(body)
        .unwrap()
        .then((payload: any) => {
          navigate("/");
        })
        .catch((e) => {
          handleErrorToast(e);
        });
    }
  };

  return (
    <div className="px-[40px] lg:px-0 flex justify-center pt-20 pb-10 ">
      <div className="lg:w-[598px] min-w-[300px] h-fit   ">
        <div className=" bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-10 ">
          <div className="text-center ">
            <p className="md:text-2xl  text-xl text-black font-onestMedium">
              Invitation to Join {businessName}
            </p>
            <p className="text-[#464748] font-onestMedium text-base md:text-lg">
              You have been invited to join {businessName} on Fez Delivery
            </p>
          </div>
          <form action="" onSubmit={onHandleSubmit}>
            <div className="">
              <div className="my-[30px]  space-y-[18px]">
                <div className=" w-full">
                  <TextInput
                    type="text"
                    label="Full Name"
                    name="fullName"
                    value={users.fullName}
                    // isRequired
                    maxLength={50}
                    onChange={handleChange}
                    totalInput={String(users.fullName.length)}
                    placeholder="Enter full name"
                  />
                  {formErrors.fullName && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.fullName}
                    </span>
                  )}
                </div>
                <div className=" w-full">
                  <TextInput
                    type="email"
                    label="Email Address"
                    isRequired
                    value={email || ""}
                    disabled
                    // className="!bg-[#E4EBF5]"
                    placeholder="Enter email address"
                  />
                </div>
                <div className=" w-full">
                  <TextInput
                    type={isPasswordVisible ? "text" : "password"}
                    label="Password"
                    name="password"
                    value={users.password}
                    onTogglePasswordVisibility={onTogglePasswordVisibility}
                    placeholder="Enter password"
                    isPasswordVisible={isPasswordVisible}
                    isPassword
                    onChange={handleChange}
                    isRegister
                  />
                  {formErrors.password && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.password}
                    </span>
                  )}
                </div>
                <div className=" w-full">
                  <TextInput
                    label="Confirm Password"
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    name="confirmPassword"
                    isPasswordVisible={isConfirmPasswordVisible}
                    onChange={handleChange}
                    isPassword
                    onTogglePasswordVisibility={
                      onToggleConfirmPasswordVisibility
                    }
                    value={users.confirmPassword}
                    placeholder="Confirm password"
                  />
                  {formErrors.confirmPassword && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.confirmPassword}
                    </span>
                  )}
                </div>
                <div className=" w-full">
                  <TextInput
                    type={"text"}
                    name="referralCode"
                    label="Referral Code"
                    disabled={referralCode!==null}
                    onChange={handleChange}
                    value={users.referralCode}
                    placeholder="Enter referral code"
                  />
                </div>
              </div>
              <div className=" mt-[18px]">
                <Button
                  title="Sign Up"
                  type="submit"
                  loading={isLoading}
                  className="!py-3"
                  icon={<FaArrowRightLong className="text-white" />}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default BusinessRegister;
