import React from 'react'

const ShipmentProgress = ({handleClick, add, upload = "add-gradient", payment = "#e0f4dc"}: any) => {
  return (
    <div className="flex items-center justify-between">
        <div onClick={handleClick} className="flex gap-1 cursor-pointer w-[100px]">
            <div> <img className="w-3 h-3" src="/images/backArrow.svg" alt="" /></div>
            <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">Go back</p>
        </div>
        <div className="flex gap-2.5">
            <div className="">
                <div className={`w-[82px] h-[6px] bg-${add} rounded-xl`}></div>
                <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">Add</p>
            </div>
            <div>
                <div className={`w-[82px] h-[6px] bg-${upload} rounded-xl`}></div>
                <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">Upload</p>
            </div>
            <div>
                <div className={`w-[82px] h-[6px] bg-${payment} rounded-xl`}></div>
                <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">Payment</p>
            </div>
        </div>
    </div>
  )
}

export default ShipmentProgress