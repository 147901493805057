import { useState, useEffect } from "react";
import { useGetTicketMessageQuery } from "services/slices/ticket";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { uniqBy } from "lodash";

const useFetchAllPages = (id: string) => {
  const [allMessages, setAllMessages] = useState<any[]>([]);
  const [isLoadingAll, setIsLoadingAll] = useState(true);
  const [errorAll, setErrorAll] = useState<
    FetchBaseQueryError | SerializedError | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  const { data, error } = useGetTicketMessageQuery(
    { id, page: currentPage },
    { skip: !id }
  );

  useEffect(() => {
    if (!id) return;

    if (currentPage > totalPages) {
      setIsLoadingAll(false);
      return;
    }

    if (data) {
      setAllMessages((prevMessages) =>
        uniqBy([...prevMessages, ...(data?.Message?.data || [])], "id")
      );
      setTotalPages(data?.last_page || 1);
      setCurrentPage((prevPage) => prevPage + 1);
    }

    if (error) {
      setErrorAll(error);
      setIsLoadingAll(false);
    }
  }, [data, error, currentPage, totalPages, id]);

  return { allMessages, isLoadingAll, errorAll };
};

export default useFetchAllPages;
