import { Button } from "components/Button";
import ModalContainer from "components/Modal";
import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { TextArea } from "components/common/form/textArea";
import Card from "components/dashboard/Card";
import { handleErrorToast } from "helpers/toastMessage";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useCreateTicketMutation,
  useGetTicketSubjectsQuery,
} from "services/slices/ticket";

const initialValues = {
  orderNumber: "",
  subject: "",
  message: "",
};

function CreateTicket() {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<any>({});
  const [ticketDetails, setTicketDetails] = useState(initialValues);
  const [subjectName, setSubjectName] = useState<string>("");
  const [addTicketSuccess, setAddTicketSuccess] = useState<boolean>(false);
  const [searchSubject, setSearchSubject] = useState<string>("");
  const {
    data,
    error: dataError,
    isLoading: isSubjectLoading,
  } = useGetTicketSubjectsQuery();
  const [createTicket, { isLoading, data: ticketData, error }] =
    useCreateTicketMutation();

    const [searchParams] = useSearchParams(); 
    const id = searchParams.get('id');  

  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.orderNumber || values.orderNumber === "") {
      errors.orderNumber = "Order ID is required";
    } else if (!values.subject || values.subject === "") {
      errors.subject = "Subject is required";
    } else if (!values.message || values.message === "") {
      errors.message = "Message is required";
    }
    return errors;
  };

  const handleErrorChange = (name: string, value: any) => {
    if (name === "orderNumber" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        orderNumber: "",
      }));
    } else if (name === "subject" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        subject: "",
      }));
    } else if (name === "message" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        message: "",
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateStepOne(ticketDetails));
    if (
      ticketDetails.message !== "" &&
      ticketDetails.message !== undefined &&
      ticketDetails.subject !== undefined &&
      ticketDetails.orderNumber !== undefined &&
      ticketDetails.subject !== "" &&
      ticketDetails.orderNumber !== ""
    ) {
      await createTicket({
        subjectId: ticketDetails.subject,
        message: ticketDetails.message,
        orderNo: ticketDetails.orderNumber,
      })
        .unwrap()
        .then((data: any) => {
          setAddTicketSuccess(true);
        })
        .catch((e: any) => {
          handleErrorToast(e)

        });
    }
  };
  const filteredSubjectSearch: any = data?.Subjects?.filter((item: any) =>
    item?.Subjects?.toLowerCase()?.includes(searchSubject.toLowerCase())
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setTicketDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    handleErrorChange(name, value);
  };

  useEffect(() => {
    setTicketDetails((prev) => ({
      ...prev,
      orderNumber: id || "",
    }));
  }, [id]);


  return (
    <div className="mt-12 md:mt-0">
      <Card>
        <h1 className="text-2xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
          Create a ticket
        </h1>
        <p className="text-[#464748] text-base font-[500] font-onestMedium w-[90%] mt-3">
          Let’s help you get this sorted out
        </p>
        <form className="mt-10" onSubmit={handleSubmit}>
          <div>
            <TextInput
              type="text"
              label="Order ID"
              value={ticketDetails.orderNumber}
              placeholder="Enter order ID"
              onChange={handleChange}
              name="orderNumber"
              isRequired
            />
            {formErrors.orderNumber && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.orderNumber}
              </span>
            )}
          </div>
          <div className="mt-10">
            <div className="flex">
              <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                Select a Subject
              </p>
              <span className="text-red-500 text-sm">*</span>
            </div>
            <Dropdown
              dropdown={
                <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] p-2 justify-between ">
                  <div className="flex items-center space-x-1">
                    <i className="ri-map-pin-line text-sm text-g-75 "></i>
                    <span
                      className={` ${
                        ticketDetails?.subject === ""
                          ? "text-[#9899A1]"
                          : "text-[#000]"
                      }  font-onestMedium text-sm`}
                    >
                      {ticketDetails?.subject === "" ||
                      ticketDetails?.subject === undefined
                        ? "Select Subject"
                        : subjectName}
                    </span>
                  </div>

                  <img
                    src={"/images/chevronDown.svg"}
                    alt="drop-down"
                    className="mt-0.5 pr-2 cursor-pointer"
                  />
                </div>
              }
              search={
                <div>
                  <TextInput
                    value={searchSubject}
                    name={"search"}
                    type={"text"}
                    placeholder="Search"
                    onChange={(e) => setSearchSubject(e.target.value)}
                  />
                </div>
              }
              dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
              dropdownOptions={[
                {
                  customChild: (
                    <div>
                      <div>
                        {filteredSubjectSearch?.map(
                          (item: any, idx: number) => {
                            return (
                              <div
                                key={idx}
                                onClick={() => {
                                  setTicketDetails((prev) => ({
                                    ...prev,
                                    subject: item.id,
                                  }));
                                  setSubjectName(item?.Subjects);
                                  setFormErrors((prev: any) => ({
                                    ...prev,
                                    subject: "",
                                  }));
                                }}
                              >
                                <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                  <p>{item?.Subjects}</p>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ),
                },
              ]}
            />
            {formErrors.subject && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.subject}
              </span>
            )}
          </div>
          <div className="mt-10">
            <TextArea
              label="Tell us about this"
              placeholder="Enter a subject e.g failed delivery"
              onChange={handleChange}
              name="message"
              isRequired
            />
            {formErrors.message && (
              <span className="text-xs text-[#BA3838] font-onestMedium">
                {formErrors.message}
              </span>
            )}
          </div>
          <div className=" mt-10">
            <Button
              title="Submit"
              disabled={isLoading}
              loading={isLoading}
              className={`!py-3 ${
                ticketDetails.message !== "" &&
                ticketDetails.orderNumber !== "" &&
                ticketDetails.subject
                  ? "!bg-[#18AF04]"
                  : "!bg-[#8C9D8A]"
              } `}
              type="submit"
            />
          </div>
        </form>
      </Card>
      <ModalContainer
        open={addTicketSuccess}
        showCloseIcon={false}
        tailwindClassName="w-[350px] sm:w-[466px]"
        closeModal={() => {
          setTicketDetails(initialValues);
          setAddTicketSuccess(false);
        }}
      >
        <div className="w-full px-8 pb-8 bg-white flex flex-col  rounded-[12px] relative">
          <p className="text-lg pb-3 mt-6 border-b border-[#E4EBF5] text-center font-onestBold text-[#0A5001] ">
            Yippee!! 💃🏾
          </p>
          <img
            className="w-6 h-6 absolute right-6 top-6 cursor-pointer"
            onClick={() => {}}
            src="/images/close.svg"
            alt=""
          />
          <div className="flex justify-center mt-8">
            {" "}
            <img
              src="/images/fezbox.svg"
              alt=""
              className="w-[123px] h-[98px]"
            />
          </div>
          <p className="text-center text-[#4A564D] text-sm font-onestRegular mt-8">
            Your ticket has been created!
          </p>
          <div className="flex justify-center mt-14">
            <Button
              title="Close"
              onClick={() => {
                setTicketDetails(initialValues);
                setAddTicketSuccess(false);
                navigate("/dashboard/ticket");
              }}
              className="!w-[210px] py-3 bg-[#18AF04] rounded-lg !font-[500] !text-base"
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
}

export default CreateTicket;
