import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface Client {
  client_id: string;
}

interface Webhook {
  client_id: string;
  webhook: string;
}

export const developers = createApi({
  reducerPath: "developers",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    clientApiKey: builder.mutation<any, Client>({
      query: (body) => ({
        url: `client/key`,
        method: "POST",
        body,
      }),
    }),
    updateWebhook: builder.mutation<any, Webhook>({
      query: (body) => ({
        url: `webhooks/store`,
        method: "POST",
        body,
      }),
    }),
    getClientKey: builder.query<any, void>({
      query: (body) => ({
        url: `client/key`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useClientApiKeyMutation,
  useGetClientKeyQuery,
  useUpdateWebhookMutation,
} = developers;
