import React, { useEffect, useState } from "react";
import ShipmentProgress from "../ShipmentProgress";
import { IoIosClose } from "react-icons/io";
import { TextInput } from "components/common/form/Input";
import Dropdown from "components/common/form/SelectDropdown";
import { formatMoney } from "helpers/formatMoney";
import { Button } from "components/Button";
import { shipmentNavTabTitle } from "constant/data";
import { FaCircle } from "react-icons/fa6";
import { TextArea } from "components/common/form/textArea";
import {
  ExportEditableState,
  ExportState,
  ExportUser,
  User,
} from "pages/dashboard/ship-now/BulkShipment";
import { HiOutlineChevronDown } from "react-icons/hi";
import { DropDownSelect } from "components/common/form/Dropdown";

interface TargetEvent {
  target: {
    name: string;
    value: string;
  };
}
export interface ItemWeight {
  id: number;
  weights_id: string;
  intlocations_id: string;
  weight: ExportState;
}

interface Props {
  setIsBulkUpload: any;
  setBulkTableData: any;
  users: ExportUser[];
  result: any;
  handleGoToError: () => void;
  tableContainerRef: React.RefObject<HTMLDivElement>;
  editable: ExportEditableState;
  errorRefs: React.MutableRefObject<{
    [key: string]: HTMLTableCellElement | null;
  }>;
  handleChange: (id: number, field: keyof ExportUser, value: string) => void;
  formErrors: any;
  handleEditClick: any;
  handleBlur: any;
  setSearchCountryState: React.Dispatch<React.SetStateAction<string>>;
  searchCountryState: string;
  filteredExportCountrySearch: any;
  setFormErrors: React.Dispatch<React.SetStateAction<any>>;
  searchPickUpZone: string;
  setSearchPickUpZone: React.Dispatch<React.SetStateAction<string>>;
  filteredPickUpZoneSearch: any;
  onGenerate: () => void;
  setShowPayment: any;
  handleClick: () => void;
  isEditOrder: boolean;
  onEditOrder: (e: any) => void;
  handleDelete: (id: number) => void;
  setIsEditOrder: React.Dispatch<React.SetStateAction<boolean>>;
  editDetails: User | any;
  setEditDetails: React.Dispatch<React.SetStateAction<ExportUser | any>>;
  setSelectedWeightOption: React.Dispatch<React.SetStateAction<any>>;
  noPayment: boolean;
  loading: boolean;
  weightLoading: boolean;
  selectedWeightOption: any;
  handleDeliveryChange: (e: TargetEvent, field: string) => void;
}

const BulkExportUpload = ({
  setIsBulkUpload,
  setBulkTableData,
  users,
  result,
  handleGoToError,
  tableContainerRef,
  editable,
  errorRefs,
  setEditDetails,
  handleChange,
  formErrors,
  handleEditClick,
  handleBlur,
  filteredExportCountrySearch,
  setFormErrors,
  searchPickUpZone,
  setSearchPickUpZone,
  filteredPickUpZoneSearch,
  onGenerate,
  setShowPayment,
  handleClick,
  isEditOrder,
  onEditOrder,
  handleDelete,
  setIsEditOrder,
  editDetails,
  noPayment,
  handleDeliveryChange,
  loading,
  setSearchCountryState,
  searchCountryState,

  selectedWeightOption,
  setSelectedWeightOption,
  weightLoading,
}: Props) => {
  const [weightIsOpen, setWeightIsOpen] = useState<boolean>(false);
  const [weightData, setWeightData] = useState<ExportState[]>([]);

  useEffect(() => {
    if (editDetails) {
      const searchedCountry = filteredExportCountrySearch?.find(
        (data: ExportState) => data?.name === editDetails?.deliveryCountry
      );
      const weights = searchedCountry?.int_price?.map(
        (item: ItemWeight) => item.weight
      );
      setWeightData(weights);
    }
  }, [editDetails]);
  return (
    <div>
      <div className="hidden    md:block mx-5 pb-[100px]">
        <div className="mt-7 relative pb-14 sm:mt-[72px] rounded-lg max-w-screen-lg mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
          <div className="w-full px-4 sm:px-10 py-6 sm:py-8 onestBold">
            <ShipmentProgress
              handleClick={() => {
                setIsBulkUpload(true);
                setBulkTableData(false);
              }}
              add={`add-gradient`}
              upload="add-gradient"
              payment="[#e0f4dc]"
            />
            <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
              Your bulk shipments
            </h1>
            <p className="text-[#4a564d] text-[13px] font-[500]">
              International - Sending from Nigeria (Export)
            </p>

            <div className="bg-[#F9F9FB] pb-4 mt-10 border-[1px] border-[#E4EBF5] rounded-lg">
              <div className="flex  bg-[#F9F9FB] justify-between  pt-4  ">
                <div className="flex text-xs text-[#4A564D] font-[600] gap-3 mb-3 ml-2">
                  <p className="">{users.length} shipments uploaded</p>
                  <div className="flex items-center gap-1">
                    <img src="/images/check-mark.svg" alt="" />
                    <p className="text-[#18AF04]">
                      {result.rowValidCount} correct shipment
                    </p>
                  </div>
                  <div
                    className="flex items-center gap-0 cursor-pointer"
                    onClick={handleGoToError}
                  >
                    <IoIosClose color={"#F64108"} size={18} />
                    <p className="text-[#F64108]">
                      {result.rowInvalidCount} incorrect shipment
                    </p>
                  </div>
                </div>

                <p className="text-xs text-[#4A564D] font-[600] flex gap-1.5 mr-[50px] ">
                  <span className="text-[#929DAE]"> Scroll right </span>
                  <div className="mt-.05">
                    {" "}
                    <img src="/images/forward-arrow.svg" alt="" />
                  </div>
                </p>
              </div>
              <div
                ref={tableContainerRef}
                className="overflow-auto  h-[550px]  relative"
              >
                <table className="whitespace-nowrap absolute overflow-y-auto text-left  border-spacing-2 rounded-2xl w-full shadow-[0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)]">
                  <thead className="bg-white !mx-12 sticky top-0 z-10 border-b-[1px] border-[#E4EBF5]">
                    <tr className="">
                      <th className="py-3 pl-4 pr-4 border-r-[1px] border-[#E4EBF5] text-xs text-[#929DAE] font-onestRegular">
                        Name of Recipient
                      </th>
                      <th className="py-3 text-xs pl-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Delivery Address
                      </th>
                      <th className="py-3 text-xs pl-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        City
                      </th>
                      <th className="py-3 text-xs pl-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Zip code
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Destination Country
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Recipient State
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Recipient Phone
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Alternate Phone number
                      </th>
                      <th className="py-3 text-xs pl-4 pr-20 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Recipient Email
                      </th>
                      <th className="py-3 text-xs pl-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Pickup Address
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Pickup Zone
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Pickup Phone number
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4  text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Value of Item
                      </th>
                      <th className="py-3 text-xs pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Cost
                      </th>
                      <th className="py-3 text-xs pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Discounted Cost
                      </th>
                      <th className="py-3 text-xs w-[400px] pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Item weight
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Quantity
                      </th>
                      <th className="py-3 text-xs pl-4 pr-4 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Additional Information
                      </th>
                      <th className="py-3 sticky right-0 text-xs bg-white pl-4 pr-10 text-[#929DAE] border-l-[1px] border-[#E4EBF5] font-onestRegular">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="overflow-y-auto max-h-[500px]">
                    {users.map((item: any, idx: number) => {
                      const searchedCountry = filteredExportCountrySearch?.find(
                        (data: ExportState) =>
                          data?.name === item?.deliveryCountry
                      );
                      const weights = searchedCountry?.int_price
                        ?.map((item: ItemWeight) => item.weight)
                        ?.sort(
                          (a: ExportState, b: ExportState) =>
                            parseFloat(a.name) - parseFloat(b.name)
                        );

                      return (
                        <tr
                          key={item.id}
                          className="text-sm text-[#161718]  w-full font-[400] border-b-[1px] border-[#E4EBF5]"
                        >
                          <td
                            ref={(el) => {
                              if (!item.nameValid) errorRefs.current.name = el;
                            }}
                            className={`${
                              item.nameValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[600]"
                            } py-5 text-xs pl-3 pr-6 border-l-[#fff]  border-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "name" ? (
                              <>
                                <input
                                  name="name"
                                  value={item.name}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                  // onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.name && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.name}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.name}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "name")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.addressValid)
                                errorRefs.current.address = el;
                            }}
                            className={` !w-[350px] whitespace-normal ${
                              item.addressValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            } py-6 pl-4 flex gap-x-1.5 pr-4 border-r-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "address" ? (
                              <>
                                <input
                                  name="address"
                                  value={item.address}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "address",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.address && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.address}
                                  </span>
                                )}
                              </>
                            ) : (
                              <div className="flex gap-1">
                                <span className="text-xs break-all">
                                  {item.address}
                                </span>
                                <img
                                  className="w-2.5 h-2.5 cursor-pointer"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "address")
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.deliveryCityValid)
                                errorRefs.current.deliveryCity = el;
                            }}
                            className={`!w-[200px] ${
                              item.deliveryCityValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[900]"
                            } py-4 pl-4 text-sx border-l-[#fff] border-[1px] border-[#E4EBF5] px-4`}
                          >
                            {editable.id === item.id &&
                            editable.field === "deliveryCity" ? (
                              <>
                                <input
                                  value={item.deliveryCity}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "deliveryCity",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.deliveryCity && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.deliveryCity}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.deliveryCity}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "deliveryCity")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.zipCodeValid)
                                errorRefs.current.zipCode = el;
                            }}
                            className={` !w-[200px] whitespace-normal ${
                              item.zipCodeValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            } py-6 pl-4 flex gap-x-1.5 pr-4 border-r-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "zipCode" ? (
                              <>
                                <input
                                  name="zipCode"
                                  value={item.zipCode}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "zipCode",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.zipCode && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.zipCode}
                                  </span>
                                )}
                              </>
                            ) : (
                              <div className="flex gap-1">
                                <span className="text-xs break-all">
                                  {item.zipCode}
                                </span>
                                <img
                                  className="w-2.5 h-2.5 cursor-pointer"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "zipCode")
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.deliveryCountryValid)
                                errorRefs.current.deliveryCountry = el;
                            }}
                            className={` !w-[500px] ${
                              item.deliveryCountryValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[900]"
                            } py-4 pl-4 text-sx border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px] px-4`}
                          >
                            {editable.id === item.id &&
                            editable.field === "deliveryCountry" ? (
                              <div className="absolute -mt-5">
                                <Dropdown
                                  dropdown={
                                    <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                      <div className="flex items-center space-x-1">
                                        <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                        <span
                                          className={` ${
                                            item.deliveryCountry === ""
                                              ? "text-[#9899A1]"
                                              : "text-[#000]"
                                          }  font-onestMedium text-sm`}
                                        >
                                          {item.deliveryCountry === ""
                                            ? "Select country"
                                            : item.deliveryCountry}
                                        </span>
                                      </div>
                                      <HiOutlineChevronDown />
                                    </div>
                                  }
                                  search={
                                    <div>
                                      <TextInput
                                        value={searchCountryState}
                                        name={"search"}
                                        type={"text"}
                                        placeholder="Search"
                                        onChange={(e) =>
                                          setSearchCountryState(e.target.value)
                                        }
                                      />
                                    </div>
                                  }
                                  dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                                  dropdownOptions={[
                                    {
                                      customChild: (
                                        <div>
                                          <div>
                                            {filteredExportCountrySearch?.map(
                                              (stateItem: any, idx: number) => {
                                                return (
                                                  <div
                                                    key={idx}
                                                    onClick={() => {
                                                      setSearchCountryState("");
                                                      handleChange(
                                                        item.id,
                                                        "deliveryCountry",
                                                        stateItem.name
                                                      );

                                                      setFormErrors(
                                                        (prev: any) => ({
                                                          ...prev,
                                                          pickUpZone: "",
                                                        })
                                                      );
                                                    }}
                                                  >
                                                    <div className="px-3 py-2  text-sm font-onestMedium text-[#4a564d] hover:bg-[#edecec] cursor-pointer">
                                                      <p>{stateItem?.name}</p>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            ) : (
                              <div className="flex w-[200px] items-center">
                                <div className="">{item.deliveryCountry} </div>
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "deliveryCountry")
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.deliveryStateValid)
                                errorRefs.current.deliveryState = el;
                            }}
                            className={` !w-[350px] whitespace-normal ${
                              item.deliveryStateValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            } py-6 pl-4 flex gap-x-1.5 pr-4 border-r-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "deliveryState" ? (
                              <>
                                <input
                                  name="deliveryState"
                                  value={item.deliveryState}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "deliveryState",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.deliveryState && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.deliveryState}
                                  </span>
                                )}
                              </>
                            ) : (
                              <div className="flex gap-1">
                                <span className="text-xs break-all">
                                  {item.deliveryState}
                                </span>
                                <img
                                  className="w-2.5 h-2.5 cursor-pointer"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "deliveryState")
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.phoneValid)
                                errorRefs.current.phone = el;
                            }}
                            className={`${
                              item.phoneValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[900]"
                            } py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "phone" ? (
                              <>
                                <input
                                  value={item.phone}
                                  type="number"
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "phone",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.phone && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.phone}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.phone}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "phone")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.altPhoneValid)
                                errorRefs.current.altPhone = el;
                            }}
                            className={`bg-[#F9F9FB] py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "altPhone" ? (
                              <>
                                <input
                                  value={item.altPhone}
                                  type="number"
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "altPhone",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.altPhone && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.altPhone}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.altPhone}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "altPhone")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.emailValid)
                                errorRefs.current.email = el;
                            }}
                            className={`py-4 pl-4 pr-6 !w-[150px]  text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] ${
                              item.emailValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            }`}
                          >
                            {editable.id === item.id &&
                            editable.field === "email" ? (
                              <input
                                value={item.email}
                                onChange={(e) =>
                                  handleChange(item.id, "email", e.target.value)
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2"
                              />
                            ) : (
                              <>
                                {item.email}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "email")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.pickUpAddressValid)
                                errorRefs.current.pickUpAddress = el;
                            }}
                            className={` !w-[400px] whitespace-normal ${
                              item.pickUpAddressValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            } py-6 pl-4 flex gap-x-1.5 pr-4 border-r-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "pickUpAddress" ? (
                              <>
                                <input
                                  name="pickUpAddress"
                                  value={item.pickUpAddress}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "pickUpAddress",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] w-full border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.pickUpAddress && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.pickUpAddress}
                                  </span>
                                )}
                              </>
                            ) : (
                              <div className="flex gap-1">
                                <span className="text-xs break-all">
                                  {item.pickUpAddress}
                                </span>
                                <img
                                  className="w-2.5 h-2.5 cursor-pointer"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "pickUpAddress")
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.pickUpZoneValid)
                                errorRefs.current.zone = el;
                            }}
                            className={` !w-[900px] ${
                              item.pickUpZoneValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[900]"
                            } py-4 pl-4 text-sx border-l-[#fff] border-[1px] border-[#E4EBF5] px-4`}
                          >
                            {editable.id === item.id &&
                            editable.field === "pickUpZone" ? (
                              <div className="absolute -mt-5">
                                <Dropdown
                                  dropdown={
                                    <div className="flex border rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                      <div className="flex items-center space-x-1">
                                        <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                        <span
                                          className={` ${
                                            item.pickUpZone === ""
                                              ? "text-[#9899A1]"
                                              : "text-[#000]"
                                          }  font-onestMedium text-sm`}
                                        >
                                          {item.pickUpZone === "" ||
                                          item.pickUpZone === undefined
                                            ? "Select Pick-up state"
                                            : item.pickUpZone}
                                        </span>
                                      </div>

                                      <img
                                        src={"/images/chevronDown.svg"}
                                        alt="drop-down"
                                        className="mt-0.5 pr-2 cursor-pointer"
                                      />
                                    </div>
                                  }
                                  search={
                                    <div>
                                      <TextInput
                                        value={searchPickUpZone}
                                        name={"search"}
                                        type={"text"}
                                        placeholder="Search"
                                        onChange={(e) => {
                                          setSearchPickUpZone(e.target.value);
                                        }}
                                      />
                                    </div>
                                  }
                                  dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                                  dropdownOptions={[
                                    {
                                      customChild: (
                                        <div>
                                          <div>
                                            {filteredPickUpZoneSearch?.map(
                                              (stateItem: any, idx: number) => {
                                                return (
                                                  <div
                                                    key={idx}
                                                    onClick={() => {
                                                      handleChange(
                                                        item.id,
                                                        "pickUpZone",
                                                        stateItem.state
                                                      );
                                                      setSearchPickUpZone("");
                                                      setFormErrors(
                                                        (prev: any) => ({
                                                          ...prev,
                                                          pickUpZone: "",
                                                        })
                                                      );
                                                    }}
                                                  >
                                                    <div className="px-3 py-2 text-sm font-onestMedium text-[#4a564d]  hover:bg-[#edecec] cursor-pointer">
                                                      <p>{stateItem?.state}</p>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            ) : (
                              <div className="flex w-[200px] items-center">
                                <div className="">{item.pickUpZone} </div>
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "pickUpZone")
                                  }
                                />
                              </div>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.pickUpPhoneValid)
                                errorRefs.current.pickUpPhone = el;
                            }}
                            className={`${
                              item.pickUpPhoneValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[900]"
                            } py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5] w-[300px]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "pickUpPhone" ? (
                              <>
                                <input
                                  value={item.pickUpPhone}
                                  type="number"
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "pickUpPhone",
                                      e.target.value
                                    )
                                  }
                                  onBlur={handleBlur}
                                  autoFocus
                                  className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                                />
                                {formErrors.pickUpPhone && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.pickUpPhone}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item.pickUpPhone}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "pickUpPhone")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.itemValueValid)
                                errorRefs.current.itemValue = el;
                            }}
                            className={`${
                              item.itemValueValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] font-[600]"
                            } py-5 text-xs pl-3 pr-6 border-l-[#fff]  border-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "itemValue" ? (
                              <input
                                value={item.itemValue}
                                type="number"
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "itemValue",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                              />
                            ) : (
                              <>
                                {item.itemValue}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "itemValue")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            ref={(el) => {
                              if (!item.costValid) errorRefs.current.cost = el;
                            }}
                            className={`${
                              item.cost > 0
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108] h-full font-[600]"
                            } py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]`}
                          >
                            <p>{formatMoney().format(item.cost)}</p>
                          </td>
                          <td
                            className={`bg-[#F9F9FB] py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]`}
                          >
                            <p>{formatMoney().format(item.discountedCost)}</p>
                          </td>
                          <td
                            className={`py-4 pl-2 !w-[350px]  text-xs border-l-[#fff]  ${
                              item.weightValid
                                ? "bg-[#F9F9FB]"
                                : "bg-[#ffeded] text-[#f64108]  h-full font-[600]"
                            }  border-[1px] border-[#E4EBF5]`}
                          >
                            {editable.id === item.id &&
                            editable.field === "weight" ? (
                              <DropDownSelect
                                lists={weights}
                                value={selectedWeightOption?.name}
                                itAbsolute
                                // loading={}
                                showcheckCountrySelected
                                checkCountrySelected={item.deliveryCountry}
                                isOpen={weightIsOpen}
                                toggleDropdown={() =>
                                  setWeightIsOpen((prev) => !prev)
                                }
                                handleOptionClick={(e: any) => {
                                  setSelectedWeightOption(e);
                                  handleChange(item.id, "weight", e.name);
                                  setWeightIsOpen(false);
                                  setFormErrors((prev: any) => ({
                                    ...prev,
                                    weight: "",
                                  }));
                                }}
                                placeholder="0"
                              />
                            ) : (
                              <>
                                {item.weight}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "weight")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td className="py-4   pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]">
                            {editable.id === item.id &&
                            editable.field === "quantity" ? (
                              <input
                                value={item.quantity}
                                type="number"
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                              />
                            ) : (
                              <>
                                {item.quantity}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "quantity")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td className="py-4 pl-4 text-xs border-l-[#fff] border-[1px] border-[#E4EBF5]">
                            {editable.id === item.id &&
                            editable.field === "additionalInfo" ? (
                              <input
                                value={item.additionalInfo}
                                onChange={(e) =>
                                  handleChange(
                                    item.id,
                                    "additionalInfo",
                                    e.target.value
                                  )
                                }
                                onBlur={handleBlur}
                                autoFocus
                                className="text-xs outline-none border-[1px] border-[#E4EBF5] py-2 mr-1"
                              />
                            ) : (
                              <>
                                {item.additionalInfo}{" "}
                                <img
                                  className="w-2.5 cursor-pointer inline h-2.5 mb-2"
                                  src="/images/edit.svg"
                                  alt=""
                                  onClick={() =>
                                    handleEditClick(item.id, "additionalInfo")
                                  }
                                />
                              </>
                            )}
                          </td>
                          <td
                            className="py-4 sticky right-0 cursor-pointer pl-5 bg-white w-[100%] text-xs text-red-500 border-r-[#fff] border-l-[#fff] border-[1px] border-[#E4EBF5]"
                            onClick={() => {
                              handleDelete(item.id);
                              if (users.length === 1 && idx === 0) {
                                setIsBulkUpload(false);
                                setBulkTableData(false);
                                setIsBulkUpload(false);
                              }
                            }}
                          >
                            {item.action}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex-col space-y-3  items-center left-[350px] right-0 px-5 shadow-faintShadow border-t justify-between  py-5 border-slate-100 bottom-[0px] w-full fixed z-[50]   bg-white">
              <div className="flex items-center space-x-6">
                <div className="w-fit">
                  <Button
                    className="py-2.5 "
                    title="Make payment"
                    onClick={() => {
                      if (!noPayment) {
                        onGenerate();
                        setShowPayment(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isEditOrder ? (
        <div className="block overflow-x-hidden md:hidden">
          <>
            <div
              onClick={() => {
                setIsBulkUpload(true);
                setBulkTableData(false);
              }}
              className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-fit md:hidden"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Go back
              </p>
            </div>

            <div className="mt-2 mb-[140px] sm:mt-[72px] rounded-lg px-4 md:px-10 pt-4 pb-8 md:py-8 max-w-[38rem] mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
              <div className="w-full">
                <div className="hidden md:flex items-center justify-between">
                  <div
                    onClick={handleClick}
                    className="flex gap-1 cursor-pointer w-[100px]"
                  >
                    <div>
                      <img
                        className="w-3 h-3"
                        src="/images/backArrow.svg"
                        alt=""
                      />
                    </div>
                    <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                      Go back
                    </p>
                  </div>
                  <div className="flex gap-2.5">
                    {shipmentNavTabTitle?.map((title: { name: string }) => (
                      <div className="">
                        <div
                          className={`w-[82px] h-[6px] bg-add-gradient rounded-xl`}
                        ></div>
                        <p className="text-[11px] flex justify-end font-[500] text-[#68726B] font-onestMedium">
                          {title.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex md:hidden items-center justify-between mt-4 mb-6 mx-4">
                  {shipmentNavTabTitle?.map(
                    (title: { name: string; id: number }) => (
                      <>
                        {title.id !== 1 && (
                          <div className="w-full h-0.5 bg-[#D4F3D5]"></div>
                        )}
                        <div className="relative">
                          <FaCircle color="#18AF04" />
                          <p className="absolute mt-1 text-[#68726B] font-[500] -left-3 text-[12px] font-onestMedium w-[100px]">
                            {title.name}
                          </p>
                        </div>
                      </>
                    )
                  )}
                </div>
                <h1 className="text-2xl mt-10 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  Your bulk shipments
                </h1>
                <p className="mt-3 text-[#4a564d] text-[14px] font-onestMedium font-[500]">
                  International - Sending from Nigeria (Export)
                </p>
                <div className="flex justify-between mt-8">
                  <div className="flex text-xs text-[#4A564D] font-[600] gap-3 mb-3 ml-2">
                    <p className="">{users.length} shipments uploaded</p>
                    <div className="flex items-center gap-1">
                      <img src="/images/check-mark.svg" alt="" />
                      <p className="text-[#18AF04]">{result.rowValidCount}</p>
                    </div>
                    <div className="flex items-center gap-0">
                      <IoIosClose color={"#F64108"} size={18} />
                      <p className="text-[#F64108]">{result.rowInvalidCount}</p>
                    </div>
                  </div>
                </div>
                <div>
                  {users.map((user: any, idx: number) => {
                    const valid =
                      user.nameValid &&
                      user.addressValid &&
                      user.pickUpAddressValid &&
                      user.pickUpZoneValid &&
                      user.phoneValid &&
                      user.emailValid &&
                      user.costValid &&
                      user.weightValid &&
                      user.itemValueValid &&
                      user.deliveryCountryValid &&
                      user.zipCodeValid &&
                      user.pickUpPhoneValid;

                    return (
                      <div
                        key={user.id}
                        className="text-sm  text-[#161718] w-full font-[400]"
                      >
                        <div
                          className={`flex justify-between w-full border-[1px] ${
                            Boolean(valid)
                              ? "border-[#E4EBF5]"
                              : "border-[#F64108]"
                          } py-3 px-4 mb-3 rounded-lg`}
                        >
                          <div>
                            <h1 className="text-[#071227] text-sm font-onestMedium font-[500] mb-1.5">
                              {user.name}
                            </h1>
                            <div className="flex items-center">
                              <div
                                className="text-[#18AF04] text-xs font-onestRegular"
                                onClick={() => onEditOrder(user)}
                              >
                                Edit order
                              </div>
                              <FaCircle
                                color="#d1d1d2"
                                size={6}
                                className="bg-[#d1d1d2] mx-2 rounded-lg"
                              />
                              <p
                                onClick={() => {
                                  handleDelete(user.id);

                                  if (users.length === 1 && idx === 0) {
                                    setIsBulkUpload(false);
                                    setBulkTableData(false);
                                    setIsBulkUpload(false);
                                  }
                                }}
                                className="ml-1 text-[#F64108] text-xs font-onestRegular"
                              >
                                Delete
                              </p>
                            </div>
                          </div>
                          <p className="text-[#071527] font-onestMedium text-[14px] font-[500]">
                            ₦ {formatMoney().format(user.cost)}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex-col space-y-3 items-center left-0 right-0 px-5 shadow-faintShadow border-t justify-between  py-5 border-slate-100 bottom-[0px] w-full fixed z-[50]   bg-white">
                  <Button
                    className="py-2.5 w-full"
                    title="Make payment"
                    loading={loading}
                    disabled={loading}
                    onClick={() => {
                      if (!noPayment && !loading) {
                        onGenerate();
                        setShowPayment(true);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      ) : (
        <div className="block overflow-x-hidden md:hidden">
          <>
            <div
              onClick={() => setIsEditOrder(false)}
              className="flex gap-1 mt-14 md:mt-0 ml-4 cursor-pointer w-[100px] md:hidden"
            >
              <div>
                {" "}
                <img className="w-3 h-3" src="/images/backArrow.svg" alt="" />
              </div>
              <p className="text-[#4a564d] font-[500] text-[13px] -mt-1 font-onestMedium">
                Cancel
              </p>
            </div>
            <div className="mt-2 mb-[140px] sm:mt-[72px] rounded-lg px-4 md:px-10 pt-4 pb-8 md:py-8 max-w-[38rem] mx-4 sm:mx-auto border-[1px] border-[#E1F0DF] flex bg-dashboard-gradient justify-center">
              <div className="w-full">
                <h1 className="text-2xl mt-6 font-onestBold font-[800] text-[#0a5001] tracking-wide">
                  Edit order
                </h1>
                <p className="mt-2 text-[#A0ABA3] text-[16px] font-onestMedium font-[500]">
                  Make changes to this order
                </p>

                <>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Recipient name"
                      isRequired
                      value={editDetails?.name}
                      name="name"
                      isError={editDetails.nameValid}
                      placeholder="Enter name"
                      onChange={(e: any) => handleDeliveryChange(e, "name")}
                    />
                  </div>

                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Recipient address"
                      isRequired
                      name="dropOffAddress"
                      isError={editDetails.addressValid}
                      value={editDetails.address}
                      onChange={(e: any) => handleDeliveryChange(e, "address")}
                      placeholder="Enter recipient address"
                    />
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Recipient City"
                      isRequired
                      name="city"
                      isError={editDetails.deliveryCityValid}
                      value={editDetails.deliveryCity}
                      onChange={(e: any) =>
                        handleDeliveryChange(e, "deliveryCity")
                      }
                      placeholder="Enter recipient city"
                    />
                  </div>
                  <div className="mt-6">
                    <TextInput
                      type="text"
                      label="Zip Code"
                      isRequired
                      name="zipCode"
                      isError={editDetails.zipCodeValid}
                      value={editDetails.zipCode}
                      onChange={(e: any) => handleDeliveryChange(e, "zipCode")}
                      placeholder="Enter zip code"
                    />
                  </div>
                  <div className="mt-6">
                    <div className="flex">
                      <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                        Destination Country
                      </p>
                      <span className="text-red-500 text-sm">*</span>
                    </div>

                    <Dropdown
                      dropdown={
                        <div
                          className={`flex border ${
                            !editDetails.deliveryCountryValid
                              ? "border-[#BA3838]"
                              : "border-[#E4EBF5]"
                          } rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-2 justify-between `}
                        >
                          <div className="flex items-center space-x-1">
                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                            <span
                              className={` ${
                                editDetails.deliveryCountry === ""
                                  ? "text-[#9899A1]"
                                  : "text-[#000]"
                              }  font-onestMedium text-sm`}
                            >
                              {editDetails.deliveryCountry === ""
                                ? "Select country"
                                : editDetails.deliveryCountry}
                            </span>
                          </div>
                          <HiOutlineChevronDown />
                        </div>
                      }
                      search={
                        <div>
                          <TextInput
                            value={searchCountryState}
                            name={"search"}
                            type={"text"}
                            placeholder="Search"
                            onChange={(e) =>
                              setSearchCountryState(e.target.value)
                            }
                          />
                        </div>
                      }
                      dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                      dropdownOptions={[
                        {
                          customChild: (
                            <div>
                              <div>
                                {filteredExportCountrySearch?.map(
                                  (stateItem: any, idx: number) => {
                                    return (
                                      <div
                                        key={idx}
                                        onClick={() => {
                                          setSearchCountryState("");
                                          handleDeliveryChange(
                                            {
                                              target: {
                                                name: "deliveryCountry",
                                                value: stateItem.name,
                                              },
                                            },
                                            "deliveryCountry"
                                          );

                                          setEditDetails((prev: any) => ({
                                            ...prev,
                                            zone: stateItem.name,
                                          }));
                                        }}
                                      >
                                        <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                          <p>{stateItem?.name}</p>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>

                  <div className="grid md:grid-cols-2 gap-x-3">
                    <div className="mt-6">
                      <TextInput
                        type="number"
                        label="Recipient phone number"
                        isRequired
                        name="recipientPhoneNumber"
                        value={editDetails.phone}
                        isError={editDetails.phoneValid}
                        placeholder="Enter phone number"
                        onChange={(e: any) => handleDeliveryChange(e, "phone")}
                      />
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="number"
                        label="Alternate phone number"
                        name="altPhone"
                        value={editDetails.altPhone}
                        isError={editDetails.altPhoneValid}
                        placeholder="Enter phone number"
                        onChange={(e: any) =>
                          handleDeliveryChange(e, "altPhone")
                        }
                      />
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="email"
                        isError={editDetails.emailValid}
                        label="Recipient email"
                        placeholder="Enter email"
                        name="recipientEmail"
                        onChange={(e: any) => handleDeliveryChange(e, "email")}
                        value={editDetails.email}
                      />
                      {formErrors.recipientEmail && (
                        <span className="text-xs text-[#BA3838] font-onestMedium">
                          {formErrors.recipientEmail}
                        </span>
                      )}
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="text"
                        label="Pick-up address"
                        isRequired
                        name="pickUpAddress"
                        isError={editDetails.pickUpAddressValid}
                        value={editDetails.pickUpAddress}
                        onChange={(e: any) =>
                          handleDeliveryChange(e, "pickUpAddress")
                        }
                        placeholder="Enter pick-up address"
                      />
                    </div>
                    <div className="mt-6">
                      <div className="flex">
                        <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestMedium">
                          Pick-up State
                        </p>
                        <span className="text-red-500 text-sm">*</span>
                      </div>
                      <Dropdown
                        dropdown={
                          <div
                            className={`flex border ${
                              !editDetails.pickUpZoneValid
                                ? "border-[#BA3838]"
                                : "border-[#E4EBF5]"
                            } rounded-[8px] items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between `}
                          >
                            <div className="flex items-center space-x-1">
                              <i className="ri-map-pin-line text-sm text-g-75 "></i>
                              <span
                                className={` ${
                                  editDetails?.pickUpZone === ""
                                    ? "text-[#9899A1]"
                                    : "text-[#000]"
                                }  font-onestMedium text-sm`}
                              >
                                {editDetails?.pickUpZone === "" ||
                                editDetails?.pickUpZone === undefined
                                  ? "Select state"
                                  : editDetails?.pickUpZone}
                              </span>
                            </div>

                            <img
                              src={"/images/chevronDown.svg"}
                              alt="drop-down"
                              className="mt-0.5 pr-2 cursor-pointer"
                            />
                          </div>
                        }
                        search={
                          <div>
                            <TextInput
                              value={searchPickUpZone}
                              name={"search"}
                              type={"text"}
                              placeholder="Search"
                              onChange={(e) =>
                                setSearchPickUpZone(e.target.value)
                              }
                            />
                          </div>
                        }
                        dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0  border border-slate-100  rounded `}
                        dropdownOptions={[
                          {
                            customChild: (
                              <div>
                                <div>
                                  {filteredPickUpZoneSearch?.map(
                                    (item: any, idx: number) => {
                                      return (
                                        <div
                                          key={idx}
                                          onClick={() => {
                                            handleDeliveryChange(
                                              {
                                                target: {
                                                  name: "pickUpZone",
                                                  value: item.state,
                                                },
                                              },
                                              "pickUpZone"
                                            );

                                            setEditDetails((prev: any) => ({
                                              ...prev,
                                              pickUpZone: item.state,
                                            }));
                                            setFormErrors((prev: any) => ({
                                              ...prev,
                                              state: "",
                                            }));
                                          }}
                                        >
                                          <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                            <p>{item?.state}</p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="number"
                        label="Pick-up phone number"
                        name="pickUpPhone"
                        value={editDetails.pickUpPhone}
                        isError={editDetails.pickUpPhoneValid}
                        placeholder="Enter pick-up phone number"
                        onChange={(e: any) =>
                          handleDeliveryChange(e, "pickUpPhone")
                        }
                      />
                    </div>
                    <div className="mt-6">
                      <TextInput
                        type="number"
                        label="Value of Item in Naira"
                        isRequired
                        name="itemValue"
                        value={editDetails.itemValue}
                        isError={editDetails.itemValueValid}
                        placeholder="Enter value"
                        onChange={(e: any) =>
                          handleDeliveryChange(e, "itemValue")
                        }
                      />
                    </div>
                    <div className="mt-6">
                      <p className="text-[#4A564D] text-sm leading-[20px] font-onestMedium">
                        Cost
                      </p>
                      <div
                        className={`flex py-2 mt-[8px]  pl-3  ${
                          editDetails.costValid
                            ? "border-[#E4EBF5]"
                            : "  border-[#BA3838]"
                        }
             border  rounded-[8px] `}
                      >
                        <p>₦{formatMoney().format(editDetails?.cost) || 0}</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6">
                      <div className="mt-6">
                        <TextInput
                          type="number"
                          label="Quantity"
                          placeholder="Enter quantity"
                          name="quantity"
                          isError={editDetails.quantityValid}
                          onChange={(e: any) =>
                            handleDeliveryChange(e, "quantity")
                          }
                          value={editDetails.quantity}
                        />
                      </div>
                      <div className="mt-6">
                        <div className="flex">
                          <p className="text-[#4A564D]  text-sm leading-[20px] font-onestMedium">
                            Weight
                          </p>
                          <span className="text-red-500 text-sm">*</span>
                        </div>
                        <DropDownSelect
                          className={
                            !editDetails.weightValid
                              ? "!border-[#BA3838]"
                              : "!border-[#E4EBF5]"
                          }
                          lists={weightData}
                          loading={weightLoading}
                          showcheckCountrySelected
                          checkCountrySelected={editDetails.deliveryCountry}
                          value={editDetails?.weight}
                          itAbsolute
                          isOpen={weightIsOpen}
                          toggleDropdown={() =>
                            setWeightIsOpen((prev) => !prev)
                          }
                          handleOptionClick={(e: any) => {
                            setSelectedWeightOption(e);
                            handleDeliveryChange(
                              {
                                target: {
                                  name: "weight",
                                  value: e.name,
                                },
                              },
                              "weight"
                            );
                            setWeightIsOpen(false);
                            setFormErrors((prev: any) => ({
                              ...prev,
                              weight: "",
                            }));
                          }}
                          placeholder="0"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <TextArea
                      label="Item description"
                      placeholder="Write a description"
                      name="itemDescription"
                      onChange={(e: any) =>
                        handleDeliveryChange(e, "description")
                      }
                      value={editDetails.description}
                    />
                  </div>
                </>
                <div className="mt-[32px] w-full">
                  <Button
                    className="!py-4 "
                    title="Update and save"
                    loading={loading}
                    disabled={loading}
                    onClick={() => {
                      const valid =
                        editDetails.nameValid &&
                        editDetails.addressValid &&
                        editDetails.pickUpAddressValid &&
                        editDetails.pickUpZoneValid &&
                        editDetails.phoneValid &&
                        editDetails.emailValid &&
                        editDetails.costValid &&
                        editDetails.weightValid &&
                        editDetails.itemValueValid &&
                        editDetails.deliveryCountryValid &&
                        editDetails.zipCodeValid &&
                        editDetails.pickUpPhoneValid;
                      if (Boolean(valid) && !loading) {
                        setIsEditOrder(false);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default BulkExportUpload;
