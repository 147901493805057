
export  const ORDERS_CHAIN = [
    {
        id: 1,
        title: "Start New Request",
        image: "/images/new-request.svg",
        url: "new-request",
        link: "ship-now"
    },
    {
        id: 2,
        title: "View Pending Orders",
        image: "/images/pending.svg",
        url: "pending-orders",
        link: "shipments"
    },
    {
        id: 3,
        title: "Top-up Wallet",
        image: "/images/top-wallet.svg",
        url: "wallet",
        link: "wallet"

    },
    {
        id: 4,
        title: "Raise a concern",
        image: "/images/open-ticket.svg",
        url: "Raise-concern",
        link: 'tickets/create-ticket'
    }
]
export const DELIVERY_NUMBERS = [
    {
        id: 1,
        title: "Pending pickup",
        name:"Pending Pick-Up",
        number: 91,
        image: "/images/pendingPickup.svg",
        color: "#FFF3C7",

    },
    {
        id: 2,
        name:"Picked-Up",
        title: "Picked up items",
        number: 14,
        image: "/images/pickedItem.svg",
        color: "#FFF3C7",
    },
    {
        id: 3,
        title: "Prepared for delivery",
        name:"Prepared For Delivery",
        number: 120,
        image: "/images/preparedDelivery.svg",
        color: "#ECFFC7",
    },
    {
        id: 4,
        title: "Delivered items",
        number: 3921,
        name:"Delivered",
        image: "/images/DItems.svg",
        color: "#CDFFC7",
    },
    {
        id: 5,
        title: "In transit back to you",
        number: 3,
        name:"In Transit Back to you",
        image: "/images/TransitBack.svg",
        color: "#C7F2FF",
    },

    {
        id: 6,
        title: "Returned items",
        number: 19,
        name:"Returned",
        
        image: "/images/returnedItems.svg",
        color: "#C7DFFF",
    },
    
    {
        id: 7,
        title: "Total shipment",
        number: 5,
        name:"",
        image: "/images/totalShipment.svg",
        color: "#CBC7FF",
    },
    // {
    //     id: 8,
    //     title: "Total unpaid",
    //     number: 4105,
    //     name:"unpaid",
    //     image: "/images/totalPaid.svg",
    //     color: "#B4F2AA",
    // },
    
    // {
    //     id: 9,
    //     title: "Total paid",
    //     number: 5,
    //     name:"paid",
    //     image: "/images/totalPaid.svg",
    //     color: "#FFD4C7",
    // },
]
