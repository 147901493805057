/* eslint-disable no-unused-vars */
import  { useEffect, useState } from "react";
import { getFromStorage } from "helpers";
import { useLocation, useNavigate } from "react-router-dom";

type PublicRoutesProps = {
  children: JSX.Element;
};

const PublicRoutes = ({ children }: PublicRoutesProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(() => getFromStorage("token"));
  const [secretKey, setSecretKey] = useState(() =>
    getFromStorage("secret-key")
  );

  const handleStorageChange = () => {
    setToken(getFromStorage("token"));
    setSecretKey(getFromStorage("secret-key"));
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/logout") {
      localStorage.removeItem("token");
      localStorage.removeItem("secret-key");
      navigate("/")
    }
  }, [location.pathname, navigate]);
  
  useEffect(() => {
    if (!token || !secretKey) {
      return;
    }

    if (token && !location.pathname.startsWith("/dashboard")) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return children;
};

export default PublicRoutes;
