import React from "react";

interface TextAreaProps {
  label?: string;
  placeholder?: string;
  labelFor?: string;
  type?: string;
  name?: string;
  value?: string;
  onChange?: any;
  onFocus?: any;
  isRequired?: boolean;
  disabled?: boolean;
}
export const TextArea = ({
  labelFor,
  placeholder,
  label,
  onChange,
  name,
  value,
  onFocus,
  isRequired,
  disabled
}: TextAreaProps) => {
  return (
    <div className="flex flex-col">
      <label
        htmlFor={labelFor}
        className="text-[#4A564D] items-center flex text-[14px] font-onestMedium"
      >
        {label}
        {isRequired && <span className="text-[#BA3838]">*</span>}
      </label>
      <textarea
        rows={4}
        name={name}
        value={value}
        onFocus={onFocus} 
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className="text-[#161718] p-2 text-[14px] mt-[8px] cursor-text border-[1px] outline-none border-[#e4ebf5] rounded-[8px] bg-transparent bg-opacity-20 placeholder-shown:text-grey w-full"
      />
    </div>
  );
};
