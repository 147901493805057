import React from 'react'

function EmptyShipmentTable() {
  return (
    <div className="text-center mt-2">
    <table className=" w-full bg-white text-left border-spacing-2 whitespace-nowrap">
      <thead className="bg-white !mx-12">
        <tr className="border-b-[1px] py-5  pl-4 pr-4 border-[#E4EBF5]">
          <th className="pl-3 py-8 ">
            <input
              type="checkbox"
              className="accent-[#289D17]"
            />
          </th>
          <th className="py-3 text-left text-xs pl-5 pr-10 text-[#929DAE] font-onestRegular">
            Order date
          </th>
          <th className="py-3 text-left text-xs  pl-5 pr-10 text-[#929DAE] font-onestRegular">
            Order ID
          </th>
          <th className="py-3 text-left text-xs  pl-5 pr-10 text-[#929DAE] font-onestRegular">
            Status
          </th>
          <th className="py-3 text-left  text-xs  pl-6 pr-10 text-[#929DAE] font-onestRegular">
            Recipient Name
          </th>
          <th className="py-3 text-left  text-xs  pl-6 pr-10 text-[#929DAE] font-onestRegular">
            Recipient Address
          </th>
          <th className="py-3 text-left  text-xs  pl-5 pr-10 text-[#929DAE] font-onestRegular">
            Recipient Phone
          </th>
          <th className="py-3 text-left  text-xs  pl-5 pr-10 text-[#929DAE] font-onestRegular">
            Recipient Email
          </th>
          <th className="py-3 text-left  text-xs  pl-6 pr-10 text-[#929DAE] font-onestRegular">
            Cost
          </th>
          <th className="py-3 text-left  text-xs  pl-4 pr-4 text-[#929DAE] font-onestRegular">
            Return Reason
          </th>
          <th className="py-3 text-left  text-xs  pl-6 pr-4 text-[#929DAE] font-onestRegular">
            Created By
          </th>
          <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
            Pick Up Date
          </th>
          <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
            Dispatch Date
          </th>
          <th className="py-3 text-left  text-xs  pl-5 pr-4 text-[#929DAE] font-onestRegular">
            Delivery Date
          </th>
          <th className="py-3 text-left  text-xs  pl-4 pr-8 text-[#929DAE] font-onestRegular">
            Additional Note
          </th>
          <th className="py-3 sticky right-0 border-l-[1px] pl-4 pr-4 border-[#E4EBF5] text-left bg-white text-xs text-[#929DAE] font-onestRegular w-[20%]">
            Actions
          </th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
    <div className="flex justify-center py-[72px] w-full">
      <div className="">
        <div className="flex justify-center pb-5 w-full">
          {" "}
          <img src="/images/new-request.svg" alt="" />
        </div>
        <p className="font-onestRegular mb-2 bmd:w-full text-sm text-[#4A564D]">
            You do not have any shipments yet
        </p>
        <p className="font-onestRegular w-[100%] bmd:w-full text-xs text-center text-[#A0ABA3]">
          When you make a shipment, it will show here
        </p>
      </div>
    </div>
  </div>
  )
}

export default EmptyShipmentTable