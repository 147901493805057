import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const getAllUsers = createApi({
  reducerPath: "getAllUsers",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllUsers: builder.query<any, any>({
      query: ({ find, page, size, sortBy }) => ({
        url: `/orgUsers${find ? "?find=" + find : "?find="}${
          page ? "&page=" + page : "&page="
        }${size ? "&size=" + size : "&size="}${sortBy ? "&sortBy=" + sortBy : "&sortBy="}`,
        method: "GET",
      }),
    }),
    getUserbyId: builder.query<any, any>({
      query: ({ id }) => ({
        url: `user/fetch/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllUsersQuery, useGetUserbyIdQuery } = getAllUsers;
