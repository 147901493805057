import React, { useEffect, useState } from "react";
import { Button } from "components/Button";
import DatePicker from "react-datepicker";
import moment from "moment";
import ModalContainer from "components/Modal";
import { useGetNotificationsQuery } from "services/slices/notification";
import PagePagination from "components/Pagination";
import { useNavigate } from "react-router";
import { filterTerms } from "constant/data";
import { Loader } from "components/Loader";
import { getDateRange } from "helpers/getDateRange";

interface User {
  userID: string | null;
  "Full Name": string | null;
  Username: string | null;
}

function Notification() {
  const [filterDate, setFilterDate] = useState(false);
  const [isSortUsers, setIsSortUsers] = useState(false);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [selectedPeriodName, setSelectedPeriodName] = useState("Last 7 days");
  const [selectedPeriod, setSelectedPeriod] = useState("last7days");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string>("");
    const [notificationEndDate, setNotificationEndDate] = useState<string>(
      moment().add(1, "days").format("YYYY-MM-DD")
    );
    const [notificationStartDate, setNotificationStartDate] = useState<string>(
      moment().subtract(7, "days").format("YYYY-MM-DD")
    );
  const [page, setPage] = useState(0);

  const usersDataString = localStorage.getItem("fez-user");

  const userData: User = usersDataString && JSON.parse(usersDataString);

  const id = userData?.userID;

  const {
    data: notificationData,
    isLoading,
  } = useGetNotificationsQuery(
    {
      id,
      startDate: notificationStartDate,
      endDate: notificationEndDate,
      page,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const navigate = useNavigate();

  const checkNotificationData = notificationData?.notifications?.data;

  useEffect(() => {}, [checkNotificationData]);

  const handleSortUsers = () => {
    setIsSortUsers(!isSortUsers);
  };

  const handleSortChange = (event: any) => {
    const { value, checked } = event.target;
  };

  const onClearDate = () => {
    setStartDate(null);
    setEndDate(null);
    setFormattedEndDate("");
    setFormattedDate("");
    setSelectedPeriodName("Last 7 days");
    setSelectedPeriod("last7days");
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setStartDate(date);
      setFormattedDate(dateStart);
    }
  };

  const handlePeriodChange = (value: string) => {
    setSelectedPeriod(value);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const dateStart = moment(date).format("DD-MM-YYYY");
      setEndDate(date);
      setFormattedEndDate(dateStart);
    }
  };

  const handleNavigation = (item: any) => {
    switch (item.action) {
      case "ticket.message":
        navigate(`/dashboard/ticket`);
        break;
      case "request.accept":
        navigate(`/dashboard/shipments`);
        break;
      case "wallet.topup":
        navigate(`/dashboard/wallet`);
        break;
      case "wallet.update":
        navigate(`/dashboard/wallet`);
        break;
      default:
        console.log("Unknown action:", item.action);
    }
  };

  useEffect(() => {
    if (Boolean(selectedPeriod.toLowerCase() === "custom")) {
      setShowDateModal(true);
    } else {
      setShowDateModal(false);
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (selectedPeriod) {
      const { startDate, endDate } = getDateRange(selectedPeriod);
      setNotificationStartDate(startDate);
      setNotificationEndDate(endDate);
    }
  }, [selectedPeriod]);


  const onApplyDate = () => {
    setNotificationStartDate(formattedDate);
    setNotificationEndDate(formattedEndDate);
    setShowDateModal(false);
  };

  return (
    <div>
      <div className="mt-[40px]  md:mt-[60px] px-[20px] md:px-[72px] mb-[150px]">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-onestBold font-[800] text-[#0a5001] tracking-wide">
            Notifications
          </h1>
        </div>
        <div className="bg-[#F9F9FB] rounded-lg  p-4 mt-[40px]">
          <div className="flex justify-between">
            <div className="relative  bmd:hidden">
              <div
                onClick={handleSortUsers}
                className="flex items-center gap-3 bg-white border-[1px] rounded-[9px] px-4 py-3 cursor-pointer border-[#E4EBF5]"
              >
                <img className="h-[10px]" src="/images/sort-menu.svg" alt="" />
                <span className="font-onestRegular text-[12px] text-[#9899A1]">
                  {" "}
                  Sort by
                </span>
              </div>
              {isSortUsers && (
                <div className="p-4 border-[1px] w-[240px] left-0 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                  <h1 className="text-[#0A5001] border-b-[1px] pb-3  border-[#E4EBF5] font-[900] text-base font-onestBold">
                    Sort Users by
                  </h1>
                  <form>
                    <div className="mt-7 flex gap-2.5">
                      <input
                        type="checkbox"
                        value="Name"
                        onChange={handleSortChange}
                      />
                      <label
                        className="text-[#464748] font-onestRegular text-sm"
                        htmlFor=""
                      >
                        Name
                      </label>
                    </div>
                    <div className="mt-7 flex gap-2.5">
                      <input
                        type="checkbox"
                        value="email"
                        onChange={handleSortChange}
                      />
                      <label
                        className="text-[#464748] font-onestRegular text-sm"
                        htmlFor=""
                      >
                        Email
                      </label>
                    </div>
                    <div className="mt-7 flex gap-2.5">
                      <input
                        type="checkbox"
                        value="role"
                        onChange={handleSortChange}
                      />
                      <label
                        className="text-[#464748] font-onestRegular text-sm"
                        htmlFor=""
                      >
                        Role
                      </label>
                    </div>
                    <div className="mt-7 flex gap-2.5">
                      <input
                        type="checkbox"
                        value="user_id"
                        onChange={handleSortChange}
                      />
                      <label
                        className="text-[#464748] font-onestRegular text-sm"
                        htmlFor=""
                      >
                        User ID
                      </label>
                    </div>
                    <div className="mt-7 flex gap-2.5">
                      <input
                        type="checkbox"
                        value="status"
                        onChange={handleSortChange}
                      />
                      <label
                        className="text-[#464748] font-onestRegular text-sm"
                        htmlFor=""
                      >
                        Status
                      </label>
                    </div>
                    <div className="flex items-center gap-3 pt-[30px]">
                      <Button
                        title="Clear"
                        className="!py-[10px] !w-fit !border-[#BEC0BE] !px-6 !rounded-[100px] !bg-transparent !border !text-[#464748]"
                      />
                      <Button
                        title="Save"
                        className="!py-[10px] !w-fit !rounded-[100px] !px-8 !bg-[#A4AFA2] !text-[#fff]"
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
            <div
              className="w-fit cursor-pointer bg-white relative"
              onClick={() => setFilterDate(!filterDate)}
            >
              <div className="flex gap-2 items-center cursor-pointer rounded-[9px] pl-4 pr-4 border-[1px] border-[#E4EBF5] ">
                <img
                  className="w-[14px] h-[14px]"
                  src="/images/filter.svg"
                  alt="search-icon"
                />
                <p className="text-[13px] whitespace-nowrap py-[10px] text-[#929DAE] outline-none">
                  Filter:
                  {selectedPeriod !== "custom"
                    ? selectedPeriodName
                    : `${notificationStartDate} - ${notificationEndDate}`}
                </p>
              </div>
              {filterDate && (
                <div className="p-4 border-[1px] w-[190px] right-0 md:left-0 top-11 bg-white z-10 absolute rounded-lg border-[#E4EBF5]">
                  <h1 className="text-[#929DAE] pb-3 font-[900] text-xs font-onestBold">
                    PERIOD
                  </h1>
                  <form className="">
                    {filterTerms.map((terms) => (
                      <div
                        className="group flex my-3 gap-2.5 hover:bg-[#D4F3D5] text-[#289D17] hover:py-2 rounded-lg pl-2"
                        onClick={() => {
                          setSelectedPeriodName(terms?.name);
                          handlePeriodChange(terms?.value);
                        }}
                      >
                        <p className="flex items-center mb-0 cursor-pointer ">
                        <span
                          className={`${
                            selectedPeriod === terms.value && "bg-[#289D17]"
                          } w-4 h-4 border border-gray-300 rounded-full flex items-center justify-center group-hover:bg-[#289D17]`}
                        >
                          <span className="w-2 h-2 bg-white rounded-full"></span>
                        </span>
                        <span
                          className={`${
                            selectedPeriod === terms.value
                              ? "text-[#289D17]"
                              : "text-[#464748] group-hover:text-[#289D17]"
                          } ml-3 text-sm  `}
                        >
                          {terms.name}
                        </span>
                      </p>
                      </div>
                    ))}
                  </form>
                </div>
              )}
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="bg-white  mt-3">
              <div className="flex mx-3 py-3 justify-between border-b-[1px] border-[#e4ebf5]">
                <div className="flex gap-4">
                  <p className="text-[#929DAE] font-[500] font-onestMedium text-sm">
                    Message
                  </p>
                </div>
                <p className="text-[#929DAE] font-[500] font-onestMedium text-sm hidden md:block">
                  Date
                </p>
              </div>
              {notificationData?.notifications?.data?.map((item: any) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => handleNavigation(item)}
                    className=" md:flex cursor-pointer md:flex-row justify-between mx-3 py-6 border-b-[1px] border-[#e4ebf5]"
                  >
                    <div className="flex gap-4 items-start">
                      <div>
                        <h1 className="text-md font-onestBold font-[600] text-[#161718] tracking-wide">
                          {item.message}
                        </h1>
                        {/* <p className="text-[#464748] font-[400] md:w-[90%] font-onestRegular text-sm">
                      {item.description}
                    </p> */}
                      </div>
                    </div>
                    <p className="text-[#929DAE] font-[500] ml-7 mt-3 md:mt-0 md:ml-0 font-onestMedium text-sm">
                      {moment(item?.created_at).format("MMM DD, YYYY")}
                    </p>
                  </div>
                );
              })}
              <div className="flex gap-6 mt-4 items-center">
              
                  <PagePagination
                  totalPages={notificationData?.notifications?.last_page}
                  page={page}
                  handlePageClick={(event) => setPage(event.selected)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalContainer
        open={showDateModal}
        showCloseIcon={false}
        tailwindClassName="max-w-[466px]"
      >
        <div className="w-full p-8 bg-white flex flex-col  rounded-[12px]">
          <p className="text-lg text-center pb-4 font-onestBold text-[#0A5001] ">
            Custom date range
          </p>
          <div className="flex gap-3 justify-between">
            <div className="">
              <DatePicker
                placeholderText={"Start date"}
                selected={startDate}
                onChange={handleDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
            <div className="">
              <DatePicker
                placeholderText={"End date"}
                selected={endDate}
                onChange={handleEndDateChange}
                className="border-[1px] border-[#E4EBF5] w-[150px] p-2.5 rounded-lg outline-none"
              />
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center pt-[30px]">
            <Button
              title="Clear"
              onClick={onClearDate}
              className="!py-[10px] !border-[#BEC0BE] !px-12 !w-fit !rounded-lg !bg-transparent !border !text-[#289D17]"
            />
            <Button
              title="Apply"
              className="!py-[10px] !w-fit !rounded-lg !px-12 !bg-[#127E04] !text-[#fff]"
              onClick={() => {
                // setSelectedPeriodName(`${formattedDate} - ${formattedEndDate}`);
                // setShowDateModal(false);
                onApplyDate();
              }}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
}

export default Notification;
