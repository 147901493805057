import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface LogoProps {
  logo?: string;
}
interface Props {
    body: LogoProps;
    id: string;
  }

export const printManifest = createApi({
  reducerPath: "printManifest",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createCustomizeLogo: builder.mutation<any, Props>({
      query: ({body,id}) => ({
        url: `/clients/${id}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateCustomizeLogoMutation } = printManifest;
