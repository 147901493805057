import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";

type MenuProps = {
  options: any[];
  onClick?: any;
  children: any;
  form_data?: any;
};

const MenuField = ({ options, onClick, children, form_data }: MenuProps) => {
  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {children}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <div className="px-1 py-1 ">
              {options.map((item, i) =>
                item.type === "submit" ? (
                  <Menu.Item key={i}>
                    {({ active }) => (
                      <form method="post">
                        {item.label === "Activate/Deactivate" && (
                          <>
                            <input
                              type="hidden"
                              name="admin_status"
                              value={form_data?.admin_status}
                            />
                            <input
                              type="hidden"
                              name="admin_id"
                              value={form_data?.admin_id}
                            />
                          </>
                        )}
                        <input
                          type="hidden"
                          name="action_type"
                          value="activate_deactivate"
                        />
                        <button
                          onClick={() => onClick(item.value)}
                          className={`${
                            active ? "bg-[#c3edcf]" : "text-gray-900"
                          } group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm`}
                        >
                          <img src={item.icon} alt="" />
                          {item.label}
                        </button>
                      </form>
                    )}
                  </Menu.Item>
                ) : (
                  <Menu.Item key={i}>
                    {({ active }) => (
                      <button
                        onClick={() => onClick(item.value)}
                        className={`${
                          active ? "bg-[#c3edcf]" : "text-gray-900"
                        } group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm`}
                      >
                        <img src={item.icon} alt="" />
                        {item.label}
                      </button>
                    )}
                  </Menu.Item>
                )
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default MenuField;
