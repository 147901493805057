import { configureStore } from "@reduxjs/toolkit";
import { auth } from "./slices/authSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { getDashboard } from "./slices/dashboard";
import { user } from "./slices/userSlices";
import { getAllUsers } from "./slices/getAllUserSlice";
import { getPricing } from "./slices/pricing";
import { wallet } from "./slices/wallet";
import { ticket } from "./slices/ticket";
import { report } from "./slices/report";
import { shipNow } from "./slices/shipNow";
import { developers } from "./slices/developer";
import { bumpa } from "./slices/bumpa";
import { notifications } from "./slices/notification";
import { authData } from "./slices/getAuthSlice";
import { settings } from "./slices/setting";
import { printManifest } from "./slices/printManifest";
import { businessType } from "./slices/businessType";

export const store = configureStore({
  reducer: {
    [auth.reducerPath]: auth.reducer,
    [shipNow.reducerPath]: shipNow.reducer,
    [getDashboard.reducerPath]: getDashboard.reducer,
    [user.reducerPath]: user.reducer,
    [getAllUsers.reducerPath]: getAllUsers.reducer,
    [getPricing.reducerPath]: getPricing.reducer,
    [wallet.reducerPath]: wallet.reducer,
    [ticket.reducerPath]: ticket.reducer,
    [report.reducerPath]: report.reducer,
    [developers.reducerPath]: developers.reducer,
    [bumpa.reducerPath]: bumpa.reducer,
    [notifications.reducerPath]: notifications.reducer,
    [authData.reducerPath]: authData.reducer,
    [settings.reducerPath]: settings.reducer,
    [printManifest.reducerPath]: printManifest.reducer,
    [businessType.reducerPath]: businessType.reducer,
    
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      auth.middleware,
      shipNow.middleware,
      getDashboard.middleware,
      user.middleware,
      getAllUsers.middleware,
      getPricing.middleware,
      wallet.middleware,
      ticket.middleware,
      report.middleware,
      developers.middleware,
      bumpa.middleware,
      notifications.middleware,
      authData.middleware,
      settings.middleware,
      printManifest.middleware,
      businessType.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
