import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { handleErrorToast } from "helpers/toastMessage";

type FileDragNDropProps = {
  children?: React.ReactNode;
  onDrop: (files: any) => void;
  loading?: boolean;
  uiType?: "dragndrop" | "text";
  setUiFileType?: any;
  fileType?: any;
  icon?: any;
  text?: String;
  bgColor?: any;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  url: string;
};

function BrandLogoUpload({
  children,
  onDrop,
  loading,
  uiType = "dragndrop",
  icon,
  text,
  bgColor,
  setUrl,
  url,
}: FileDragNDropProps) {
  const [acceptedFiles, setAcceptedFiles] = useState<any[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => {
      setAcceptedFiles(files);
      onDrop(files);
    },
    onDropRejected: (rejectedFiles) => {
      handleErrorToast(rejectedFiles?.[0]?.errors?.[0]?.message);
      // toast.error(rejectedFiles?.[0]?.errors?.[0]?.message);
    },
    disabled: loading,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    },
  });
  const s3Config = {
    region: `${process.env.REACT_APP_AWS_DEFAULT_REGION}`,
    credentials: {
      accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
    },
  };

  const s3Client = new S3Client(s3Config);

  const uploadFile = async (file: File) => {
    try {
      const command = new PutObjectCommand({
        Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
        Key: acceptedFiles[0]?.name,
        Body: acceptedFiles[0],
      });
      const res = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_DEFAULT_REGION}.amazonaws.com/${acceptedFiles[0]?.name}`;
      await s3Client.send(command);
      setUrl(res);
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      uploadFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <section
      className={`container ${
        url ? "" : "border-[4px] border-[#E4EBF5] border-dotted"
      }`}
    >
      {uiType === "dragndrop" && (
        <>
          <div
            {...getRootProps({
              className: `mt-2 flex ${bgColor} justify-center rounded-lg cursor-pointer`,
            })}
          >
            <input {...getInputProps()} />
            <div
              className={`${
                url ? "flex flex-col" : "flex flex-col"
              } gap-6 justify-center px-2.5 w-full py-4 items-center`}
            >
              {url ? (
                <img
                  src={url}
                  alt="business-logo"
                  className="w-[200px] h-[200px] object-cover"
                />
              ) : (
                <>
                  <img src={icon} alt="business-logo" />
                  <div className="flex gap-1">
                    <p className="text-[#4a564d] text-[14px] font-[500]">
                      {text}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default BrandLogoUpload;
