import { useSendInviteMutation } from "services/slices/userSlices";
import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import React, { useEffect, useState } from "react";
import { useValidateBusinessMutation } from "services/slices/dashboard";
import { useNavigate } from "react-router";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";

const CreateUsers = () => {
  const [user, setUser] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isInviteUser, setIsInviteUser] = useState<boolean>(false);
  const [validateEmailError, setValidateEmailError] = useState<string>("");
  const [formErrors, setFormErrors] = useState<any>({});
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const [sendInvite, { isLoading: isCreateUserLoading }] =
    useSendInviteMutation();

  const [
    validateBuisness,
    {
      isLoading: isValidatingBusiness,
      isError: isValidateBusinessError,
      data: validateBusinessData,
    },
  ] = useValidateBusinessMutation();

  const data = [
    { name: "admin", id: 0 },
    { name: "basic", id: 1 },
  ];

  const navigate = useNavigate();

  const validateStepOne = (value: string) => {
    let errors: any = {};
    if (!value || value === "") {
      errors.email = "Email is required";
    } else if (value !== "" && !regex.test(value)) {
      errors.email = "Invalid email format";
    }

    return errors;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (userEmail) {
        validateBuisness({
          type: "user.email",
          value: userEmail,
        })
          .unwrap()
          .then((response) => {
            setValidateEmailError("");
          })
          .catch((error: { data: { description: string } }) => {
            setValidateEmailError(error.data.description);
          });
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [userEmail]);
  useEffect(() => {
    if (userEmail !== "" && user !== "" && !isValidateBusinessError) {
      setIsInviteUser(true);
    } else {
      setIsInviteUser(false);
    }
  }, [user, userEmail, isValidateBusinessError]);


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateStepOne(userEmail));
    if (
      userEmail !== "" &&
      user !== "" &&
      !isValidatingBusiness &&
      Boolean(validateBusinessData)
    ) {
      const body: any = {
        email: userEmail,
        role: user,
      };

      await sendInvite(body)
        .unwrap()
        .then((payload) => {
          handleSuccessToast(payload.description);
        })
        .catch((e: any) => {

          handleErrorToast(e)

        });
    }
  };

  return (
    <div className="px-[20px] lg:px-0 flex justify-center pt-14   overflow-auto ">
      <div className="lg:w-[598px] min-w-[300px] h-fit">
        <div className=" bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-[32px] ">
          <div className=" ">
            <p className="md:text-2xl text-xl text-[#161718] font-onestMedium">
              Add New User
            </p>
            <p className="text-[#464748] font-onestMedium text-sm md:text-base">
              Kindly enter the email address of the person you want to add to
              your business
            </p>
          </div>
          <form action="" onSubmit={handleSubmit}>
            <div className="">
              <div className="my-[30px]  space-y-[18px]">
                <div className=" w-full">
                  <TextInput
                    type="text"
                    label="Email Address"
                    isRequired
                    placeholder="Enter email address"
                    value={userEmail}
                    isDataError={validateEmailError !== ""}
                    onChange={(e: any) => {
                      if (e.target.value !== "") {
                        setFormErrors((prev: any) => ({
                          ...prev,
                          email: "",
                        }));
                      } else if (regex.test(e.target.value)) {
                        setFormErrors((prev: any) => ({
                          ...prev,
                          email: "",
                        }));
                      }
                      setUserEmail(e.target.value);
                    }}
                  />
                  {validateEmailError !== "" && (
                    <div className="flex mt-1 items-center space-x-1">
                      <img src="/images/warning.svg" alt="" />
                      <p className="text-xs text-[#BA3838] font-onestMedium">
                        {validateEmailError}
                      </p>
                    </div>
                  )}
                  {formErrors.email && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.email}
                    </span>
                  )}
                </div>
                <div>
                  <p className="text-sm mb-1 text-[#4A564D] font-onestRegular">
                    Select user role <span className="text-[#BA3838]">*</span>
                  </p>
                  <div className="flex md:flex-row flex-col  justify-between items-center md:space-y-0 space-y-3 md:space-x-4">
                    {data.map((item, idx) => (
                      <div
                        key={idx}
                        className={`border flex justify-between w-full items-center py-3 px-4 ${
                          user === item?.name
                            ? "border-[#289D17]"
                            : "border-[#E4EBF5]"
                        } rounded-[8px]`}
                      >
                        <p className="text-sm text-[#161718]">{item.name}</p>
                        <div onClick={() => setUser(item.name)}>
                          {user === item?.name ? (
                            <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center shrink-0 border border-[#289D17] cursor-pointer">
                              <div className="bg-[#289D17] h-[10px] w-[10px] rounded-full shrink-0 "></div>
                            </div>
                          ) : (
                            <div className="w-[20px] h-[20px] rounded-full shrink-0 border cursor-pointer"></div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {user === "admin" && (
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-2 bg-[#FAFAFA] py-4 px-3 border border-[#E1F0DF] rounded-[8px]">
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Create shipments
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Edit orders
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Manage users
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Top-up wallets
                        </p>
                      </div>
                    </div>
                  )}
                  {user === "basic" && (
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-2 bg-[#FAFAFA] py-4 px-3 border border-[#E1F0DF] rounded-[8px]">
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Create shipments
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img src="/images/cancelled-check.svg" alt="" />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Edit orders
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img src="/images/cancelled-check.svg" alt="" />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Manage users
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img src="/images/cancelled-check.svg" alt="" />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Top-up wallets
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className=" mt-[20px]">
                <Button
                  title="Invite user"
                  disabled={isCreateUserLoading || !isInviteUser}
                  loading={isCreateUserLoading}
                  className={`!py-3 ${!isInviteUser && "!bg-[#8C9D8A]"}`}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUsers;
