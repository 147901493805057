import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import AuthLayout from "components/layout/AuthLayout";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useSendAuthOtpMutation,
  useSignupMutation,
  useValidateAuthOtpMutation,
} from "services/slices/authSlice";
import { useValidateBusinessMutation } from "services/slices/dashboard";
import { useGetStatesQuery } from "services/slices/dashboard";
import Dropdown from "components/common/form/SelectDropdown";
import { DropDownSelect } from "components/common/form/Dropdown";
import { Loader } from "components/Loader";
import { RiErrorWarningLine } from "react-icons/ri";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";
import { FaArrowRight } from "react-icons/fa6";
import { formatTime } from "helpers/formatTime";
import { useBusinessTypeQuery } from "services/slices/businessType";

const intialValues = {
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  referralCode: "",
  businessName: "",
  businessPhoneNumber: "",
  businessEmail: "",
  state: "",
  businessType: "",
  businessAddress: "",
};
const businessType: any = [
  { id: 1, name: "SME" },
  { id: 2, name: "Fintech" },
  { id: 3, name: "eCommerce" },
  { id: 4, name: "Bank" },
  { id: 5, name: "Pharma" },
  { id: 6, name: "Beauty" },
  { id: 7, name: "Logistics" },
  { id: 8, name: "Others" },
];
interface AuthProps {
  userFullName?: string;
  userEmailAddress?: string;
  businessName?: string;
  businessAddress?: string;
  businessState?: number;
  businessType?: number;
  businessEmail?: string;
  phone?: string;
  password: string;
  password_confirmation: string;
  referral?: string;
  otp: string;
}
const Register = () => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const [timer, setTimer] = useState(15 * 60);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isRegisterContinue, setIsRegisterContinue] = useState<boolean>(false);
  const [isOtpStep, setIsOtpStep] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [typeNumber, setTypeNumber] = useState<number>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isContinueOne, setIsContinueOne] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [validatePhoneError, setValidatePhoneError] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [validateBusinessNameError, setValidateBusinessNameError] =
    useState<string>("");
  const [validatebusinnessEmailError, setValidateBusinessEmailError] =
    useState<string>("");
  const [validateEmailError, setValidateEmailError] = useState<string>("");
  const [users, setUsers] = useState(intialValues);
  const [formErrors, setFormErrors] = useState<any>({});
  const [signup, { isLoading: isRegisterLoading }] = useSignupMutation();

  const [
    validateBuisness,
    {
      isLoading: isValidatingBusiness,
      isError: isValidateBusinessError,
      data: validateBusinessData,
    },
  ] = useValidateBusinessMutation();
  const [sendAuthOtp, { isLoading: isAuthOtpSending }] =
    useSendAuthOtpMutation();
  const [validateAuthOtp, { isLoading: isAuthOtpValidating }] =
    useValidateAuthOtpMutation();
  const { data, error: dataError } = useGetStatesQuery();
  const { data: businessType, error: businessTypeError } =
    useBusinessTypeQuery();
  const validateStepTwo = (values: any) => {
    let errors: any = {};
    if (!values.businessEmail || values.businessEmail === "") {
      errors.businessEmail = "Business email is required";
    } else if (
      values.businessEmail === "" &&
      !regex.test(values.businessEmail)
    ) {
      errors.businessEmail = "Invalid business email format";
    }
    if (!values.businessName || values.businessName === "") {
      errors.businessName = "Business name is required";
    }
    if (!values.state) {
      errors.state = "State is required";
    }
    if (query === "") {
      errors.businessType = "Business type is required";
    }
    if (!values.businessAddress || values.businessAddress === "") {
      errors.businessAddress = "Business address is required";
    }
    if (!values.businessPhoneNumber || values.businessPhoneNumber === "") {
      errors.businessPhoneNumber = "Business phone number is required";
    }
    return errors;
  };

  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.email || values.email === "") {
      errors.email = "Email is required";
    } else if (values.email !== "" && !regex.test(values.email)) {
      errors.email = "Invalid email format";
    }
    if (!values.fullName || values.fullName === "") {
      errors.fullName = "Name is required";
    }
    if (!values.password || values.password === "") {
      errors.password = "Password is required";
    } else if (values.password !== "" && values.password.length < 8) {
      errors.password = "Password must have 8 characters";
    }
    if (!values.confirmPassword || values.confirmPassword === "") {
      errors.confirmPassword = "Confirm password is required";
    } else if (
      values.confirmPassword !== values.password &&
      values.confirmPassword !== ""
    ) {
      errors.confirmPassword = "Confirm password must be same as password";
    } else if (
      values.confirmPassword !== "" &&
      values.confirmPassword.length < 8
    ) {
      errors.confirmPassword = "Password must have 8 characters";
    }
    return errors;
  };

  const validateStepThree = (otp: any) => {
    let errors: any = {};
    if (!otp || otp === "") {
      errors.otp = "OTP is required";
    } else if (otp !== "" && otp.length < 6) {
      errors.otp = "OTP must have 6 characters";
    }
    return errors;
  };

  useEffect(() => {
    if (
      users.fullName !== "" &&
      users.email !== "" &&
      users.password !== "" &&
      users.confirmPassword &&
      !isValidateBusinessError
    ) {
      setIsContinueOne(true);
    }
  }, [users, isValidateBusinessError]);

  const onStepOne = async (e: any) => {
    e.preventDefault();

    const validationErrors = validateStepOne(users);
    await setFormErrors(validationErrors);

    const isAllEmpty = Object.values(validationErrors).every(
      (value) => value === ""
    );

    if (
      users.fullName !== "" &&
      users.email !== "" &&
      users.password !== "" &&
      users.confirmPassword &&
      !isValidateBusinessError &&
      !validateEmailError &&
      !isAuthOtpSending &&
      (Object.keys(validationErrors).length === 0 || isAllEmpty)
    ) {
      setIsRegisterContinue(true);
    }
  };
  const referralCode = param.get("referral");

  useEffect(() => {
    if (referralCode) {
      setUsers((prev) => ({
        ...prev,
        referralCode,
      }));
    }
  }, []);
  const filteredSearch = data?.states
    ?.filter((item: any) =>
      item.state?.toLowerCase()?.includes(searchState.toLowerCase())
    )
    ?.sort((a: any, b: any) => a.state.localeCompare(b.state));

  const onRegisterUser = async () => {
    const body: AuthProps = {
      businessName: users?.businessName,
      businessAddress: users?.businessAddress,
      businessState: Number(users?.state),
      businessType: Number(typeNumber),
      businessEmail: users?.businessEmail,
      phone: users?.businessPhoneNumber,
      referral: users?.referralCode,
      userFullName: users?.fullName,
      userEmailAddress: users?.email,
      password: users?.password,
      password_confirmation: users?.password,
      otp,
    };
    if (users?.referralCode) {
      body.referral = users?.referralCode;
    }
    await signup(body)
      .unwrap()
      .then((data: any) => {
        handleSuccessToast(data.description);
        localStorage.setItem("webhook", data?.webhooks?.[0]?.url);
        localStorage.setItem(
          "integration-key",
          data?.platformIntegrations?.[0]?.integration_key
        );
        localStorage.setItem("token", data?.authDetails?.authToken);
        localStorage.setItem("secret-key", data?.orgDetails["secret-key"]);
        localStorage.setItem("fez-org-code", data?.orgDetails["orgCode"]);
        localStorage.setItem("fez-org-id", data?.orgDetails["orgId"]);
        localStorage.setItem("fez-org-email", data?.orgDetails["orgEmail"]);
        localStorage.setItem(
          "expire-token-date",
          data?.authDetails?.expireToken
        );
        localStorage.setItem("fez-user", JSON.stringify(data?.userDetails));
        localStorage.setItem(
          "fez-org-details",
          JSON.stringify(data?.orgDetails)
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 500);
      })
      .catch((e) => {
        handleErrorToast(e);
      });
  };

  const onRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (users.state === "" || !users.state) {
      setFormErrors((prev: any) => ({
        ...prev,
        state: "State is required",
      }));
    }
    if (query === "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessType: "Business type is required",
      }));
    }
    setFormErrors(validateStepTwo(users));
    if (
      users?.businessEmail !== "" &&
      users?.businessName !== "" &&
      users?.password !== "" &&
      users?.businessName !== "" &&
      users?.state !== "" &&
      query !== "" &&
      !isValidatingBusiness &&
      !validateBusinessNameError &&
      !validatebusinnessEmailError &&
      !validatePhoneError &&
      Boolean(validateBusinessData)
    ) {
      onSendAuthOtp();
    }
  };

  const onValidateOtp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateStepThree(otp));
    const body = {
      otp,
      email: users.email,
    };
    if (
      (!formErrors.otp || formErrors.otp === "") &&
      otp !== "" &&
      otp.length === 6
    ) {
      await validateAuthOtp(body)
        .unwrap()
        .then((res) => {
          onRegisterUser();
        })
        .catch((error: { data: { description: string } }) => {
          handleErrorToast(error);
        });
    }
  };
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (users.email) {
        validateBuisness({
          type: "user.email",
          value: users.email,
        })
          .unwrap()
          .then((response) => {
            setValidateEmailError("");
          })
          .catch((error: { data: { description: string } }) => {
            setValidateEmailError(error.data.description);
          });
      }
      if (users.businessPhoneNumber) {
        validateBuisness({
          type: "business.phone",
          value: users.businessPhoneNumber,
        })
          .unwrap()
          .then((response) => {
            setValidatePhoneError("");
          })
          .catch((error: { data: { description: string } }) => {
            setValidatePhoneError(error?.data?.description);
          });
      }
      if (users.businessName) {
        validateBuisness({
          type: "business.name",
          value: users.businessName,
        })
          .unwrap()
          .then((response) => {
            setValidateBusinessNameError("");
          })
          .catch((error: { data: { description: string } }) => {
            setValidateBusinessNameError(error?.data?.description);
          });
      }
      if (users.businessEmail) {
        validateBuisness({
          type: "business.email",
          value: users.businessEmail,
        })
          .unwrap()
          .then((response) => {
            setValidateBusinessEmailError("");
          })
          .catch((error: any) => {
            setValidateBusinessEmailError(error?.data?.description);
          });
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [
    users.email,
    users.businessPhoneNumber,
    users.businessEmail,
    users.businessName,
    users.businessPhoneNumber,
  ]);

  const handleErrorChange = (name: string, value: any) => {
    if (name === "password" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    } else if (name === "password" && value.length < 8) {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "Password must have 8 characters",
      }));
    } else if (name === "password" && value.length >= 8) {
      setFormErrors((prev: any) => ({
        ...prev,
        password: "",
      }));
    }
    if (name === "email" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    } else if (name === "email" && value !== "" && !regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "Invalid email format",
      }));
    } else if (name === "email" && value !== "" && regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    }
    if (name === "fullName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "",
      }));
    } else if (name === "fullName" && value.length > 50) {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "Fullname cannot be more than 50 character length",
      }));
    } else if (name === "fullName" && value.length <= 50) {
      setFormErrors((prev: any) => ({
        ...prev,
        fullName: "",
      }));
    }
    if (name === "confirmPassword" && value === users.password) {
      setFormErrors((prev: any) => ({
        ...prev,
        confirmPassword: "",
      }));
    } else if (name === "confirmPassword" && value === users.password) {
      setError("");
    }
  };
  const handleErrorChangeTwo = (name: string, value: any) => {
    if (name === "businessEmail" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessEmail: "",
      }));
    } else if (name === "businessEmail" && value !== "" && !regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        businessEmail: "Invalid email format",
      }));
    } else if (name === "businessEmail" && value !== "" && regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        businessEmail: "",
      }));
    }
    if (name === "businessName" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessName: "",
      }));
    }
    if (name === "businessPhoneNumber" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessPhoneNumber: "",
      }));
    }
    if (name === "businessAddress" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessAddress: "",
      }));
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUsers((prev) => ({
      ...prev,
      [name]: value,
    }));

    handleErrorChange(name, value);
    handleErrorChangeTwo(name, value);
  };

  const onTogglePasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsPasswordVisible((prevState) => !prevState);
  };
  const onToggleConfirmPasswordVisibility = (e: any) => {
    e.preventDefault();
    setIsConfirmPasswordVisible((prevState) => !prevState);
  };
  useEffect(() => {
    if (businessTypeError) {
      handleErrorToast(businessTypeError);
    }
  }, [businessTypeError]);
  useEffect(() => {}, [data, dataError]);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option: any) => {
    setQuery(option.name);
    setIsOpen(false);
    setTypeNumber(option.id);
    setFormErrors((prev: any) => ({
      ...prev,
      businessType: "",
    }));
  };

  const onSendAuthOtp = async () => {
    setOtp("");
    await sendAuthOtp({
      email: users.email,
    })
      .unwrap()
      .then((res: { description: string }) => {
        setIsOtpStep(true);
        handleSuccessToast(res.description);
        setTimer(900);
      })
      .catch((err) => handleErrorToast(err));
  };

  useEffect(() => {
    if (users.state !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        state: "",
      }));
    }
    if (query !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        businessType: "",
      }));
    }
  }, [users.state, users.businessType]);

  return (
    <AuthLayout
      headerTitle={
        !isOtpStep && (
          <p className="md:text-sm text-xs font-onestRegular text-black">
            Already have an account?{" "}
            <span
              className="ml-1 font-onestMedium text-[#15B900] cursor-pointer"
              onClick={() => navigate("/")}
            >
              Sign In
            </span>
          </p>
        )
      }
      children={
        <>
          {!isOtpStep ? (
            <>
              {!isRegisterContinue ? (
                <div className="sm:px-[40px] w-full lg:px-0 ">
                  <div className="flex justify-center max-w-[600]">
                    <div className="h-fit w-full  ">
                      <div className=" bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-10 ">
                        <div className=" ">
                          <p className="md:text-2xl text-xl text-black font-onestMedium">
                            Hello there
                          </p>
                          <p className="text-[#A0ABA3] font-onestMedium text-base md:text-lg">
                            Sign up to continue
                          </p>
                        </div>
                        <form action="" onSubmit={onStepOne}>
                          <div className="">
                            <div className="my-[30px]  space-y-[18px]">
                              <div className=" w-full">
                                <TextInput
                                  type="text"
                                  label="Full Name"
                                  name="fullName"
                                  value={users.fullName}
                                  // isRequired
                                  maxLength={50}
                                  onChange={handleChange}
                                  totalInput={String(users.fullName.length)}
                                  placeholder="Enter full name"
                                />
                                {formErrors.fullName && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.fullName}
                                  </span>
                                )}
                              </div>
                              <div className=" w-full">
                                <TextInput
                                  type="text"
                                  name="email"
                                  label="Email Address"
                                  // isRequired
                                  onChange={handleChange}
                                  value={users.email}
                                  isDataError={Boolean(validateEmailError)}
                                  placeholder="Enter email address"
                                />
                                {validateEmailError !== "" && (
                                  <div className="flex mt-1 items-center space-x-1">
                                    <RiErrorWarningLine className="text-[#BA3838]" />
                                    <p className="text-xs text-[#BA3838] font-onestMedium">
                                      {validateEmailError}
                                    </p>
                                  </div>
                                )}
                                {formErrors.email && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.email}
                                  </span>
                                )}
                              </div>
                              <div className=" w-full">
                                <TextInput
                                  type={isPasswordVisible ? "text" : "password"}
                                  label="Password"
                                  // isRequired
                                  name="password"
                                  value={users.password}
                                  onTogglePasswordVisibility={
                                    onTogglePasswordVisibility
                                  }
                                  placeholder="Enter password"
                                  isPasswordVisible={isPasswordVisible}
                                  isPassword
                                  // minLength={8}
                                  onChange={handleChange}
                                  isRegister
                                />
                                {formErrors.password && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.password}
                                  </span>
                                )}
                              </div>
                              <div className=" w-full">
                                <TextInput
                                  label="Confirm Password"
                                  type={
                                    isConfirmPasswordVisible
                                      ? "text"
                                      : "password"
                                  }
                                  name="confirmPassword"
                                  isPasswordVisible={isConfirmPasswordVisible}
                                  onChange={handleChange}
                                  // minLength={8}
                                  isPassword
                                  onTogglePasswordVisibility={
                                    onToggleConfirmPasswordVisibility
                                  }
                                  value={users.confirmPassword}
                                  placeholder="Confirm password"
                                />
                                {formErrors.confirmPassword && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.confirmPassword}
                                  </span>
                                )}
                              </div>
                              <div className=" w-full">
                                <TextInput
                                  type={"text"}
                                  name="referralCode"
                                  label="Referral Code"
                                  disabled={referralCode !==null}
                                  onChange={handleChange}
                                  value={users.referralCode}
                                  placeholder="Enter referral code"
                                />
                              </div>
                            </div>
                            <div className=" mt-[18px]">
                              <Button
                                title="Continue"
                                type="submit"
                                className={`${
                                  !isContinueOne && "!bg-[#8C9D8A]"
                                }`}
                                disabled={isRegisterLoading}
                                loading={isRegisterLoading}
                                icon={<FaArrowRight className="text-white" />}
                                iconRight
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="sm:px-[40px] w-full lg:px-0 ">
                  <div className="flex justify-center max-w-[600]">
                    <div className="w-full h-fit   ">
                      <div className=" bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-10 ">
                        <div
                          className="flex items-center space-x-2 mb-2 cursor-pointer w-fit"
                          onClick={() => setIsRegisterContinue(false)}
                        >
                          <img src="/images/arrowBack.svg" alt="" />
                          <p className="text-sm font-onestMedium text-[#A0ABA3]">
                            Go Back
                          </p>
                        </div>
                        <div className=" ">
                          <p className="md:text-2xl text-xl text-black font-onestMedium">
                            Hi {users.fullName}
                          </p>
                          <p className="text-[#A0ABA3] font-onestMedium text-base md:text-lg">
                            Fill in your business details
                          </p>
                        </div>
                        <form action="" onSubmit={onRegister}>
                          <div className="">
                            <div className="my-[30px]  space-y-[18px]">
                              <div className=" w-full">
                                <TextInput
                                  type="text"
                                  label="Business Name"
                                  name="businessName"
                                  value={users.businessName}
                                  // isRequired
                                  maxLength={50}
                                  onChange={handleChange}
                                  placeholder="Enter business name"
                                />
                                {validateBusinessNameError !== "" && (
                                  <div className="flex mt-1 items-center space-x-1">
                                    <RiErrorWarningLine className="text-[#BA3838]" />
                                    <p className="text-xs text-[#BA3838] font-onestMedium">
                                      {validateBusinessNameError}
                                    </p>
                                  </div>
                                )}
                                {formErrors.businessName && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.businessName}
                                  </span>
                                )}
                              </div>
                              <div className=" w-full">
                                <TextInput
                                  type="text"
                                  name="businessEmail"
                                  label="Business Email"
                                  // isRequired
                                  onChange={handleChange}
                                  value={users.businessEmail}
                                  placeholder="Enter business email"
                                />
                                {validatebusinnessEmailError !== "" && (
                                  <div className="flex mt-1 items-center space-x-1">
                                    <RiErrorWarningLine className="text-[#BA3838]" />
                                    <p className="text-xs text-[#BA3838] font-onestMedium">
                                      {validatebusinnessEmailError}
                                    </p>
                                  </div>
                                )}
                                {formErrors.businessEmail && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.businessEmail}
                                  </span>
                                )}
                              </div>
                              <div className=" w-full">
                                <TextInput
                                  type="number"
                                  name="businessPhoneNumber"
                                  label="Business Phone Number"
                                  // isRequired
                                  onChange={handleChange}
                                  value={users.businessPhoneNumber}
                                  placeholder="Enter business phone number"
                                />
                                {validatePhoneError !== "" && (
                                  <div className="flex mt-1 items-center space-x-1">
                                    <RiErrorWarningLine className="text-[#BA3838]" />
                                    <p className="text-xs text-[#BA3838] font-onestMedium">
                                      {validatePhoneError}
                                    </p>
                                  </div>
                                )}

                                {formErrors.businessPhoneNumber && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.businessPhoneNumber}
                                  </span>
                                )}
                              </div>
                              <div className=" w-full">
                                <TextInput
                                  type={"text"}
                                  name="businessAddress"
                                  // isRequired
                                  label="Business Address"
                                  onChange={handleChange}
                                  value={users.businessAddress}
                                  placeholder="Enter business address"
                                />
                                {formErrors.businessAddress && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.businessAddress}
                                  </span>
                                )}
                              </div>
                              <div>
                                <p className="text-[#4A564D] mb-2 text-sm leading-[20px] font-onestRegular">
                                  State
                                </p>
                                <Dropdown
                                  dropdown={
                                    <div className="flex border rounded items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                      <div className="flex items-center space-x-1">
                                        <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                        <span
                                          className={` ${
                                            users.state === ""
                                              ? "text-[#9899A1]"
                                              : "text-[#000]"
                                          }  font-onestMedium text-sm`}
                                        >
                                          {users.state === ""
                                            ? "Select state"
                                            : stateName}
                                        </span>
                                      </div>

                                      <img
                                        src={"/images/chevronDown.svg"}
                                        alt="drop-down"
                                        className="mt-0.5 pr-2 cursor-pointer"
                                      />
                                    </div>
                                  }
                                  search={
                                    <div>
                                      <TextInput
                                        value={searchState}
                                        name={"search"}
                                        type={"text"}
                                        placeholder="Search"
                                        onChange={(e) =>
                                          setSearchState(e.target.value)
                                        }
                                      />
                                    </div>
                                  }
                                  dropdownContainerClasses={`shadow-cardShadow-4 !w-full !right-0   border border-slate-100  rounded `}
                                  dropdownOptions={[
                                    {
                                      customChild: (
                                        <div>
                                          <div>
                                            {filteredSearch?.map(
                                              (item: any, idx: number) => {
                                                return (
                                                  <div
                                                    key={idx}
                                                    onClick={() => {
                                                      setUsers((prev) => ({
                                                        ...prev,
                                                        state: item.id,
                                                      }));
                                                      setStateName(item?.state);
                                                      setFormErrors(
                                                        (prev: any) => ({
                                                          ...prev,
                                                          state: "",
                                                        })
                                                      );
                                                    }}
                                                  >
                                                    <div className="px-3 py-2  hover:bg-[#edecec] cursor-pointer">
                                                      <p>{item?.state}</p>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                                {formErrors.state && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.state}
                                  </span>
                                )}
                              </div>
                              <div>
                                <DropDownSelect
                                  title="Business type"
                                  titleClassName="!font-normal font-onestRegular"
                                  lists={businessType.businessTypes}
                                  value={query}
                                  setQuery={setQuery}
                                  isOpen={isOpen}
                                  toggleDropdown={toggleDropdown}
                                  handleOptionClick={handleOptionClick}
                                  placeholder="Select type"
                                  name="example-dropdown"
                                />
                                {formErrors.businessType && (
                                  <span className="text-xs text-[#BA3838] font-onestMedium">
                                    {formErrors.businessType}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              <p className="text-[11px] text-[#4A564D] font-onestMedium">
                                By clicking the ‘Create your account’ button,
                                you agree to our{" "}
                                <span className="text-[#289D17] underline">
                                  Terms of Service
                                </span>{" "}
                                and that you have read and understood our{" "}
                                <span className="text-[#289D17] underline">
                                  Privacy Policy
                                </span>
                                .
                              </p>
                            </div>
                            <div className=" mt-[20px]">
                              <Button
                                title="Create your account"
                                type="submit"
                                disabled={isRegisterLoading}
                                loading={isRegisterLoading}
                                icon={<img src="/images/arrowLeft.svg" />}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="sm:px-[40px] w-full lg:px-0 ">
              <div className="flex justify-center max-w-[600]">
                <div className="w-full h-fit   ">
                  <div className=" bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-10 ">
                    <div
                      onClick={() => setIsOtpStep(false)}
                      className="flex gap-2 mb-[20px] items-center cursor-pointer w-fit"
                    >
                      <div>
                        {" "}
                        <img
                          className="w-4 h-4"
                          src="/images/arrowBack.svg"
                          alt=""
                        />
                      </div>
                      <p className="text-[#A0ABA3] font-[500] text-[13px]  font-onestMedium">
                        Go back
                      </p>
                    </div>
                    <div className=" ">
                      <p className="md:text-2xl text-xl text-black font-onestMedium">
                        Hi {users.fullName}
                      </p>
                      <p className="text-[#464748] text-base mt-12">
                        Enter the verification token sent to {users.email}
                      </p>
                    </div>
                    <form action="" onSubmit={onValidateOtp}>
                      <div className="">
                        <div className="my-[30px]  space-y-[18px]">
                          <div className=" w-full">
                            <TextInput
                              type="string"
                              label="Enter OTP"
                              // isRequired
                              name="otp"
                              // minLength={6}
                              maxLength={6}
                              onChange={(e) => {
                                if (
                                  e.target.name === "otp" &&
                                  e.target.value !== ""
                                ) {
                                  setFormErrors((prev: any) => ({
                                    ...prev,
                                    otp: "",
                                  }));
                                } else if (
                                  e.target.name === "otp" &&
                                  e.target.value.length >= 6
                                ) {
                                  setFormErrors((prev: any) => ({
                                    ...prev,
                                    otp: "",
                                  }));
                                }
                                setOtp(e.target.value);
                              }}
                              value={otp}
                              totalInput={String(otp.length)}
                              placeholder="Enter OTP"
                              onKeyDown={(evt) =>
                                [
                                  "e",
                                  "E",
                                  "+",
                                  "-",
                                  "ArrowUp",
                                  "ArrowDown",
                                ].includes(evt.key) && evt.preventDefault()
                              }
                            />
                            {formErrors.otp && (
                              <span className="text-xs text-[#BA3838] font-onestMedium">
                                {formErrors.otp}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className=" mt-[18px]">
                          <Button
                            title="Submit"
                            type="submit"
                            loading={isAuthOtpValidating}
                            disabled={isAuthOtpValidating}
                          />
                        </div>
                        <p className="text-sm flex justify-center items-center font-onestMedium text-[#A0ABA3] text-center mt-2">
                          Token expired?{" "}
                          {isAuthOtpSending ? (
                            <span>
                              <Loader />
                            </span>
                          ) : (
                            <div className="pl-2">
                              {timer === 0 ? (
                                <span
                                  className="text-[#15B900] font-onestMedium cursor-pointer"
                                  onClick={onSendAuthOtp}
                                >
                                  Resend OTP
                                </span>
                              ) : (
                                <span className="text-[#15B900]">
                                  {formatTime(timer)}
                                </span>
                              )}
                            </div>
                          )}
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      }
    />
  );
};
export default Register;
