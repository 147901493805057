import React, { useEffect, useState } from "react";
import { getFromStorage} from "helpers";
import { useLocation, useNavigate } from "react-router-dom";

interface PrivateRoutesProps {
  children: React.ReactNode;
}

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(() => getFromStorage("token"));
  const [secretKey, setSecretKey] = useState(() =>
    getFromStorage("secret-key")
  );
  const [expireDate, setExpireDate] = useState(() =>
    getFromStorage("expire-token-date")
  );
  const formatDate = (date: Date): string => {
    const padZero = (num: number): string => num.toString().padStart(2, "0");

    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1);
    const day = padZero(date.getDate());
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };



  const handleStorageChange = () => {
    setToken(getFromStorage("token"));
    setSecretKey(getFromStorage("secret-key"));
    setExpireDate(getFromStorage("expire-token-date"));
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/logout") {
      localStorage.removeItem("token");
      localStorage.removeItem("secret-key");
      navigate("/");
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const date = new Date();
    if (expireDate <= formatDate(date)) {
      localStorage.removeItem("token");
      localStorage.removeItem("secret-key");
      navigate("/");
    }
  }, [expireDate, navigate]);

  useEffect(() => {
    if (!token || !secretKey) {
      navigate("/");
      return;
    }

    if (token && !location.pathname.startsWith("/dashboard")) {
      navigate("/dashboard");
    }
    // const isTokenExpired = (token:string) => {
    //   try {
    //     const payload = JSON.parse(atob(token.split(".")[1]));
    //     return payload.exp < Date.now() / 1000;
    //   } catch (e) {
    //     return true;
    //   }
    // };

    // const refreshToken = async () => {
    //   try {
    //     const response = await fetch("/api/refresh-token", {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });

    //     if (response.ok) {
    //       const data = await response.json();
    //       saveToStorage("token", data.token);
    //       setToken(data.token);
    //     } else {
    //       navigate("/login");
    //     }
    //   } catch (error) {
    //     console.error("Failed to refresh token:", error);
    //     navigate("/login");
    //   }
    // };

    // if (isTokenExpired(token)) {
    //   refreshToken();
    // }

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate, token, secretKey, location.pathname]);

  if (!token || !secretKey) {
    return null;
  }

  return <>{children}</>;
};

export default PrivateRoutes;
