import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface UserSendInviteProps {
  email: string;
  role: string;
}
interface UserInvitedInfoProps {
  user_id: string
}
// interface UserDeleteProps {}

interface UserResendInviteProps {
  userId: string
}
interface UserCreateProps {
    fullname: string,
    email: string,
    password: string,
    user_id: string
}
interface UserUpdateProps {
    fullname: string,
    email: string,
    role: string
}
interface UserDeleteInviteProps {
    userId: string
}

export const user = createApi({
  reducerPath: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendInvite: builder.mutation<any, UserSendInviteProps>({
      query: (body) => ({
        url: `user/invite`,
        method: "POST",
        body,
      }),
    }),

    invitedUser: builder.mutation<any, UserInvitedInfoProps>({
      query: (body) => ({
        url: `user/invited/verification`,
        method: "POST",
        body,
      }),
    }),
    deleteUser: builder.mutation<any, { id: string }>({
      query: ({id}) => ({
        url: `user/${id}/delete`,
        method: "POST",
      }),
    }),
    resendInvite: builder.mutation<any, UserResendInviteProps>({
      query: (body) => ({
        url: `user/invite-resend`,
        method: "POST",
        body,
      }),
    }),
    createUser: builder.mutation<any, UserCreateProps>({
      query: (body) => ({
        url: `user/invited/register`,
        method: "POST",
        body,
      }),
    }),
    updateUser: builder.mutation<any, UserUpdateProps>({
      query: (body) => ({
        url: `update-users`,
        method: "PUT",
        body,
      }),
    }),
    deleteInvite: builder.mutation<any, UserDeleteInviteProps>({
      query: (body) => ({
        url: `user/invite-delete`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
    useSendInviteMutation,
    useInvitedUserMutation,
    useDeleteUserMutation,
    useResendInviteMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteInviteMutation,
} = user;
