import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


interface Client {
    platform: string;
    userID: string;
    orgID: string;
    clientAddress: string;
    clientPhone: string;
    userfullname: string;
    clientName: string;
    data: {
      recipientName: string;
      recipientPhone: string;
      recipientAddress: string;
      recipientState: string;
      additionalDetails: string;
      uniqueID: string;
      itemDescription: string;
      valueOfItem: number;
      senderAddress: string;
      senderPhone: string;
      senderName: string;
      BatchID: string;
    }[];
  };
  

interface Key {
  client_id: string;
  integration_key: string;
  platform: string;
}

export const bumpa = createApi({
  reducerPath: "bumpa",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_URL}`,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("token");
      const secretKey = localStorage.getItem("secret-key");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("secret-key", `${secretKey}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    importBumpa: builder.mutation<any, Client>({
      query: (body) => ({
        url: `platform-import-order`,
        method: "POST",
        body,
      }),
    }),
    updateBumpaKey: builder.mutation<any, Key>({
      query: (body) => ({
        url: `platform-integration`,
        method: "POST",
        body,
      }),
    }),
    getBumpaOrders: builder.mutation<any, any>({
      query: (body) => ({
        url: `platform-integration/orders`,
        method: "POST",
        body
      }),
    }),
  }),
});

export const {
  useImportBumpaMutation,
  useGetBumpaOrdersMutation,
  useUpdateBumpaKeyMutation,
} = bumpa;
