import { Button } from "components/Button";
import { TextInput } from "components/common/form/Input";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useGetUserbyIdQuery } from "services/slices/getAllUserSlice";
// import { useValidateBusinessMutation } from "services/slices/getStatesandCountrySlice";
import { useUpdateUserMutation } from "services/slices/userSlices";
import { handleErrorToast, handleSuccessToast } from "helpers/toastMessage";

const EditUsers = () => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const [user, setUser] = useState("basic");
  const [users, setUsers] = useState({
    name: "",
    email: "",
  });

  const [formErrors, setFormErrors] = useState<any>({});

  const validateStepOne = (values: any) => {
    let errors: any = {};
    if (!values.email || values.email === "") {
      errors.email = "Email is required";
    } else if (values.email !== "" && !regex.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.password || values.password === "") {
      errors.password = "Password is required";
    }
    return errors;
  };

  const { id } = useParams();
  const {
    data: userData,
    isLoading,
  
  } = useGetUserbyIdQuery({ id });

  const navigate = useNavigate();

  useEffect(() => {
    setUsers({
      name: userData?.users?.fullname,
      email: userData?.users?.email,
    });
    setUser(userData?.users?.role);
  }, [userData]);

  const data = [
    { name: "admin", id: 0 },
    { name: "basic", id: 1 },
  ];

  const [updateUser, { isLoading: isCreateUserLoading }] =
    useUpdateUserMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateStepOne(users));
    if (users.name !== "" && users.email !== "" && user !== "") {
      const body: any = {
        fullname: users.name,
        email: users.email,
        role: user,
        user_id: id,
      };

    if(userData.users.email!==users.email|| userData.users.role!==user || userData.users.fullname!==users.name){
      await updateUser(body)
      .unwrap()
      .then((payload) => {
        handleSuccessToast(payload.description);
        setTimeout(() => {
          navigate("/dashboard/users");
        }, 2000);
      })
      .catch((e: any) => {
        handleErrorToast(e);
      });
    }
    }
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUsers((prev) => ({
      ...prev,
      [name]: value,
    }));
    handleErrorChange(name, value);
  };
  const handleErrorChange = (name: string, value: any) => {
    if (name === "email" && value !== "") {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    } else if (name === "email" && value !== "" && !regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "Invalid email format",
      }));
    } else if (name === "email" && value !== "" && regex.test(value)) {
      setFormErrors((prev: any) => ({
        ...prev,
        email: "",
      }));
    }
  };
  return (
    <div className="px-[40px] lg:px-0 flex justify-center pt-14 pb-[100px] h-[100vh] overflow-auto ">
      <div className="w-full lg:w-[598px] min-w-[300px] h-fit ">
        <div className=" bg-base-gradient border border-[#E1F0DF] rounded-[12px] py-10 px-4 md:p-[32px] ">
          <div className=" ">
            <p className="md:text-2xl text-xl text-[#161718] font-onestMedium">
              Edit User
            </p>
          </div>
          <form onSubmit={handleSubmit} action="">
            <div className="">
              <div className="my-[30px]  space-y-[18px]">
                <div className=" w-full">
                  <TextInput
                    type="text"
                    label="Full Name"
                    isRequired
                    name="name"
                    value={users.name}
                    onChange={handleChange}
                    defaultValue={userData?.users?.name}
                    placeholder="Enter full name"
                  />
                  {formErrors.name && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.name}
                    </span>
                  )}
                </div>
                <div className=" w-full">
                  <TextInput
                    type="email"
                    label="Email Address"
                    isRequired
                    name="email"
                    onChange={handleChange}
                    value={users.email}
                    defaultValue={userData?.users?.email}
                    placeholder="Enter email address"
                  />
                  {formErrors.email && (
                    <span className="text-xs text-[#BA3838] font-onestMedium">
                      {formErrors.email}
                    </span>
                  )}
                </div>
                <div>
                  <p className="text-sm mb-1 text-[#4A564D] font-onestRegular">
                    Select user role <span className="text-[#BA3838]">*</span>
                  </p>
                  <div className="flex md:flex-row flex-col  justify-between items-center md:space-y-0 space-y-3 md:space-x-4">
                    {data.map((item, idx) => (
                      <div
                        key={idx}
                        className={`border flex justify-between w-full items-center py-3 px-4 ${
                          user === item?.name
                            ? "border-[#289D17]"
                            : "border-[#E4EBF5]"
                        } rounded-[8px]`}
                      >
                        <p className="text-sm text-[#161718]">{item.name}</p>
                        <div onClick={() => setUser(item.name)}>
                          {user === item?.name ? (
                            <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center shrink-0 border border-[#289D17] cursor-pointer">
                              <div className="bg-[#289D17] h-[10px] w-[10px] rounded-full shrink-0 "></div>
                            </div>
                          ) : (
                            <div className="w-[20px] h-[20px] rounded-full shrink-0 border cursor-pointer"></div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {user === "admin" && (
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-2 bg-[#FAFAFA] py-4 px-3 border border-[#E1F0DF] rounded-[8px]">
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Create shipments
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Edit orders
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Manage users
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Top-up wallets
                        </p>
                      </div>
                    </div>
                  )}
                  {user === "basic" && (
                    <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-2 bg-[#FAFAFA] py-4 px-3 border border-[#E1F0DF] rounded-[8px]">
                      <div className="flex items-center space-x-2">
                        <img
                          src="/images/check_circle.svg"
                          className="w-[18px] h-[18px]"
                          alt=""
                        />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Create shipments
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img src="/images/cancelled-check.svg" alt="" />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Edit orders
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img src="/images/cancelled-check.svg" alt="" />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Manage users
                        </p>
                      </div>
                      <div className="flex items-center space-x-2">
                        <img src="/images/cancelled-check.svg" alt="" />
                        <p className="text-sm text-[#464748] font-onestMedium">
                          Top-up wallets
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className=" mt-[20px]">
                <Button
                  loading={isCreateUserLoading}
                  disabled={isCreateUserLoading}
                  title="Save changes"
                  className="!py-3"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUsers;
