import React from "react";

function Help() {
    const url =
    'https://www.youtube.com/embed/_BSND1lO3yU?si=GChgsum2rx9o8Wql';
    
  return (
    <div className="mt-[40px]  md:mt-[60px] px-[20px] md:px-[72px] mb-[150px]">
      <h1 className="text-2xl font-onestBold font-[800] text-[#0A5001] tracking-wide">
        Help
      </h1>
      <p className="font-onestRegular mt-4 text-[#464748]">
        Call our helpline on{" "}
        <span className="border-b-[1px] font-onestRegular  border-[#0A5001]">
          +2349060005049{" "}
        </span>{" "}
        if you have any questions or need assistance.
      </p>
      <div className="bg-[#F9F9FB] border-[2px] border-[#E4EBF5] rounded-lg mt-8 px-3 pt-4">
        <p className="font-onestRegular text-[#464748]">
          For more information and helpful tips, watch the B2B tutorial below.
        </p>
        <div>
          <div className="h-[234px] mt-4 mb-4 sm:h-[420px] bmd:h-[600px] z-20">
            <iframe
              className=""
              width="100%"
              height="100%"
              id="player"
              src={url}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          {/* <div className="absolute left-0 bottom-0 -z-10 border-4 border-red-900">
            <img src='/images/help.svg' alt="Hero Pattern" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Help;
